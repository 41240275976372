import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppeazService } from '../tools/services/appeaz.service';
import { MessagingService } from '../tools/services/messaging/messaging.service';
import { UserService } from '../tools/services/user.service';
import { BaseAuthenticationGuard } from './guard/base-authentication-guard';

@Injectable()
export class AuthenticatedUserGuard extends BaseAuthenticationGuard implements CanActivate {

    constructor(protected appeazService: AppeazService,
        protected messagingService: MessagingService,
        protected userService: UserService,
        protected router: Router) {
        super(appeazService, messagingService, userService, router, ['Designer', 'Manager', 'Publisher']);
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.userService.getCurrentUser() && this.userService.isAdmin()) {
            return this.isAdminAuthorized(next, state);
        } else if (this.userService.getCurrentUser()) {
            return this.isUserAuthorized(next, state);
        } else {
            // The user is not logged. Redirecting to login page
            this.router.navigate(['/login']);
        }
        return false;
    }

    private isAdminAuthorized(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let canActivate = false;
        switch (activatedRoute.routeConfig.path) {
            case 'appeazs':
            case 'appeaz/:id':
            case 'help':
            case 'about':
            case 'profile':
                canActivate = true;
                break;
            case 'editor/:id':
                canActivate = false;
                break;
            default:
                // Unknown path, redirecting to myAppeaz page
                canActivate = false;
                this.router.navigate(['/appeazs']);
        }
        return canActivate;
    }

    private isUserAuthorized(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let canActivate = false;
        switch (activatedRoute.routeConfig.path) {
            case 'appeazs':
            case 'help':
            case 'about':
            case 'profile':
                canActivate = true;
                break;
            case 'editor/:id':
                // Checking that the user is registered in the appeaz
                return super.canActivate(activatedRoute, state);
            case 'appeaz/:id':
            default:
                // Unknown path, redirecting to myAppeaz page
                canActivate = false;
                this.router.navigate(['/appeazs']);
        }
        return canActivate;
    }

}
