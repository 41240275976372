<eazly-header i18n-headerText="@@CreateAccount" headerText="Create an account">
</eazly-header>

<div class="main container">
  <ng-container *ngTemplateOutlet="alertTemplate"></ng-container>
  <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !name.valid }">
      <label class="control-label">
        <span i18n="@@CreateAccountEmail">Email:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <input type="text" id="email" class="form-control" name="email" required [(ngModel)]="account.email"
        #email="ngModel">
      <div *ngIf="f.submitted && !email.valid" class="alert alert-danger" i18n="@@CreateAccountEmailRequired">
        The Email is required
      </div>
    </div>

    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !name.valid }">
      <label class="control-label">
        <span i18n="@@CreateAccountFirstName">First name:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <input type="text" id="fname" class="form-control" name="fname" required [(ngModel)]="account.first_name"
        #fname="ngModel">
      <div *ngIf="f.submitted && !fname.valid" class="alert alert-danger" i18n="@@CreateAccountFirstNameRequired">
        The first name is required
      </div>
    </div>

    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !name.valid }">
      <label class="control-label">
        <span i18n="@@CreateAccountName">Last name:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <input type="text" id="name" class="form-control" name="name" required [(ngModel)]="account.last_name"
        #name="ngModel">
      <div *ngIf="f.submitted && !name.valid" class="alert alert-danger" i18n="@@CreateAccountNameRequired">
        The last name is required
      </div>
    </div>

    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !name.valid }">
      <label class="control-label">
        <span i18n="@@CreateAccountPassword">Password:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <div class="ectzAlertInfo alert alert-info eazlyItemListInfo">
        <span i18n='@@accountInfoPasswordInstructions'>
          Please input a password matching the following conditions:
        </span>
        <ul class="">
          <li i18n='@@accountInfoPasswordInstructions8Char'>At least 8 characters</li>
          <li i18n='@@accountInfoPasswordInstructionsCharCase'>At least one uppercase and one lowercase</li>
          <li i18n='@@accountInfoPasswordInstructionsNumberChar'>At least one number</li>
          <li i18n='@@accountInfoPasswordInstructionsSpecialChar'>At least one special character (eg. &@.:,!?=+)</li>
        </ul>
      </div>
      <input type="Password" id="password" class="form-control" name="password" required [(ngModel)]="account.password"
        #password="ngModel">
      <div *ngIf="f.submitted && !password.valid" class="alert alert-danger" i18n="@@CreateAccountPasswordRequired">
        The password is required
      </div>
    </div>

    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !name.valid }">
      <label class="control-label">
        <span i18n="@@CreateAccountCPassword">Confirm Password:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <input type="Password" id="cpassword" class="form-control" name="cpassword" [(ngModel)]="model.cpassword" required
        #cpassword="ngModel">
      <div *ngIf="f.submitted && !cpassword.valid" class="alert alert-danger" i18n="@@CreateAccountCPasswordRequired">
        The confirmation of the password is required</div>
    </div>
    <div class="form-group row">
      <div class="col-4"></div>
      <button id="submit" type="submit" class="btn btn-primary btn-lg col-4" i18n="@@CreateAccountSubmit">
        <i class="fas fa-check"></i> Save</button>
      <div class="col-4"></div>
    </div>
  </form>
</div>
<ng-template #alertTemplate>
  <alert *ngFor="let alert of alerts" [type]="alert.type" [dismissible]="true" dismissOnTimeout="10000">
    <span *ngIf="alert.serverResponse === 'passwordsNotMatching'">
      <span id="alertPasswordNotMatch" i18n='@@AccountPasswordNotMatchFailure'>
        <strong>Password and password confirmation don't match !</strong>
      </span>
    </span>
    <span *ngIf="alert.serverResponse === 'com.eazly.backend.exceptions.PasswordTooWeakException'">
      <span id="alertPasswordTooWeak" i18n='@@PasswordTooWeakFailure'>
        <strong>The password provided is too weak ! It does not match with the expected format.</strong>
      </span>
    </span>
    <span *ngIf="alert.serverResponse === 'unknownError'">
      <span id="alertUnknownError" i18n='@@AlertUnknownError'>
        <strong>The server has encountered an error: {{alert.message}}</strong>
      </span>
    </span>
  </alert>
</ng-template>
