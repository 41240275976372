import { Asset } from '../models/asset';

export const assets: Asset[] =
    [

        {
            'category': 'Label',
            'name': 'Message avec texte riche',
            'id': 'RICH_TEXT_MESSAGE',
            'icon': 'assets/icons/rich_text_message.svg'
        },
        {
            'category': 'Label',
            'name': 'Message de succès',
            'id': 'SUCCESS_MESSAGE',
            'icon': 'assets/icons/success_message.svg'
        },
        {
            'category': 'Label',
            'name': 'Message d\'information',
            'id': 'INFORMATION_MESSAGE',
            'icon': 'assets/icons/info_message.svg'
        },
        {
            'category': 'Label',
            'name': 'Message d\'alerte',
            'id': 'WARNING_MESSAGE',
            'icon': 'assets/icons/warning_message.svg'
        },
        {
            'category': 'Label',
            'name': 'Message d\'erreur',
            'id': 'ERROR_MESSAGE',
            'icon': 'assets/icons/error_message.svg'
        },
        {
            'category': 'Label',
            'name': 'Titre 1',
            'id': 'HEADER_1',
            'icon': 'assets/icons/titre1.svg'
        },
        {
            'category': 'Label',
            'name': 'Titre 2',
            'id': 'HEADER_2',
            'icon': 'assets/icons/titre2.svg'
        },
        {
            'category': 'Label',
            'name': 'Titre 3',
            'id': 'HEADER_3',
            'icon': 'assets/icons/titre3.svg'
        },
        {
            'category': 'Label',
            'name': 'Titre 4',
            'id': 'HEADER_4',
            'icon': 'assets/icons/titre4.svg'
        },
        {
            'category': 'Label',
            'name': 'Titre 5',
            'id': 'HEADER_5',
            'icon': 'assets/icons/titre5.svg'
        },
        {
            'category': 'Label',
            'name': 'Titre 6',
            'id': 'HEADER_6',
            'icon': 'assets/icons/titre6.svg'
        },
        {
            'category': 'Input',
            'name': 'Champ de type texte',
            'id': 'TEXT_FIELD',
            'icon': 'assets/icons/champ_texte.svg'
        },
        {
            'category': 'Input',
            'name': 'Zone de texte',
            'id': 'TEXT_AREA',
            'icon': 'assets/icons/zone_de_texte.svg'
        },
        {
            'category': 'Input',
            'name': 'Champ de type nombre',
            'id': 'INTEGER',
            'icon': 'assets/icons/nombre.svg'
        },
        {
            'category': 'Input',
            'name': 'Champ de type date',
            'id': 'DATE',
            'icon': 'assets/icons/date.svg'
        },
        {
            'category': 'Choice',
            'name': 'Bouton radio',
            'id': 'RADIO_BUTTON',
            'icon': 'assets/icons/radio.svg'
        },
        {
            'category': 'Choice',
            'name': 'Liste de boutons radio verticale',
            'id': 'RADIO_LIST_VERTICAL',
            'icon': 'assets/icons/radio_list_vertical.svg'
        },
        {
            'category': 'Choice',
            'name': 'Liste de boutons radio horizontale',
            'id': 'RADIO_LIST_HORIZONTAL',
            'icon': 'assets/icons/radio_list_horizontal.svg'
        },
        {
            'category': 'Choice',
            'name': 'Case à cocher',
            'id': 'CHECK_BOX',
            'icon': 'assets/icons/checkbox.svg'
        },
        {
            'category': 'Choice',
            'name': 'Liste de valeur',
            'id': 'COMBO_BOX',
            'icon': 'assets/icons/combobox.svg'
        }
    ];
