
import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Appeaz } from '../../tools/models/appeaz';
import { AppeazService } from '../../tools/services/appeaz.service';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';
import { UserService } from '../../tools/services/user.service';


export const AUTH_GUARD_DEFAULT_ROLES: InjectionToken<string[]> = new InjectionToken<string[]>('');
/**
 * This class is responsible for checking the access to a page for a given user and a given AppeazId if the page is related to an appeaz.
 * When the page requested is about an appeaz, this class first ensures that the user is registered in the appeaz's user list.
 * If the user is registered, it compares the registered role in the appeaz for the user with the role requested by the guard overriding this class.
 *
 * This BaseClass has 3 overriding classes:
 *  - UserGuard is used to limit the access to the different pages to the following roles 'Designer', 'Manager', 'Publisher'
 *  - PublisherGuard is used to limit the access to the different pages to the following roles: 'Publisher', 'Manager'
 *  - ManagerGuard is used to limit the access to the different pages to the following role: 'Manager'
 *
 * These guards are used in the 'eazly.routing.ts' file.
 */
@Injectable()
export class BaseAuthenticationGuard implements CanActivate, OnDestroy {

    errorMessage: EazlyMessage;
    /**
     * The 'roles' array contains the different roles that are compatible to the matching guard.
     */
    private roles: string[] = [];

    constructor(protected appeazService: AppeazService,
        protected messagingService: MessagingService,
        protected userService: UserService,
        protected router: Router,
        @Inject(AUTH_GUARD_DEFAULT_ROLES) rolesToCheck: string[]
    ) {
        this.roles = rolesToCheck;
    }

    ngOnDestroy() {
        if (this.errorMessage) {
            this.messagingService.notifyError(this.errorMessage);
        }
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        let appeazs: Appeaz[];
        let toreturn: boolean;
        const id = +next.params.id;
        toreturn = false;
        return this.appeazService.getMyappeazs().pipe(
            map(
                data => {
                    appeazs = data;
                    appeazs.forEach(appeaz => {
                        if (appeaz.id === id) {
                            for (const role of this.roles) {
                                if (appeaz.role === role) {
                                    toreturn = true;
                                    return toreturn;
                                }
                            }
                        }
                        // The appeaz requested does not belong to the user, returning false
                        return false;
                    });
                    return toreturn;
                },
                error => {
                    this.handleError(error);
                }
            ));
    }

    private handleError(error: any) {
        if (error.status === 0) {
            this.errorMessage = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.HostUnreachable, error.status + ' ' + error.statusText);
            this.userService.logout();
            this.router.navigate(['/login']);
        }
        if (error.status === 401) {
            this.errorMessage = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
            this.userService.logout();
            this.router.navigate(['/login']);

        }
        if (error.status === 403) {
            this.errorMessage = new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.InsufficientRole, error.status + ' ' + error.statusText);
            this.router.navigate(['/appeazs']);
        }
    }
}
