<eazly-header i18n-manageMyAccount="@@ManageMyAccountTitle" manageMyAccount="Manage my account"></eazly-header>
<div class="main container">
  <div class="row">
    <div class="col-3">
      <ul class="nav nav-pills nav-fill nav-stacked navbar-nav">
        <li>
          <button id="#baccountInformation" class="active show btn  btn-block text-right"
            (click)="accountInformationClicked()" data-toggle="tab" href="#accountInformation">
            <span i18n="@@AccountInformation">
              Account information
            </span>
            <i *ngIf="accountInfoSelected" class="fas fa-angle-right"></i>
          </button>
        </li>
        <li>
          <button id="#buserInformation" class=" btn  btn-block text-right" (click)="userInformationClicked()"
            data-toggle="tab" href="#userInformation">
            <span i18n="@@UserInformation">User information</span>
            <i *ngIf="userInfoSelected" class="fas fa-angle-right"></i>
          </button>
        </li>
        <li>
          <button id="#buserSettings" class=" btn  btn-block text-right" (click)="userSettingsClicked()"
            data-toggle="tab" href="#userSettings">
            <span i18n="@@UserSettings">User settings</span>
            <i *ngIf="userSettingsSelected" class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content col-9">
      <div id="accountInformation" class="tab-pane fade active show">
        <span *ngIf="accountInfoSelected">
          <div class="mainContainer container-shadow">
            <eazly-account-information> Account information loading </eazly-account-information>
          </div>
        </span>
      </div>
      <div id="userInformation" class="tab-pane fade">
        <span *ngIf="userInfoSelected">
          <div class="mainContainer container-shadow">
            <eazly-user-information> User information loading </eazly-user-information>
          </div>
        </span>
      </div>
      <div id="userSettings" class="tab-pane fade">
        <span *ngIf="userSettingsSelected">
          <div class="mainContainer container-shadow">
            <eazly-user-settings> User settings loading </eazly-user-settings>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
