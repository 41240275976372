export class Browser {
    name: string;
    version: number;
}

export const browsers: Browser[] = [
    {
        name: 'chrome', version: 50
    }
];
