import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { DynamicModification } from '../../models/dynamic-modification';
import { DynamicModificationValueList } from '../../models/dynamic-modification-value';
import { LabelTranslation } from '../../models/label-translation';
import { Locale } from '../../models/locale';
import { LanguageService } from '../shared/language.service';

/**
 * Service for retrieving or setting a label from arrays of LabelTranslations
 */
@Injectable()
export class LabelTranslationService {

  constructor(private readonly logger: NGXLogger, private readonly languageService: LanguageService) { }

  /**
   * Returns the label for the requested locale from the given labelTranslations array or the defaultLabel
   * if no matching labelTranslation is found.
   *
   * @param locale the requested locale
   * @param labelTranslations array of labelTranslations in which the searching will be done
   * @param defaultLabel the label to return if no matching labelTranslation is found
   * @returns The label for the requested locale or the label of the field if no matching labelTranslation was found
   */
  public getLabelForLocaleAndCountryCode(locale: Locale, labelTranslations: LabelTranslation[], defaultLabel?: string): string {
    let labelToReturn = defaultLabel;
    this.logger.debug(`Searching label for [Locale = \'${locale.languageCode}\';`,
      `countryCode = \'${locale.countryCode}\'] in `, labelTranslations, ` (default return value: \'${defaultLabel}\')`);
    const labelTranslation: LabelTranslation = this.findLabelTranslation(locale, labelTranslations);
    if (labelTranslation && labelTranslation?.label) {
      this.logger.debug('Matching label translation found: ', labelTranslation);
      labelToReturn = labelTranslation.label;
    }
    this.logger.debug(`Returning label ${labelToReturn}`);
    return labelToReturn;
  }

  /**
   * Set a new label translation or update the existing label translation
   * for the given locale and label
   *
   * @param locale the locale of the label to update
   * @param label the new value of the label
   */
  public setLabelTranslation(locale: Locale, label: string, labelTranslations: LabelTranslation[]): void {

    const existingLabelTranslation: LabelTranslation = this.findLabelTranslation(locale, labelTranslations);
    if (existingLabelTranslation) {
      existingLabelTranslation.label = label;
    } else {
      const labelTranslation: LabelTranslation = new LabelTranslation(locale, label);
      labelTranslations.push(labelTranslation);
    }
  }

  /**
   * Find the LabelTranslation from the given parameter "labelTranslations" whose locale is matching the parameter "locale".
   * If no matching LabelTranslation is found, "undefined" is returned
   *
   * @param locale The requested locale
   * @param labelTranslations The array of labelTranslations to search
   * @returns The matching labelTranslation or undefined if no matching labelTranslation was found
   */
  public findLabelTranslation(locale: Locale, labelTranslations: LabelTranslation[]): LabelTranslation {
    this.logger.debug(`Searching label translation for [Locale = \'${locale.languageCode}\'; countryCode = \'${locale.countryCode}\'] in `, labelTranslations);
    let labelTranslationToReturn: LabelTranslation;
    if (labelTranslations?.length > 0) {
      const filteredLabelTranslations: LabelTranslation[] = labelTranslations
        .filter(element => element.locale === locale.languageCode && element.countryCode === locale.countryCode);
      if (filteredLabelTranslations.length > 0) {
        const matchingLabelTranslation: LabelTranslation = filteredLabelTranslations[0];
        this.logger.debug('Matching label translation found: ', matchingLabelTranslation);
        labelTranslationToReturn = matchingLabelTranslation;
      }
    }
    this.logger.debug('Returning ', labelTranslationToReturn);
    return labelTranslationToReturn;
  }

  public getDMLabelForTitle(dynamicModification: DynamicModification, isI18N: boolean, locale?: Locale): string {
    let labelToReturn: string = dynamicModification.modificationTarget.label;
    if (isI18N) {
      labelToReturn = this.getLabelForLocaleAndCountryCode(
        locale,
        dynamicModification.modificationTarget.labelTranslations,
        dynamicModification.modificationTarget.label);
    }
    return labelToReturn;
  }

  /**
   * Returns a map of Locale and LabelTranslations for the label of the dynamicModification whose translation is missing. <br>
   * The keys of the map are the Locale whose translation is missing <br>
   * The values of the map are the LabelTranslation for the Locale. <br>
   * An empty string as the label is considered as a missing locale.
   *
   * @param dynamicModification The dynamic modification to analyze
   * @returns a Map of Locale whose translation is missing for the label of the dynamic modification
   */
  public getLocalesWithoutTranslationForLabel(dynamicModification: DynamicModification): Map<Locale, LabelTranslation> {
    const results: Map<Locale, LabelTranslation> = new Map();
    const labelTranslations: LabelTranslation[] = dynamicModification?.modificationTarget?.labelTranslations;
    const sortedLabelTranslations: Map<string, Map<string, LabelTranslation>> = this.sortLabelTranslationsByLocale(labelTranslations);

    let allLabelsAreEmpty = true;
    // Building the list to return based on the locales supported by the Appeaz and the LabelTranslations of the
    // dynamic modification
    for (const locale of this.languageService.getSupportedLocales()) {
      const labelTranslationForSupportedLocale: LabelTranslation = sortedLabelTranslations.get(locale.languageCode)?.get(locale.countryCode);
      const translatedLabel: string = labelTranslationForSupportedLocale?.label;
      if (!translatedLabel) {
        results.set(locale, labelTranslationForSupportedLocale);
      }
      if (translatedLabel !== '') {
        allLabelsAreEmpty = false;
      }
    }
    if (allLabelsAreEmpty) {
      // EAZ-1097: If all labels are empty, we consider that there is no missing translation
      results.clear();
    }
    this.logger.debug(`The dynamic modification ${dynamicModification?.id} has missing translation in the following locales:`, results);
    return results;
  }

  /**
   * Returns a map of Locale and DynamicModificationValueList for the values of the list of the dynamicModification whose translation is missing. <br>
   * The keys of the map are the Locale whose translation is missing <br>
   * The values of the map are the DynamicModificationValueList for the Locale. <br>
   * An empty string as the label is considered as a missing locale.
   *
   * @param dynamicModification The dynamic modification to analyze
   * @returns a Map of Locale whose translation is missing for the values of list of the dynamic modification
   */
  public getLocalesWithoutTranslationForValues(dynamicModification: DynamicModification): Map<Locale, DynamicModificationValueList[]> {
    const values: DynamicModificationValueList[] = dynamicModification?.modificationTarget?.values;
    const results: Map<Locale, DynamicModificationValueList[]> = new Map();
    if (values) {
      for (const value of values) {
        const sortedLabelTranslations: Map<string, Map<string, LabelTranslation>> = this.sortLabelTranslationsByLocale(value.labelTranslations);
        for (const locale of this.languageService.getSupportedLocales()) {
          const translatedLabel: string = sortedLabelTranslations.get(locale.languageCode)?.get(locale.countryCode)?.label;
          if (!translatedLabel) {
            let valuesWithMissingTranslationForLocale: DynamicModificationValueList[];
            if (results.has(locale)) {
              valuesWithMissingTranslationForLocale = results.get(locale);
            } else {
              valuesWithMissingTranslationForLocale = [];
            }
            valuesWithMissingTranslationForLocale.push(value);
            results.set(locale, valuesWithMissingTranslationForLocale);
          }
        }
      }
      this.logger.debug(`The dynamic modification ${dynamicModification?.id} has missing translation in the following locales:`, results);
    } else {
      this.logger.debug(`The dynamic modification ${dynamicModification?.id} has no values.`);
    }
    return results;
  }

  private sortLabelTranslationsByLocale(labelTranslations: LabelTranslation[]) {
    const sortedLabelTranslations: Map<string, Map<string, LabelTranslation>> = new Map();

    // Sorting the labelTranslations of the dynamicModification
    if (labelTranslations) {
      for (const labelTranslation of labelTranslations) {
        let localeEntry: Map<string, LabelTranslation> = sortedLabelTranslations.get(labelTranslation.locale);
        if (!localeEntry) {
          localeEntry = new Map();
          sortedLabelTranslations.set(labelTranslation.locale, localeEntry);
        }
        localeEntry.set(labelTranslation.countryCode, labelTranslation);
      }
    }
    return sortedLabelTranslations;
  }
}
