import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../../tools/models/user';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';
import { UserService } from '../../tools/services/user.service';
import { passwordFieldsMatchValidator } from '../validators/PasswordFieldsMatchValidator';

@Component({
  selector: 'eazly-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss']
})
export class AccountInformationComponent implements OnInit, OnDestroy {

  // Booleans for form validation
  passwordsMismatch: boolean;
  passwordsTooWeak: boolean;
  passwordNotProvided: boolean;
  passwordConfirmNotProvided: boolean;

  isEditingAccountInfo: boolean;
  user: User;
  alerts: any = [];

  model: any = {};
  passwordFormGroup: FormGroup;
  errorReport: EazlyMessage;

  constructor(private userService: UserService,
    private readonly messagingService: MessagingService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router) { }

  ngOnInit() {
    this.user = this.userService.getCurrentUser();
    this.isEditingAccountInfo = false;
    this.passwordsMismatch = false;
    this.passwordsTooWeak = false;
    this.passwordNotProvided = false;
    this.passwordConfirmNotProvided = false;

    this.passwordFormGroup = this.formBuilder.group({
      'inputPassword': [this.model.password, [
        Validators.required]
      ],
      'inputPasswordConfirm': [this.model.passwordConfirm, [
        Validators.required]
      ],
    }, { validators: passwordFieldsMatchValidator });
  }

  ngOnDestroy() {
    if (this.errorReport) {
      this.messagingService.notifyError(this.errorReport);
    }
  }

  onSubmit() {
    if (this.passwordFormGroup.valid) {
      this.updateFormValidationBooleans();
      this.userService.updatePassword(this.passwordFormGroup.get('inputPassword').value).subscribe(data => {
        this.setEditAccountInfoVisibility(false);
        // if success, push success alert
        const alert = {
          type: 'success',
          serverResponse: 'success'
        };
        this.alerts.push(alert);
      },
        httpErrorResponse => {
          switch (httpErrorResponse.status) {
            case 400:
              this.passwordsTooWeak = true;
              break;
            case 401:
            case 403:
              this.userService.logout();
              this.errorReport = new EazlyMessage(
                AlertType.danger,
                'UserService',
                MessageCause.SessionExpired,
                `${httpErrorResponse.status} ${httpErrorResponse.statusText}`);
              this.router.navigate(['/login']);
              break;
            default:
              const unknownErrorAlert = {
                type: 'danger',
                serverResponse: 'unknownError',
                message: httpErrorResponse.error.message
              };
              this.alerts.push(unknownErrorAlert);
              break;
          }
        });
    } else {
      // Updating boolean for the display of error messages
      this.updateFormValidationBooleans();
    }
  }

  private setEditAccountInfoVisibility(visible: boolean): void {
    this.isEditingAccountInfo = visible;
    if (!visible) {
      // Resetting fields and boolean for the form validation
      this.passwordFormGroup.reset();
      this.passwordsMismatch = false;
      this.passwordsTooWeak = false;
      this.passwordNotProvided = false;
      this.passwordConfirmNotProvided = false;
    }
  }

  private updateFormValidationBooleans(): void {
    // The other errors are handled by the FrontEnd, thus their values are updated
    this.passwordsMismatch = this.passwordFormGroup?.errors && this.passwordFormGroup.errors['passwordFieldsMatchError'];
    this.passwordNotProvided = this.passwordFormGroup.controls['inputPassword'].hasError('required');
    this.passwordConfirmNotProvided = this.passwordFormGroup.controls['inputPasswordConfirm'].hasError('required');
    // Reseting passwordTooWeak since its value is handled by the server response.
    this.passwordsTooWeak = false;

  }
}
