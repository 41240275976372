import { Component, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ModalWindowComponent } from '../../../tools/modal-window/modal-window.component';
import { AlertType, EazlyMessage, MessageCause } from '../../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../../tools/services/messaging/messaging.service';
import { UserService } from '../../../tools/services/user.service';

@Component({
  selector: 'eazly-reset-password-window',
  templateUrl: './reset-password-window.component.html',
  styleUrls: ['./reset-password-window.component.scss']
})
export class ResetPasswordWindowComponent implements OnInit {

  @ViewChild(ModalWindowComponent, { static: true })
  private readonly modalWindowComponent: ModalWindowComponent;

  emailToReset: string;
  alerts: any = [];

  constructor(private readonly userService: UserService, private readonly messagingService: MessagingService, private logger: NGXLogger) { }

  ngOnInit(): void {
  }

  public showModal() {
    this.emailToReset = '';
    this.modalWindowComponent.show();
  }

  hideModal() {
    this.modalWindowComponent.hide();
  }

  requestResetPasswordEmail(email: string) {
    if (email !== undefined && email !== '') {
      this.logger.debug('[Send recovery email to reset user password] Preparing the password reset email send to user (whose mail: "' + email + '")');
      this.messagingService.notifyError(new EazlyMessage(AlertType.success, 'BackendService', MessageCause.EmailSentSuccessful, ''));
      this.userService.sendEmailToResetPassword(email)
        .subscribe(
          data => {
            this.logger.debug('[Send recovery email to reset user password] Password reset email sent to user (whose mail: "' + email + '")');
          },
          httpErrorResponse => {
            this.logger.debug('[Send recovery email to reset user password] Error occurred from server side');
            this.messagingService.notifyError(new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.InternalError, ''));
          });
    }
  }
}
