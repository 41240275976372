import { DataType } from '../../data-type';
import { UnaryConditionType } from './unary-visibility-condition-type';
import { VisibilityCondition } from './visibility-condition';

export class UnaryVisibilityCondition extends VisibilityCondition {
    private operator: UnaryConditionType;

    constructor(id: string, dataType: DataType, operatorToSet: UnaryConditionType) {
        super(id, dataType);
        this.operator = operatorToSet;
    }

    getConditionType(): UnaryConditionType {
        return this.operator;
    }
}
