import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  envName: 'prod',
  ngxLogLevel: NgxLoggerLevel.WARN,
  protocol: window.location.protocol,
  host: window.location.host,
  hostname: window.location.hostname,
  apiV1Path: 'api/v1',
  eazlyVersion: '2.5.0'
};
