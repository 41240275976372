import { DataType } from '../../data-type';

export abstract class VisibilityCondition {
    // Id of the field to check
    modificationId: string;
    // Type of the field to check
    dataType: DataType;

    constructor(id: string, dataType: DataType) {
        this.modificationId = id;
        this.dataType = dataType;
    }
}
