<!--The content below is only a placeholder and can be replaced.-->

<eazly-auth-header></eazly-auth-header>

<div class="main container">
  <h1 i18n="@@homeConnection">
    Connection
  </h1>
  <eazly-alert-manager></eazly-alert-manager>
  <eazly-authentication-form></eazly-authentication-form>
</div>
