import { Pipe, PipeTransform } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { DynamicModificationValueList } from '../models/dynamic-modification-value';
import { LabelTranslation } from '../models/label-translation';
import { Locale } from '../models/locale';
import { LabelTranslationService } from '../services/i18n/label-translation.service';
import { LanguageService } from '../services/shared/language.service';

/**
 * Pipe for retrieving the translated label of the value of a list value
 */
@Pipe({ name: 'listValueLabelTranslationPipe' })
export class ListValueLabelTranslationPipe implements PipeTransform {

    constructor(private readonly logger: NGXLogger,
        private readonly labelTranslationService: LabelTranslationService,
        private readonly languageService: LanguageService
    ) { }

    public transform(value: DynamicModificationValueList): string {
        let transformedValue = value.value;
        const selectedLocale: Locale = this.languageService.getSelectedLocale();
        if (selectedLocale) {
            this.logger.debug('ListValueLabelTranslationPipe: Transforming', value,
                `for the locale \'${selectedLocale?.languageCode}\' and country code \'${selectedLocale?.countryCode}\'`);
            const labelTranslation: LabelTranslation = this.labelTranslationService.findLabelTranslation(
                selectedLocale, value.labelTranslations);
            if (labelTranslation && labelTranslation.label.length > 0) {
                transformedValue = labelTranslation.label;
            }
        } else {
            this.logger.debug('Selected locale is undefined.');
        }
        this.logger.debug(`ListValueLabelTranslationPipe: Transformed value = ${transformedValue}`);
        return transformedValue;
    }
}
