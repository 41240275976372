import { VisibilityCriteria } from './visibility-criteria/visibility-criteria';



export class DynamicModificationProperties {
    elementType: string;
    editable: string;
    required: string;
    textMaxLength: string;
    regExpPattern: string;
    defaultValue: string;
    visibilityCriteria: VisibilityCriteria;
}
