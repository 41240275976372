<eazly-loading [isRunning]="isLoading"></eazly-loading>

<div class="container">
  <ng-container *ngTemplateOutlet="alertTemplate"></ng-container>
  <eazly-alert-manager></eazly-alert-manager>
  <div class="card container-shadow">
    <div class="card-header">
      Conception
    </div>
    <div class="card-body">

      <p class="card-text title-text" i18n="@@PublishActiveVersion">Active version: </p>
      <div *ngIf="activeVersion">
        <p class="card-text"> {{activeVersion.versionName}}</p>
      </div>
      <p class="card-text title-text" i18n="@@PublishVersionDate">Publish date: </p>
      <div *ngIf="activeVersion">
        <p class="card-text">{{ activeVersion.publicationDate | date:'long' }} par <span
            [ngStyle]="{'font-style': 'italic','font-weight': 'bold'}">{{activeVersion.publisher}}</span></p>
      </div>
      <p class="card-text title-text" i18n="@@PublishEditionVersion">In edition version: </p>
      <div *ngIf="draftVersion">
        <p class="card-text">{{draftVersion.timeStamp| date:'long'}}</p>
      </div>
    </div>
  </div>

  <a type="button" id="publishButton" i18n="@@PublishSubmit" class="btn btn-primary col-sm-4 btn-lg"
    (click)="doPublishAPI()" tooltip="Publish the Appeaz" i18n-tooltip="@@MyAppeazsPublishButton" placement="right">
    <i class="fas fa-cloud-upload-alt"></i> Publish
  </a>
</div>

<ng-template #alertTemplate>

  <alert *ngFor="let alert of alerts" [type]="alert.type" [dismissible]="true" dismissOnTimeout="10000">

    <span *ngIf="alert.serverResponse ===  'publish success'">
      <span id="alertPublishSuccess" i18n='@@alertPublishSuccess'>
        <strong>Publication done successfuly!</strong>
      </span>
    </span>

    <span *ngIf="alert.serverResponse ===  'no publish'">
      <span id="alertNoPublish" i18n='@@alertNoPublish'>
        <strong>No publication done. No modification was found.</strong>
      </span>
    </span>

  </alert>

  <alert *ngFor="let alert of permanentalerts" [type]="alert.type" [dismissible]="false">

    <span *ngIf="alert.serverResponse === 'no draft version'">
      <span id="alertNoDraftVersion" i18n='@@alertNoDraftVersionPublish'>
        <strong>No draft version!</strong>
      </span>
    </span>

  </alert>
</ng-template>
