<!--
  This generic component can be customized by any other component.
  To use this modal window, the component that need to instantiate the modal window must feature 
  three div with the following attribute: {modalWindowHeader, modalWindowBody, modalWindowFooter}.
  These divs will be loaded in the corresponding <ng-content>
-->
<ng-template class="modal" #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title modal-title-style" id="modal-window-title">
      <ng-content select="[modalWindowHeader]"></ng-content>
    </h4>
  </div>
  <div class="modal-body">
    <div class="container modal-body-container">
      <ng-content select="[modalWindowBody]"></ng-content>
    </div>
  </div>
  <div class="modal-footer">
    <div class="modal-footer-content">
      <ng-content select="[modalWindowFooter]"></ng-content>
    </div>
  </div>
</ng-template>
