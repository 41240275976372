import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Appeaz } from '../../tools/models/appeaz';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';
import { ImportWindowComponent } from '../modal-windows/import-window/import-window.component';

@Component({
  selector: 'eazly-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss']
})
export class ImportExportComponent {
  @Input() myappeaz: Appeaz;
  @Input() exportBtnActive = false;

  @Output() exportButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(ImportWindowComponent, { static: true })
  importWindowComponent: ImportWindowComponent;

  reader: FileReader;

  private importedFile: File;

  constructor(private readonly loggerService: NGXLogger,
    private readonly messagingService: MessagingService) {
    this.importProcess();
  }

  public importProcess() {
    this.importedFile = undefined;
    this.reader = new FileReader();
    this.reader.onload = (onLoadEvent: ProgressEvent<FileReader>) => {
      const fileContent = onLoadEvent.target.result as string;
      const errorReport = new EazlyMessage(AlertType.danger,
        'ImportExportService', MessageCause.ImportInvalidFileMessage, '');
      // Scenarios processed by try/catch satisfy the various rules guaranteeing that the file is a json file.
      try {
        const importedFileContentAsJson = JSON.parse(fileContent);
        // Check if file elements are stepId and modifications
        if (importedFileContentAsJson.every(item =>
          item.hasOwnProperty('stepId') &&
          Array.isArray(item.modifications)
        )
        ) {
          this.loggerService.debug('Imported file is valid. Showing modal.');
          this.importWindowComponent.showModal(this.importedFile, importedFileContentAsJson);
        } else {
          this.messagingService.notifyError(errorReport);
          this.loggerService.error('The JSON file does not respect the expected file format: only stepId and modifications elements are required ');
        }
      } catch (error) {
        this.messagingService.notifyError(errorReport);
        this.loggerService.error('JSON file not in expected format.');
      }
    };
  }

  public handleExportButtonClicked(): void {
    this.exportBtnActive = !this.exportBtnActive;
    this.exportButtonClicked.emit();
  }

  public handleImportButtonClicked(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files[0];
    this.loggerService.debug('Handling import button clicked with the file ', file);
    if (file.type === 'application/json') {
      this.importedFile = file;
      this.reader.readAsText(file);
    }
    else {
      const errorReport = new EazlyMessage(AlertType.danger,
        'ImportExportService', MessageCause.ImportInvalidFileMessage, '');
      this.messagingService.notifyError(errorReport);
      this.loggerService.error('The file is not in JSON format');
    }
    // Resetting the fileInput value to allow the selection of the same file many times.
    fileInput.value = '';
  }
}
