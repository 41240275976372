export class User {
    id: number;
    last_name: string;
    first_name: string;
    username: string;
    password: string;
    email: string;
    eazly_role: string;
    token: number;
    actif: boolean;
    expert: boolean;

}
