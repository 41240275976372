import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppeazListComponent } from './app/appeaz-list/appeaz-list.component';
import { AppeazComponent } from './app/appeaz/appeaz.component';
import { AdminGuard } from './app/authentication/authenticated-admin.gard';
import { AuthenticatedManagerGuard } from './app/authentication/authenticated-manager.guard';
import { AuthenticatedNewUserGuard } from './app/authentication/authenticated-new-user.guard';
import { AuthenticatedPublisherGuard } from './app/authentication/authenticated-publisher.guard';
import { AuthenticatedUserGuard } from './app/authentication/authenticated-user.guard';
import { AuthenticationComponent } from './app/authentication/authentication.component';
import { AccountComponent } from './app/create-account/account.component';
import { DashboardComponent } from './app/dashboard/dashboard.component';
import { EditorComponent } from './app/editor/editor.component';
import { AboutComponent } from './app/other/about/about.component';
import { HelpComponent } from './app/other/help/help.component';
import { PublishComponent } from './app/publish/publish.component';
import { ResetPasswordComponent } from './app/reset-password/reset-password.component';
import { UserProfileComponent } from './app/user-profile/user-profile.component';
import { UsersComponent } from './app/users/users.component';


const routes: Routes = [
  { path: '', redirectTo: '/appeazs', pathMatch: 'full' },
  { path: 'login', component: AuthenticationComponent },
  { path: 'editor/:id', component: EditorComponent, canActivate: [AuthenticatedUserGuard] },
  { path: 'appeazs', component: AppeazListComponent, canActivate: [AuthenticatedUserGuard] },
  { path: 'appeaz/:id', component: AppeazComponent, canActivate: [AuthenticatedUserGuard] },
  { path: 'users/:id', component: UsersComponent, canActivate: [AuthenticatedManagerGuard] },
  { path: 'publish/:id', component: PublishComponent, canActivate: [AuthenticatedPublisherGuard] },
  { path: 'help', component: HelpComponent, canActivate: [AuthenticatedUserGuard] },
  { path: 'about', component: AboutComponent, canActivate: [AuthenticatedUserGuard] },
  { path: 'profile', component: UserProfileComponent, canActivate: [AuthenticatedUserGuard] },
  { path: 'account/activate/:token', component: AccountComponent, canActivate: [AuthenticatedNewUserGuard] },
  { path: 'account/resetpassword/:token', component: ResetPasswordComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AdminGuard] },
  { path: '**', redirectTo: '/appeazs', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
