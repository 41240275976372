import { DataDescriptor } from './data-descriptor';
import { DynamicModificationProperties } from './dynamic-modification-properties';
import { DynamicModificationValueList } from './dynamic-modification-value';
import { LabelTranslation } from './label-translation';


export class DynamicModificationTarget {

    label: string;
    infoText: string;
    properties: DynamicModificationProperties;
    values: DynamicModificationValueList[];
    dataDescriptor: DataDescriptor;
    labelTranslations: LabelTranslation[];
}
