import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppRoutingModule } from '../../eazly.routing';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../other/footer/footer.module';
import { LoadingModule } from '../other/loading/loading.module';
import { AlertManagerModule } from '../tools/alert-manager/alert-manager.module';
import { AppeazListItemComponent } from './appeaz-list-item/appeaz-list-item.component';
import { AppeazListComponent } from './appeaz-list.component';
@NgModule({
  declarations: [
    AppeazListComponent,
    AppeazListItemComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    RouterModule,
    LoadingModule,
    AlertManagerModule,
    AlertModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot()
  ],
  bootstrap: [AppeazListComponent]
})
export class AppeazListModule { }
