import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppRoutingModule } from '../../eazly.routing';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../other/footer/footer.module';
import { LoadingModule } from '../other/loading/loading.module';
import { AlertManagerModule } from '../tools/alert-manager/alert-manager.module';
import { ModalWindowModule } from '../tools/modal-window/modal-window.module';
import { ListValueLabelTranslationPipe } from '../tools/pipes/label-translation-pipe';
import { DomHtmlService } from '../tools/services/dom-html.service';
import { LabelTranslationService } from '../tools/services/i18n/label-translation.service';
import { LanguageSelectorService } from '../tools/services/language-selector.service';
import { LanguageService } from '../tools/services/shared/language.service';
import { UndoRedoManagerService } from '../tools/undo-redo-manager/undo-redo-manager.service';
import { EditorComponent } from './editor.component';
import { ImportExportComponent } from './import-export/import-export.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { DynamicFieldConfigurationWindowComponent } from './modal-windows/dynamic-field-configuration-window/dynamic-field-configuration-window.component';
import { ImportWindowComponent } from './modal-windows/import-window/import-window.component';
import { ListValueEditorWindowComponent } from './modal-windows/list-value-editor-window/list-value-editor-window.component';
import { OperandsTranslationPipe } from './modal-windows/visibility-window/operands-translation.pipe';
import { VisibilityWindowComponent } from './modal-windows/visibility-window/visibility-window.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button/toggle-button.component';
import { UndoRedoComponent } from './undo-redo/undo-redo.component';
import { ViewerComponent } from './viewer/viewer.component';

@NgModule({
  declarations: [
    DynamicFieldConfigurationWindowComponent,
    EditorComponent,
    ListValueEditorWindowComponent,
    NavigatorComponent,
    OperandsTranslationPipe,
    UndoRedoComponent,
    ViewerComponent,
    VisibilityWindowComponent,
    ToggleButtonComponent,
    ImportExportComponent,
    ImportWindowComponent,
    LanguageSelectorComponent,
    ListValueLabelTranslationPipe
  ],
  imports: [
    AlertManagerModule,
    AlertModule,
    AppRoutingModule,
    CommonModule,
    DragDropModule,
    FooterModule,
    FormsModule,
    HeaderModule,
    LoadingModule,
    ModalWindowModule,
    ReactiveFormsModule,
    TooltipModule.forRoot()
  ],
  providers: [DomHtmlService,
    UndoRedoManagerService,
    LanguageService,
    LanguageSelectorService,
    ListValueLabelTranslationPipe,
    LabelTranslationService],
  bootstrap: [EditorComponent]
})
export class EditorModule { }
