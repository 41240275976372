import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { BinaryConditionType } from '../../../tools/models/visibility-criteria/conditions/binary-visibility-condition-type';
import { UnaryConditionType } from '../../../tools/models/visibility-criteria/conditions/unary-visibility-condition-type';

/**
 * This class is used to translate the operators of the VisibilityModalWindow defined in the following classes: UnaryConditionType, BinaryConditionType.
 * It allows to define custom labels that are independent from the names of the enum.
 * This class is used as a Pipe (from the Angular notation).
 * @obensoussia Explain why we need to use this class instead of using the other method of translation
 */
@Pipe({
  name: 'operandsTranslation'
})
export class OperandsTranslationPipe implements PipeTransform {

  // Translation map: group the different translations maps depending on the locale
  // Key = locale
  // Value = Map whose key are the values of the enum to translate and values are the translation in the locale
  private translationsMap: Map<string, Map<string, string>>;

  constructor(@Inject(LOCALE_ID) protected localeId: string) {
    this.translationsMap = new Map();
    this.translationsMap.set('fr', this.buildFrenchTranslationMap());
    this.translationsMap.set('en-US', this.buildEnglishTranslationMap());
  }

  /**
   * This method override the transform method from the PipeTransform interface.
   * It translate the value given in the parameter to the language of the localeId
   * @param value value to translate in the localeid
   * @returns valueToReturn = translation of the value in the localeId. If no translation has been found, returns the value given in parameter
   */
  transform(value: any): any {
    let valueToReturn: string = value;
    if (this.translationsMap.has(this.localeId)) {
      const localeTranslationMap = this.translationsMap.get(this.localeId);
      if (localeTranslationMap.has(value)) {
        valueToReturn = localeTranslationMap.get(value);
      }
    }
    return valueToReturn;
  }

  /**
   * This method initialize the map with the french translation
   */
  private buildFrenchTranslationMap(): Map<string, string> {
    const frenchTranslationMap: Map<string, string> = new Map();
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.MATCHES], 'Contient');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.NOT_CONTAINS], 'Ne contient pas');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_EQUAL], 'Est égal à');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_NOT_EQUAL], 'Est différent de');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_LESS_THAN], 'Est inférieur à');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_LESS_OR_EQUAL_THAN], 'Est inférieur ou égal à ');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_GREATER_THAN], 'Est supérieur à');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_GREATER_OR_EQUAL_THAN], 'Est supérieur ou égal à');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.STARTS_WITH], 'Commence par');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.ENDS_WITH], 'Se termine par');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.LESS_THAN_DATE], 'Est avant');
    frenchTranslationMap.set(BinaryConditionType[BinaryConditionType.GREATER_THAN_DATE], 'Est après');
    frenchTranslationMap.set(UnaryConditionType[UnaryConditionType.IS_NOT_NULL], 'Est renseigné');
    frenchTranslationMap.set(UnaryConditionType[UnaryConditionType.IS_NULL], 'N’est pas renseigné');
    frenchTranslationMap.set(UnaryConditionType[UnaryConditionType.IS_TRUE], 'Est vrai');
    frenchTranslationMap.set(UnaryConditionType[UnaryConditionType.IS_FALSE], 'Est faux');
    return frenchTranslationMap;
  }

  /**
   * This method initialize the map with the french translation
   */
  private buildEnglishTranslationMap(): Map<string, string> {
    const englishTranslationMap: Map<string, string> = new Map();
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.MATCHES], 'Contains');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.NOT_CONTAINS], 'Does not contain');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_EQUAL], 'Is equal to');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_NOT_EQUAL], 'Is different from');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_LESS_THAN], 'Is less than');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_LESS_OR_EQUAL_THAN], 'Is less than or equal to');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_GREATER_THAN], 'Is greater than');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.IS_GREATER_OR_EQUAL_THAN], 'Is greater than or equal to');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.STARTS_WITH], 'Start with');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.ENDS_WITH], 'End with');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.LESS_THAN_DATE], 'Is before');
    englishTranslationMap.set(BinaryConditionType[BinaryConditionType.GREATER_THAN_DATE], 'Is after');
    englishTranslationMap.set(UnaryConditionType[UnaryConditionType.IS_NOT_NULL], 'Is filled');
    englishTranslationMap.set(UnaryConditionType[UnaryConditionType.IS_NULL], 'Is not filled');
    englishTranslationMap.set(UnaryConditionType[UnaryConditionType.IS_TRUE], 'Is true');
    englishTranslationMap.set(UnaryConditionType[UnaryConditionType.IS_FALSE], 'Is false');
    return englishTranslationMap;
  }
}
