import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EazlyMessage } from './eazly-message';

@Injectable()
export class MessagingService {

    // Behavior Subject always return the last value on subscription,
    // so a subscription after the value was set can be retrieved
    // See https://stackoverflow.com/questions/39494058/behaviorsubject-vs-observable
    protected updateSubject: BehaviorSubject<EazlyMessage> = new BehaviorSubject<EazlyMessage>(null);
    /* eslint-disable @typescript-eslint/member-ordering */
    update$: Observable<EazlyMessage> = this.updateSubject.asObservable();
    /* eslint-enable @typescript-eslint/member-ordering */

    constructor(protected angularZone: NgZone) { }

    notifyError(error: EazlyMessage) {
        // Forcing emitting the event in the AngularZone in order to notify the AlertManager for a UI refresh
        this.angularZone.run(() => {
            this.updateSubject.next(error);
        });

    }

}
