<ng-container *ngTemplateOutlet="alertTemplate"></ng-container>

<div class="row">
  <label class="control-label col-4 text-right">
    <span i18n="@@accountInfoLogin">Login:</span>
  </label>
  <label class="col-8 text-left">{{user.username}}</label>
</div>
<div *ngIf="!isEditingAccountInfo; else editAccountInfo" class="row">
  <div class="col-12">
    <button id="displayEditAccountInfo" (click)="setEditAccountInfoVisibility(true)" class="btn ">
      <i class="fas fa-edit"></i>
      <span i18n="@@accountInfoEditPasswordButton">
        Edit your password</span>
    </button>
  </div>
</div>

<ng-template #editAccountInfo>
  <h3 i18n='@@accountInfoPasswordUpdateTitle'>
    Password update
  </h3>
  <div class="ectzAlertInfo alert alert-info eazlyItemListInfo">
    <span i18n='@@accountInfoPasswordInstructions'>
      Please input a password matching the following conditions:
    </span>
    <ul class="">
      <li i18n='@@accountInfoPasswordInstructions8Char'>At least 8 characters</li>
      <li i18n='@@accountInfoPasswordInstructionsCharCase'>At least one uppercase and one lowercase</li>
      <li i18n='@@accountInfoPasswordInstructionsNumberChar'>At least one number</li>
      <li i18n='@@accountInfoPasswordInstructionsSpecialChar'>At least one special character (eg. &@.:,!?=+)</li>
    </ul>
  </div>
  <form id="passwordForm" (ngSubmit)="onSubmit()" #f="ngForm" [formGroup]="passwordFormGroup">
    <span *ngIf="passwordsMismatch">
      <div id="alertPasswordNotMatch" i18n='@@AccountInfoUpdatePasswordNotMatchFailure' class="alert alert-danger">
        Password and password confirmation don't match !
      </div>
    </span>
    <span *ngIf="passwordsTooWeak">
      <div id="alertPasswordTooWeak" i18n='@@PasswordTooWeakFailure' class="alert alert-danger">
        The password provided is too weak ! It does not match with the expected format.
      </div>
    </span>
    <div class="form-group row">
      <div class="col-4 text-right">
        <label class="control-label">
          <span i18n="@@accountInfoPassword">Password:</span>
          <i class="required-info" aria-hidden="true"></i>
        </label>
      </div>
      <div class="col-8 text-left">
        <input type="password" id="passwordInput" class="form-control" name="passwordInput"
          formControlName="inputPassword" required="true">
        <div *ngIf="passwordNotProvided" class="alert alert-danger" i18n="@@accountInfoPasswordInputRequired">
          Password is required.
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4 text-right">
        <label class="control-label">
          <span i18n="@@accountInfoConfirmPassword">Confirm Password:</span>
          <i class="required-info" aria-hidden="true"></i>
        </label>
      </div>
      <div class="col-8 text-left">
        <input type="password" id="passwordConfirmInput" class="form-control" name="passwordConfirmInput"
          formControlName="inputPasswordConfirm" required>
        <div *ngIf="passwordConfirmNotProvided" class="alert alert-danger" i18n="@@accountInfoPasswordInputRequired">
          Confirm Password is required.
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 container">
        <button id="hideEditAccountInfo" type="button" (click)="setEditAccountInfoVisibility(false)" class="btn">
          <i class="fas fa-long-arrow-alt-left" aria-hidden="true"></i>
          <span i18n="@@accountInfoCancelEditPasswordButton">
            Cancel
          </span>
        </button>
        <button id="submit" type="submit" class="btn ">
          <i class="fas fa-check" aria-hidden="true"></i>
          <span i18n="@@accountInfoSubmit">Submit</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #alertTemplate>
  <alert *ngFor="let alert of alerts" [type]="alert.type" [dismissible]="true" dismissOnTimeout="10000">
    <span *ngIf="alert.serverResponse === 'success'">
      <span id="alertSuccess" i18n='@@AccountInfoUpdatePasswordSuccess'>
        <strong>Password update successful !</strong> Your password has been successfully updated.
      </span>
    </span>
    <span *ngIf="alert.serverResponse === 'unknownError'">
      <span id="alertUnknownError" i18n='@@AlertUnknownError'>
        <strong>Password update failed ! The server has encountered an error: {{alert.message}}</strong>
      </span>
    </span>
  </alert>
</ng-template>
