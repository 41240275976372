import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AlertManagerComponent } from './alert-manager.component';

@NgModule({
  declarations: [AlertManagerComponent],
  imports: [
    CommonModule,
    AlertModule.forRoot()
  ],
  exports: [AlertManagerComponent]
})
export class AlertManagerModule { }
