import { User } from "./user";

export class Appeaz {
    id: number;
    name: string;
    url: string;
    version ='';
    status: boolean | string;
    apiKey: string;
    role: string;
    userQuota: number;
    expert: boolean;
    multilingue: boolean;
    stylesheet: string;
    admins: User[];
    modificationDate: Date | string ='';
    publicationDate: Date | string ='';
}
