import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../tools/models/user';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';
import { UserService } from '../../tools/services/user.service';

@Component({
  selector: 'eazly-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit, OnDestroy {

  user: User;
  isEditingUserInfo: boolean;
  alerts: any = [];
  errorReport: EazlyMessage;
  emailConfirmation: string;
  constructor(private userService: UserService, private router: Router, private messagingService: MessagingService) { }

  ngOnInit() {
    this.user = this.userService.getCurrentUser();
    this.isEditingUserInfo = false;
  }

  ngOnDestroy() {
    if (this.errorReport) {
      this.messagingService.notifyError(this.errorReport);
    }
  }

  onSubmit() {
    // EAZ-604 deactivation of the edition of the email
    // this condition must be put back into place
    /*if (this.user.email === this.emailConfirmation) {*/
    this.isEditingUserInfo = false;

    // if success, push success alert
    this.userService.updateUser(this.user).subscribe(data => {
      const alert = {
        type: 'success',
        serverResponse: 'success'
      };
      this.alerts.push(alert);
    },
      error => {
        if (401 === error.status || 403 === error.status) {
          this.userService.logout();
          this.router.navigate(['/login']);
          this.errorReport = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
        }
      }
    );
    // EAZ-604 deactivation of the edition of the email
    // this condition must be put back into place
    /* } else {
       const alert = {
         type: 'danger',
         serverResponse: 'emailsNotMatching'
       };
       this.alerts.push(alert);
     }*/
  }

  displayEditUsertInfo() {
    this.isEditingUserInfo = true;
  }
}
