import { Observable } from 'rxjs';
import { DynamicModification } from '../../../models/dynamic-modification';
import { ViewerService } from '../../../services/viewer.service';
import { OperationSource } from '../../message-bus/message-types/operation-message';
import { Operation, OperationType } from './operation';

export class EditDmOperation implements Operation {

    name: string;
    operationType: OperationType = OperationType.EDIT_DM;
    stepId: string;
    // EAZ-750:
    // Context of execution of the operation to send to the engine.
    // When the context is "UNDO", the engine should also restore visibility conditions
    // removed by the "DO" operation during the consistency management
    operationSource: OperationSource;
    private viewerService: ViewerService;
    private appeazUrl: string;
    private dynamicModification: DynamicModification;
    private editedProperties: string[];


    constructor(viewerService: ViewerService,
        context: OperationSource,
        name: string,
        appeazUrl: string,
        stepId: string,
        dynamicModification: DynamicModification,
        editedProperties: string[]) {
        this.name = name;
        this.viewerService = viewerService;
        this.appeazUrl = appeazUrl;
        this.stepId = stepId;
        this.dynamicModification = dynamicModification;
        this.operationSource = context;
        this.editedProperties = editedProperties;

    }

    execute(): Observable<number> {
        return this.viewerService.updateDynamicModification(this.operationSource, this.appeazUrl, this.dynamicModification.id,
            this.stepId, this.dynamicModification);
    }

    getName(): string {
        return this.name;
    }

    getDynamicModification(): DynamicModification {
        return this.dynamicModification;
    }

    getOperationType(): OperationType {
        return this.operationType;
    }

    getStepId(): string {
        return this.stepId;
    }

    getEditedProperties(): string[] {
        return this.editedProperties;
    }

}
