import { Component, OnInit } from '@angular/core';
import { User } from '../../tools/models/user';
import { UserService } from '../../tools/services/user.service';

@Component({
  selector: 'eazly-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  user: User;
  selectedLanguage: string;
  supportedLanguages: string[];
  constructor(private userService: UserService) { }

  ngOnInit() {
    this.user = this.userService.getCurrentUser();
    this.supportedLanguages = ['English', 'Français'];
    this.selectedLanguage = 'English';
  }

  setLanguage(language: string) {
    this.selectedLanguage = language;
  }
}
