import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseAuthenticationGuard } from './guard/base-authentication-guard';

@Injectable()
export class AdminGuard extends BaseAuthenticationGuard {
    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.userService.getCurrentUser() && this.userService.isAdmin()) {
            return true;
        } else {
            this.router.navigate(['/appeaz']);
            return false;
        }
    }
}
