import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AppRoutingModule } from '../../eazly.routing';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../other/footer/footer.module';
import { LoadingModule } from '../other/loading/loading.module';

import { AlertManagerModule } from '../tools/alert-manager/alert-manager.module';
import { EnvironementDashboardComponent } from './environement-dashboard/environement-dashboard.component';
import { PublishComponent } from './publish.component';


@NgModule({
  declarations: [
    PublishComponent,
    EnvironementDashboardComponent],
  imports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    HeaderModule,
    ReactiveFormsModule,
    AlertManagerModule,
    FooterModule,
    AlertModule.forRoot(),
    LoadingModule
  ],
  bootstrap: [PublishComponent]
})
export class PublishModule { }
