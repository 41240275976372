import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'eazly-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {

  @Output() toggleChanged = new EventEmitter<boolean>();

  toggleState = true;

  constructor() { }

  ngOnInit(): void {
  }

  toggleSwitch() {
    this.toggleState = !this.toggleState;
    this.toggleChanged.emit(this.toggleState);
  }

}
