import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

/**
 * This class is used to calculate the URL to call in the different services (AppeazService and UserService)
 * to reach the Backend.
 * Several cases are taken into account:
 * - Execution with npm run start
 * - Execution with npm run test
 * - Execution for Selenium tests
 * - Deployment of the Frontend as a war in production or for demo
 */
@Injectable()
export class UrlHelperService {
    private static NPM_PORT = '4200';
    private static KARMA_PORT = '9876';
    private static DEFAULT_PORT = '8080';
    private url: string;

    constructor() {
        const hostUrlSplitted: string[] = environment.host.split(':');
        if (hostUrlSplitted.length > 1) {
            let localport;
            if (UrlHelperService.NPM_PORT === hostUrlSplitted[1] || UrlHelperService.KARMA_PORT === hostUrlSplitted[1]) {
                localport = UrlHelperService.DEFAULT_PORT;
            } else {
                localport = hostUrlSplitted[1];
            }
            this.url = environment.protocol + '//' + environment.hostname + ':' + localport + '/';
        } else {
            this.url = environment.protocol + '//' + environment.host + '/';
        }
    }

    getBaseUrl(): string {
        return this.url;
    }

    getBaseURLWithApiV1Url(): string {
        return Location.joinWithSlash(this.url, environment.apiV1Path);
    }

}
