<eazly-modal-window>
    <div modalWindowHeader>
        <div id="import-title" class="modal-title" i18n="@@ImportWindowTitle">Import</div>
        <div *ngIf="importedFile">"{{importedFile.name}}"</div>
    </div>
    <div modalWindowBody>
        <div [hidden]="showConfirmationMessage" id="stepSelectionMsg" i18n='@@stepSelectionMsg'>Please select the steps
            to be imported</div>
        <div class="importBody" [hidden]="showConfirmationMessage">
            <eazly-navigator id="navigator-import" [myappeaz]="myappeaz" [steps]="intersectionStep"
                (checkBoxChecked)="enableButton()">
            </eazly-navigator>
        </div>
    </div>
    <div modalWindowFooter [hidden]="showConfirmationMessage">
        <button (click)="hide()" id="CancelModalWindow" type="button" class="btn eazlybtn btn-lg" i18n="@@Cancel">
            <i class="fas fa-arrow-left"></i>
            Cancel
        </button>
        <button id="showConfirmation" type="submit" class="btn eazlybtn btn-lg" i18n="@@showConfirmation"
            (click)="showConfirmation()" [disabled]="isButtonDisabled">
            <i class="fas fa-check"></i>
            Import
        </button>
    </div>

    <div modalWindowBody class="import-confirmation-content" [hidden]="!showConfirmationMessage">
        <div class="confirmation-content">
            <p id="DeleteStepsMessage" i18n="@@DeleteStepsMessage">Please note that the import will overwrite the
                selected steps.
            </p>
            <p id="ContinueMessage" i18n="@@ContinueMessage">Would you like to continue ?</p>
        </div>
    </div>
    <div modalWindowFooter [hidden]="!showConfirmationMessage">
        <button (click)="hideConfirmation()" id="BackModalWindow" type="button" class="btn eazlybtn btn-lg"
            i18n="@@BackModalWindow">
            <i class="fas fa-arrow-left"></i>
            Back
        </button>
        <button id="ConfirmModalWindow" type="submit" class="btn eazlybtn btn-lg" i18n="@@ConfirmModalWindow"
            (click)="handleSubmitButtonClicked()">
            <i class="fas fa-check"></i>
            Confirm
        </button>
    </div>

</eazly-modal-window>
