import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class LoggingErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error) {
        const loggingService = this.injector.get(NGXLogger);
        loggingService.error(error);
        throw error;
    }

}
