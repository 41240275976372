<div *ngIf="user">
  <div [className]="isLoading ? 'row rowPrincipal isDisabledRow' : 'row rowPrincipal'">
    <div [hidden]="!isLoading" class="spinner-border disabledRowSpinner" role="status">
    </div>
    <div [className]="isAdmin ? 'col-sm-4 colTable' : 'col-sm-5 colTable'">
      <div class="row nameRow">
        <div *ngIf="user.first_name !== undefined && user.first_name !== null">
          &nbsp; {{user.first_name}} {{user.last_name}} &nbsp;
        </div>
        <div *ngIf="isMe" i18n="@@YourOwnAccount"> (You)</div>
        <div *ngIf="user.first_name === undefined ||  user.first_name === null">
          <span i18n="@@NewUserText">&nbsp; (Not specified)</span>
          <i title="This user didn't respond yet to your request for account creation." i18n-title="@@NewUserMenu"
            class="fas fa-question-circle"></i>
        </div>
      </div>
      <div class="row emailRow">
        &nbsp; {{user.email}}
      </div>
    </div>

    <div class="col-sm-6 colTable">
      <rating [max]="3" [(ngModel)]="y" [readonly]="isMe" [customTemplate]="tt"
        [titles]="['Designer','Publisher','Manager']"></rating>
      <ng-template #tt let-i="index" let-v="value">
        <button [disabled]="isMe" (click)="choose(i)" class="btn btn-rating btn-{{i < v ? 'warning' : 'default'}}">
        </button>
      </ng-template>
    </div>
    <div *ngIf="isAdmin" class="col-sm-1 colTable">
      <input type="checkbox" class="checkbox" (change)="setUserExpert()" [(ngModel)]="isExpert" />
    </div>
    <div class="col-sm-1 colTable">
      <div *ngIf="!isMe">
        <span id="showdeleteModal" class="btn deletecolumn" (click)="showModal()">
          <i class="fas fa-trash-alt"></i>
        </span>
      </div>
    </div>
  </div>
</div>

<eazly-modal-window>
  <div modalWindowHeader i18n="@@UserModalDeleteHeader">
    Deleting the user {{user.first_name}} {{user.last_name}}
  </div>
  <div modalWindowBody>
    <p>This action will remove {{user.first_name}} {{user.last_name}} from this Appeaz.</p>
    <p>Are you sure you want to proceed this action?</p>
  </div>
  <div modalWindowFooter>
    <button (click)="hideModal()" type="button" class="btn btn-lg " i18n="@@Cancel">
      <i class="fas fa-long-arrow-alt-left"></i> Cancel</button>
    <button id="confirmDeletion" (click)="deleteUser()" class="btn btn-lg " i18n="@@UserModalDeleteButton">
      <i class="fas fa-trash"></i> Delete</button>
  </div>
</eazly-modal-window>
