<eazly-loading [isRunning]="isLoading"></eazly-loading>
<div class="viewer-container">
    <div [ngClass]="toggleState? 'viewer-column': 'viewer-column full'">
      <div class="eazlyPreviewDiv">
        <eazly-alert-manager></eazly-alert-manager>
        <iframe id="wysiwygViewer" #wysiwygViewer>
        </iframe>
      </div>
    </div>
    <div id="paletteColumnId" class="palette-column" [ngClass]="toggleState? 'palette-column-show': 'palette-column-hide'">
      <div id="palette" class="card">
        <div class="card-header card-title" i18n="@@paletteTitle">
          Palette
        </div>
        <div class="card-body" id="palette-card">
          <div id="accordion" role="tablist">
            <div class="card active">
              <div class="card-header active" id="label-title">
                <h6 class="mb-0">
                  <a data-toggle="collapse" class="card-heading" role="button" aria-expanded="false"
                    aria-controls="label-items" href="#label-items" i18n="@@paletteItemLabels">
                    Labels</a>
                </h6>
              </div>
              <div class="card-body collapse show" id="label-items" role="tabpanel" aria-labelledby="label-title"
                data-parent="#accordion">
                <div class="list-group">
                  <div *ngFor="let asset of assets">
                    <div *ngIf="asset.category == 'Label'">
                      <a id="{{asset.id}}" draggable="true" (dragstart)="drag($event)" (dragend)="dragend($event)"
                        class="list-group-item list-group-item-action">
                        <img id='{{asset.id}}' class="palette-item-icon" src='{{asset.icon}}' title='{{asset.name}}' alt='{{asset.name}}'>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="input-title">
                <h6 class="mb-0">
                  <a data-toggle="collapse" class="card-heading" role="button" aria-expanded="false"
                    aria-controls="input-items" href="#input-items" i18n="@@paletteItemInputs">Inputs</a>
                </h6>
              </div>
              <div class="card-body collapse" id="input-items" role="tabpanel" aria-labelledby="input-title"
                data-parent="#accordion">
                <div *ngFor="let asset of assets">
                  <div *ngIf="asset.category == 'Input'">
                    <a draggable="true" id='{{asset.id}}' (dragstart)="drag($event)" (dragend)="dragend($event)"
                      class="list-group-item list-group-item-action">
                      <img id='{{asset.id}}' class="palette-item-icon" src='{{asset.icon}}' title='{{asset.name}}' alt='{{asset.name}}'>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="choice-title">
                <h6 class="mb-0">
                  <a data-toggle="collapse" class="card-heading" role="button" aria-expanded="false"
                    aria-controls="choice-items" href="#choice-items" i18n="@@paletteItemChoices">Choices</a>
                </h6>
              </div>
              <div class="card-body collapse" id="choice-items" role="tabpanel" aria-labelledby="choice-title"
                data-parent="#accordion">
                <div *ngFor="let asset of assets">
                  <div *ngIf="asset.category == 'Choice'">
                    <a draggable="true" id='{{asset.id}}' (dragstart)="drag($event)" (dragend)="dragend($event)"
                      class="list-group-item list-group-item-action">
                      <img id='{{asset.id}}' class="palette-item-icon" src='{{asset.icon}}' title='{{asset.name}}'  alt='{{asset.name}}'>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<eazly-visibility-window (onSubmitVisibilityProperties)="bindDataFromVisibilityModal($event)" [isI18N]='myappeaz.multilingue'>
</eazly-visibility-window>
<eazly-dynamic-field-configuration-window
  (onSubmitDynamicModificationSettings)="relayDmUpdateFromModalWindowToEazlyEngine($event)" [isI18N]='myappeaz.multilingue'>
</eazly-dynamic-field-configuration-window>
<eazly-list-value-editor-window (onSubmitListValue)="relayDmUpdateFromModalWindowToEazlyEngine($event)" [isI18N]='myappeaz.multilingue'>
</eazly-list-value-editor-window>
