import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AppRoutingModule } from '../../eazly.routing';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../other/footer/footer.module';
import { LoadingModule } from '../other/loading/loading.module';
import { AccountInformationComponent } from './account-information/account-information.component';
import { UserInformationComponent } from './user-information/user-information.component';
import { UserProfileComponent } from './user-profile.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LoadingModule,
    AlertModule.forRoot()
  ],

  declarations: [
    UserProfileComponent,
    AccountInformationComponent,
    UserInformationComponent,
    UserSettingsComponent]
})
export class UserProfileModule { }
