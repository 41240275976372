import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataDescriptor } from '../models/data-descriptor';
import { DynamicModification } from '../models/dynamic-modification';
import { StepDynamicModifications } from '../models/step-dynamic-Modifications';

@Injectable({ providedIn: 'root' })
export class DataDescriptorService {
    private static readonly APPLICATION_JSON = 'application/json';

    @SessionStorage('auth.Engine.token')
    public engineToken;

    protected httpClient: HttpClient;

    constructor(private readonly http: HttpClient) {
        this.httpClient = http;
    }

    public getDataDictionnary(url: string, version: string): Observable<DataDescriptor[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': DataDescriptorService.APPLICATION_JSON,
                'x-access-token': this.engineToken
            })
        };
        const constructedURL = `${url}/eazly-api/v1/data-descriptors/${version}`;
        return this.http.get<DataDescriptor[]>(constructedURL, httpOptions).pipe(
            map(
                response => response,
                catchError((err: HttpErrorResponse) => throwError(err))
            ));
    }

    /**
     * This methods returns an observable containing
     * all the DataDescriptor used in the specified step
     *
     * @param url the URL of the appeaz
     * @param stepId the Id of the step
     * @returns an array containing the DataDescriptor used in the step
     */
    public getDataDictionnaryOfAStep(url: string, stepId: string): Observable<DataDescriptor[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': DataDescriptorService.APPLICATION_JSON,
                'x-access-token': this.engineToken
            })
        };
        const constructedURL = `${url}/eazly-api/v1/pages/${stepId}/data-descriptors`;
        return this.http.get<DataDescriptor[]>(constructedURL, httpOptions).pipe(
            map(
                response => response,
                catchError((err: HttpErrorResponse) => throwError(err))
            ));
    }

    getAvailableDataDescriptor(url: string, dmLabel: string): Observable<DataDescriptor> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': DataDescriptorService.APPLICATION_JSON,
                'x-access-token': this.engineToken
            })
        };
        const constructedURL = `${url}/eazly-api/v1/data-descriptors/${dmLabel}`;
        return this.http.get<DataDescriptor>(constructedURL, httpOptions).pipe(map(
            response => response,
            catchError((err: HttpErrorResponse) => throwError(err))
        ));
    }

    getLinkedDynamicModifications(url: string, datadescriptorId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': DataDescriptorService.APPLICATION_JSON,
                'x-access-token': this.engineToken,
            })
        };
        return this.http.get(`${url}/eazly-api/v1/data-descriptors/${datadescriptorId}/dynamic-modifications`, httpOptions).pipe(map(
            response => response,
            catchError((err: HttpErrorResponse) => throwError(err))
        ));
    }

    public async isDMLinked(url: string, dm: DynamicModification): Promise<boolean> {
        let isLinkedDM = false;
        let allLinkedFields: StepDynamicModifications[];
        if (this.doesSupportDataDescriptor(dm)) {
            return new Promise((resolve) => {
                if (dm?.modificationTarget?.dataDescriptor !== undefined) {
                    this.getLinkedDynamicModifications(url, dm.modificationTarget.dataDescriptor.id).subscribe(
                        response => {
                            allLinkedFields = response;
                            if (allLinkedFields !== undefined && allLinkedFields.length > 1) {
                                isLinkedDM = true;
                            }
                            resolve(isLinkedDM);
                        });
                } else {
                    resolve(false);
                }
            });
        } else {
            return new Promise((resolve) => {
                resolve(false);
            });
        }
    }

    private doesSupportDataDescriptor(dm: DynamicModification): boolean {
        return !(dm?.modificationTarget?.properties?.elementType.includes('HEADER') || dm?.modificationTarget?.properties?.elementType.includes('MESSAGE'));
    }
}
