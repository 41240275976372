/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { DynamicModification } from '../models/dynamic-modification';
import { DynamicModificationValueList } from '../models/dynamic-modification-value';
import { LabelTranslation } from '../models/label-translation';
import { Locale } from '../models/locale';
import { LabelTranslationService } from './i18n/label-translation.service';
import { LanguageService } from './shared/language.service';
import { EazlyTinyMce } from './tinymce/eazly-tinymce';

const EAZLY_BUTTON_ATTRIBUTE = 'eazly-button';
const LIST_VALUE_EDIT_BUTTON = 'list-value-edit';
const VISIBILITY_EYE_OFF_CLASS = 'eazly-visibility-eye-off';
const VISIBILITY_ICON_CLASS = 'eazly-visibility-icon';
const ONLINE_HELP_TOOLTIP_TEXT_OFF = 'online-help-tooltip-text-off';
const EAZLY_DYNAMIC_ACTION_ITEM = 'eazly-dynamic-action-item';

@Injectable()
export class DomHtmlService {

    public GENERATE_I18N_WARNINGS = false;
    private subscription: Subscription;

    private clickEventHandlerForClosingTinyMce;

    private readonly EAZLY_DIV_WITH_DM_AND_TOOLS = 'eazly-div-with-dm-and-tools';

    constructor(private readonly logger: NGXLogger,
        private readonly labelTranslationService: LabelTranslationService,
        private readonly languageService: LanguageService) {
        this.init();
    }

    init(): void { }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getDiv(id: string) {
        const divElement = document.createElement('div');
        divElement.id = id + '-element';
        return divElement;
    }

    getDynamicDom(element: HTMLElement, url: string, dynamicModif: DynamicModification,
        appeazIsMultilingual: boolean, isLinkedField: boolean): HTMLDivElement {
        const dynamic = document.createElement('div');
        dynamic.id = element.id + '-element';
        dynamic.classList.add('row', 'rowDynamic');
        if (appeazIsMultilingual) {
            this.addOrRemoveMissingTranslationCssClass(dynamic, dynamicModif);
        }
        dynamic.setAttribute('dynamic_id', element.getAttribute('dynamic_id'));

        const moveImage = this.createImage(url + 'assets/arrows-alt.svg', 'dialog-move-img-' + element.id, 'auto', '32px');
        moveImage.classList.add('col-xs-1', EAZLY_DYNAMIC_ACTION_ITEM, 'move-button', 'hidden-on-hover');
        moveImage.setAttribute('draggable', 'true');

        const deleteButton = this.createButton(`col-xs-1 ${EAZLY_DYNAMIC_ACTION_ITEM} delete-button eazly-delete hidden-on-hover`, element.id + '-delete');
        deleteButton.setAttribute('draggable', 'false');
        deleteButton.setAttribute('ondragstart', 'return false;');

        const deleteImage = this.createImage(url + 'assets/trash-alt.svg', 'dialog-delete-img-' + element.id, 'auto', '32px');
        deleteImage.classList.add('eazly-delete');
        deleteImage.setAttribute('dynamic_id', element.getAttribute('dynamic_id'));
        deleteImage.setAttribute('dynamic_html_id', element.id.toString());
        deleteImage.setAttribute('draggable', 'false');
        deleteImage.setAttribute('ondragstart', 'return false;');
        deleteImage.firstElementChild.classList.add('eazly-delete');
        deleteImage.firstElementChild.setAttribute('dynamic_id', element.getAttribute('dynamic_id'));
        deleteImage.firstElementChild.setAttribute('draggable', 'false');
        deleteImage.firstElementChild.setAttribute('ondragstart', 'return false;');
        deleteButton.appendChild(deleteImage);

        this.updateElement(element, dynamicModif, isLinkedField, appeazIsMultilingual);

        const divReceivingToolbar = document.createElement('div');
        divReceivingToolbar.id = element.id + '-div-with-dm-and-tools';
        divReceivingToolbar.classList.add('row', this.EAZLY_DIV_WITH_DM_AND_TOOLS);

        const divDeleteAndMove = document.createElement('div');
        divDeleteAndMove.id = element.id + '-eazly-dynamic-action';
        divDeleteAndMove.classList.add('col-xs-4', 'eazly-dynamic-action');
        divDeleteAndMove.setAttribute('draggable', 'false');

        const eyeButton = this.createEyeIcon('fa fa-eye col-xs-1 eazly-visibility-icon', 'dialog-visibility-eye', divReceivingToolbar.id, dynamicModif);
        divDeleteAndMove.appendChild(eyeButton);
        divDeleteAndMove.appendChild(moveImage);
        divDeleteAndMove.appendChild(deleteButton);

        if (this.GENERATE_I18N_WARNINGS) {
            const spani18nWarning: HTMLElement = this.generateSpanI18NWarning(dynamicModif);
            if (spani18nWarning) {
                divReceivingToolbar.appendChild(spani18nWarning);
            }
        }
        divReceivingToolbar.appendChild(element);
        divReceivingToolbar.appendChild(divDeleteAndMove);
        dynamic.appendChild(divReceivingToolbar);
        this.injectDmOptionDialog(divReceivingToolbar, url, dynamicModif, appeazIsMultilingual);

        const dmIsARichTextMessage: boolean = dynamicModif.modificationTarget.properties.elementType?.includes('RICH_TEXT_MESSAGE');
        if (dmIsARichTextMessage) {
            this.addScriptForTinyMce(divReceivingToolbar, element);
        }
        this.injectScriptAnimation(divReceivingToolbar, element);
        return dynamic;
    }

    updateElement(divElement: HTMLElement, dynamicModif: DynamicModification, isLinkedField: boolean, isI18N: boolean) {
        // Making the label of the field editable by setting the attribute 'contenteditable'
        const wysiwygViewer: HTMLIFrameElement = document.getElementById('wysiwygViewer') as HTMLIFrameElement;
        const isMDTheme = (wysiwygViewer?.contentWindow.document.head.innerHTML.search('material/materialCommonStyle.css') > -1);
        let spanContainingLabelToEdit: HTMLElement;
        let divContainingLabel: HTMLElement;
        const labelContainingDatadescriptorName = document.createElement('label');
        const spanContainingDatadescriptorName = document.createElement('span');
        if (dynamicModif.modificationTarget.properties.elementType?.includes('HEADER')
            || dynamicModif.modificationTarget.properties.elementType?.includes('RICH_TEXT_MESSAGE')) {
            spanContainingLabelToEdit = divElement;
        } else {
            if (dynamicModif.modificationTarget.properties.elementType?.includes('MESSAGE')) {
                spanContainingLabelToEdit = divElement.getElementsByClassName('ectzAlertText')[0] as HTMLElement;
                spanContainingLabelToEdit.id = 'ectz-msg-span' + dynamicModif.id.toString();
            } else {
                spanContainingLabelToEdit = divElement.getElementsByClassName('egoReadOnlyLabelRenderer')[0] as HTMLElement;
                const primaryBoxContainingLabel: HTMLElement = divElement.getElementsByClassName('eCitizPrimaryBox')[0] as HTMLElement;
                //EAZ-1035
                // The user-select CSS property controls whether the user can select text.
                // This property is added to the div containing the label to prevent lanching edition action on the label
                primaryBoxContainingLabel.style.userSelect = 'none';
                divContainingLabel = divElement.getElementsByClassName('eCitizPrimaryBox')[0] as HTMLElement;
                const primaryLabel: HTMLElement = primaryBoxContainingLabel.firstChild as HTMLElement;
                if (this.shouldDataDescriptorNameBeDisplayed(dynamicModif, isI18N) || isLinkedField) {
                    spanContainingDatadescriptorName.textContent = `(${dynamicModif.modificationTarget.dataDescriptor.name})`;
                    spanContainingDatadescriptorName.id = 'span-dialog-config-' + divElement.id;
                    spanContainingDatadescriptorName.setAttribute('dynamic_id', dynamicModif.id.toString());
                    spanContainingDatadescriptorName.classList.add('eazly-datadescriptor-span');
                    if (isLinkedField) {
                        spanContainingDatadescriptorName.style.textDecoration = 'underline';
                    }
                    if (!isMDTheme) {
                        if (primaryLabel !== undefined && primaryLabel.style !== undefined) {
                            primaryLabel.style.marginBottom = '0px';
                        }
                        labelContainingDatadescriptorName.appendChild(spanContainingDatadescriptorName);
                        labelContainingDatadescriptorName.classList.add('eazly-datadescriptor-name');
                        labelContainingDatadescriptorName.title = 'Configurations';
                        labelContainingDatadescriptorName.id = 'label-dialog-config-' + divElement.id;
                        labelContainingDatadescriptorName.setAttribute('dynamic_id', dynamicModif.id.toString());
                        labelContainingDatadescriptorName.style.marginBottom = '0px';
                        divContainingLabel.appendChild(labelContainingDatadescriptorName);
                    }
                    else {
                        spanContainingDatadescriptorName.title = 'Configurations';
                        spanContainingDatadescriptorName.id = 'label-dialog-config-' + divElement.id;
                        spanContainingDatadescriptorName.classList.add('eazly-datadescriptor-name');
                        spanContainingDatadescriptorName.classList.add('eazly-datadescriptor-span-MD');
                        primaryLabel.appendChild(spanContainingDatadescriptorName);
                        primaryLabel.style.width = 'inherit';
                    }
                }
                primaryBoxContainingLabel.style.width = '35%';
            }
        }

        if (!dynamicModif.modificationTarget.properties.elementType?.includes('RICH_TEXT_MESSAGE')) {
            // Setting the label as contenteditable only for elements that are not rich text messages
            // Rich text messsage will use a TinyMce injected by the method 'addScriptForTinyMce' instead
            spanContainingLabelToEdit.setAttribute('contenteditable', 'true');
        }
        spanContainingLabelToEdit.classList.add('eazly-editable-label');
        spanContainingLabelToEdit.setAttribute('dynamic_id', dynamicModif.id.toString());
        divElement.classList.add('col-xs-8');
        divElement.classList.add('eazly-body-element');

        this.hideOnlineHelp(dynamicModif, divElement);
    }

    /**
     * Utility method for reducing updateElement Cognitive Complexity.
     * Hide help tooltip
     *
     * @param divElement an HTML element on which the online help tooltip is to be manipulated.
     * @param dynamicModif the dynamic modification to update.
    */

    hideOnlineHelp(dynamicModif: DynamicModification, divElement: HTMLElement) {
        if (dynamicModif.modificationTarget.infoText !== null && dynamicModif.modificationTarget.infoText !== undefined) {
            const onlineHelpSpan = divElement.querySelectorAll('span.ectzOnlineHelpText')[0];
            if (onlineHelpSpan !== undefined) {
                onlineHelpSpan.classList.add(ONLINE_HELP_TOOLTIP_TEXT_OFF);
            }
        }
    }

    addScriptForTinyMce(elementTriggeringTinyMceInit: HTMLElement, spanContainingLabelToEdit: HTMLElement) {
        const iframeElement: HTMLIFrameElement = $('#wysiwygViewer')[0] as HTMLIFrameElement;
        const iframeContentWindow: any = iframeElement.contentWindow;
        // EAZ-784: Initializing a clickEventHandler on the iframe and document.body for triggering
        // the 'blur' event on active eazlyTinyMce to close it
        if (this.clickEventHandlerForClosingTinyMce === undefined) {
            this.clickEventHandlerForClosingTinyMce = (ev: MouseEvent) => {
                const elementClicked: HTMLElement = ev.target as HTMLElement;
                // Checking if the element clicked is not a TinyMCE modal window
                if (!elementClicked.closest('.tox-dialog')) {
                    const activeEditor = iframeContentWindow?.eazlyTinyMce?.activeEditor;
                    if (activeEditor?.editorContainer?.style.opacity === '1') {
                        activeEditor.fire('blur');
                    }
                }
            };
        }
        iframeContentWindow.removeEventListener('click', this.clickEventHandlerForClosingTinyMce);
        iframeContentWindow.addEventListener('click', this.clickEventHandlerForClosingTinyMce);
        document.body.removeEventListener('click', this.clickEventHandlerForClosingTinyMce);
        document.body.addEventListener('click', this.clickEventHandlerForClosingTinyMce);

        elementTriggeringTinyMceInit.addEventListener('mousedown', (ev: MouseEvent) => {
            // EAZ-784: Calling preventDefault on the mousedown event to prevent the triggering of the 'blur' event
            // which would cause the closure of the TinyMCE when clicking on empty areas of the toolbar or menu bar
            const elementClicked: HTMLElement = ev.target as HTMLElement;
            const parentId = elementClicked.parentElement?.id;
            if (parentId && !parentId.endsWith('eazly-dynamic-action')) {
                ev.preventDefault();
            }
        });
        // Adding a TinyMCE initialization when clicking on the spanContainingLabelToEdit for
        // Rich text messages
        elementTriggeringTinyMceInit.addEventListener('click', (ev: MouseEvent) => {
            const elementClicked: HTMLElement = ev.target as HTMLElement;
            const parentId = elementClicked.parentElement?.id;
            if (elementClicked.closest('.eazlyTinyMce')) {
                // EAZ-784: Preventing event propagation when clicking on children of '.eazlyTinyMce'
                //  to prevent blinking of the TinyMce and display errors
                ev.preventDefault();
                ev.stopPropagation();
            } else if (parentId && !parentId.endsWith('eazly-dynamic-action')) {
                // Hiding displayed toolbars to prevent overlap with the tinymce
                for (const jqueryToolbar of iframeContentWindow.document.getElementsByClassName('tool-container')) {
                    jqueryToolbar.style.display = 'none';
                }
                this.initTinyMce(spanContainingLabelToEdit);
            }
        });
    }

    /**
     * Utility method for facilitating unit tests.
     * Instantiate and initialize an eazlyTinyMce with the given iframe and span
     *
     * @param spanContainingLabelToEdit the htmlElement on which the tinyMce should be initialized
     */
    initTinyMce(spanContainingLabelToEdit: HTMLElement) {
        const iframeElement: HTMLIFrameElement = $('#wysiwygViewer')[0] as HTMLIFrameElement;
        const eazlyTinyMce: EazlyTinyMce = new EazlyTinyMce(iframeElement.contentWindow, spanContainingLabelToEdit);
        eazlyTinyMce.initTinyMce();
    }

    injectScriptAnimation(divReceivingToolbar, element) {
        const s = document.createElement('script');
        s.type = 'text/javascript';

        const code1 =
            // JQueryToolbar
            '$("#' + divReceivingToolbar.id + '").toolbar({content: "#dialog-' + divReceivingToolbar.id + '",position: "bottom", event: "hover" });'
            // Display move and delete button
            + '$("#' + divReceivingToolbar.id + '").on(\'toolbarShown\', function(event) {'
            + ' $(this).children(".eazly-dynamic-action").children(".hidden-on-hover").stop().animate('
            + '{ opacity: "1"}, 300, function() {$("#' + element.id + '");});'
            + '});'
            // Hide move and delete button
            + '$("#' + divReceivingToolbar.id + '").on(\'toolbarHidden\', function(event) {'
            + ' $(this).children(".eazly-dynamic-action").children(".hidden-on-hover").stop().animate('
            + '{ opacity: "0"}, 300, function() {$("#' + element.id + '");});'
            + '});';
        try {
            s.appendChild(document.createTextNode(code1));
            divReceivingToolbar.appendChild(s);
        } catch (e) {
            s.text = code1;
            divReceivingToolbar.appendChild(s);
        }
    }

    injectDmOptionDialog(doc, url: string, dynamic: DynamicModification, appeazIsMultilingual: boolean) {
        const dialog = document.createElement('div');
        dialog.id = 'dialog-' + doc.id;
        dialog.classList.add('hidden');
        if (!dynamic.modificationTarget.properties.elementType?.includes('MESSAGE')
            && !dynamic.modificationTarget.properties.elementType?.includes('HEADER')) {
            const pencilButton = this.createiconDouble('fa fa-pencil eazly-dm-options eazly-pencil', 'fa fa-ban eazly-pencil', 'dialog-edit-' + doc.id);
            dialog.setAttribute('dialog_id', doc.id);
            if (dynamic.modificationTarget.properties.editable === 'true') {
                pencilButton.setAttribute('title', 'Rendre le champ non éditable');
                const redSignElement = <HTMLElement>pencilButton.childNodes.item(1);
                redSignElement.style.opacity = '0';
            }
            pencilButton.setAttribute('dynamic_id', dynamic.id.toString());
            pencilButton.setAttribute(EAZLY_BUTTON_ATTRIBUTE, 'editable');
            const requiredButton = this.createRequiredIcon('fa fa-asterisk eazly-dm-options eazly-required', 'dialog-required-' + doc.id, dynamic, doc);
            requiredButton.setAttribute('dynamic_id', dynamic.id.toString());
            requiredButton.setAttribute(EAZLY_BUTTON_ATTRIBUTE, 'required');
            dialog.appendChild(requiredButton);
            dialog.appendChild(pencilButton);
        } else {
            this.logger.debug(dynamic, ' is either a Message or a Header. Required and Editable buttons were not added.');
        }
        const visibilityButton = this.createToolIcon('fa fa-eye eazly-dm-options', 'dialog-visibility-' + doc.id,
            dynamic.id.toString());
        visibilityButton.setAttribute('title', 'Visibilité');
        visibilityButton.setAttribute('dynamic_id', dynamic.id.toString());
        visibilityButton.href = '#dialog-visibilities-' + doc.id;
        visibilityButton.setAttribute(EAZLY_BUTTON_ATTRIBUTE, 'visibility');
        dialog.appendChild(visibilityButton);

        if (dynamic.modificationTarget.properties.elementType?.indexOf('COMBO_BOX') >= 0
            || dynamic.modificationTarget.properties.elementType?.indexOf('RADIO_LIST_HORIZONTAL') >= 0
            || dynamic.modificationTarget.properties.elementType?.indexOf('RADIO_LIST_VERTICAL') >= 0) {
            const listValueEditButton = this.createToolIcon('fa fa-list-ul eazly-dm-options', 'dialog-list-value-' + doc.id, dynamic.id.toString());
            listValueEditButton.href = '#dialog-options-' + doc.id;
            listValueEditButton.setAttribute('dynamic_id', dynamic.id.toString());
            listValueEditButton.setAttribute('data-target', '#dialog-options-' + doc.id);
            listValueEditButton.setAttribute(EAZLY_BUTTON_ATTRIBUTE, LIST_VALUE_EDIT_BUTTON);

            if (appeazIsMultilingual) {
                this.addOrRemoveMissingTranslationCssClassForListValue(dynamic, listValueEditButton);
            }
            dialog.appendChild(listValueEditButton);
        } else {
            this.logger.debug(dynamic, ' is neither a Combo or a RadioList. List value edit button was not added.');
        }
        if (this.isSupportingConfigButton(dynamic)) {
            const configButton = this.createToolIcon('fa fa-gear eazly-dm-options',
                'dialog-config-' + doc.id, dynamic.id.toString());
            configButton.setAttribute('title', 'Configuration');
            configButton.setAttribute('dynamic_id', dynamic.id.toString());
            configButton.href = '#dialog-configs-' + doc.id;
            configButton.setAttribute('data-target', '#dialog-configs-' + doc.id);
            configButton.setAttribute(EAZLY_BUTTON_ATTRIBUTE, 'configuration');
            dialog.appendChild(configButton);
        } else {
            this.logger.debug(dynamic, ' is not supporting the ConfigButton. Configuration button was not added');
        }

        doc.appendChild(dialog);
    }

    /**
     * Add or remove the CSS class "has-notification" to the listValueEditor button for the dynamic modification depending on
     * the missing translations of the values.
     *
     * The "listValueEditButton" must be provided if the button has not been added to the iframeWysiwyg document.
     * If the parameter "listValueEditButton" is not provided, it will be retrieved from the iframeWysiwyg document using a QuerySelector.
     *
     * @param dynamic the dynamic modification whose listValueEditButton should be updated
     * @param listValueEditButton The HTML element to edit
     */
    addOrRemoveMissingTranslationCssClassForListValue(dynamic: DynamicModification, listValueEditButton: HTMLElement): void {
        let listValueEditButtonToEdit: Element = listValueEditButton;
        if (listValueEditButtonToEdit === undefined) {
            const wysiwygViewer: HTMLIFrameElement = document.getElementById('wysiwygViewer') as HTMLIFrameElement;
            const iframeDoc: Document = wysiwygViewer.contentWindow.document;
            // Updating the second "a" element due to toolbar behavior: the displayed "a" element is the second one
            listValueEditButtonToEdit = iframeDoc.querySelectorAll(`a[dynamic_id="${dynamic.id}"][${EAZLY_BUTTON_ATTRIBUTE}="${LIST_VALUE_EDIT_BUTTON}"]`)[1];
        }
        const valuesWithoutTranslationsMap: Map<Locale, DynamicModificationValueList[]> =
            this.labelTranslationService.getLocalesWithoutTranslationForValues(dynamic);
        const hasValueWithMissingTranslation = valuesWithoutTranslationsMap.size > 0;
        if (hasValueWithMissingTranslation) {
            listValueEditButtonToEdit.classList.add('has-notification');
            this.logger.debug(`The dynamic modification ${dynamic.id} has missing translation for its values`);
        } else {
            listValueEditButtonToEdit.classList.remove('has-notification');
            this.logger.debug(`The dynamic modification ${dynamic.id} has no missing translation for its values`);
        }
    }

    /**
     * Utility method for checking visibility rule.
     * Check if this field has a visibility rule
     *
     * @param dynamic the field we want to check if it has a visibility rule
     * @return returns true if the field has at least one visibility rule else false
     */
    public hasVisibilityRule(dynamic: DynamicModification): boolean {
        return dynamic?.modificationTarget?.properties?.visibilityCriteria?.conditions?.length > 0;
    }

    createEyeIcon(classname: string, prefixId: string, divReceivingToolbarId: string, dynamic: DynamicModification): HTMLElement {
        const spanEye: HTMLElement = document.createElement('span');
        spanEye.id = prefixId + 'span';
        spanEye.classList.add(EAZLY_DYNAMIC_ACTION_ITEM);
        spanEye.setAttribute('dynamic_id', dynamic.id.toString());
        spanEye.setAttribute(EAZLY_BUTTON_ATTRIBUTE, 'eyeIcon');
        spanEye.setAttribute('draggable', 'false');
        spanEye.setAttribute('ondragstart', 'return false;');

        const button = document.createElement('a');
        button.className = classname;
        button.href = '#';
        button.id = prefixId + divReceivingToolbarId;
        button.setAttribute('dynamic_id', dynamic.id.toString());
        button.setAttribute(EAZLY_BUTTON_ATTRIBUTE, 'eyeIcon');
        // Display the eye button correctly while moving from one step to another
        button.classList.add(VISIBILITY_ICON_CLASS);
        if (!this.hasVisibilityRule(dynamic)) {
            button.classList.add(VISIBILITY_EYE_OFF_CLASS);
            spanEye.classList.add(VISIBILITY_EYE_OFF_CLASS);
        }
        button.setAttribute('draggable', 'false');
        button.setAttribute('ondragstart', 'return false;');

        spanEye.appendChild(button);
        return spanEye;
    }

    createButton(clasname: string, id: string): HTMLButtonElement {
        const button = document.createElement('button');
        button.className = clasname;
        button.id = id;
        button.style.borderRadius = '0px';
        return button;
    }

    createRequiredIcon(clasname: string, id: string, dynamic: DynamicModification, doc): HTMLAnchorElement {
        const button = document.createElement('a');
        if (dynamic.modificationTarget.properties.editable === 'false') {
            button.className = 'eazly-required-disabled';
        }
        const icon = document.createElement('i');
        button.href = '#';
        icon.className = clasname;
        icon.setAttribute('aria-hidden', 'true');
        button.appendChild(icon);
        button.id = id;

        if (dynamic.modificationTarget.properties.required === 'true') {
            button.setAttribute('title', 'Rendre ce champ facultatif');
        } else {
            icon.className = icon.className + ' ' + 'eazly-required-off';
            button.setAttribute('title', 'Rendre ce champ obligatoire');
        }
        return button;
    }

    createToolIcon(clasname: string, id: string, dynamicId: string): HTMLAnchorElement {
        const button = document.createElement('a');
        const icon = document.createElement('i');
        button.id = id;
        button.href = '#';
        icon.className = clasname;
        icon.setAttribute('dynamic_id', dynamicId);
        button.appendChild(icon);
        icon.id = 'icon-' + id;
        const notification: HTMLElement = document.createElement('i');
        notification.className = 'fa fa-solid fa-circle notification-badge';
        button.appendChild(notification);
        return button;
    }

    createiconDouble(clasname: string, clasname2: string, id: string): HTMLAnchorElement {
        const button = document.createElement('a');
        const icon = document.createElement('i');
        const icon2 = document.createElement('i');
        button.href = '#';
        icon.className = clasname;
        icon2.className = clasname2;
        button.appendChild(icon);
        button.appendChild(icon2);
        button.classList.add('fa-stack');
        icon.classList.add('fa-stack-2x');
        icon2.classList.add('fa-stack-2x');
        icon2.style.color = 'red';
        button.id = id;
        return button;
    }

    createImage(source: string, id: string, width: string, height: string) {
        const spanImage = document.createElement('span');
        const image = document.createElement('img');
        image.id = id;
        image.src = source;
        image.style.width = width;
        image.style.height = height;
        spanImage.appendChild(image);
        return spanImage;
    }

    createScript(scriptname: string) {
        const myscript = document.createElement('script');
        myscript.type = 'text/javascript';
        myscript.src = scriptname;
        return myscript;
    }

    createCss(scriptname: string) {
        const myscript = document.createElement('link');
        myscript.type = 'text/css';
        myscript.rel = 'stylesheet';
        myscript.href = scriptname;
        return myscript;
    }

    /**
     * Refresh the HTMLs related to the I18N warnings for the given dynamic modification and its HTML.
     *
     * @param htmlOfDM HTMLElement associated the dynamic modification
     * @param dynamicModification The Dynamic Modification
     */
    public refreshI18NWarning(htmlOfDM: HTMLElement, dynamicModification: DynamicModification): void {
        // Updating CSS class for missing translations
        this.addOrRemoveMissingTranslationCssClass(htmlOfDM, dynamicModification);
        // Updating notification icon for missing translations
        if (this.GENERATE_I18N_WARNINGS) {
            const newI18NWarning: HTMLSpanElement = this.generateSpanI18NWarning(dynamicModification);
            const oldI18NWarning: HTMLSpanElement = htmlOfDM.querySelector('span.eazly-i18n-warning');
            if (oldI18NWarning) {
                // Replacing the existing I18NWarning node
                oldI18NWarning.parentNode.replaceChild(newI18NWarning, oldI18NWarning);
            } else {
                // Inserting the new I18NWarning node
                htmlOfDM.getElementsByClassName(this.EAZLY_DIV_WITH_DM_AND_TOOLS).item(0).appendChild(newI18NWarning);
            }
        }
        // Updating notification badge for list values
        if (dynamicModification?.modificationTarget?.values) {
            this.addOrRemoveMissingTranslationCssClassForListValue(dynamicModification, undefined);
        }
    }

    private shouldDataDescriptorNameBeDisplayed(dynamicModif: DynamicModification, isI18N: boolean): boolean {
        let showDatadescriptorName = true;
        const translatedLabel: string = this.labelTranslationService.getDMLabelForTitle(
            dynamicModif, isI18N, this.languageService.getSelectedLocale());
        if (dynamicModif.modificationTarget.dataDescriptor === undefined
            || translatedLabel === dynamicModif.modificationTarget.dataDescriptor.name) {
            showDatadescriptorName = false;
        }
        return showDatadescriptorName;
    }

    private isSupportingConfigButton(dynamic: DynamicModification): boolean {
        let isSupportingConfigButton = false;

        switch (dynamic.modificationTarget.properties.elementType) {
            case 'CHECK_BOX':
            case 'COMBO_BOX':
            case 'DATE':
            case 'INTEGER':
            case 'RADIO_BUTTON':
            case 'RADIO_LIST_HORIZONTAL':
            case 'RADIO_LIST_VERTICAL':
            case 'TEXT_AREA':
            case 'TEXT_FIELD':
                isSupportingConfigButton = true;
                break;
            default:
                isSupportingConfigButton = false;
        }
        return isSupportingConfigButton;
    }

    /**
     * Generate a HTMLSpanElement for the I18N warning for the dynamic modification given in parameter.
     *
     * If the dynamic modification has missing translations, this method will generate HTML for displaying
     * a warning along with the list of locale whose translations is missing. The current locale
     * will be displayed in bold if it is listed in the warning.
     *
     * If there is no missing translation, this method will return undefined.
     *
     * @param dynamicModification The DynamicModification whose HTML is being generated
     * @returns A HTMLSpanElement containing a warning if the DynamicModification has missing translations
     */
    private generateSpanI18NWarning(dynamicModification: DynamicModification): HTMLSpanElement {
        const localesWithoutTranslationsForLabel: Map<Locale, LabelTranslation> =
            this.labelTranslationService.getLocalesWithoutTranslationForLabel(dynamicModification);
        this.logger.debug('The modification ', dynamicModification, ' has the following missing translations: ', localesWithoutTranslationsForLabel);

        const localesWithoutTranslationsForLabelSet: Set<Locale> = new Set(localesWithoutTranslationsForLabel?.keys());
        let localesWithoutTranslationsForValuesSet: Set<Locale> = new Set();
        if (dynamicModification?.modificationTarget?.values) {
            const localesWithoutTranslationsForValues: Map<Locale, DynamicModificationValueList[]> =
                this.labelTranslationService.getLocalesWithoutTranslationForValues(dynamicModification);
            this.logger.debug('The modification ', dynamicModification,
                ' has the following missing translations for its values: ', localesWithoutTranslationsForValues);
            localesWithoutTranslationsForValuesSet = new Set(localesWithoutTranslationsForValues.keys());
        }

        // Creating a new set using the spread syntax "..." to merge all the locales and to remove duplicates
        const localesWithMissingTranslations: Set<Locale> = new Set([...localesWithoutTranslationsForLabelSet, ...localesWithoutTranslationsForValuesSet]);
        const sortedLocalesWithMissingTranslations: Locale[] = Array.from(localesWithMissingTranslations)
            .sort((locale1, locale2) => locale1.language.localeCompare(locale2.language));

        const spanI18NWarning: HTMLSpanElement = document.createElement('span');
        if (sortedLocalesWithMissingTranslations.length > 0) {
            spanI18NWarning.classList.add('eazly-i18n-warning');
            const spanI18NWarningIcon: HTMLSpanElement = document.createElement('span');
            spanI18NWarningIcon.classList.add('eazly-i18n-warning-icon');
            spanI18NWarningIcon.textContent = '!';

            const spanI18NWarningTooltip: HTMLSpanElement = document.createElement('span');
            spanI18NWarningTooltip.classList.add('eazly-i18n-warning-tooltip');

            const spanI18NWarningText: HTMLSpanElement = document.createElement('span');
            spanI18NWarningText.classList.add('eazly-i18n-warning-text');
            const missingTranslationsInLocalesList: string[] = [];
            for (const locale of sortedLocalesWithMissingTranslations) {
                if (this.languageService.isSelectedLocale(locale)) {
                    missingTranslationsInLocalesList.push(`<b> ${locale.language} </b>`);
                }
                else {
                    missingTranslationsInLocalesList.push(locale.language);
                }
                if (locale?.countryCode.length > 0) {
                    missingTranslationsInLocalesList.push(`(${locale.countryCode})`);
                }
            }
            const warningText = 'Traduction(s) manquante(s): ';
            spanI18NWarningText.innerHTML = warningText.concat(missingTranslationsInLocalesList.join(', '));

            spanI18NWarningTooltip.appendChild(spanI18NWarningText);
            // Defining a content span with the position set to "relative" whose parent position is
            // "absolute" to position the i18nWarning on top of other HTMLElements without impacting
            const spanI18NWarningContent: HTMLSpanElement = document.createElement('span');
            spanI18NWarningContent.classList.add('eazly-i18n-warning-content');

            spanI18NWarningContent.appendChild(spanI18NWarningIcon);
            spanI18NWarningContent.appendChild(spanI18NWarningTooltip);
            spanI18NWarning.appendChild(spanI18NWarningContent);
        }
        return spanI18NWarning;
    }

    /**
     * Add or remove the CSS class 'eazly-missing-translation' to the HTMLElement given in parameter
     * depending on the missing of translations in the dynamicModification
     *
     * @param dynamic The HTML element whose CSS class should be edited
     * @param dynamicModif The DynamicModification associated with the HTMLElement
     */
    private addOrRemoveMissingTranslationCssClass(dynamic: HTMLElement, dynamicModif: DynamicModification) {
        const labelHasMissingTranslation: boolean = this.labelTranslationService.getLocalesWithoutTranslationForLabel(dynamicModif)?.size > 0;
        const valuesHaveMissingTranslation: boolean = this.labelTranslationService.getLocalesWithoutTranslationForValues(dynamicModif)?.size > 0;
        if (labelHasMissingTranslation || valuesHaveMissingTranslation) {
            dynamic.classList.add('eazly-missing-translation');
        } else {
            dynamic.classList.remove('eazly-missing-translation');
        }
    }
}
