<div class="card" id="navigator" #navigator>
  <div class="card-header" i18n="@@editorTitle">
    Navigator
  </div>
  <div class="input-group margin-bottom-sm">
    <div class="input-group-prepend">
      <span class="input-group-text" id="basic-addon1">
        <i class="fas fa-search"></i>
      </span>
    </div>
    <input type="text" class="search form-control" placeholder="Search for a step" i18n-placeholder="@@paletteSearch"
      name="search" id="search">
  </div>
  <div class="card-body" [ngClass]="{'separation-between-body-and-footer': displayStepSelection == true}">
    <div class="list-group">
      <div *ngFor="let step of steps">
        <div [attr.id]="'nav-item-' + step.id" class="nav-item list-group-item list-group-item-action btn"
          [ngClass]="{'active': selectedStep == step}" (click)="select(step)">
          <a>
            {{step.name}}
          </a>
          <div *ngIf="displayStepSelection" class="step-checkbox">
            <input class="checkbox-step-selection" type="checkbox" [(ngModel)]="stepSelectionCheckBoxStates[step.id]"
              (change)="toggleCheckbox(step.id)" (click)="$event.stopPropagation()">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="list-group-item check-all" *ngIf="displayStepSelection">
      <label class="col-md-11" i18n="@@checkUnCheckAll">
        Check/Uncheck all
      </label>
      <input type="checkbox" class="checkbox-step-selection" [(ngModel)]="selectAll" (change)="toggleSelectAll()">
    </div>
    <div class="export-btn-container" *ngIf="displayExportButton" (click)="exportSteps()">
      <button class="btn-export btn col-md-12" i18n="@@exportButton">
        <i class="fas fa-download"></i>
        Export</button>
    </div>
  </div>
</div>
