
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Step } from '../models/step';

@Injectable()
export class StepService {

    constructor(protected http: HttpClient) { }

    getSteps(url: string, keyJWT: string): Observable<Step[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-access-token': keyJWT,
            })
        };

        return this.http.get(url + '/eazly-api/v1/pages/', httpOptions).pipe(map(
            (response: any) => response,
            // catchError((err: HttpErrorResponse) => {
            catchError((err: any) => throwError(err.json())
            )));
    }

}
