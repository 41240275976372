import { DataType } from '../../data-type';
import { BinaryConditionType } from './binary-visibility-condition-type';
import { VisibilityCondition } from './visibility-condition';

export class BinaryVisibilityCondition extends VisibilityCondition {
    // The type of valueToCompare depends on the type of VisibilityCondition.dataTypeOfTheField
    valueToCompare: any;
    private operator: BinaryConditionType;

    constructor(id: string, dataType: DataType, conditionToSet: BinaryConditionType) {
        super(id, dataType);
        this.operator = conditionToSet;
    }

    getConditionType(): BinaryConditionType {
        return this.operator;
    }
}
