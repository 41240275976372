import { Observable } from 'rxjs';
import { DynamicModification } from '../../models/dynamic-modification';
import { ViewerService } from '../../services/viewer.service';
import { OperationSource } from '../message-bus/message-types/operation-message';
import { AbstractEditDmAction } from './edit-dm-interface';
import { EditDmOperation } from './operations/edit-dm-operation';
import { Operation } from './operations/operation';

export class EditDMAction extends AbstractEditDmAction {
    name: string;
    viewerService: ViewerService;

    protected operation: Operation;
    protected reverseOperation: Operation;

    private appeazUrl: string;
    private stepId: string;
    private idAppeaz: number;
    private appeazRole: string;
    private editedProperties: string[];

    constructor(viewerService: ViewerService, idAppeaz: number, appeazUrl: string, appeazRole: string, stepId: string,
        dynamicModification: DynamicModification, dmLabel: string) {
        super();
        this.viewerService = viewerService;
        this.name = dmLabel;
        this.appeazUrl = appeazUrl;
        this.stepId = stepId;
        this.idAppeaz = idAppeaz;
        this.appeazRole = appeazRole;
        this.editedProperties = super.getEditedProperties(dynamicModification);
        this.operation = new EditDmOperation(this.viewerService, OperationSource.DO, this.name, appeazUrl, stepId,
            dynamicModification, this.editedProperties);
    }

    buildReverseOperation(dynamicModification: any): void {
        const cleanedDynamicModification: DynamicModification = super.cleanServerResponse(dynamicModification, this.editedProperties);
        this.reverseOperation = new EditDmOperation(this.viewerService, OperationSource.UNDO, this.name, this.appeazUrl,
            this.stepId, cleanedDynamicModification, this.editedProperties);
    }

    do(): Observable<any> {
        const observableToReturn: Observable<any> = super.do();
        // Converting the context of the operation from 'do' to 'redo' after executing it once
        const editDmOperation: EditDmOperation = this.operation as EditDmOperation;
        if (OperationSource.DO === editDmOperation.operationSource) {
            editDmOperation.operationSource = OperationSource.REDO;
        }
        return observableToReturn;
    }

    undo(): Observable<any> {
        return super.undo();
    }
}
