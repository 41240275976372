import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../tools/models/user';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';
import { UserService } from '../../tools/services/user.service';
import { ResetPasswordWindowComponent } from '../modal-windows/reset-password-window/reset-password-window.component';

@Component({
  selector: 'eazly-authentication-form',
  templateUrl: './authentication-form.component.html',
  styleUrls: ['./authentication-form.component.scss']
})
export class AuthenticationFormComponent implements OnInit {

  @ViewChild(ResetPasswordWindowComponent, { static: true })
  resetPasswordWindowComponent: ResetPasswordWindowComponent;

  model: any = {};
  user: User;
  returnUrl: string;

  constructor(private route: ActivatedRoute, private router: Router, private messagingService: MessagingService, private userService: UserService) { }

  ngOnInit() {
    this.userService.logout();
    if ((this.route.snapshot.queryParams !== undefined && this.route.snapshot.queryParams['expired'])) {

      const errorMessage = new EazlyMessage(AlertType.danger, 'AppeazService',
        MessageCause.SessionExpired, '');
      this.messagingService.notifyError(errorMessage);
    }

  }

  login() {
    this.userService.login(this.model.login, this.model.password)
      .subscribe(
        idUser => {
          this.userService.getUser(idUser)
            .subscribe(
              user => {
                this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/appeazs';
                this.router.navigate([this.returnUrl]);
              },
              // Managing Get_User errors
              error => {
                this.handleError(error);
              });
        },
        // Managing Login error
        error => {
          this.handleError(error);
        });
  }

  onSubmit() {
    this.login();
  }

  showModal() {
    this.resetPasswordWindowComponent.showModal();
  }

  private handleError(error: any) {
    if (error.status === 0) {
      const errorReport = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.HostUnreachable, `${error.status} ${error.statusText}`);
      this.messagingService.notifyError(errorReport);
    }
    if (error.status === 401 || error.status === 403) {
      const errorReport = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.InvalidLoginPassword, `${error.status} ${error.statusText}`);
      this.messagingService.notifyError(errorReport);
    }
  }
}
