import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Appeaz } from '../../tools/models/appeaz';
import { Locale } from '../../tools/models/locale';
import { AppeazService } from '../../tools/services/appeaz.service';
import { LanguageSelectorService } from '../../tools/services/language-selector.service';
import { EazlyMessage } from '../../tools/services/messaging/eazly-message';
import { LanguageService } from '../../tools/services/shared/language.service';

@Component({
  selector: 'eazly-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input()
  protected myappeaz: Appeaz;
  selectedLocale: Locale;
  errorReport: EazlyMessage;

  public locales: Locale[] = [];

  constructor(
    private readonly languageSelectorService: LanguageSelectorService,
    private readonly appeazService: AppeazService,
    private readonly languageService: LanguageService,
    private readonly logger: NGXLogger) { }

  public ngOnInit(): void {
    this.getLocalesData();
  }

  public getLocalesData(): void {
    if (this.myappeaz.multilingue) {
      this.appeazService.getAppeazJWT(this.myappeaz.id, this.myappeaz.role).subscribe(
        (dataJwt) => {
          this.languageSelectorService.getLocales(this.myappeaz.url, dataJwt).subscribe(
            (response) => {
              this.locales = response.supportedLocales;
              this.locales.sort((a, b) => a.language.localeCompare(b.language));
              this.selectedLocale = this.locales.filter(
                locale => locale.languageCode === response.defaultLocale.languageCode && locale.countryCode === response.defaultLocale.countryCode)[0];
              this.languageService.setDefaultLocale(response.defaultLocale);
              this.languageService.setSupportedLocales(this.locales);
              this.changeLocale(response.defaultLocale);
              this.languageService.notifyLocalesDataLoaded();
            },
            (error) => {
              this.logger.error('Error fetching locales:', error);
            });
        },
        (error) => {
          // Error from appeazService.getAppeazJwt already handled in the AppeazService
          this.errorReport = error;
        }
      );
    }
  }

  public changeLocale(locale: Locale): void {
    this.languageService.setSelectedLocale(locale);
  }
}
