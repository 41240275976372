import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { NGXLogger } from 'ngx-logger';
import { Subject, Subscription } from 'rxjs';
import { Appeaz } from '../../tools/models/appeaz';
import { Step } from '../../tools/models/step';
import { AppeazService } from '../../tools/services/appeaz.service';
import { ImportExportService } from '../../tools/services/import-export.service';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';
import { StepService } from '../../tools/services/steps.service';
import { ImportOperation } from '../../tools/undo-redo-manager/actions/operations/import-operation';
import { Operation } from '../../tools/undo-redo-manager/actions/operations/operation';
import { OperationMessage, OperationSource } from '../../tools/undo-redo-manager/message-bus/message-types/operation-message';
import { UndoRedoManagerService } from '../../tools/undo-redo-manager/undo-redo-manager.service';

@Component({
  selector: 'eazly-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('navigator', { static: false }) myElementRef!: ElementRef;

  @Output()
  stepChange = new EventEmitter<Step>();

  @Output()
  checkBoxChecked = new EventEmitter<void>();
  @Output()
  exportButtonClicked = new EventEmitter<void>();

  @Input()
  myappeaz: Appeaz;

  @Input() steps: Step[];
  // Json containing a list of step Id elements whose attribute is the state of the checkbox
  stepSelectionCheckBoxStates: any = [];
  selectAll = false;
  displayStepSelection = false;
  displayExportButton = false;

  stepsMap: Map<string, Step> = new Map<string, Step>();
  selectedStep: Step;
  errorReport: EazlyMessage;

  private subscriptions: Subscription[] = [];

  constructor(private stepService: StepService,
    private appeazService: AppeazService,
    private messagingService: MessagingService,
    private undoRedoManagerService: UndoRedoManagerService,
    private readonly loggerService: NGXLogger,
    private readonly importExportService: ImportExportService) {
  }

  ngOnInit() {
    this.initStep();
    this.subscribeToUndoRedoManagerChannels();
    this.stepSelectionCheckBoxStates = [];
    this.selectAll = false;
    this.displayStepSelection = false;
  }

  ngOnDestroy() {
    if (this.errorReport) {
      this.messagingService.notifyError(this.errorReport);
    }
    this.unsubscribeToUndoRedoManagerChannels();
  }

  ngAfterViewChecked() {
    this.setupSearchFilter();
  }

  public setDisplayOfStepSelection(display: boolean): void {
    this.displayStepSelection = display;
    this.stepSelectionCheckBoxStates = [];
    this.selectAll = false;
  }

  public toggleCheckbox(step: Step): void {
    this.stepSelectionCheckBoxStates[step.id] = !this.stepSelectionCheckBoxStates[step.id];
    this.selectAll = false;
    this.checkBoxChecked.emit();
  }

  public toggleDisplayOfStepSelection(): void {
    this.setDisplayOfStepSelection(!this.displayStepSelection);
  }

  public toggleDisplayOfExportButton(): void {
    this.displayExportButton = !this.displayExportButton;
  }

  public toggleSelectAll(): void {
    for (const step of this.steps) {
      //EAZ-1020
      //Check if the step to export is displayed
      const nativeElement: HTMLElement = this.myElementRef.nativeElement;
      const element: Element = nativeElement.querySelector('#nav-item-' + step.id);
      const style = window.getComputedStyle(element);
      if (style.display !== 'none') {
        this.stepSelectionCheckBoxStates[step.id] = this.selectAll;
      }
    }
    this.checkBoxChecked.emit();
  }

  public getSelectedStepIds(): string[] {
    const selectedStepIds: string[] = [];
    for (const stepId in this.stepSelectionCheckBoxStates) {
      if (this.stepSelectionCheckBoxStates[stepId]) {
        selectedStepIds.push(stepId);
      }
    }
    return selectedStepIds;
  }

  public getSelectedSteps(): Step[] {
    const selectedSteps: Step[] = [];
    this.steps.forEach((step) => {
      if (this.stepSelectionCheckBoxStates[step.id]) {
        selectedSteps.push(step);
      }
    });
    return selectedSteps;
  }

  public exportSteps(): void {
    this.importExportService.exportStepDynamicModifications(this.myappeaz.url, this.getSelectedStepIds()).subscribe(
      response => {
        const jsonToExport = JSON.stringify(response);
        const blob = new Blob([jsonToExport], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const date = new Date();
        const currentDate = date.toLocaleDateString();
        link.download = `${this.myappeaz.name} ${currentDate}.json`;
        link.click();
        URL.revokeObjectURL(url);
        const infoReport = new EazlyMessage(AlertType.info,
          'ImportExportService', MessageCause.ExportInfoMessage, '');
        this.messagingService.notifyError(infoReport);
      },
      error => {
        const errorReport = new EazlyMessage(AlertType.danger,
          'ImportExportService', MessageCause.ExportFailedMessage, error);
        this.messagingService.notifyError(errorReport);
      }
    );
    this.selectAll = false;
    this.displayExportButton = false;
    this.setDisplayOfStepSelection(false);
    this.exportButtonClicked.emit();
  }

  getappeaz() {
    return this.myappeaz;
  }

  public resetSearchInput() {
    const searchElement: HTMLInputElement = this.myElementRef.nativeElement.querySelector('input[id="search"]');
    searchElement.value = '';
  }

  setupSearchFilter(): void {
    const nativeElement: HTMLElement = this.myElementRef.nativeElement;
    const searchElement: Element = nativeElement.querySelector('input[id="search"]');
    const listItemsElement: NodeListOf<Element> = nativeElement.querySelectorAll('div.nav-item');
    jQuery(searchElement).on('keyup', function (e) {
      const filter = $(searchElement).val() as string;
      if (filter.toString().length > 2) {
        const filterString = filter.trim().toLowerCase();
        listItemsElement.forEach((element: Element) => {
          jQuery(element).hide().filter(function () {
            const text = $(element).text().toLowerCase();
            return text.indexOf(filterString) >= 0;
          }).show();
        });
      } else if (filter.toString().length < 1) {
        listItemsElement.forEach((element: Element) => {
          jQuery(element).show();
        });
        this.selectAll = false;
      }
    }.bind(this));
  }

  select(step) {
    if (this.selectedStep.id !== step.id) {
      this.loggerService.debug('Navigator selection changed (Previous selection: ', this.selectedStep, '; New Selection: ', step, ')');
      this.selectedStep = step;
      this.stepChange.emit(this.selectedStep);
    }
  }

  private initStep() {
    if (this.getappeaz()) {
      this.appeazService.getAppeazJWT(this.myappeaz.id, this.myappeaz.role).subscribe(dataJwt => {
        this.stepService.getSteps(this.myappeaz.url, dataJwt).subscribe(steps => {
          this.steps = steps;
          this.selectedStep = steps[0];
          this.loggerService.debug('Navigator initializing. Emitting selected step ', this.selectedStep);
          this.stepChange.emit(this.selectedStep);
          this.steps.forEach((step: Step) => {
            this.stepsMap.set(step.id, step);
          });
        }, error => {
          // Handling error from StepService.getSteps
        });
      },
        error => {
          // Error from appeazService.getAppeazJwt already handled in the AppeazService
          this.errorReport = error;
        });
    }
  }

  private subscribeToUndoRedoManagerChannels() {
    // The navigator only need to subscribe to successful undo or redo.
    // It is not impacted if the undo or redo fail or if it is a 'do' action
    const undoChannel: Subject<OperationMessage> = this.undoRedoManagerService.getSubscriptionChannel(OperationSource.UNDO);
    const undoSubscription: Subscription = undoChannel.subscribe(
      (operationMessage: OperationMessage) => {
        this.handleUndoRedoEvent(operationMessage);
      }
    );
    const redoChannel: Subject<OperationMessage> = this.undoRedoManagerService.getSubscriptionChannel(OperationSource.REDO);
    const redoSubscription: Subscription = redoChannel.subscribe(
      (operationMessage: OperationMessage) => {
        this.handleUndoRedoEvent(operationMessage);
      }
    );
    this.subscriptions.push(undoSubscription);
    this.subscriptions.push(redoSubscription);
  }

  private unsubscribeToUndoRedoManagerChannels() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private handleUndoRedoEvent(operationMessage: OperationMessage) {
    const operation: Operation = operationMessage.getOperation();
    const stepIdOfTheOperation: string = operation.getStepId();
    if (!(operation instanceof ImportOperation) && this.selectedStep && this.selectedStep.id !== stepIdOfTheOperation) {
      const editedStep: Step = this.stepsMap.get(stepIdOfTheOperation);
      this.select(editedStep);
    }
  }

}
