
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertType, EazlyMessage, MessageCause } from '../tools/services/messaging/eazly-message';
import { MessagingService } from '../tools/services/messaging/messaging.service';
import { UserService } from '../tools/services/user.service';

@Injectable()
export class AuthenticatedNewUserGuard implements CanActivate, OnDestroy {

    errorReport: EazlyMessage;

    constructor(private router: Router, private messagingService: MessagingService, private userService: UserService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const token = +next.params.token;
        return this.userService.isActiveUser(token).pipe(
            map(
                isactif => {
                    if (isactif === false) {
                        return true;
                    } else {
                        this.router.navigate(['/login']);
                        return false;
                    }

                },
                error => {
                    this.errorReport = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.UserNotFound, error.status + ' ' + error.statusText);
                    this.router.navigate(['/login']);
                    return false;
                }));
    }

    ngOnDestroy(): void {
        if (this.errorReport) {
            this.messagingService.notifyError(this.errorReport);
        }
    }

}
