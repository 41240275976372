import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Appeaz } from '../tools/models/appeaz';
import { AppeazService } from '../tools/services/appeaz.service';
import { AppeazDashboardComponent } from './appeaz-dashboard/appeaz-dashboard.component';
@Component({
  selector: 'eazly-appeaz-component',
  templateUrl: './appeaz.component.html',
  styleUrls: ['./appeaz.component.scss']
})
export class AppeazComponent implements OnInit {

  @HostBinding('class') class = 'page-content';
  @ViewChild(AppeazDashboardComponent)
  private appeazDashboardComponent: AppeazDashboardComponent;

  myappeaz: Appeaz;
  title = 'dashboard';
  ready: Promise<boolean>;

  constructor(private router: Router, private route: ActivatedRoute, private appeazService: AppeazService) { }

  ngOnInit() {

    this.ready = Promise.resolve(false);
    this.getAppeaz();
  }
  getAppeaz(): void {
    this.route.params.subscribe((params: Params) => {
      const ident = params['id'];

      if (ident.toString().match('new')) {
        this.myappeaz = new Appeaz();
        this.ready = Promise.resolve(true);

      } else {
        const id = +this.route.snapshot.paramMap.get('id');

        this.appeazService.getAppeazById(id)
          .subscribe(
            data => {
              this.myappeaz = data;
              this.ready = Promise.resolve(true);
              if (this.appeazDashboardComponent !== undefined) {
                this.appeazDashboardComponent.myappeaz = data;
              }
            },
            error => {
              this.ready = Promise.resolve(true);
            });

      }
    });
  }


  goBack(): void {
    this.router.navigate(['/appeazs']);
  }
}
