import { LabelTranslation } from './label-translation';

/**
 * Class representing a value of a ListValue
 */
export class DynamicModificationValueList {
    id: number;
    value: string;
    index: number;
    labelTranslations: LabelTranslation[];

    constructor(index?: number, value?: string, labelTranslations?: LabelTranslation[]) {
        this.index = index;
        this.value = value;
        if (labelTranslations) {
            this.labelTranslations = labelTranslations;
        } else {
            this.labelTranslations = [];
        }
    }
}
