
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DynamicModification } from '../models/dynamic-modification';
import { Locale } from '../models/locale';
import { OperationSource } from '../undo-redo-manager/message-bus/message-types/operation-message';
@Injectable()
export class ViewerService {
    @SessionStorage('auth.Engine.token')
    public engineToken;

    users: DynamicModification[];
    protected httpClient: HttpClient;

    constructor(private readonly http: HttpClient) {
        this.httpClient = http;
    }

    addDynamicModification(source: string, stepId: string, dynamicModification: DynamicModification, idAppeaz: number, appeazRole: string): Observable<number> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-access-token': this.engineToken
            })
        };
        const constructedURL = source + '/eazly-api/v1/pages/' + stepId + '/dynamic-modifications';
        return this.http.post<number>(constructedURL, dynamicModification, httpOptions).pipe(map(
            response => response,
            catchError((err: HttpErrorResponse) => throwError(err))
        ));
    }

    /**
     * Method calling the API Rest for editing an existing DM.
     *
     * @param operationSource OperationSource indicating if the request is done from the 'DO', 'UNDO' or 'REDO'
     * @param appeazUrl url of the Appeaz
     * @param id id of the DM to edit
     * @param stepId id of the Step containing the DM to edit
     * @param dynamicModification DM with the values of properties to set
     * @returns an Observable of a DM containing the old values of properties of the DM for building the 'UNDO' operation
     */
    updateDynamicModification(
        operationSource: OperationSource,
        appeazUrl: string,
        id: number,
        stepId: string,
        dynamicModification: DynamicModification): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-access-token': this.engineToken,
                'eazly-operation-source': operationSource.toString()
            }),
        };
        const constructedURL = `${appeazUrl}/eazly-api/v1/pages/${stepId}/dynamic-modifications/${id}`;
        return this.http.put(constructedURL, dynamicModification, httpOptions).pipe(map(
            response => response,
            catchError((err: HttpErrorResponse) => throwError(err))
        ));
    }

    getDynamicModification(url: string, stepId: string, dynamicmodificationId: number): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-access-token': this.engineToken,
            })
        };

        return this.http.get(`${url}/eazly-api/v1/pages/${stepId}/dynamic-modifications/${dynamicmodificationId}`, httpOptions)
            .pipe(
                map(
                    response => response,
                    catchError((err: HttpErrorResponse) => throwError(err))
                ));
    }

    getDynamicModificationPreviewById(url: string, stepId: string, dynamicmodificationId: number, locale: Locale): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-access-token': this.engineToken,
            }), responseType: 'text' as 'text',
        };
        const localeToRequest = this.getLocaleToRequest(locale);
        return this.http.get(`${url}/eazly-api/v1/pages/${stepId}/dynamic-modifications/${dynamicmodificationId}/preview${localeToRequest}`, httpOptions);

    }

    getStepPreviewById(url: string, stepId: string, locale: Locale): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-access-token': this.engineToken
            }), responseType: 'text' as 'text',
        };
        const localeToRequest = this.getLocaleToRequest(locale);
        return this.http.get(`${url}/eazly-api/v1/pages/${stepId}/preview${localeToRequest}`, httpOptions);
    }

    deleteDynamicModificationPreviewById(url: string, stepId: string, dynamicmodificationId: number, idAppeaz: number, appeazRole: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({

                'x-access-token': this.engineToken,
            })
        };
        return this.http.delete(url + '/eazly-api/v1/pages/' + stepId + '/dynamic-modifications/' +
            dynamicmodificationId, httpOptions).pipe(
                map(
                    response => response,
                    catchError((err: HttpErrorResponse) => throwError(err))
                ));
    }
    getStepDynamicModifications(url: string, stepId: string): Observable<DynamicModification[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-access-token': this.engineToken,
            })
        };
        return this.http.get(url + '/eazly-api/v1/pages/' + stepId + '/dynamic-modifications', httpOptions).pipe(map(
            response => response['modifications'],
            catchError((err: HttpErrorResponse) => throwError(err))
        ));
    }

    /**
     * Return a suffix to append to API rest URLs for the requested locale with a starting '/'.
     * Ex: '/fr_CA'
     *
     *  If the requested locale is not defined, an empty string will be returned
     *
     * @param locale The requested locale
     * @returns a suffix to append to API rest URLs corresponding to the locale provided
     */
    private getLocaleToRequest(locale: Locale): string {
        let localeToRequest = '';
        if (locale?.languageCode) {
            localeToRequest = localeToRequest.concat('/', locale.languageCode);
            if (locale?.countryCode) {
                localeToRequest = localeToRequest.concat('_', locale.countryCode);
            }
        }
        return localeToRequest;
    }
}
