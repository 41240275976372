import { AfterViewChecked, AfterViewInit, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage, SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Appeaz } from '../tools/models/appeaz';
import { User } from '../tools/models/user';
import { AppeazService } from '../tools/services/appeaz.service';
import { AlertType, EazlyMessage, MessageCause } from '../tools/services/messaging/eazly-message';
import { MessagingService } from '../tools/services/messaging/messaging.service';
import { UserService } from '../tools/services/user.service';

@Component({
  selector: 'eazly-appeazs',
  templateUrl: './appeaz-list.component.html',
  styleUrls: ['./appeaz-list.component.scss']
})
export class AppeazListComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {

  @HostBinding('class') class = 'page-content';

  /**
  * List of Appeaz items that should be expanded in the Appeaz list
  */
  @LocalStorage('appeaz.list.expanded.items')
  private expandedItems: string[];

  isLoading: boolean;
  isConfiguration: boolean;
  myAppeazs: Appeaz[];
  alerts: any = [];
  user: User;
  errorMessage: EazlyMessage;
  filteredAppeazs: Appeaz[];
  searchValue: string = this.sessionStorage.retrieve('searchValue');


  constructor(private router: Router,
    private readonly appeazService: AppeazService,
    private readonly userService: UserService,
    private readonly messagingService: MessagingService,
    public sessionStorage: SessionStorageService) { }

  ngOnInit() {
    this.user = this.userService.getCurrentUser();
    this.isLoading = true;
    this.isConfiguration = false;
    if (this.user.eazly_role === 'EAZLY_PLATFORM_ADMIN') {
      this.isConfiguration = true;
      this.appeazService.getAllappeazs()
        .subscribe(
          data => {
            this.myAppeazs = data;
            this.isLoading = false;
            this.initializeFilteredAppeazs();
          },
          error => {
            this.isLoading = false;
            this.handleError(error);
          });
    } else {
      this.appeazService.getMyappeazs()
        .subscribe(
          data => {
            this.myAppeazs = data;
            this.isLoading = false;
            this.initializeFilteredAppeazs();
          },
          error => {
            this.isLoading = false;
            this.handleError(error);
          });
    }
  }

  ngOnDestroy() {
    if (this.errorMessage) {
      this.messagingService.notifyError(this.errorMessage);
    }
  }

  ngAfterViewInit() {
    if (this.expandedItems) {
      this.expandedItems.forEach(itemId => {
        const $collapsiblePanel = jQuery('collapse' + itemId);

        ($collapsiblePanel as any).collapse('show');

      });
    }
  }

  ngAfterViewChecked() {

    const thisComponent = this;

    jQuery('.collapse[data-appeaz]').on('show.bs.collapse', function () {
      const active = jQuery(this).attr('data-appeaz');
      jQuery(this).siblings('.panel-heading').addClass('active');
      thisComponent.setExpanded(active, true);
    });
    jQuery('.collapse[data-appeaz]').on('hide.bs.collapse', function () {
      const active = jQuery(this).attr('data-appeaz');
      jQuery(this).siblings('.panel-heading').removeClass('active');
      thisComponent.setExpanded(active, false);
    });
  }

  initializeFilteredAppeazs(): void {
    this.filteredAppeazs = this.myAppeazs;
    if (this.searchValue) {
      this.filterResults(this.searchValue);
    }
  }

  filterResults(text: string): void {
    this.sessionStorage.store('searchValue', text);
    this.filteredAppeazs = this.myAppeazs.filter((appeaz) =>
      appeaz?.name.toLowerCase().includes(text.toLowerCase())
    );
  }

  private setExpanded(changedId: string, expanded: boolean) {
    if (!this.expandedItems) {
      this.expandedItems = [];
    }
    if (this.expandedItems.some((itemId) => itemId === changedId)) {
      if (!expanded) {
        this.expandedItems = this.expandedItems.filter(itemId => itemId !== changedId);
      }
    } else {
      if (expanded) {
        this.expandedItems.push(changedId);
        //    this.expandedItems = this.expandedItems; // trigger storage save
      }
    }
  }

  private handleError(error: any) {
    if (error.status === 0) {
      this.errorMessage = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.HostUnreachable, `${error.status} ${error.statusText}`);
      this.userService.logout();
      this.router.navigate(['/login']);
    }
    if (error.status === 401) {
      this.errorMessage = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.SessionExpired, `${error.status} ${error.statusText}`);
      this.userService.logout();
      this.router.navigate(['/login']);

    }
    if (error.status === 403) {
      this.errorMessage = new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.InsufficientRole, `${error.status} ${error.statusText}`);
      this.router.navigate(['/appeazs']);
    }
  }
}
