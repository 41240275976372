/* eslint-disable max-lines */
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  Inject,
  LOCALE_ID
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { Appeaz } from '../../tools/models/appeaz';
import { Asset } from '../../tools/models/asset';
import { DataDescriptor } from '../../tools/models/data-descriptor';
import { DataType, frenchDataType, englishDataType } from '../../tools/models/data-type';
import { DynamicModification } from '../../tools/models/dynamic-modification';
import { DynamicModificationProperties } from '../../tools/models/dynamic-modification-properties';
import { DynamicModificationTarget } from '../../tools/models/dynamic-modification-target';
import { LabelTranslation } from '../../tools/models/label-translation';
import { Locale } from '../../tools/models/locale';
import { Step } from '../../tools/models/step';
import { StepDynamicModifications } from '../../tools/models/step-dynamic-Modifications';
import { VisibilityCondition } from '../../tools/models/visibility-criteria/conditions/visibility-condition';
import { VisibilityCriteria } from '../../tools/models/visibility-criteria/visibility-criteria';
import { VisibilityMode } from '../../tools/models/visibility-criteria/visibility-mode';
import { AppeazService } from '../../tools/services/appeaz.service';
import { DataDescriptorService } from '../../tools/services/data-descriptor.service';
import { DomHtmlService } from '../../tools/services/dom-html.service';
import { DynamicModificationService } from '../../tools/services/dynamic-modification.service';
import { LabelTranslationService } from '../../tools/services/i18n/label-translation.service';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';
import { PaletteService } from '../../tools/services/palette.service';
import { LanguageService } from '../../tools/services/shared/language.service';
import { UserService } from '../../tools/services/user.service';
import { ViewerService } from '../../tools/services/viewer.service';
import { AddDMAction } from '../../tools/undo-redo-manager/actions/add-dm-action';
import { CompositeEditDMAction } from '../../tools/undo-redo-manager/actions/composite-edit-dm-action';
import { DeleteDmAction } from '../../tools/undo-redo-manager/actions/delete-dm-action';
import { EditDMAction } from '../../tools/undo-redo-manager/actions/edit-dm-action';
import { AddDmOperation } from '../../tools/undo-redo-manager/actions/operations/add-dm-operation';
import { DeleteDmOperation } from '../../tools/undo-redo-manager/actions/operations/delete-dm-operation';
import { EditDmOperation } from '../../tools/undo-redo-manager/actions/operations/edit-dm-operation';
import { ImportOperation } from '../../tools/undo-redo-manager/actions/operations/import-operation';
import { Operation, OperationType } from '../../tools/undo-redo-manager/actions/operations/operation';
import { OperationMessage, OperationSource } from '../../tools/undo-redo-manager/message-bus/message-types/operation-message';
import { UndoRedoManagerService } from '../../tools/undo-redo-manager/undo-redo-manager.service';
import { DynamicFieldConfigurationWindowComponent } from '../modal-windows/dynamic-field-configuration-window/dynamic-field-configuration-window.component';
import { ImportWindowComponent } from '../modal-windows/import-window/import-window.component';
import { ListValueEditorWindowComponent } from '../modal-windows/list-value-editor-window/list-value-editor-window.component';
import { VisibilityWindowComponent } from '../modal-windows/visibility-window/visibility-window.component';


const EAZLY_REQUIRED_DISABLED = 'eazly-required-disabled';
const EAZLY_REQUIRED_OFF = 'eazly-required-off';
const EAZLY_DROPPABLE_ACTIVE_CLASS = 'eazly-droppable-active';
const VISIBILITY_EYE_OFF_CLASS = 'eazly-visibility-eye-off';
const DYNAMIC_ID = 'dynamic_id';
@Component({
  selector: 'eazly-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  @Input()
  stepBeingEdited: Step;

  @ViewChild('wysiwygViewer', { static: true })
  iframe: ElementRef;

  @ViewChild(VisibilityWindowComponent, { static: true })
  visibilityWindowComponent: VisibilityWindowComponent;

  @ViewChild(ImportWindowComponent, { static: true })
  importWindowComponent: ImportWindowComponent;

  @ViewChild(DynamicFieldConfigurationWindowComponent, { static: true })
  dmSettingWindowComponent: DynamicFieldConfigurationWindowComponent;

  @ViewChild(ListValueEditorWindowComponent, { static: true })
  listValueEditorWindowComponent: ListValueEditorWindowComponent;

  @Input()
  myappeaz: Appeaz;

  @Output()
  errorEmit = new EventEmitter<EazlyMessage>();

  toggleState = true;

  assets: Asset[];
  url: string;
  isLoading: boolean;
  dynamics = new Map<number, DynamicModification>();

  errorMessage: EazlyMessage;
  alreadyTried: boolean;
  needToRefresh: boolean;

  // EAZ-1046: Counter of page loading used to check if the injection of DM should be aborted
  currentLoadingNumber: number;

  public toggleElement: HTMLElement;
  public subscription: Subscription;
  public languageSubscription: Subscription;
  public localesDataSubscription: Subscription;

  public languageSelectorElement: HTMLSelectElement;

  private subscriptions: Subscription[] = [];
  private eventListeners: Map<string, Function> = new Map();

  constructor(
    private readonly languageService: LanguageService,
    public sanitizer: DomSanitizer,
    public domhtmlService: DomHtmlService,
    private messagingService: MessagingService,
    private appeazService: AppeazService,
    private dynamicModificationService: DynamicModificationService,
    private paletteService: PaletteService,
    private viewerService: ViewerService,
    private userService: UserService,
    private undoRedoManagerService: UndoRedoManagerService,
    private router: Router,
    private readonly logger: NGXLogger,
    private readonly dataDescriptorService: DataDescriptorService,
    private readonly labelTranslationService: LabelTranslationService,
    @Inject(LOCALE_ID) protected localeId: string) { }

  getappeaz() {
    return this.myappeaz;
  }

  ngOnInit() {
    this.currentLoadingNumber = 0;
    this.isLoading = true;
    this.alreadyTried = false;
    if (this.getappeaz().multilingue) {
      this.localesDataSubscription = this.languageService.localesDataLoadedSubject.subscribe(() => {
        this.logger.debug(`Initializing preview with the locale \'${this.languageService.getSelectedLocale()}\'`);
        this.initAttributes();
      });
    } else {
      this.initAttributes();
    }
    this.init();
  }

  initAttributes(): void {
    this.url = this.iframe.nativeElement.baseURI;
    if (this.getappeaz() && this.stepBeingEdited) {
      this.updateiframe(false);
    }
    this.subscribeToUndoRedoManagerChannels();
    this.url = this.iframe.nativeElement.baseURI;
    this.toggleState = true;
  }

  init(): void {
    this.toggleElement = document.querySelector('eazly-toggle-button .toggle');
    this.subscription = fromEvent(document.body, 'click').subscribe(
      (event: MouseEvent) => {
        if (event.target === this.toggleElement) {
          this.toggleState = !this.toggleState;
          this.updateiframe(true);
        }
      });
    if (this.getappeaz().multilingue) {
      this.languageSubscription = this.languageService.selectedLocaleObservable.subscribe((locale) => {
        if (this.stepBeingEdited && locale) {
          this.logger.debug(`Updating iframe with language ${locale.languageCode}`);
          this.updateiframe(true);
        }
      });
    }
  }

  ngOnDestroy() {
    // Removing event Listeners
    const doc = this.iframe.nativeElement.contentDocument;
    this.eventListeners.forEach((f: Function, key: string) => {
      if (key !== 'load' && doc !== null) {
        doc.removeEventListener(key, f);
      } else {
        this.iframe.nativeElement.removeEventListener(key, f);
      }
    });
    this.iframe.nativeElement.src = '';
    this.unsubscribeToUndoRedoManagerChannels();
    // Temporary fix due to the bug in JIRA EAZ - 341
    if (this.needToRefresh) {
      window.location.reload();
    }
    if (this.errorMessage) {
      this.messagingService.notifyError(this.errorMessage);
    }
    this.subscription.unsubscribe();
    if (this.getappeaz().multilingue) {
      this.languageSubscription.unsubscribe();
      this.localesDataSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.getappeaz() && this.stepBeingEdited) {
      this.isLoading = true;
      // Checking that 'this.stepBeingEdited' has already been initialized
      // and that the stepBeingEdited has changed before trying to call updateIframe.
      // If 'this.stepBeingEdited' is undefined, the 'navigator' component has not been
      // initialized yet. When the navigator will be initialized, 'this.stepBeingEdited'
      // will be initialized as well.
      if (this.stepBeingEdited
        && changes?.stepBeingEdited?.currentValue?.id !== changes?.stepBeingEdited?.previousValue?.id) {
        this.stepBeingEdited = changes.stepBeingEdited.currentValue;
        this.logger.debug(
          'The step being edited changed from \'',
          changes.stepBeingEdited.previousValue,
          '\' to \'',
          changes.stepBeingEdited.currentValue,
          '\'. Calling iframe update.');
        this.updateiframe(false);
      }
    }
  }

  ngAfterViewChecked() {
    jQuery('.collapse').on('show.bs.collapse', function () {
      jQuery(this).siblings('.card-header').addClass('active');
      jQuery(this).parent().addClass('active');
    });
    jQuery('.collapse').on('hide.bs.collapse', function () {
      jQuery(this).siblings('.card-header').removeClass('active');
      jQuery(this).parent().removeClass('active');
    });
  }

  /**
   * EventHandler for the 'load' event of the iframe.
   *
   * This EventHandler retrieves the dynamic modifications of the step being edited
   * and inject their HTML render in the iframe.
   *
   * The 'load' event is triggered each time the content of the iframe is rewritten
   * (see method 'writeIframeContentWindow()'), which includes the initialization of the page
   * and the change of 'this.stepBeingEdited' (see 'ngOnChanges') by the EventEmitter
   * of the Navigator component.
   *
   * The loadEvent parameter is unused by eazly.
   *
   * @param loadEvent the event triggering the EventHandler. Not used by eazly.
   * @param alreadyTried boolean indicating weither the method has already been called or not by
   * the retry mechanism for the JWT
   */
  onLoad(loadEvent: Event, alreadyTried: boolean) {
    this.currentLoadingNumber++;
    this.logger.debug(`OnLoad triggered. Current Loading number: ${this.currentLoadingNumber}`);
    // EAZ-1046: Creating a deep copy of the currentLoadingNumber. This copy will be transferred
    // to the methods handling the loading and injection of DM in the page. This loadingNumber
    // will be compared to the currentLoadingNumber to know if the loading should be aborted or not
    const loadingNumber = Number.parseInt(this.currentLoadingNumber.toString(), 10);
    this.clearAnchors();

    if (this.getappeaz() && this.stepBeingEdited) {
      this.isLoading = true;
      fetch(this.url + 'assets/viewer.js').then((status) => {
        if (status.status === 200) {
          const doc = this.iframe.nativeElement.contentDocument;
          this.initIframe(doc);
          this.dynamics = new Map<number, DynamicModification>();
          this.appeazService.getAppeazJWT(this.getappeaz().id, this.getappeaz().role).subscribe(
            (dataJwt) => {
              this.viewerService.getStepDynamicModifications(this.getappeaz().url, this.stepBeingEdited.id).subscribe(
                (data) => {
                  this.populateIframeWithDM(data, loadingNumber).then(
                    () => {
                      this.isLoading = false;
                    });
                },
                (error) => {
                  // Handling error of viewerService.getStepDynamicModifications
                  this.handleGetStepDMError(error, alreadyTried);
                }
              );
            },
            // Managing error for appeazService.getAppeazJWT
            (error) => {
              // Error Loading
              if (!alreadyTried) {
                this.onLoad(undefined, true);
              } else {
                this.handleGettingJWTFailure(error);
              }
            }
          );
        }
      }).catch(function (error) {
        // Error Loading
      });
    }
  }

  /**
   * Clear all content of 'ectzAnchor' HTMLElements
   *
   * @param doc The Document of the wysiwyg preview
   */
  public clearAnchors(): void {
    // EAZ-1046: Clearing all anchors before loading DMs. In some case, DMs from the previous could be inserted
    // just before the update of the currentLoadingNumber
    const doc = this.iframe.nativeElement.contentDocument;
    const anchorDivs: HTMLCollection = doc.getElementsByClassName('ectzAnchor');
    Array.from(anchorDivs).forEach((anchorDiv: HTMLElement) => {
      anchorDiv.innerHTML = '';
    });
  }

  initIframe(doc: any) {
    this.injectScript(doc);
    this.initListeners(doc);
  }

  injectScript(doc: any) {
    const myscript = this.domhtmlService.createScript(this.url + 'assets/viewer.js');
    doc.head.appendChild(myscript);

    const toolbarscript = this.domhtmlService.createScript(this.url + 'assets/jquery.toolbar.js');
    doc.head.appendChild(toolbarscript);
    if (doc.defaultView.window.eazlyTinyMce === undefined) {
      const tinyMCEScript = this.domhtmlService.createScript(this.url + 'assets/libraries/eazlytinymce/js/eazlytinymce/eazlytinymce.min.js');
      doc.head.appendChild(tinyMCEScript);
    }
    if (this.myappeaz.stylesheet) {
      const customstyle = this.domhtmlService.createCss(this.myappeaz.stylesheet);
      doc.head.appendChild(customstyle);
    }
    // Adding eazly CSS files at the end to give class defined in those CSS a higher priority
    const eazlystyle = this.domhtmlService.createCss(this.url + 'eazly.css');
    doc.head.appendChild(eazlystyle);
    const toolbarstyle = this.domhtmlService.createCss(this.url + 'assets/jquery.toolbar.css');
    doc.head.appendChild(toolbarstyle);
  }

  initListeners(doc: any) {
    const dropEventFunction: Function = this.dropEvent.bind(this);
    const dragstartEventFunction: Function = this.dragstartEvent.bind(this);
    const clickEventFunction: Function = this.clickEvent.bind(this);
    const keyupEventFunction: Function = this.keyupEvent.bind(this);
    const keydownEventFunction: Function = this.keydownEvent.bind(this);
    const focusoutFunction: Function = this.focusout.bind(this);
    const saveTinyMceContentFunction: Function = this.saveTinyMceContent.bind(this);

    this.eventListeners.set('drop', dropEventFunction);
    this.eventListeners.set('dragstart', dragstartEventFunction);
    this.eventListeners.set('click', clickEventFunction);
    this.eventListeners.set('keyup', keyupEventFunction);
    this.eventListeners.set('keydown', keydownEventFunction);
    this.eventListeners.set('focusout', focusoutFunction);
    this.eventListeners.set('tinymce_save', saveTinyMceContentFunction);

    doc.addEventListener('drop', dropEventFunction, false);
    doc.addEventListener('dragstart', dragstartEventFunction, false);
    doc.addEventListener('keyup', keyupEventFunction, false);
    doc.addEventListener('click', clickEventFunction, false);
    doc.addEventListener('keydown', keydownEventFunction, false);
    doc.addEventListener('focusout', focusoutFunction, false);
    doc.addEventListener('tinymce_save', saveTinyMceContentFunction, false);
  }

  // Event triggered by the palette
  drag(event) {
    if (this.toggleState) {
      const data = event.srcElement.id;
      const doca = this.iframe.nativeElement.contentDocument;
      doca.body.attributes['dragData'] = data;
      event.dataTransfer.setDragImage(document.getElementById(data), 0, 0);
      event.dropEffect = 'move';
      this.displayDroppableAreas();
    }
  }

  dragend(event) {
    if (this.toggleState) {
      const doca = this.iframe.nativeElement.contentDocument;
      const taboptions = doca.body.querySelectorAll('.ectzAnchor');
      doca.body.attributes['dragData'] = undefined;
      let index = 0;
      for (index = 0; index < taboptions.length; index++) {
        taboptions[index].classList.remove(EAZLY_DROPPABLE_ACTIVE_CLASS);
      }
    }
  }

  // Event triggered by the move button
  dragstartEvent(event) {
    if (this.toggleState) {
      if (event.target.id.indexOf('eazly-li') >= 0) {
        event.target.parentNode.parentNode.attributes['dragli'] = event.target.parentNode.id;
      } else {
        const doca = this.iframe.nativeElement.contentDocument;
        doca.body.attributes['dragDataLocal'] = event.target.parentNode.parentNode.id;
        // The display of the droppable area (orange dotted line) is done
        // by the viewer.js file in the assets
      }
      const dragIcon = event.target.parentNode.parentNode as HTMLDivElement;
      event.dataTransfer.setDragImage(dragIcon, 0, 0);
    }
  }

  keydownEvent(event) {
    if (event.target.attributes['contenteditable']) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
      if (event.target.innerText.length < 1 && event.keyCode === 8) {
        event.preventDefault();
      }
    }
  }

  keyupEvent(event) {
    event.preventDefault();
    if (event.target && event.target.attributes['contenteditable'] && event.keyCode === 13) {
      event.target.blur();
    }
    // UndoRedo KeyEvents
    // Checking if the user has pressed Ctrl+Z
    if (event.ctrlKey && event.keyCode === 90) {
      this.undoRedoManagerService.undo();
    }
    // Checking if the user has pressed Ctrl+Y
    if (event.ctrlKey && event.keyCode === 89) {
      this.undoRedoManagerService.redo();
    }
  }

  focusout(event) {
    if (event.target.attributes['contenteditable']) {
      event.preventDefault();
      if (!(event.target.innerText.length < 1 && event.keyCode === 8)) {
        const iframeDocument = this.iframe.nativeElement.contentDocument;
        const element = iframeDocument.getElementById(event.target.id) as HTMLDivElement;
        const id: number = Number.parseInt(element.getAttribute('dynamic_id'), 10);

        this.updateLabel(id, element.textContent);
      }
    }
  }

  /**
   * EAZ-716
   * This method calls the updateLabel() method
   * This method is called only when the event 'tinymce_save' is detected
   * The event 'tinymce_save' is fired by onSave() method in EazlyTinyMce component
   * onSave() method is called when the 'blur' is detected on tinyMce editor
   *
   * @param event
   */

  saveTinyMceContent(event: CustomEvent) {
    const eventDetails = event.detail;
    const dynamicId: number = Number.parseInt(eventDetails.dynamicId, 10);
    const textSaved: string = eventDetails.text;
    this.updateLabel(dynamicId, textSaved);
  }

  updateLabel(dynamicId: number, newLabel: string) {
    const labelChanged: boolean = newLabel !== this.labelTranslationService.getDMLabelForTitle(
      this.dynamics.get(dynamicId), this.getappeaz().multilingue, this.languageService.getSelectedLocale());
    if (labelChanged) {
      if (!Number.isNaN(dynamicId)) {
        // JLH:Not Sure but seems to occur when the DM feature a DataDescriptor (ex. Text Input)
        const modificationsToSendToTheEngine: DynamicModification = new DynamicModification();
        modificationsToSendToTheEngine.id = dynamicId;
        modificationsToSendToTheEngine.modificationTarget = new DynamicModificationTarget();
        modificationsToSendToTheEngine.modificationTarget.properties = new DynamicModificationProperties();
        if (!this.getappeaz().multilingue || this.languageService.isDefaultLocaleSelected()) {
          this.logger.debug(`Updating label of the modification target with \'${newLabel}\'`);
          modificationsToSendToTheEngine.modificationTarget.label = newLabel;
        }
        if (this.getappeaz().multilingue) {
          modificationsToSendToTheEngine.modificationTarget.labelTranslations = [];
          const labelTranslation: LabelTranslation = new LabelTranslation(this.languageService.getSelectedLocale(), newLabel.trim());
          modificationsToSendToTheEngine.modificationTarget.labelTranslations.push(labelTranslation);
          this.updateDynamicModif(modificationsToSendToTheEngine, labelTranslation.label);
        } else {
          this.updateDynamicModif(modificationsToSendToTheEngine, newLabel);
        }
      } else {
        // JLH:Not Sure but seems to occur when the DM does not feature a DataDescriptor (ex. Message or Title)
        const modificationsToSendToTheEngine: DynamicModification = new DynamicModification();
        modificationsToSendToTheEngine.modificationTarget = new DynamicModificationTarget();
        modificationsToSendToTheEngine.id = dynamicId;
        modificationsToSendToTheEngine.modificationTarget.label = newLabel;

        this.updateDynamicModif(modificationsToSendToTheEngine, newLabel);
      }
    }
  }

  clickEvent(event) {
    const elementClicked: HTMLElement = event.target as HTMLElement;
    // Checking if the element clicked is a dialog from TinyMCE
    if (!elementClicked.closest('.tox-dialog')) {
      event.preventDefault();
      const iframeDocument = this.iframe.nativeElement.contentDocument;
      if (elementClicked.classList.contains('eazly-delete')) {
        let dynamicIdAsString: string;
        if (elementClicked.hasAttribute('dynamic_id')) {
          dynamicIdAsString = elementClicked.getAttribute('dynamic_id');
        } else {
          dynamicIdAsString = elementClicked.querySelector('[dynamic_id]').getAttribute('dynamic_id');
        }
        const dynamicModifId: number = Number.parseInt(dynamicIdAsString, 10);
        this.handleDeleteDynamicModification(dynamicModifId);
      }
      if (elementClicked.id.indexOf('dialog-visibility-') >= 0) {
        this.openVisibilityModal(elementClicked);
      }
      if (elementClicked.id.indexOf('dialog-config') >= 0) {
        this.openSettingsModal(elementClicked);
      }
      if (elementClicked.id.indexOf('dialog-list-value') >= 0) {
        this.openListValueEditorModal(elementClicked);
      }
      if (elementClicked.id.indexOf('dialog-edit') >= 0 || elementClicked.className.indexOf('eazly-pencil') >= 0) {
        this.changeEditable(event, iframeDocument);
      }
      if (elementClicked.id.indexOf('dialog-required') >= 0 || elementClicked.className.indexOf('eazly-required') >= 0) {
        this.changeRequired(event, iframeDocument);
      }
      if (elementClicked.id.indexOf('eazly-li-delete') >= 0) {
        event.target.parentNode.remove();
      }
    }
  }

  openVisibilityModal(target: any) {
    // Retrieving the latest information of the dynamic modification before opening the visibilityWindow
    const id = target.getAttribute('dynamic_id');
    const dynamicModificationId = Number.parseInt(id, 10);
    this.synchronizeDynamicModificationMap().then(() => {
      this.viewerService.getDynamicModification(this.getappeaz().url, this.stepBeingEdited.id, dynamicModificationId).subscribe(
        (dynamicModification) => {
          this.visibilityWindowComponent.showModal(dynamicModification, this.dynamics);
          this.dynamics.get(dynamicModificationId).modificationTarget.properties.visibilityCriteria
            = dynamicModification.modificationTarget.properties.visibilityCriteria;
        });
    });
  }

  bindDataFromVisibilityModal(data: any) {
    if (data[0] instanceof VisibilityCriteria && data[1] !== undefined) {
      this.editVisibilityConfiguration(data[0], data[1]);
    }
  }

  openSettingsModal(target: any): void {
    const id = target.getAttribute('dynamic_id');
    const dynamicModificationId = Number.parseInt(id, 10);

    this.viewerService.getDynamicModification(this.getappeaz().url, this.stepBeingEdited.id, dynamicModificationId).subscribe(
      (dynamicModification) => {
        const canEditRegEx: boolean = this.myappeaz.expert || this.userService.isAdmin();
        const allReferencedDynamics = this.extractAllRefrencedDynamics(dynamicModification.id);
        this.dmSettingWindowComponent.show(
          dynamicModification,
          canEditRegEx,
          this.getappeaz().url,
          this.stepBeingEdited,
          this.localeId,
          allReferencedDynamics
        );
        this.dynamics.get(dynamicModificationId).modificationTarget.properties.regExpPattern
          = dynamicModification.modificationTarget.properties.regExpPattern;
        this.dynamics.get(dynamicModificationId).modificationTarget.properties.textMaxLength
          = dynamicModification.modificationTarget.properties.textMaxLength;
        this.dynamics.get(dynamicModificationId).modificationTarget.infoText
          = dynamicModification.modificationTarget.infoText;
      });
  }

  openListValueEditorModal(target: any): void {
    const id = target.getAttribute('dynamic_id');
    const dynamicModificationId = Number.parseInt(id, 10);
    this.viewerService.getDynamicModification(this.getappeaz().url, this.stepBeingEdited.id, dynamicModificationId).subscribe(
      (dynamicModification) => {
        this.listValueEditorWindowComponent.show(dynamicModification, this.dynamics);
        this.dynamics.get(dynamicModificationId).modificationTarget.values
          = dynamicModification.modificationTarget.values;
      });
  }

  /**
   * This method updates one or several DM by relaying to the eazly engine a set of DM containing the new properties.
   *
   * Each item of the ordered array 'dynamicModificationsWithValuesToSendToEngine' corresponds to one call to the
   * edit DM API Rest.
   * If the array has more than one DM, all these calls are wrapped inside a unique CompositeEditDMAction
   * which will be registered as a unique Undoable action in the Undo/Redo Stack.
   *
   * This method is called when submitting a modal window (see viewer.component.html).
   * The array of DM is sent by any modal window ("DynamicFieldConfigurationWindowComponent" or "ListValueEditorWIndowComponent")
   * except the visibility modal window using an EventEmitter.
   *
   * The DMs can include the following properties to update:
   * - TextMaxLength and RegExpPattern for the "DynamicFieldConfigurationWindowComponent"
   * - The DataDescriptor (name, association) for the "DynamicFieldCOnfigurationWindowComponent"
   * - DynamicModificationUserValue[] (id, index and value) for the "ListValueEditorWIndowComponent"
   * @param dynamicModificationsWithValuesToSendToEngine a dynamic modification array containing DM
   * with ONLY the values to update that will be sent to the eazly engine
   */
  relayDmUpdateFromModalWindowToEazlyEngine(dynamicModificationsWithValuesToSendToEngine: DynamicModification[]) {
    this.logger.debug('Relaying the modifications from the Modal window to the engine: ', dynamicModificationsWithValuesToSendToEngine);
    if (dynamicModificationsWithValuesToSendToEngine.length > 1) {
      this.updateDynamicModificationWithCompositeAction(dynamicModificationsWithValuesToSendToEngine);
    } else {
      const dynamicModif: DynamicModification = this.dynamics.get(dynamicModificationsWithValuesToSendToEngine[0].id);
      const dmLabel = dynamicModif.modificationTarget.label;
      this.updateDynamicModif(dynamicModificationsWithValuesToSendToEngine[0], dmLabel);
    }
  }

  getLibelle(type: string): string {
    let label: string;
    switch (true) {
      case type.includes('COMBO_BOX'):
        label = 'Label de liste';
        break;
      case type.includes('RADIO_LIST_HORIZONTAL'):
        label = 'Label de liste de choix horizontale';
        break;
      case type.includes('RADIO_LIST_VERTICAL'):
        label = 'Label de liste de choix verticale';
        break;
      case type.includes('RADIO_BUTTON'):
        label = 'Label de choix';
        break;
      case type.includes('HEADER'):
        label = 'Label de titre';
        break;
      case type.includes('MESSAGE'):
        label = 'Label de message';
        break;
      case type.includes('TEXT'):
        label = 'Label de texte';
        break;
      case type.includes('INTEGER'):
        label = 'Label d\'entier';
        break;
      case type.includes('CHECK_BOX'):
        label = 'Label de choix';
        break;
      case type.includes('DATE'):
        label = 'Label de date';
        break;
      default:
        label = 'Label';
    }
    return label;
  }

  dropEvent(event) {
    const doca = this.iframe.nativeElement.contentDocument;
    const type = doca.body.attributes['dragData'];
    const target = event.target;
    if (target.className.indexOf('ectzAnchor') >= 0) {
      let rank = this.getPositon(target, event);
      if (type !== undefined) {
        const shadowPlaceholder = this.appendShadowImage(target, rank);
        const libelle = this.getLibelle(type);
        const dynamicModifProperties = this.dynamicModificationService.createDynamicModificationProperties(type);
        dynamicModifProperties.editable = 'true';
        dynamicModifProperties.required = 'false';
        const modificationTarget = this.dynamicModificationService.createDynamicModificationTargetWithoutDataDescriptor(libelle, dynamicModifProperties);
        const dynamicModif = this.dynamicModificationService.createDynamicModification(undefined, event.target.id, rank, modificationTarget, 'ELEMENT_ADDING');
        doca.body.attributes['dragData'] = undefined;
        this.handleAddDynamicModification(dynamicModif, rank, shadowPlaceholder);
      } else {
        const data = doca.body.attributes['dragDataLocal'];
        const element = doca.getElementById(data) as HTMLElement;
        const divWithDynamicId: HTMLElement = element?.querySelector('[dynamic_id]');
        if (element !== null && divWithDynamicId) {
          const id = Number.parseInt(divWithDynamicId.getAttribute(DYNAMIC_ID), 10);
          const dynamicModif = this.dynamics.get(id);
          const oldAnchor = dynamicModif.anchorReference;
          const newAnchor = event.target.id;
          const oldRank = dynamicModif.priorityOrder;
          const label = element.innerText;
          doca.body.attributes['dragDataLocal'] = undefined;
          if (oldAnchor === newAnchor && oldRank < rank) {
            // Adjusting the rank when moving to the same anchor
            // and after the old rank
            rank--;
          }
          this.handleMoveDynamicModification(id, label, oldAnchor, newAnchor, oldRank, rank);
        }
        const taboptions = doca.body.querySelectorAll('.ectzAnchor');
        let index = 0;
        for (index = 0; index < taboptions.length; index++) {
          taboptions[index].classList.remove(EAZLY_DROPPABLE_ACTIVE_CLASS);
          taboptions[index].classList.remove('eazly-droppable-hover');
        }
      }
    } else if (target.className.indexOf('options-name-input') >= 0) {
      event.preventDefault();
      const id = event.target.parentNode.parentNode.attributes['dragli'];
      const offsetTopEvent = event.offsetY;
      const offsetY = event.target.parentNode.offsetHeight / 2;
      if (offsetTopEvent > offsetY) {
        event.target.parentNode.parentNode.insertBefore(event.target.parentNode.parentNode.querySelector('#' + id), event.target.parentNode.nextSibling);
      } else {
        event.target.parentNode.parentNode.insertBefore(event.target.parentNode.parentNode.querySelector('#' + id), event.target.parentNode);
      }
    } else if (target.className.indexOf('eazly-li-value') >= 0) {
      event.preventDefault();
      const id = event.target.parentNode.attributes['dragli'];
      const offsetTopEvent = event.offsetY;
      const offsetY = event.target.offsetHeight / 2;
      if (offsetTopEvent > offsetY) {
        event.target.parentNode.insertBefore(event.target.parentNode.querySelector('#' + id), event.target.nextSibling);
      } else {
        event.target.parentNode.insertBefore(event.target.parentNode.querySelector('#' + id), event.target);
      }
    }
  }

  public verifiedFieldsWithSameDataDescriptorName(fieldsList: DynamicModification[]): string {
    const dataDescriptorNameSet = new Set<string>();
    const dataDescriptorNameListDouble: string[] = [];
    fieldsList.forEach((dynamicModification: DynamicModification) => {
      if (dataDescriptorNameSet.has(dynamicModification.modificationTarget.dataDescriptor.name)) {
        dataDescriptorNameListDouble.push(dynamicModification.modificationTarget.dataDescriptor.name);
      } else {
        dataDescriptorNameSet.add(dynamicModification.modificationTarget.dataDescriptor.name);
      }
    });
    return dataDescriptorNameListDouble.join(', ');
  }

  public verifiedFieldsWithSameDataDescriptorNameInSameStep(stepList: StepDynamicModifications[]): Map<string, string> {
    let dataDescriptorInDouble = '';
    const mapOfStepNameAndItsDoubleDataDescriptor = new Map<string, string>();
    stepList.forEach((stepDM: StepDynamicModifications) => {
      dataDescriptorInDouble = this.verifiedFieldsWithSameDataDescriptorName(stepDM.modifications);
      if (dataDescriptorInDouble) {
        mapOfStepNameAndItsDoubleDataDescriptor.set(stepDM.stepName, dataDescriptorInDouble);
      }
    });
    return mapOfStepNameAndItsDoubleDataDescriptor;
  }

  // The boolean is used to determine if the method was called after a JWT retrieval failure
  private updateiframe(alreadyTried: boolean) {
    this.appeazService.getAppeazJWT(this.getappeaz().id, this.getappeaz().role).subscribe(
      (dataJwt) => {
        this.paletteService.getAssets(this.getappeaz().url, dataJwt).subscribe(
          (assets) => (this.assets = assets)
        );
        let localeToLoad: Locale;
        if (this.getappeaz().multilingue) {
          localeToLoad = this.languageService.getSelectedLocale();
        } else {
          localeToLoad = this.languageService.getDefaultLocale();
        }
        this.viewerService.getStepPreviewById(this.getappeaz().url, this.stepBeingEdited.id, localeToLoad).subscribe(
          (data) => {
            this.writeIframeContentWindow(data);
            // EAZ-726:
            // Adding the 'load' EventListener on the iframe after the first
            // writing of the iframeContent to prevent triggering the eventHandler twice
            // on Studio initialization
            this.addLoadEventListenerOnIframe();
          },
          (error) => {
            // Handling error for viewerService.getStepPreviewById
            this.handleGetStepPreviewByIdError(error, alreadyTried);
          }
        );
      },
      // Managing error for appeazService.getAppeazJWT
      (error) => {
        if (!alreadyTried) {
          this.updateiframe(true);
        } else {
          this.handleGettingJWTFailure(error);
        }
      }
    );
  }

  private writeIframeContentWindow(data: string) {
    this.removeActiveTinyMceEditors(this.iframe.nativeElement.contentWindow.tinymce);
    this.removeActiveTinyMceEditors(this.iframe.nativeElement.contentWindow.eazlyTinyMce);
    this.logger.debug('Writing iframe content');
    this.iframe.nativeElement.contentWindow.document.open('text/html');
    this.iframe.nativeElement.contentWindow.document.write(data);
    this.iframe.nativeElement.contentWindow.document.close();
    this.logger.debug('Writing iframe content done');
  }

  /**
   * EAZ-783
   * Remove all instances of TinyMce editors from the tinyMce given in parameter.
   * The tinyMce parameter must be the variable in which the library
   * tinyMce exports its module
   *
   * @param tinyMce the variable exported by the TinyMce library
   */
  private removeActiveTinyMceEditors(tinyMce: any) {
    if (tinyMce !== undefined) {
      const editors: any[] = tinyMce.editors;
      for (const editor of editors) {
        this.logger.debug('Removing TinyMce editor:', editor);
        editor.remove();
      }
    }
  }

  /**
   * Method for adding on the iframe the eventListener of the 'load' event.
   *
   * The eventHandler attached to this event will inject the HTML renders
   * of the DynamicModifications when the content of the iframe is rewritten
   * by the method 'writeIframeContentWindow'.
   *
   * EAZ-726:
   * To prevent injecting the HTML renders of the DMs twice in the same page, the
   * eventListener is only added after having successfully initialized
   * the iframe and written in it the content of the first step preview.
   */
  private addLoadEventListenerOnIframe() {
    if (!this.eventListeners.has('load')) {
      this.logger.debug('Adding load event listener on the iframe');
      const onLoadFunction: Function = this.onLoad.bind(this);
      this.eventListeners.set('load', onLoadFunction);
      this.iframe.nativeElement.addEventListener('load', onLoadFunction);
    }
  }

  private async populateIframeWithDM(dynamicModifications: DynamicModification[], loadingNumber: number): Promise<any> {
    this.logger.debug(`Populating iframe with DM for loading number ${loadingNumber}`);
    // Populate the iframe with the DM
    this.sortDynamicModificationsByAnchorAndPriorityOrder(dynamicModifications).then(
      (sortedDm: Map<string, DynamicModification[]>) => {
        if (loadingNumber === this.currentLoadingNumber) {
          this.logger.debug('Loading number matches. Injecting sorted DM');
          this.injectSortedDMIntoThePreview(sortedDm, loadingNumber);
        } else {
          this.logger.debug('Loading number does not match. Aborting injection of sorted DM');
        }
      }
    );
  }

  private async sortDynamicModificationsByAnchorAndPriorityOrder(
    dynamicModificationsToSort: DynamicModification[]): Promise<Map<string, DynamicModification[]>> {
    return new Promise((resolve) => {
      // Sorting the dynamic modifications by anchor
      const dynamicModifMapSortingDmByAnchor: Map<string, DynamicModification[]>
        = this.sortDynamicModificationsByAnchor(dynamicModificationsToSort);
      // Sorting the dynamic modifications by priority order
      for (const arrayToSortByPriorityOrder of dynamicModifMapSortingDmByAnchor.values()) {
        arrayToSortByPriorityOrder.sort((dm1, dm2) => {
          if (dm1.priorityOrder < dm2.priorityOrder) {
            return -1;
          }
          if (dm1.priorityOrder > dm2.priorityOrder) {
            return 1;
          }
          return 0;
        });
      }
      resolve(dynamicModifMapSortingDmByAnchor);
    });
  }

  private sortDynamicModificationsByAnchor(dynamicModificationsToSort: DynamicModification[]): Map<string, DynamicModification[]> {
    const dynamicModifMapSortingDmByAnchor: Map<string, DynamicModification[]> = new Map();
    for (const dynModif of dynamicModificationsToSort) {
      let arrayOfDmOfAnAnchor: DynamicModification[];
      if (dynamicModifMapSortingDmByAnchor.has(dynModif.anchorReference)) {
        arrayOfDmOfAnAnchor = dynamicModifMapSortingDmByAnchor.get(dynModif.anchorReference);
      } else {
        arrayOfDmOfAnAnchor = [];
        dynamicModifMapSortingDmByAnchor.set(dynModif.anchorReference, arrayOfDmOfAnAnchor);
      }
      arrayOfDmOfAnAnchor.push(dynModif);
    }
    return dynamicModifMapSortingDmByAnchor;
  }

  private async injectSortedDMIntoThePreview(dynamicModificationsMap: Map<string, DynamicModification[]>, loadingNumber: number): Promise<any> {
    return new Promise(async (resolve) => {
      for (const entry of dynamicModificationsMap.entries()) {
        if (loadingNumber === this.currentLoadingNumber) {
          const anchor: string = entry[0];
          this.logger.debug('Loading number matches. Injecting sorted dm in anchor ', anchor);
          const dynamicModifications: DynamicModification[] = entry[1];
          this.loadAndAppendAllModificationsOfAnAnchor(anchor, dynamicModifications, loadingNumber);
        } else {
          this.logger.debug('Loading number does not match. Aborting injection of sorted dm in anchors.');
        }
      }
      resolve('done');
    });
  }

  // The array given in parameter must contain only modifications to the same anchor
  private loadAndAppendAllModificationsOfAnAnchor(anchorRef: string, dynamicModifications: DynamicModification[], loadingNumber: number) {
    // Build a HTML Div placeholder for each DM that will be replaced to ensure the correct ordering
    const doc = this.iframe.nativeElement.contentDocument;
    // Trying to find the HTML element corresponding to the anchor in which the DM have been inserted
    // anchorRef is based on the business code of the fields of a page (or the label if there is no business code)
    // Thus, if the business code has been edited or the label has been edited (if there is no business code),
    // The DM will be associated to an AnchorReference that won't be found in the page
    const anchorHtmlElement: HTMLElement = doc.getElementById(anchorRef);
    if (anchorHtmlElement !== null && anchorHtmlElement !== undefined) {
      this.logger.debug('Anchor "', anchorRef, '" found. Injecting the following DM: ', dynamicModifications);
      this.injectPlaceHolderDiv(dynamicModifications, loadingNumber, anchorHtmlElement);
      // Generating the dynamic field dom and replacing the placeholder with it
      this.replacePlaceHolderWithHTMLOfDM(dynamicModifications, anchorRef, loadingNumber, anchorHtmlElement);
    } else {
      this.logger.error('Anchor "', anchorRef, '" was not found. The following DM will not be injected: ', dynamicModifications);
    }
  }

  /**
   * Inject placeholders for dynamic modifications in the HTMLElement of the Anchor.
   * The placeholders will be replaced with the HTML of DM once they are generated.
   * The usage of placeholders is necessary to ensure the order of DM in the Anchor due to the async nature
   * of the generation of HTML of a DM.
   *
   * @param dynamicModifications The dynamic modifications of the anchor that will be generated
   * @param loadingNumber the number of loading of the preview used to check if the loading should be aborted
   * @param anchorHtmlElement HTMLElement of the anchor
   */
  private injectPlaceHolderDiv(dynamicModifications: DynamicModification[], loadingNumber: number, anchorHtmlElement: HTMLElement) {
    for (const dynamicModification of dynamicModifications) {
      if (loadingNumber === this.currentLoadingNumber) {
        this.logger.debug('Loading number matches. Injecting HTML placeholder for DM');
        const divPlaceholderToBeReplaced = document.createElement('div');
        divPlaceholderToBeReplaced.classList.add(dynamicModification.priorityOrder.toString());
        anchorHtmlElement.appendChild(divPlaceholderToBeReplaced);
      } else {
        this.logger.debug('Loading number does not matches. Aborting injection of HTML placeholder for DM');
      }
    }
  }

  /**
   * Replace the placeholders created in the HTMLElement of the anchor with the HTML of the corresponding dynamic modification
   *
   * @param dynamicModifications The dynamic modifications of the anchor that will be generated
   * @param anchorRef the anchorRef
   * @param loadingNumber the number of loading of the preview used to check if the loading should be aborted
   * @param anchorHtmlElement HTMLElement of the anchor
   */
  private replacePlaceHolderWithHTMLOfDM(dynamicModifications: DynamicModification[],
    anchorRef: string,
    loadingNumber: number,
    anchorHtmlElement: HTMLElement) {
    for (const modif of dynamicModifications) {
      if (anchorRef === modif.anchorReference && loadingNumber === this.currentLoadingNumber) {
        // Add an await here for loading DM in block
        this.replacePlaceHolderWithHTMLOfOneDM(modif, loadingNumber, anchorHtmlElement, 0);
      }
    }
  }

  private replacePlaceHolderWithHTMLOfOneDM(modif: DynamicModification,
    loadingNumber: number,
    anchorHtmlElement: HTMLElement,
    counter: number) {
    this.generateDomOfOneDM(modif).then(
      (dynamicDom) => {
        if (dynamicDom) {
          if (loadingNumber === this.currentLoadingNumber) {
            this.logger.debug(`Loading number matches. Replacing placeholder with HTML of DM with id ${modif.id}`);
            const divPlaceholderToBeReplaced = anchorHtmlElement.getElementsByClassName(modif.priorityOrder.toString())[0];
            anchorHtmlElement.replaceChild(dynamicDom, divPlaceholderToBeReplaced);
            this.replaceEctzInstallTooltipScript(modif, anchorHtmlElement);
          } else {
            this.logger.debug('Loading number does not matches. Aborting replacement of placeholders');
          }
        } else if (counter < 3) {
          this.logger.error('Retrying to display dynamic field correctly, attempts number :', counter + 1);
          this.replacePlaceHolderWithHTMLOfOneDM(modif, loadingNumber, anchorHtmlElement, counter + 1);
        }
      }
    );
  }

  // This method generate the DOM of one DM for the loading of all of the DM of an anchor
  private async generateDomOfOneDM(modif: DynamicModification): Promise<HTMLDivElement> {
    return new Promise((resolve) => {
      // Register the DM in the "this.dynamics" map
      const visibilityCriteriaJSON: VisibilityCriteria = JSON.parse(JSON.stringify(modif.modificationTarget.properties.visibilityCriteria));
      modif.modificationTarget.properties.visibilityCriteria.visibilityMode = visibilityCriteriaJSON.visibilityMode;
      modif.modificationTarget.properties.visibilityCriteria.conditions = visibilityCriteriaJSON.conditions;
      this.dynamics.set(modif.id, modif);
      this.logger.debug(`Generating DOM of DM with [ID = ${modif.id}] with the locale ${this.languageService.getSelectedLocale()}`);
      this.viewerService.getDynamicModificationPreviewById(
        this.getappeaz().url, this.stepBeingEdited.id, modif.id, this.languageService.getSelectedLocale()).subscribe(
          (data) => {
            if (this.doesLabelGeneratedCorrectly(data)) {
              const domparser = new DOMParser();
              const dynamicHTML = domparser.parseFromString(data, 'text/html');
              const element = dynamicHTML.body.firstChild as HTMLElement;
              element.setAttribute('dynamic_id', modif.id.toString());
              // This method will finish by resolving with the HTMLDivElement for the DM.
              // It will then be injected in the preview by the caller method
              if (this.toggleState) {
                // EAZ-997: Generating eazly HTML tools and wrapping the HTMLElement of the field in it.
                this.dataDescriptorService.isDMLinked(this.getappeaz().url, modif).then(
                  (isLinked) => {
                    const dynamicDom = this.domhtmlService.getDynamicDom(
                      element,
                      this.url,
                      modif,
                      this.getappeaz().multilingue,
                      isLinked);
                    resolve(dynamicDom);
                  });
              } else {
                // EAZ-997: Resolving the HTMLElement returned by the "getDynamicModificationPreviewById" API after wrapping it in a div
                const divWrapper: HTMLDivElement = document.createElement('div');
                divWrapper.appendChild(element);
                resolve(divWrapper);
              }
            } else {
              this.logger.error(
                'The dynamic field label not generated correctly', data);
              resolve(null);
            }
          });
    });
  }

  private displayDroppableAreas() {
    const doca = this.iframe.nativeElement.contentDocument;
    const taboptions = doca.body.querySelectorAll('.ectzAnchor');
    let index = 0;
    for (index = 0; index < taboptions.length; index++) {
      taboptions[index].classList.add(EAZLY_DROPPABLE_ACTIVE_CLASS);
    }
  }

  private changeEditable(event: any, iframeDocument: any) {
    let targetid;
    let editableToggleButtonElement: HTMLElement;
    if (event.target.id.indexOf('dialog-edit') >= 0) {
      // User has clicked on the background of the button
      targetid = event.target.id.substring(12, event.target.id.length);
      editableToggleButtonElement = event.target as HTMLElement;
    } else if (event.target.className.indexOf('eazly-pencil') >= 0) {
      // User has clicked on the icon of the button
      editableToggleButtonElement = event.target.parentNode as HTMLElement;
      targetid = event.target.parentNode.id.substring(12, event.target.parentNode.id.length);
    }
    const element = iframeDocument.getElementById(targetid) as HTMLElement;
    const divelement = element.querySelector('[dynamic_id]') as HTMLDivElement;
    const primaryBox = element.getElementsByClassName('eCitizPrimaryBox').item(0);
    const id = divelement.getAttribute(DYNAMIC_ID);
    const modificationsToSendToTheEngine: DynamicModification = new DynamicModification();
    modificationsToSendToTheEngine.modificationTarget = new DynamicModificationTarget();
    modificationsToSendToTheEngine.modificationTarget.properties = new DynamicModificationProperties();
    modificationsToSendToTheEngine.id = Number.parseInt(id, 10);
    const redForbiddenIconElement: any = editableToggleButtonElement.children[1];
    if (redForbiddenIconElement.style.opacity === '1' || redForbiddenIconElement.style.opacity === '') {
      modificationsToSendToTheEngine.modificationTarget.properties.editable = 'true';
    } else {
      modificationsToSendToTheEngine.modificationTarget.properties.editable = 'false';
      modificationsToSendToTheEngine.modificationTarget.properties.required = 'false';
    }
    this.updateDynamicModif(modificationsToSendToTheEngine, primaryBox.textContent);
  }

  private changeRequired(event: any, iframeDocument: any) {
    let targetid;
    let requiredButton;

    if (event.target.id.indexOf('dialog-required') >= 0) {
      // User has clicked on the background of the button
      targetid = event.target.id.substring(16, event.target.id.length);
      requiredButton = event.target as HTMLElement;
    } else if (event.target.className.indexOf('eazly-required') >= 0) {
      // User has clicked on the image of the icon
      requiredButton = event.target.parentNode as HTMLElement;
      targetid = event.target.parentNode.id.substring(16, event.target.parentNode.id.length);
    }
    const element = iframeDocument.getElementById(targetid) as HTMLElement;
    const divelement = element.querySelector('[dynamic_id]') as HTMLDivElement;
    const id = divelement.getAttribute(DYNAMIC_ID);
    const modificationsToSendToTheEngine: DynamicModification = new DynamicModification();
    modificationsToSendToTheEngine.modificationTarget = new DynamicModificationTarget();
    modificationsToSendToTheEngine.modificationTarget.properties = new DynamicModificationProperties();
    modificationsToSendToTheEngine.id = Number.parseInt(id, 10);

    const primaryBox = element.getElementsByClassName('eCitizPrimaryBox').item(0);
    if (requiredButton.children[0].classList.contains(EAZLY_REQUIRED_OFF)) {
      modificationsToSendToTheEngine.modificationTarget.properties.required = 'true';
    } else {
      modificationsToSendToTheEngine.modificationTarget.properties.required = 'false';
    }
    this.updateDynamicModif(modificationsToSendToTheEngine, primaryBox.textContent);
  }

  private editVisibilityConfiguration(visibilityCriteria: VisibilityCriteria, dynamicToModify: any) {
    const modificationsToSendToTheEngine: DynamicModification = new DynamicModification();
    modificationsToSendToTheEngine.modificationTarget = new DynamicModificationTarget();
    modificationsToSendToTheEngine.modificationTarget.properties = new DynamicModificationProperties();
    modificationsToSendToTheEngine.modificationTarget.properties.visibilityCriteria = new VisibilityCriteria();
    modificationsToSendToTheEngine.id = dynamicToModify.id;
    modificationsToSendToTheEngine.modificationTarget.properties.visibilityCriteria.visibilityMode = visibilityCriteria.visibilityMode;
    if (visibilityCriteria.visibilityMode !== VisibilityMode.ALWAYS_VISIBLE) {
      modificationsToSendToTheEngine.modificationTarget.properties.visibilityCriteria.conditions = visibilityCriteria.conditions;
    }
    const dmLabel = dynamicToModify.modificationTarget.label;
    this.updateDynamicModif(modificationsToSendToTheEngine, dmLabel);
  }

  private handleMoveDynamicModification(id: number, label: string, oldAnchor: string, newAnchor: string, oldRank: number, newRank: number) {
    if (oldAnchor === newAnchor && oldRank === newRank) {
      // Nothing to do for the engine, DM droped in the same anchor between the same DM
    } else {
      const modificationsToSendToTheEngine: DynamicModification = new DynamicModification();
      modificationsToSendToTheEngine.id = id;
      modificationsToSendToTheEngine.anchorReference = newAnchor;
      modificationsToSendToTheEngine.priorityOrder = Math.max(0, newRank);
      this.updateDynamicModif(modificationsToSendToTheEngine, label);
    }
  }

  private appendDynamicModification(dynamicId: number, stepId: string, rank: number, creatingFromDropEvent: boolean, shadowPlaceholder: HTMLDivElement): Promise<any> {
    return new Promise((resolve, reject) => {
      this.viewerService.getDynamicModificationPreviewById(
        this.getappeaz().url, stepId, dynamicId, this.languageService.getSelectedLocale()).subscribe(
          (data) => {
            const domparser = new DOMParser();
            const dynamicHTML = domparser.parseFromString(data, 'text/html');
            const element = dynamicHTML.body.firstChild as HTMLElement;
            element.setAttribute('dynamic_id', dynamicId.toString());
            // Retrieving dynamic modification from this.dynamics since it has been synchronized in the "doSubscription"
            // The Dynamic modification from the AddDmOperation does not have the dataDescriptor
            const dynamicModification: DynamicModification = this.dynamics.get(dynamicId);
            this.dataDescriptorService.isDMLinked(this.getappeaz().url, dynamicModification).then(
              (isLinked) => {
                const htmlDivOfDm = this.domhtmlService.getDynamicDom(
                  element,
                  this.url,
                  dynamicModification,
                  this.getappeaz().multilingue,
                  isLinked);
                const doc = this.iframe.nativeElement.contentDocument;
                const targetAnchor: HTMLDivElement = doc.getElementById(dynamicModification.anchorReference);
                if (targetAnchor !== undefined && targetAnchor !== null) {
                  if (creatingFromDropEvent) {
                    targetAnchor.replaceChild(
                      htmlDivOfDm,
                      shadowPlaceholder);
                  } else {
                    // If the new DM is the last of the anchor, appendChild at the end
                    if (targetAnchor.children.length - 1 < rank) {
                      targetAnchor.appendChild(htmlDivOfDm);
                    } else {
                      // Inserting the DM between existing DM and updating their position
                      const oldNode: Element = targetAnchor.children.item(rank);
                      targetAnchor.insertBefore(htmlDivOfDm, oldNode);
                    }
                  }
                  this.replaceEctzInstallTooltipScript(dynamicModification, element);
                } else {
                  this.logger.error(
                    'The anchor ', dynamicModification.anchorReference,
                    ' could not be found. The dynamic modification ', dynamicId, ' can\'t be added.');
                }
                resolve('done');
              });
          });
    });
  }

  private appendShadowImage(target: any, rank: number): HTMLDivElement {
    const dynamic = document.createElement('div');
    const shadowImage = this.domhtmlService.createImage(this.url + 'assets/shadow.svg', 'shadowImage', '100%', '100%');
    dynamic.appendChild(shadowImage);
    target.insertBefore(dynamic, target.children[rank]);
    return dynamic;
  }

  private getPositon(target, event): number {
    const offsetTopEvent = event.offsetY;
    const offsetTopDiv = target.offsetTop;
    let rank = 0;
    for (const child of target.childNodes) {
      if (child.offsetTop + (child.offsetHeight / 2) - offsetTopDiv < offsetTopEvent) {
        rank++;
      }
    }
    return rank;
  }

  /***********************************************/
  /********** DM OPERATION  HANDLING *************/
  /***********************************************/
  private handleAddDynamicModification(dynamicModif: DynamicModification, rank: number, shadowPlaceholder: HTMLDivElement) {
    const addDmAction: AddDMAction = new AddDMAction(
      this.viewerService,
      this.getappeaz().id,
      this.getappeaz().url,
      this.getappeaz().role,
      this.stepBeingEdited.id,
      dynamicModif,
      rank,
      shadowPlaceholder
    );
    this.undoRedoManagerService.do(addDmAction);
  }

  // Update the preview by appending the new DM
  private handleAddDmSuccess(operationMessage: OperationMessage, operation: Operation, creatingFromDropEvent: boolean, shadowPlaceholder: HTMLDivElement) {
    // Checking if the operation is affecting a different page
    // that is not currently displayed by the viewer
    if (this.stepBeingEdited.id === operation.getStepId()) {
      const addDmOperation: AddDmOperation = operation as AddDmOperation;
      this.appendDynamicModification(operationMessage.getResultOfTheOperation(),
        addDmOperation.getStepId(), addDmOperation.getRank(), creatingFromDropEvent, shadowPlaceholder);
    }
    // The viewer does not need to edit the HTML if the stepBeingEdited is different than the step of the operation
    // The preview will be updated automatically with the loading of the page after the navigation
  }

  private handleAddDmFailure(error: any, dynamicModif: DynamicModification, rank: any, alreadyRetried: boolean, shadowPlaceholder: HTMLDivElement) {
    if (401 === error.status && !alreadyRetried) {
      this.alreadyTried = true;
      // Session expired (JWT expired), should renew the JWT
      this.appeazService.getAppeazJWT(this.getappeaz().id, this.getappeaz().role).subscribe(
        (dataJwt) => {
          this.handleAddDynamicModification(dynamicModif, rank, shadowPlaceholder);
        },
        (errorOnGettingJWT) => {
          this.handleGettingJWTFailure(error);
        }
      );
    } else {
      // Other error specific to viewerService.updateDynamicModification
      this.handleAddDynamicModificationError(error);
    }
  }

  private updateDynamicModif(dynamicModif: DynamicModification, dmLabel: string) {
    const editDmAction: EditDMAction = new EditDMAction(
      this.viewerService,
      this.getappeaz().id,
      this.getappeaz().url,
      this.getappeaz().role,
      this.stepBeingEdited.id,
      dynamicModif,
      dmLabel
    );
    this.undoRedoManagerService.do(editDmAction);
  }

  /**
   * This method builds a Composite Action for editing Dynamic Modifications.
   * All the editions will be registered as a unique "Undoable" action in the
   * UndoRedo stack.
   *
   * The order of execution of the sub-actions which will constitute the Composite Action
   * is the same as the order of the array 'dynamicModifications' given in the parameters.
   *
   * For each DynamicModification of the array, the Dynamic Modifications sharing the same Id
   * in the DataBase will be updated to match the parameters described in the Dynamic Modification
   * being iterated.
   *
   * The Composite Action is only used for handling the changes of DataDescriptors done in the
   * DynamicFieldConfigurationWindow which requires a set of sequential operations to be performed
   *
   * @param dynamicModifications an array of DynamicModifications describing the new parameters to send to the engine.
   */
  private updateDynamicModificationWithCompositeAction(dynamicModifications: DynamicModification[]) {
    const compositeEditDmAction: CompositeEditDMAction = new CompositeEditDMAction(
      this.logger,
      this.viewerService,
      this.getappeaz().id,
      this.getappeaz().url,
      this.getappeaz().role,
      this.stepBeingEdited.id,
      dynamicModifications);
    compositeEditDmAction.buildOperations().then(() => {
      this.undoRedoManagerService.do(compositeEditDmAction);
    });
  }

  // Update the preview and the toolbars by updating the DOM related to the edited DM
  private handleEditDmSuccess(operation: Operation) {
    this.logger.debug(
      'Handle edit DM success on ',
      operation.getStepId(),
      ' while being at ',
      this.stepBeingEdited
    );
    const editOperation: EditDmOperation = operation as EditDmOperation;

    // Checking if the edition of the DM is done on the page currently displayed by the Wysiwyg preview
    if (this.stepBeingEdited.id === operation.getStepId()) {
      this.logger.debug(
        'Updating the preview of the dynamic modification. ',
        editOperation.getDynamicModification(),
        ' by updating the following properties: ',
        editOperation.getEditedProperties());
      // The step being displayed contains the DM being edited: Updating the preview
      const dynamicModification: DynamicModification = editOperation.getDynamicModification();
      const dynamicModificationId: number = dynamicModification.id;
      const iframeDocument = this.iframe.nativeElement.contentDocument;
      const mainDivContainingDm = iframeDocument.querySelector(`div[dynamic_id="${dynamicModificationId}"]`);
      this.logger.debug('Handle edit DM success with properties: ', editOperation.getEditedProperties());

      for (const editedProperty of editOperation.getEditedProperties()) {
        if ('dataDescriptorName' === editedProperty) {
          this.regeneratePreview(operation.getStepId(), dynamicModificationId, mainDivContainingDm);
        }
        if ('Label' === editedProperty || 'labelTranslations' === editedProperty) {
          this.handleLabelUpdate(mainDivContainingDm, dynamicModification, dynamicModificationId);
          // If the DM is associated to a ListValue, the UI of the ListValue is updated
          if (dynamicModification.modificationTarget.values) {
            // Updating the preview with the values associated with the new DataDescriptorName
            this.handleValueListUpdate(
              mainDivContainingDm,
              operation.getStepId(),
              dynamicModificationId
            );
          }
          this.regeneratePreview(operation.getStepId(), dynamicModificationId, mainDivContainingDm);
        }
        if ('Values' === editedProperty) {
          // Updating the previews with the new values for this DataDescriptorName
          this.dynamics.get(dynamicModificationId).modificationTarget.values = dynamicModification.modificationTarget.values;
          this.handleValueListUpdate(
            mainDivContainingDm,
            operation.getStepId(),
            dynamicModificationId
          );
        }
        if ('Editable' === editedProperty) {
          this.handleEditableUpdate(
            mainDivContainingDm,
            iframeDocument,
            operation.getStepId(),
            dynamicModificationId,
            dynamicModification
          );
        }
        if ('Required' === editedProperty) {
          this.handleRequiredUpdate(
            mainDivContainingDm,
            iframeDocument,
            operation.getStepId(),
            dynamicModificationId,
            dynamicModification
          );
        }
        if ('textMaxLength' === editedProperty) {
          this.dynamics.get(dynamicModificationId).modificationTarget.properties.textMaxLength =
            dynamicModification.modificationTarget.properties.textMaxLength;
          this.handleMaxNumberOfCharactersUpdate(
            mainDivContainingDm,
            iframeDocument,
            operation.getStepId(),
            dynamicModification,
            dynamicModificationId
          );
        }
        if ('regExpPattern' === editedProperty) {
          this.dynamics.get(dynamicModificationId).modificationTarget.properties.regExpPattern =
            dynamicModification.modificationTarget.properties.regExpPattern;
          this.handleRegExUpdate(
            mainDivContainingDm,
            iframeDocument,
            operation.getStepId(),
            dynamicModification,
            dynamicModificationId
          );
        }

        if ('defaultValue' === editedProperty) {
          // Regenerate the preview
          this.regeneratePreview(operation.getStepId(), dynamicModificationId, mainDivContainingDm);
        }

        if ('infoText' === editedProperty) {
          this.dynamics.get(dynamicModificationId).modificationTarget.infoText = dynamicModification.modificationTarget.infoText;
          this.handleInfoTextUpdate(mainDivContainingDm, iframeDocument, operation.getStepId(), dynamicModification, dynamicModificationId);
          this.regeneratePreview(operation.getStepId(), dynamicModificationId, mainDivContainingDm);
        }

        if ('visibilityCriteria' === editedProperty) {
          this.dynamics.get(dynamicModificationId).modificationTarget.properties.visibilityCriteria =
            dynamicModification.modificationTarget.properties.visibilityCriteria;
          this.handleVisibilityUpdate(
            mainDivContainingDm,
            iframeDocument,
            operation.getStepId(),
            dynamicModificationId,
            dynamicModification
          );
        }

        if ('AnchorReference' === editedProperty) {
          this.handleMoveUpdate(
            mainDivContainingDm,
            dynamicModification,
            iframeDocument
          );
        }
      }
    } else {
      // The step being displayed does not contains the DM being edited:
      // Nothing to do (the preview will be loaded automatically with the new values)
      this.logger.debug(
        'Skipping preview update of the dynamic modification: the step owning the DM has not been loaded yet. ',
        editOperation.getDynamicModification()
      );
    }
  }

  private handleInfoTextUpdate(mainDivContainingDm: any, iframeDocument: any, stepId: string,
    dynamicModification: DynamicModification, dynamicModificationId: number) {
    const infoText = dynamicModification?.modificationTarget?.infoText;
    if (infoText !== undefined) {
      this.dynamics.get(dynamicModificationId).modificationTarget.infoText = infoText;
    }
  }

  private handleLabelUpdate(mainDivContainingDm: any, dynamicModification: DynamicModification, dynamicModificationId: number) {
    const spanContainingLabelToEdit: HTMLElement = mainDivContainingDm.getElementsByClassName('eazly-editable-label')[0];
    const dynModifInFrontModel = this.dynamics.get(dynamicModificationId);
    dynModifInFrontModel.modificationTarget.label = dynamicModification.modificationTarget.label;
    spanContainingLabelToEdit.textContent = dynamicModification.modificationTarget.label;
  }

  private handleValueListUpdate(mainDivContainingDm: any, stepId: string, dynamicModificationId: number) {
    this.regeneratePreview(stepId, dynamicModificationId, mainDivContainingDm);
    this.viewerService.getDynamicModification(this.getappeaz().url, this.stepBeingEdited.id, dynamicModificationId).subscribe(
      (response) => {
        this.dynamics.get(dynamicModificationId).modificationTarget.values = response.modificationTarget.values;
      });
  }

  private handleMaxNumberOfCharactersUpdate(
    mainDivContainingDm: any,
    iframeDocument: any,
    stepId: string,
    dynamicModification: DynamicModification,
    dynamicModificationId: number) {
    const value = dynamicModification.modificationTarget.properties.textMaxLength;
    this.dynamics.get(dynamicModificationId).modificationTarget.properties.textMaxLength = value;
  }

  private handleRegExUpdate(
    mainDivContainingDm: any,
    iframeDocument: any,
    stepId: string,
    dynamicModification: DynamicModification,
    dynamicModificationId: number) {
    const regEx = dynamicModification.modificationTarget.properties.regExpPattern;
    this.dynamics.get(dynamicModificationId).modificationTarget.properties.regExpPattern = regEx;
  }

  private handleEditableUpdate(
    mainDivContainingDm: any,
    iframeDocument: any,
    stepId: string,
    dynamicModificationId: number,
    dynamicModification: DynamicModification) {
    const pencilButtons = iframeDocument.querySelectorAll(`a[eazly-button=editable][dynamic_id="${dynamicModificationId}"]`);
    const pencilButton: HTMLElement = pencilButtons[pencilButtons.length - 1];
    const requiredButtons = iframeDocument.querySelectorAll(`a[eazly-button=required][dynamic_id="${dynamicModificationId}"]`);
    const requiredButton: HTMLElement = requiredButtons[requiredButtons.length - 1];

    let redForbiddenIconElement: HTMLElement;
    if (pencilButton != null) {
      redForbiddenIconElement = pencilButton.childNodes.item(1) as HTMLElement;
    }
    if (dynamicModification.modificationTarget.properties.editable === 'false') {
      this.handleNonEditableModificationTarget(pencilButton, requiredButton, redForbiddenIconElement, mainDivContainingDm, stepId, dynamicModificationId);
    } else {
      this.dynamics.get(dynamicModificationId).modificationTarget.properties.editable = 'true';
      // Regenerate the preview
      this.regeneratePreview(stepId, dynamicModificationId, mainDivContainingDm);
      if (pencilButton != null) {
        pencilButton.setAttribute('title', 'Rendre le champ non éditable');
      }
      if (redForbiddenIconElement != null) {
        redForbiddenIconElement.style.opacity = '0';
      }
      if (requiredButton != null) {
        requiredButton.classList.remove(EAZLY_REQUIRED_DISABLED);
        requiredButton.children[0].classList.add(EAZLY_REQUIRED_OFF);
      }
    }
  }

  private handleNonEditableModificationTarget(
    pencilButton: HTMLElement,
    requiredButton: HTMLElement,
    redForbiddenIconElement: HTMLElement,
    mainDivContainingDm: any,
    stepId: string,
    dynamicModificationId: number,
  ) {
    this.dynamics.get(dynamicModificationId).modificationTarget.properties.editable = 'false';
    this.dynamics.get(dynamicModificationId).modificationTarget.properties.required = 'false';
    // Regenerate the preview
    this.regeneratePreview(stepId, dynamicModificationId, mainDivContainingDm);
    if (redForbiddenIconElement != null) {
      redForbiddenIconElement.style.opacity = '1';
    }
    if (pencilButton != null) {
      pencilButton.setAttribute('title', 'Rendre le champ éditable');
    }
    if (this.dynamics.get(dynamicModificationId).modificationTarget.properties.required === 'false') {
      if (requiredButton != null) {
        requiredButton.children[0].classList.add(EAZLY_REQUIRED_OFF);
        requiredButton.classList.add(EAZLY_REQUIRED_DISABLED);
      }
    } else {
      if (requiredButton != null) {
        requiredButton.classList.add(EAZLY_REQUIRED_DISABLED);
      }
    }
  }

  private handleImportSuccess(importOperation: ImportOperation): void {
    let stepBeingEditedIsModified = false;
    for (const stepModification of importOperation.stepsToImport) {
      if (this.stepBeingEdited.id === stepModification.stepId) {
        stepBeingEditedIsModified = true;
      }
    }
    if (stepBeingEditedIsModified) {
      this.logger.debug('Import has modified the current step. Refreshing iframe.');
      this.updateiframe(false);
    } else {
      this.logger.debug('Import has not modified the current step. No iframe refreshing.');
    }
    const errorReport = new EazlyMessage(AlertType.success,
      'ImportExportService', MessageCause.ImportSuccessMessage, '');
    this.messagingService.notifyError(errorReport);
  }

  private handleImportFailed(error: any, importOperation: ImportOperation): void {
    if (error.status === 409) {
      this.verifiedFieldsWithSameDataDescriptorNameInSameStep(importOperation.getStepsToImport()).forEach((dataDescriptorNames, stepName) => {
        this.errorMessage = new EazlyMessage(
          AlertType.danger,
          stepName,
          MessageCause.ImportFailedMessageBecauseOfDataDescriptorConflict,
          dataDescriptorNames,
        );
        this.messagingService.notifyError(this.errorMessage);
      });
    } else {
      this.errorMessage = new EazlyMessage(
        AlertType.danger,
        'ImportExportService',
        MessageCause.ImportFailedMessageBecauseOfWrongData,
        ''
      );
      this.messagingService.notifyError(this.errorMessage);
    }
  }

  private regeneratePreview(stepId: string, dynamicModificationId: number, mainDivContainingDm: HTMLDivElement) {
    const appeazUrl: string = this.getappeaz().url;
    // Retrieving the HTML preview of the field
    this.viewerService.getDynamicModificationPreviewById(
      appeazUrl,
      stepId,
      dynamicModificationId,
      this.languageService.getSelectedLocale()).subscribe(
        (data) => {
          const domparser = new DOMParser();
          const dynamicHTML = domparser.parseFromString(data, 'text/html');
          const element = dynamicHTML.body.firstChild as HTMLElement;
          element.setAttribute('dynamic_id', dynamicModificationId.toString());
          // Retrieving the latest data on the DynamicModification
          this.viewerService.getDynamicModification(appeazUrl, stepId, dynamicModificationId).subscribe(
            (dynamicModification: DynamicModification) => {
              this.dynamics.set(dynamicModificationId, dynamicModification);
              // Checking the DataDescriptor label to display
              this.dataDescriptorService.isDMLinked(this.getappeaz().url, dynamicModification).then(
                (isLinked) => {
                  this.domhtmlService.updateElement(element, this.dynamics.get(dynamicModificationId), isLinked,
                    this.getappeaz().multilingue);
                  const elementToReplace: HTMLDivElement = mainDivContainingDm.querySelector(`#${element.id}`);
                  elementToReplace.parentElement.replaceChild(element, elementToReplace);
                  this.replaceEctzInstallTooltipScript(dynamicModification, element);
                });
              if (this.getappeaz().multilingue) {
                this.domhtmlService.refreshI18NWarning(mainDivContainingDm, dynamicModification);
              }
            });
        });
  }

  /**
   * ECTZ-8928
   * Replace the HTMLScriptElement containing "ectzInstallToolip" of the HTMLElement of a DynamicModification generated by the engine
   * with a new HTMLScriptElement created from the iframeDocument to make it evaluated when inserted in the page.
   *
   * @param dynamicModification The dynamic modification corresponding to the HTMLElement to modify
   * @param element The HTMLElement representing the DynamicModification
   */
  private replaceEctzInstallTooltipScript(dynamicModification: DynamicModification, element: HTMLElement): void {
    if (dynamicModification.modificationTarget.infoText !== null && dynamicModification.modificationTarget.infoText !== undefined) {
      this.logger.debug(`Replacing scripts for the modification with id ${dynamicModification.id}`);
      Array.from(element.getElementsByTagName('script')).forEach((script: HTMLScriptElement) => {
        if (script.textContent.includes('ectzInstallToolip')) {
          const iframeDocument: Document = this.iframe.nativeElement.contentDocument;
          const parentElement: HTMLElement = script.parentElement;
          this.logger.debug(`Script with installToolTip found. Replacing the script from the parent ${parentElement}.`);
          // Creating a new script from the iframe to make it evaluated when adding it to the DOM
          const newScript: HTMLScriptElement = iframeDocument.createElement('script');
          newScript.textContent = script.textContent;
          Array.from(script.attributes).forEach((attribute: Attr) => {
            newScript.setAttribute(attribute.name, attribute.value);
          });
          parentElement.replaceChild(newScript, script);
        }
      });
    }
  }

  private handleRequiredUpdate(
    mainDivContainingDm: any,
    iframeDocument: any,
    stepId: string,

    dynamicModificationId: number,
    dynamicModification: DynamicModification) {
    const buttonsRequired = iframeDocument.querySelectorAll(`a[eazly-button=required][dynamic_id="${dynamicModificationId}"]`);
    const requiredButton: HTMLElement = buttonsRequired[buttonsRequired.length - 1];

    if (dynamicModification.modificationTarget.properties.required === 'false') {
      this.handleRequiredModificationTarget(requiredButton, mainDivContainingDm, stepId, dynamicModificationId);
    } else {
      this.dynamics.get(dynamicModificationId).modificationTarget.properties.required = 'true';
      // Regenerate the preview
      this.regeneratePreview(stepId, dynamicModificationId, mainDivContainingDm);

      if (requiredButton != null) {
        requiredButton.setAttribute('title', 'Rendre ce champ facultatif');
        requiredButton.firstElementChild.classList.remove(EAZLY_REQUIRED_OFF);
      }
    }
  }

  private handleRequiredModificationTarget(
    requiredButton: HTMLElement,
    mainDivContainingDm: any,
    stepId: string,
    dynamicModificationId: number,
  ) {
    this.dynamics.get(dynamicModificationId).modificationTarget.properties.required = 'false';
    // Regenerate the preview
    this.regeneratePreview(stepId, dynamicModificationId, mainDivContainingDm);

    if (requiredButton != null) {
      requiredButton.setAttribute('title', 'Rendre ce champ obligatoire');
      requiredButton.firstElementChild.classList.add(EAZLY_REQUIRED_OFF);
    }
  }

  private handleVisibilityUpdate(
    mainDivContainingDm: any,
    iframeDocument: any,
    stepId: string,
    dynamicModificationId: number,
    dynamicModification: DynamicModification) {
    // Regenerate the preview
    this.regeneratePreview(stepId, dynamicModification.id, mainDivContainingDm);
    this.refreshEyeIcon(dynamicModification);
  }

  private handleMoveUpdate(
    mainDivContainingDm: any,
    dynamicModification: DynamicModification,
    iframeDocument: any) {
    const oldAnchor = mainDivContainingDm.parentNode;
    const oldAnchorReference = oldAnchor.attributes['id'].value;
    const newAnchorReference = dynamicModification.anchorReference;
    if (mainDivContainingDm) {
      // Retrieve the anchor and the rank to insert the DM
      const targetAnchor: HTMLElement = iframeDocument.querySelector(`div[id="${newAnchorReference}"]`);
      const rank: number = dynamicModification.priorityOrder;
      let nodeToInsertBefore: Node = targetAnchor.children[rank];
      if (oldAnchorReference === newAnchorReference) {
        // Adjustement of the rank may be required when moving within the same anchor
        const children: HTMLCollection = targetAnchor.children;
        // Calculating the old rank of the field
        let oldRank = 0;
        let oldRankFound = mainDivContainingDm === children[oldRank];
        while (oldRank < children.length && !oldRankFound) {
          oldRank++;
          oldRankFound = mainDivContainingDm === children[oldRank];
        }
        if (oldRank < rank) {
          // Inserting the modification before the next sibling when old rank is lower than the new rank
          nodeToInsertBefore = targetAnchor.children[rank].nextSibling;
        }
      }
      targetAnchor.insertBefore(mainDivContainingDm, nodeToInsertBefore);
    }
  }

  private handleEditDmFailure(error: any, dynamicModif: DynamicModification, dmLabel: string, alreadyRetried: boolean) {
    // Handling error of UpdateDynamicModification
    if (401 === error.status && !alreadyRetried) {
      this.alreadyTried = true;
      // Session expired (JWT expired), should renew the JWT
      this.appeazService.getAppeazJWT(this.getappeaz().id, this.getappeaz().role).subscribe(
        (dataJwt) => {
          this.updateDynamicModif(dynamicModif, dmLabel);
        },
        (errorOnGettingJWT) => {
          this.handleGettingJWTFailure(error);
        }
      );
    } else {
      // Other error specific to viewerService.updateDynamicModification
      this.handleUpdateDynamicModificationError(error);
    }
  }

  private handleDeleteDynamicModification(dynamicModifId: number) {
    const dynamicModif: DynamicModification = this.dynamics.get(dynamicModifId);
    const deleteDmAction: DeleteDmAction = new DeleteDmAction(
      this.viewerService,
      this.getappeaz().url,
      this.stepBeingEdited.id,
      this.getappeaz().id,
      this.getappeaz().role,
      dynamicModif
    );
    this.undoRedoManagerService.do(deleteDmAction);
    this.displayInformationMessageAfterDeletingDynamicModif(dynamicModifId);
  }

  // this method is used to extract all dynamics which use a deleted dynamic modification
  // or the a deleted item of list values to define their visibility
  private extractAllRefrencedDynamics(dynamicModifId: number): string {
    let allReferencedDynamics = '';
    this.dynamics.forEach(
      (dynamic) => {
        // run through all dynamics
        if (dynamic.modificationTarget.properties.visibilityCriteria !== undefined
          && dynamic.modificationTarget.properties.visibilityCriteria.conditions !== undefined) {
          // run through all conditions of current dynamic
          for (const condition of dynamic.modificationTarget.properties.visibilityCriteria.conditions) {
            // check if current dynamic is used to define visibility of other fields
            if (dynamicModifId !== null) {
              allReferencedDynamics = this.processReferencedDynamicsAfterDeletingDynamicModification(
                condition,
                dynamicModifId,
                allReferencedDynamics,
                dynamic);
            }
            if (allReferencedDynamics.length > 0) {
              break;
            }
          }
        }
      });
    return allReferencedDynamics;
  }

  private processReferencedDynamicsAfterDeletingDynamicModification(condition: VisibilityCondition,
    dynamicModifId: number, allReferencedDynamics: string, dynamic: DynamicModification): string {
    let referencedDynamicsList = allReferencedDynamics;
    if (condition.modificationId === dynamicModifId.toString()) {
      // construct string of all referenced dynamics to display in the message
      if (referencedDynamicsList.length > 0) {
        referencedDynamicsList = `${referencedDynamicsList}, ${this.dynamics.get(dynamic.id).modificationTarget.label}`;
      } else {
        referencedDynamicsList = this.dynamics.get(dynamic.id).modificationTarget.label;
      }
    }
    return referencedDynamicsList;
  }

  // This method is used to display message when the
  // deleted dynamic modif is used to define the vibility of other fields
  private displayInformationMessageAfterDeletingDynamicModif(dynamicModifId: number) {
    const allReferencedDynamics = this.extractAllRefrencedDynamics(dynamicModifId);
    if (allReferencedDynamics.length > 0) {
      const errorMessage = new EazlyMessage(
        AlertType.info,
        'ViewerService',
        MessageCause.DeletionOfDynamicFieldWhichWasUsedInVisibilityCondition,
        allReferencedDynamics
      );
      this.messagingService.notifyError(errorMessage);
    }
  }

  // Update the preview by deleting the DOM of the deleted DM
  private handleDeleteDmSuccess(operation: Operation) {
    const deleteDmOperation: DeleteDmOperation = operation as DeleteDmOperation;
    this.dynamics.delete(deleteDmOperation.getDynamicModificationId());
    // Checking if the operation is affecting a different page
    // that is not currently displayed by the viewer
    if (this.stepBeingEdited.id === operation.getStepId()) {
      this.updateIframeAfterDmDeletion(deleteDmOperation.getDynamicModificationId());
    }
    // The viewer does not need to edit the HTML if the stepBeingEdited is different than the step of the operation
    // The preview will be updated automatically with the loading of the page after the navigation
  }

  private updateIframeAfterDmDeletion(dynamicModificationId: number) {
    // Removing the HTML corresponding to the DM
    // and updating the position of the other DM in the anchor
    const iframeDocument = this.iframe.nativeElement.contentDocument;
    const mainDivContainingDm = iframeDocument.querySelector(`div[dynamic_id="${dynamicModificationId}"]`);
    if (mainDivContainingDm) {
      mainDivContainingDm.remove();
      this.removeToolContainers(iframeDocument);
    }
  }

  private removeToolContainers(iframeDocument: any) {
    const toolContainers: NodeList = iframeDocument.body.querySelectorAll('.tool-container');
    toolContainers.forEach((toolContainer: Node) => {
      $(toolContainer).hide();
    });
  }

  private handleDmDeleteFailure(error: any, dynamicModifId: number, alreadyRetried: boolean) {
    // Handling errors of the DELETE API
    if (401 === error.status && !alreadyRetried) {
      this.alreadyTried = true;
      // Session on the eazly engine expired (JWT expired), Renewing the Appeaz JWT
      this.appeazService.getAppeazJWT(this.getappeaz().id, this.getappeaz().role).subscribe(
        (dataJwt) => {
          // Retrying with a valid JWT
          this.handleDeleteDynamicModification(dynamicModifId);
        },
        (errorFromJWT) => {
          this.handleGettingJWTFailure(error);
        }
      );
      // Error from GetAppeazJWT already handled by appeazService
    } else {
      // Handling the errors from the operation
      this.handleDeleteDMError(error);
    }
  }

  /***********************************************/
  /************** ERROR HANDLING *****************/
  /***********************************************/
  private handleGetStepPreviewByIdError(error: any, alreadyTried: boolean) {
    if (error.status === 401) {
      // Session expired (JWT expired), should renew the JWT
      if (!alreadyTried) {
        this.updateiframe(true);
      } else {
        this.handleGettingJWTFailure(error);
      }
    }
    if (error.status === 400) {
      const errorReport = new EazlyMessage(
        AlertType.danger,
        'ViewerService',
        MessageCause.DynamicCouldNotBeEdited,
        `${error.status} ${error.statusText}`
      );
      this.messagingService.notifyError(errorReport);
    }
    this.handleError403(error);
    this.handleError404(error, MessageCause.StepOrDMNotFound);
    this.handleError500(error);
    this.handleError504(error);
  }

  private handleGetStepDMError(error: any, alreadyTried: boolean) {
    if (error.status === 401) {
      // Session expired (JWT expired), should renew the JWT
      if (!alreadyTried) {
        this.onLoad(undefined, true);
      } else {
        this.handleGettingJWTFailure(error);
      }
    }
    this.handleError403(error);
    this.handleError404(error, MessageCause.StepNotFound);
    this.handleError504(error);
  }

  private handleAddDynamicModificationError(error: any) {
    if (400 === error.status) {
      const errorMessage = new EazlyMessage(
        AlertType.danger,
        'ViewerService',
        MessageCause.DynamicCouldNotBeCreated,
        `${error.status} ${error.statusText}`
      );
      this.messagingService.notifyError(errorMessage);
    }
    this.handleError401(error);
    this.handleError403(error);
    this.handleError404(error, MessageCause.StepNotFound);
    if (error.status === 409) {
      const errorReport = new EazlyMessage(
        AlertType.danger,
        'ViewerService',
        MessageCause.DynamicHasConflict,
        `${error.status} ${error.statusText}`
      );
      this.messagingService.notifyError(errorReport);
    }
    this.handleError500(error);
    this.handleError504(error);
  }

  private handleUpdateDynamicModificationError(error: any) {
    if (error.status === 0) {
      const errorReport = new EazlyMessage(
        AlertType.danger,
        'ViewerService',
        MessageCause.HostUnreachable,
        `${error.status}  ${error.statusText}`
      );
      this.messagingService.notifyError(errorReport);
    }
    if (error.status === 400) {
      let errorReport: EazlyMessage;
      const errorCustomMessage: string = error?.error;
      if (errorCustomMessage.includes('default value')) {
        errorReport = new EazlyMessage(
          AlertType.danger,
          'ViewerService',
          MessageCause.InvalidDefaultValue,
          errorCustomMessage
        );
      } else {
        errorReport = new EazlyMessage(
          AlertType.danger,
          'ViewerService',
          MessageCause.DynamicCouldNotBeEdited,
          `${error.status} ${error.statusText}`
        );
      }
      this.messagingService.notifyError(errorReport);
    }
    this.handleError401(error);
    this.handleError403(error);
    this.handleError404(error, MessageCause.StepOrDMNotFound);
    if (error.status === 409) {
      this.logger.debug('Handling error ', error);
      let errorReport: EazlyMessage;
      const errorCustomMessage: string = error?.error;
      if (errorCustomMessage.includes('Association')) {
        const labelOfTheDM = this.getParameterInErrorMessage(errorCustomMessage, 0);
        const detailedNameRequested = this.getParameterInErrorMessage(errorCustomMessage, 1);
        const labelOfTheDMUsingTheRequestedName = this.getParameterInErrorMessage(errorCustomMessage, 2);
        errorReport = new EazlyMessage(
          AlertType.danger,
          'ViewerService',
          MessageCause.DataDescriptorAssociationConflict,
          `\"${labelOfTheDM}\";\"${detailedNameRequested}\";\"${labelOfTheDMUsingTheRequestedName}\"`
        );
      } else if (errorCustomMessage.includes('Renaming')) {
        const initialDataName = this.getParameterInErrorMessage(errorCustomMessage, 0);
        const requestedDataName = this.getParameterInErrorMessage(errorCustomMessage, 1);
        errorReport = new EazlyMessage(
          AlertType.danger,
          'ViewerService',
          MessageCause.DataDescriptorRenamingConflict,
          `\"${initialDataName}\";\"${requestedDataName}\"`
        );
      } else if (errorCustomMessage.includes('Type conflict')) {
        const detailedNameRequested = this.getParameterInErrorMessage(errorCustomMessage, 0);
        let detailedTypeRequested = '';
        let typeUsingSameDataName = '';
        if (this.localeId === 'fr') {
          detailedTypeRequested = frenchDataType.get(DataType[this.getParameterInErrorMessage(errorCustomMessage, 1)]);
          typeUsingSameDataName = frenchDataType.get(DataType[this.getParameterInErrorMessage(errorCustomMessage, 2)]);
        } else if (this.localeId === 'en-US') {
          detailedTypeRequested = englishDataType.get(DataType[this.getParameterInErrorMessage(errorCustomMessage, 1)]);
          typeUsingSameDataName = englishDataType.get(DataType[this.getParameterInErrorMessage(errorCustomMessage, 2)]);
        }
        errorReport = new EazlyMessage(
          AlertType.danger,
          'ViewerService',
          MessageCause.DataDescriptorTypeConflict,
          `${detailedNameRequested};${detailedTypeRequested};${typeUsingSameDataName}`
        );
      } else {
        errorReport = new EazlyMessage(
          AlertType.danger,
          'ViewerService',
          MessageCause.DynamicHasConflict,
          `${error.status} ${error.statusText}`
        );
      }
      this.messagingService.notifyError(errorReport);
    }
    this.handleError500(error);
    this.handleError504(error);
  }

  private getParameterInErrorMessage(errorMessage: string, parameterPosition: number): string {
    // Copying the errorMessage
    let parameter = errorMessage.slice();
    // Removing the part of the message preceding the parameter to extract
    for (let i = 0; i < parameterPosition; i++) {
      // Removing the part preceding the first occurence of ' " '
      parameter = parameter.slice(parameter.indexOf('"') + 1, parameter.length);
      // Removing the part preceding the second occurence of ' " '
      parameter = parameter.slice(parameter.indexOf('"') + 1, parameter.length);
    }
    // Extracting the parameter from the message
    // Removing the part preceding the first occurence of ' " '
    parameter = parameter.slice(parameter.indexOf('"') + 1);
    // Removing the part following the second occurence of ' " '
    parameter = parameter.slice(0, parameter.indexOf('"'));
    return parameter;
  }

  private handleError500(error: any) {
    if (error.status === 500) {
      this.errorMessage = new EazlyMessage(
        AlertType.danger,
        'ViewerService',
        MessageCause.InternalError,
        `${error.status} ${error.statusText}`
      );
      this.router.navigate(['/appeazs']);
    }
  }

  private handleDeleteDMError(error: any) {
    this.handleError401(error);
    this.handleError403(error);
    this.handleError404(error, MessageCause.StepOrDMNotFound);
    this.handleError504(error);
  }

  private handleError401(error: any) {
    if (401 === error.status) {
      this.errorMessage = new EazlyMessage(
        AlertType.danger,
        'AppeazService',
        MessageCause.SessionExpired,
        `${error.status} ${error.statusText}`
      );
      this.userService.logout();
      this.router.navigate(['/login'], { queryParams: { expired: 'true' } });
    }
  }

  private handleError403(error: any) {
    if (error.status === 403) {
      this.errorMessage = new EazlyMessage(
        AlertType.danger,
        'ViewerService',
        MessageCause.NotHaveTheRoleToAccess,
        `${error.status} ${error.statusText}`
      );
      this.router.navigate(['/appeazs']);
    }
  }

  private handleGettingJWTFailure(error: any) {
    this.errorMessage = error;
    this.needToRefresh = true;
  }

  private handleError404(error: any, messageCause: MessageCause) {
    if (error.status === 404) {
      const errorReport = new EazlyMessage(
        AlertType.danger,
        'ViewerService',
        messageCause,
        `${error.status} ${error.statusText}`
      );
      this.messagingService.notifyError(errorReport);
    }
  }

  private handleError504(error: any) {
    if (error.status === 504) {
      this.errorMessage = new EazlyMessage(
        AlertType.danger,
        'ViewerService',
        MessageCause.AppeazAccessTimeOut,
        `${error.status} ${error.statusText}`
      );
      this.router.navigate(['/appeazs']);
    }
  }

  /***********************************************/
  /********** UNDO/REDO SUBSCRIPTION *************/
  /***********************************************/

  private subscribeToUndoRedoManagerChannels() {
    // Subscribing to channels notifying success of operations
    const doChannel: Subject<OperationMessage> = this.undoRedoManagerService.getSubscriptionChannel(OperationSource.DO);
    const doSubscription: Subscription = doChannel.subscribe(
      (operationMessage: OperationMessage) => {
        this.synchronizeDynamicModificationMap().then(() => {
          const operation: Operation = operationMessage.getOperation();
          if (OperationType.ADD_DM === operation.getOperationType()) {
            this.handleAddDmSuccess(operationMessage, operation, true, (operation as AddDmOperation).shadowPlaceholder);
          }
          if (OperationType.DELETE_DM === operation.getOperationType()) {
            this.handleDeleteDmSuccess(operation);
          }
          if (OperationType.EDIT_DM === operation.getOperationType()) {
            this.handleEditDmSuccess(operation);
          }
          if (OperationType.IMPORT === operation.getOperationType()) {
            this.handleImportSuccess(operation as ImportOperation);
          }
          this.alreadyTried = false;
          this.refreshAllEyeIcon();
        });
      }
    );

    const undoChannel: Subject<OperationMessage> = this.undoRedoManagerService.getSubscriptionChannel(OperationSource.UNDO);
    const undoSubscription: Subscription = undoChannel.subscribe(
      (operationMessage: OperationMessage) => {
        this.handleOperationFromUndoRedoChannel(operationMessage);
      }
    );

    const redoChannel: Subject<OperationMessage> = this.undoRedoManagerService.getSubscriptionChannel(OperationSource.REDO);
    const redoSubscription: Subscription = redoChannel.subscribe(
      (operationMessage: OperationMessage) => {
        this.handleOperationFromUndoRedoChannel(operationMessage);
      }
    );

    // Subscribing to channels notifying failure of operations
    const errorDoChannel: Subject<OperationMessage> = this.undoRedoManagerService.getErrorSubscriptionChannel(OperationSource.DO);
    const errorDoSubscription: Subscription = errorDoChannel.subscribe(
      (operationMessage: OperationMessage) => {
        const operation: Operation = operationMessage.getOperation();

        if (OperationType.ADD_DM === operation.getOperationType()) {
          const addDmOperation: AddDmOperation = <AddDmOperation>operation;
          this.handleAddDmFailure(
            operationMessage.getResultOfTheOperation(),
            addDmOperation.getDynamicModification(),
            addDmOperation.getRank(),
            this.alreadyTried,
            addDmOperation.shadowPlaceholder
          );
        }

        if (OperationType.DELETE_DM === operationMessage.getOperation().getOperationType()) {
          const deleteDmOperation: DeleteDmOperation = operation as DeleteDmOperation;
          this.handleDmDeleteFailure(
            operationMessage.getResultOfTheOperation(),
            deleteDmOperation.getDynamicModificationId(),
            false
          );
        }
        if (OperationType.EDIT_DM === operationMessage.getOperation().getOperationType()) {
          const editDmOperation: EditDmOperation = operation as EditDmOperation;
          this.handleEditDmFailure(
            operationMessage.getResultOfTheOperation(),
            editDmOperation.getDynamicModification(),
            editDmOperation.getName(),
            false
          );
        }
        if (OperationType.IMPORT === operationMessage.getOperation().getOperationType()) {
          const importDmOperation: ImportOperation = operation as ImportOperation;
          this.handleImportFailed(operationMessage.getResultOfTheOperation(), importDmOperation);
        }
      }
    );
    const errorUndoChannel: Subject<OperationMessage> = this.undoRedoManagerService.getErrorSubscriptionChannel(OperationSource.UNDO);
    const errorUndoSubscription: Subscription = errorUndoChannel.subscribe(
      (operationMessage: OperationMessage) => {
        const operation: Operation = operationMessage.getOperation();

        if (OperationType.ADD_DM === operation.getOperationType()) {
          const addDmOperation: AddDmOperation = <AddDmOperation>operation;
          this.handleAddDmFailure(
            operationMessage.getResultOfTheOperation(),
            addDmOperation.getDynamicModification(),
            addDmOperation.getRank(),
            this.alreadyTried,
            addDmOperation.shadowPlaceholder
          );
        }

        if (OperationType.DELETE_DM === operation.getOperationType()) {
          const deleteDmOperation: DeleteDmOperation = operationMessage.getOperation() as DeleteDmOperation;
          this.handleDmDeleteFailure(
            operationMessage.getResultOfTheOperation(),
            deleteDmOperation.getDynamicModificationId(),
            false
          );
        }

        if (OperationType.EDIT_DM === operationMessage.getOperation().getOperationType()) {
          const editDmOperation: EditDmOperation = operation as EditDmOperation;
          this.handleEditDmFailure(
            operationMessage.getResultOfTheOperation(),
            editDmOperation.getDynamicModification(),
            editDmOperation.getName(),
            false
          );
        }

        if (OperationType.IMPORT === operationMessage.getOperation().getOperationType()) {
          this.handleImportFailed(operationMessage.getResultOfTheOperation(), operationMessage.getOperation() as ImportOperation);
        }
      }
    );
    const errorRedoChannel: Subject<OperationMessage> =
      this.undoRedoManagerService.getErrorSubscriptionChannel(
        OperationSource.REDO
      );
    const errorRedoSubscription: Subscription = errorRedoChannel.subscribe(
      (operationMessage: OperationMessage) => {
        const operation: Operation = operationMessage.getOperation();

        if (OperationType.ADD_DM === operation.getOperationType()) {
          const addDmOperation: AddDmOperation = operationMessage.getOperation() as AddDmOperation;
          this.handleAddDmFailure(
            operationMessage.getResultOfTheOperation(),
            addDmOperation.getDynamicModification(),
            addDmOperation.getRank(),
            this.alreadyTried,
            addDmOperation.shadowPlaceholder
          );
        }
        if (OperationType.DELETE_DM === operation.getOperationType()) {
          const deleteDmOperation: DeleteDmOperation = operationMessage.getOperation() as DeleteDmOperation;
          this.handleDmDeleteFailure(
            operationMessage.getResultOfTheOperation(),
            deleteDmOperation.getDynamicModificationId(),
            false
          );
        }
        if (OperationType.EDIT_DM === operationMessage.getOperation().getOperationType()) {
          const editDmOperation: EditDmOperation = operation as EditDmOperation;
          this.handleEditDmFailure(
            operationMessage.getResultOfTheOperation(),
            editDmOperation.getDynamicModification(),
            editDmOperation.getName(),
            false
          );
        }

        if (OperationType.IMPORT === operationMessage.getOperation().getOperationType()) {
          this.handleImportFailed(operationMessage.getResultOfTheOperation(), operationMessage.getOperation() as ImportOperation);
        }
      }
    );

    this.subscriptions.push(doSubscription);
    this.subscriptions.push(undoSubscription);
    this.subscriptions.push(redoSubscription);
    this.subscriptions.push(errorDoSubscription);
    this.subscriptions.push(errorUndoSubscription);
    this.subscriptions.push(errorRedoSubscription);
  }

  private handleOperationFromUndoRedoChannel(operationMessage: OperationMessage) {
    this.synchronizeDynamicModificationMap().then(() => {
      const operation: Operation = operationMessage.getOperation();
      if (OperationType.ADD_DM === operation.getOperationType()) {
        this.handleAddDmSuccess(operationMessage, operation, false, (operation as AddDmOperation).shadowPlaceholder);
      }
      if (OperationType.DELETE_DM === operation.getOperationType()) {
        this.handleDeleteDmSuccess(operation);
      }
      if (OperationType.EDIT_DM === operation.getOperationType()) {
        this.handleEditDmSuccess(operation);
      }
      if (OperationType.IMPORT === operation.getOperationType()) {
        this.handleImportSuccess(operation as ImportOperation);
      }
      this.alreadyTried = false;
      this.refreshAllEyeIcon();
    });
  }

  private refreshAllEyeIcon() {
    this.dynamics.forEach((dynamic) => {
      this.refreshEyeIcon(dynamic);
    });
  }

  private refreshEyeIcon(dynamic: DynamicModification) {
    const iframeDocument = this.iframe.nativeElement.contentDocument;
    const elementsWithEyeIconAttr: NodeList = iframeDocument.querySelectorAll(`[dynamic_id="${dynamic.id}"][eazly-button="eyeIcon"]`);
    elementsWithEyeIconAttr.forEach((elementWithEyeIconAttr) => {
      if (elementWithEyeIconAttr !== undefined) {
        this.updateVisibilityEyeButton(elementWithEyeIconAttr as HTMLElement, dynamic);
      }
    });
  }

  private updateVisibilityEyeButton(eyeHtmlElement: HTMLElement, dynamic: DynamicModification) {
    if ((dynamic?.modificationTarget?.properties?.visibilityCriteria?.conditions?.length <= 0) || !this.toggleState) {
      eyeHtmlElement.classList.add(VISIBILITY_EYE_OFF_CLASS);
    } else {
      eyeHtmlElement.classList.remove(VISIBILITY_EYE_OFF_CLASS);
    }
  }

  private unsubscribeToUndoRedoManagerChannels() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  // This method synchronize the local attribute "this.dynamics" with
  // the values stored in the DataBase by using the API Rest
  private async synchronizeDynamicModificationMap(): Promise<any> {
    return new Promise((resolve) => {
      this.viewerService.getStepDynamicModifications(this.getappeaz().url, this.stepBeingEdited.id).subscribe(
        (dynamicModifications: DynamicModification[]) => {
          this.dynamics = new Map<number, DynamicModification>();
          this.logger.debug('Synchronizing the variable "dynamics" with the following model ', dynamicModifications);
          dynamicModifications.forEach((dynamicModification) => {
            this.logger.debug('Inserting the Dynamic modification ', dynamicModification);
            this.dynamics.set(dynamicModification.id, dynamicModification);
          });
          resolve(this.dynamics);
        },
        (error) => {
          this.logger.error('Error during synchronization of the model', error);
          resolve(error);
        }
      );
    });
  }

  private doesLabelGeneratedCorrectly(htmlString: string): boolean {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const classNamePrefix = 'ectz-cssid';
    // spans containg label of inputs
    let spans = doc.querySelectorAll(`span[id^="${classNamePrefix}"][id$="-label"]`);
    if (spans && spans.length > 0) {
      return !(this.extractID(spans[0].id).toString() === spans[0].textContent);
    }
    // spans containg label of rich messages
    spans = doc.querySelectorAll(`span[id^="${classNamePrefix}"]`);
    if (spans && spans.length > 0) {
      return !(this.extractID(spans[0].id).toString() === spans[0].textContent);
    }
    // spans containg label of messages
    spans = doc.querySelectorAll(`span.${'ectzAlertText'}`);
    if (spans && spans.length > 0) {
      return !(this.extractID(spans[0].parentElement.id).toString() === spans[0].textContent);
    }
    // headings containg headings (ex. H1, H2 .. H6)
    const headings = doc.
      querySelectorAll
      (`h1[id^="${classNamePrefix}"], h2[id^="${classNamePrefix}"], h3[id^="${classNamePrefix}"],
             h4[id^="${classNamePrefix}"], h5[id^="${classNamePrefix}"], h6[id^="${classNamePrefix}"]`);
    if (headings && headings.length > 0) {
      return !(this.extractID(headings[0].id)?.toString() === headings[0].textContent);
    }
    return true;
  }

  private extractID(id: string): number | null {
    const match = id.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

}
