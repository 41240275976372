import { Observable } from 'rxjs';

export abstract class Operation {

    name: string;
    operationType: OperationType;
    stepId: string;

    getName(): string {
        return this.name;
    }

    getOperationType(): OperationType {
        return this.operationType;
    }

    getStepId(): string {
        return this.stepId;
    }

    abstract execute(): Observable<any>;
}

export enum OperationType {
    'ADD_DM' = 'adding',
    'DELETE_DM' = 'deletion',
    'EDIT_DM' = 'editing',
    'IMPORT' = 'import'
}
