<div class="alert alert-warning" role="alert">
  This setting page is under construction.
</div>
<div class="row">
  <label class="control-label col-4 text-right">
    <span i18n="@@userSettingLanguage">
      Language:</span>
  </label>
  <div class="dropdown col-8 text-left">
    <button disabled class="btn  dropdown-toggle" type="button" id="languageDropDownMenuButton" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      {{selectedLanguage}}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <span *ngFor="let language of supportedLanguages">
        <a class="dropdown-item" (click)="setLanguage(language)">{{language}}</a>
      </span>
    </div>
  </div>
</div>
