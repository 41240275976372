import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'eazly-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss']
})
export class ModalWindowComponent implements OnInit {
  @ViewChild('modalTemplate')
  private readonly modalTemplate: ElementRef;
  private modalRef: BsModalRef;

  constructor(private readonly modalService: BsModalService) { }

  ngOnInit() {
  }

  public show(): void {
    this.modalRef = this.modalService.show(this.modalTemplate);
  }

  public hide(): void {
    this.modalRef.hide();
  }

  /**
 * This method returns a substring of the string given in parameter
 *
 * @param stringToTruncate The title to truncate
 * @returns The title truncated with 60 char
 */
  public truncateString(stringToTruncate: string): string {
    let truncatedTitle = stringToTruncate;
    if (stringToTruncate.length > 60) {
      truncatedTitle = stringToTruncate.substring(0, 60) + '...';
    }
    return truncatedTitle;
  }
}
