import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from '../../tools/modal-window/modal-window.component';
import { User } from '../../tools/models/user';
import { AppeazService } from '../../tools/services/appeaz.service';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';
import { UserService } from '../../tools/services/user.service';

@Component({
  selector: 'eazly-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss']
})
export class UserItemComponent implements OnInit, OnDestroy {

  @Input()
  isExpert: boolean;
  @Input()
  user: any;
  @Input()
  appeaz: number;
  @Output()
  eventEmitter = new EventEmitter<number>();
  @Output()
  errorMesssageEmiter = new EventEmitter<EazlyMessage>();

  @ViewChild(ModalWindowComponent, { static: true })
  private readonly modalWindowComponent: ModalWindowComponent;

  y: number;
  isMe: boolean;
  isAdmin: boolean;
  isLoading: boolean;
  me: User;
  errorReport: EazlyMessage;

  constructor(private readonly userService: UserService,
    private readonly appeazService: AppeazService,
    private readonly messagingService: MessagingService) { }

  ngOnInit() {
    const role = this.user.role;
    switch (role) {
      case 'Tester': {
        this.y = 0;
        break;
      }
      case 'Designer': {
        this.y = 1;
        break;
      }
      case 'Publisher': {
        this.y = 2;
        break;
      }
      case 'Manager': {
        this.y = 3;
        break;
      }
      default: {
        this.y = 1;
        break;
      }
    }
    this.me = this.userService.getCurrentUser();
    this.isMe = false;
    if (this.me.id === this.user.id) {
      this.isMe = true;
    }
    this.isAdmin = this.userService.isAdmin();
    this.isExpert = this.user.expert;
  }

  ngOnDestroy() {
    if (this.errorReport) {
      // Emitting the error to the parent component so that the error
      // can be sent to the AlertManager of the HomePage
      // after the AlertManager of UserComponent is destroyed
      this.errorMesssageEmiter.emit(this.errorReport);
    }
  }

  deleteUser() {
    this.hideModal();
    this.eventEmitter.emit(this.user.id);

  }

  setUserExpert() {
    if (this.isAdmin) {
      this.isLoading = true;
      const oldvalue = this.user.expert;
      this.user.expert = this.isExpert;
      this.appeazService.updateAppeazRole(this.appeaz, this.user).subscribe(
        res => {
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.user.expert = oldvalue;
          this.isExpert = oldvalue;
          this.errorReport = error;
          this.handleError(error);
        });
    } else {
      const errorMessage = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.InsufficientRole, '');
      this.messagingService.notifyError(errorMessage);
      throw new Error();
    }
  }

  showModal() {
    this.modalWindowComponent.show();
  }
  hideModal() {
    this.modalWindowComponent.hide();
  }

  choose(rank: number) {
    this.isLoading = true;
    const oldvalue = this.user.eazly_role;
    switch (rank) {
      case 0: {
        this.user.eazly_role = 'Designer';
        break;
      }
      case 1: {
        this.user.eazly_role = 'Publisher';
        break;
      }
      case 2: {
        this.user.eazly_role = 'Manager';
        break;
      }
      default: {
        break;
      }
    }
    this.appeazService.updateAppeazRole(this.appeaz, this.user).subscribe(
      res => {
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.user.eazly_role = oldvalue;
        switch (this.user.eazly_role) {
          case 'Tester': {
            this.y = 0;
            break;
          }
          case 'Designer': {
            this.y = 1;
            break;
          }
          case 'Publisher': {
            this.y = 2;
            break;
          }
          case 'Manager': {
            this.y = 3;
            break;
          }
          default: {
            this.y = 1;
            break;
          }
        }
        this.errorReport = error;
        this.handleError(error);
      });
  }

  private handleError(error: any) {
    if (error.status === 0) {
      const errorMessage = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.HostUnreachable, error.status + ' ' + error.statusText);
      this.messagingService.notifyError(errorMessage);
    }
    if (error.status === 401) {
      const errorMessage = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
      this.messagingService.notifyError(errorMessage);

    }
    if (error.status === 403) {
      const errorMessage = new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.InsufficientRole, error.status + ' ' + error.statusText);
      this.messagingService.notifyError(errorMessage);
    }

    if (error.status === 404) {
      const errorMessage = new EazlyMessage(AlertType.warning, 'VersionService', MessageCause.ActiveVersionNotFound, error.status + ' ' + error.statusText);
      this.messagingService.notifyError(errorMessage);
    }
  }

}
