<eazly-loading [isRunning]="isLoading"></eazly-loading>

<eazly-header i18n-headerText="@@MyAppeazTitle" headerText="My Appeaz"></eazly-header>
<div class="main container">
  <eazly-alert-manager></eazly-alert-manager>
  <div class="input-group margin-bottom-sm search-input">
    <div class="input-group-prepend">
      <span class="input-group-text" id="basic-addon1">
        <i class="fas fa-search"></i>
      </span>
    </div>
    <input type="text" placeholder="Search by name" #filter (keyup)="filterResults(filter.value)"
      [value]="sessionStorage.retrieve('searchValue')" i18n-placeholder='@@filterPlaceholder'>
  </div>
  <div class="panel-group">
    <div *ngFor="let appeaz of filteredAppeazs">
      <div class="panel panel-default">
        <eazly-appeaz-list-item [appeaz]="appeaz"></eazly-appeaz-list-item>
      </div>
    </div>
  </div>

  <a *ngIf="isConfiguration" routerLink="/appeaz/new" type="button" id="addAppeaz"
    class="btn btn-primary col-sm-4 btn-lg" tooltip="Add a new Appeaz" i18n-tooltip="@@MyAppeazsAddNewAppeaz"
    placement="bottom">
    <i class="fa fa-plus-circle" aria-hidden="true"></i>
  </a>
</div>
