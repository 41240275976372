<eazly-modal-window>
  <div modalWindowHeader id="config-title" class="modal-title" i18n="@@DynamicModificationSettingWindowTitle">
    Settings of "{{fieldName}}"
  </div>
  <div modalWindowBody>
    <form id="dmConfigurationForm" autocomplete="off" (ngSubmit)="updateDynamicModificationConfiguration()" #f="ngForm"
      [formGroup]="dmSettingsForm" novalidate>
      <div class="container container-configs">
        <div *ngIf="isSupportingDataDescriptor()">
          <div *ngFor="let alert of alerts"
            class="alert alert-info alert-dismissible fade show information-message-style" role="alert">
            <div i18n="@@DissociationOfDynamicFieldWhichWasUsedInCondition"
              *ngIf="alert.messageCause === messageCause.DissociationOfDynamicFieldWhichWasUsedInCondition">
              This link was used by one or many visibility conditions:
              <strong>{{alert.origin}}</strong>.
              <p>
                You can abort this modification by clicking on the cancel button.
              </p>
            </div>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="h5" i18n="@@DynamicModificationSettingWindowDataDescriptorSettingLabel">
            Homonyms distinction and field links
          </div>
          <!-- divDataNameLabelRow-->
          <ng-container *ngIf="!isLinkedField()">
            <div class="modal-input-field">
              <div class="row">
                <!-- divLabelCol -->
                <div class="col-sm-12 modal-input-label-container">
                  <label class="eazly-configs-label control-label"
                    i18n="@@DynamicModificationSettingWindowDataNameSettingLabel">
                    Detailed name
                  </label>
                  <i class="required-info required-info-with-margin" aria-hidden="true"></i>
                  <i class="tooltip-eazly" i18n-tooltip="@@DatadescriptorNameTooltip"
                    tooltip="Enter a unique detailed name to distinguish homonyms or enter an existing detailed name to link fields together manually."
                    aria-haspopup="true" aria-expanded="false" placement="right"></i>
                </div>
              </div>
              <!-- divDataNameInputRow -->
              <div class="row">
                <div class="col-sm-12">
                  <input id="inputDataName" class="form-control" type="string" list="dataDescriptorsSuggestion"
                    required="true" formControlName="inputDataName" oncopy="false" onpaste="false"
                    (input)="dataNameInputEventHandler($event)" (focus)="setInputDataNameFocusEventHandler($event)"
                    (focusout)="setInputDataNameFocusEventHandler($event)">
                  <datalist id="dataDescriptorsSuggestion">
                    <option *ngFor="let dataDescriptor of compatibleSuggestionsForDataName" value="{{dataDescriptor}}">
                    </option>
                  </datalist>
                  <div *ngIf="dmSettingsForm.controls['inputDataName'].hasError('required')" class="alert alert-danger"
                    i18n="@@DynamicModificationSettingWindowDataNameRequired">
                    Please input a detailed name.
                  </div>
                  <div *ngIf="dmSettingsForm.controls['inputDataName'].hasError('hasDataTypeConflict')"
                    class="alert alert-danger" i18n="@@LinkedDynamicModificationSettingWindowDataTypeConflict">
                    This detailed name for {{getDataTypeConflictParameters(1)}}
                    cannot be used for {{getDataTypeConflictParameters(0)}}.
                  </div>
                  <div *ngIf="dmSettingsForm.controls['inputDataName'].hasError('hasDataNameAlreadyUsedConflict')"
                    class="alert alert-danger" i18n="@@LinkedDynamicModificationSettingWindowDataNameAlreadyUsed">
                    This detailed name is already used by another field on this page.
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-row-element">
              <h6 i18n="@@DatadescriptorNameNotLinked">This field is not linked to any other field</h6>
              <div class="datadescriptor-notlinked" i18n="@@DatadescriptorNameNotLinkedSuggestion">
                To link this field manually with other fields, enter a detailed name identical to the field to link
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isLinkedField() 
          && !dmSettingsForm.controls['inputDataName'].hasError('hasDataTypeConflict')
          && !dmSettingsForm.controls['inputDataName'].hasError('hasDataNameAlreadyUsedConflict')">
            <div class="row modal-input-field">
              <!-- divLabelCol -->
              <div class="col-sm-12 modal-input-label-container">
                <label class="eazly-configs-label control-label"
                  i18n="@@LinkedDynamicModificationSettingWindowDataNameSettingLabel">
                  Detailed name shared between fields
                </label>
                <i class="required-info required-info-with-margin" aria-hidden="true"></i>
                <i class="tooltip-eazly" i18n-tooltip="@@NotLinkedDatadescriptorNameTooltip"
                  tooltip="You can rename the detailed name shared between fields.Renaming this name has no impact on existing links."
                  aria-haspopup="true" aria-expanded="false" placement="right"></i>
              </div>
              <!-- divDataNameInputRow -->
              <div class="col-sm-12">
                <input id="inputSharedDataName" class="form-control" type="string" required="true"
                  formControlName="inputDataName" oncopy="false" onpaste="false" (input)="updateHasAnyError()">
                <div *ngIf="dmSettingsForm.controls['inputDataName'].hasError('required')" class="alert alert-danger"
                  i18n="@@LinkedDynamicModificationSettingWindowDataNameRequired">
                  Please input a detailed name shared between fields.
                </div>
                <div *ngIf="dmSettingsForm.controls['inputDataName'].hasError('hasRenamingConflict')"
                  class="alert alert-danger" i18n="@@LinkedDynamicModificationSettingWindowRenamingConflict">
                  This detailed name is already used by other fields.
                </div>
              </div>
            </div>
            <div class="row modal-row-element">
              <div class="col-sm-12 modal-input-label-container">
                <label class="eazly-configs-label control-label" i18n="@@LinkedFieldds">
                  Linked fields
                </label>
                <i class="tooltip-eazly" i18n-tooltip="@@linkedFieldsTooltip"
                  tooltip="All other fields sharing same detailed name." aria-haspopup="true" aria-expanded="false"
                  placement="right"></i>
              </div>
              <div class="col-sm-12 eazly-linkedfields-table">
                <div class="row rowHeaderLinkedFields">
                  <div class="col-sm-6 colDesign">
                    <span class="eazly-linkedfields-table-headers" i18n="@@LinkedFieldsTableStepName">Step name</span>
                  </div>
                  <div class="col-sm-6 colDesign">
                    <span class="eazly-linkedfields-table-headers" i18n="@@LinkedFieldsTableDMLabel">Label</span>
                  </div>
                </div>
                <div class="stepsTable" *ngFor="let step of allLinkedFields">
                  <div class="row rowLinkedFields">
                    <div class="col-sm-6 colTable">
                      {{step.stepName}}
                    </div>
                    <div class="col-sm-6 colTable">
                      {{step.modifications[0].modificationTarget.label}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button id="DissociateModalWindow" (click)="dissociateThisField()" type="button"
              class="btn eazlybtn btn-lg modal-row-element" i18n="@@DissociateButton">
              <i class="fas fa-unlink"></i>
              Dissociate the field
            </button>
          </ng-container>
        </div>
        <div *ngIf="isSupportingDefaultValue()">
          <div class="h5" i18n="@@DynamicModificationSettingWindowDefaultValueSettingLabel">
            Default value
          </div>
          <div class="modal-input-field">
            <!-- divdefvalRow -->
            <div class="row">
              <!-- divLabeldefvalRow -->
              <div class="col-sm-12 modal-input-label-container">
                <label class="eazly-configs-label control-label" i18n="@@defvalabel">
                  Default value
                </label>
              </div>
            </div>
            <div class="row">
              <!-- divInputDefaultValueCol -->
              <div class="col-sm-12">
                <!-- inputDefaultValueName -->
                <input id="inputDefaultValue" type="text" class="form-control" name="defaultValue"
                  formControlName="inputDefaultValue" *ngIf="isSimpleText()">
                <input id="inputDefaultValue" type="number" class="form-control" name="defaultValue"
                  formControlName="inputDefaultValue" *ngIf="isNumberValue()" min="{{getIntegerMinValue()}}"
                  max="{{getIntegerMaxValue()}}" (keypress)="allowOnlyNumberOnKeyPress($event, true)"
                  (focusout)="inputDefaultValueNumberEventHandler($event)"
                  (input)="inputDefaultValueNumberEventHandler($event)">
                <div *ngIf="dmSettingsForm.controls['inputDefaultValue'].hasError('min')" class="alert alert-danger"
                  i18n="@@DynamicModificationSettingWindowDefaultNumberToLow">
                  Please input a number greater than -100,000,000.
                </div>
                <div *ngIf="dmSettingsForm.controls['inputDefaultValue'].hasError('max')" class="alert alert-danger"
                  i18n="@@DynamicModificationSettingWindowDefaultNumberToHigh">
                  Please input a number lower than 100,000,000.
                </div>
                <div *ngIf="dmSettingsForm.controls['inputDefaultValue'].hasError('hasInvalidNumber')"
                  class="alert alert-danger" i18n="@@DynamicModificationSettingWindowDefaultNumberInvalid">
                  Please input a valid number.
                </div>
                <textarea id="inputTextAreaDefaultValue" type="text" class="form-control" name="defaultValue"
                  formControlName="inputDefaultValue" *ngIf="isTextArea()"
                  (keyup)="resizeTextArea('inputTextAreaDefaultValue')"
                  (keypress)="resizeTextArea('inputTextAreaDefaultValue')"></textarea>
                <select id="selectdefval" *ngIf="isListValue()" name="defaultValue"
                  class="select-visibility-modal form-control" formControlName="inputDefaultValue">
                  <option value="">
                  </option>
                  <option *ngFor="let value of dynamicModification.modificationTarget.values" [value]="value.id">
                    {{ value | listValueLabelTranslationPipe }}
                  </option>
                </select>
                <select id="booleanSelectDefVal" *ngIf="isBooleanValue()" name="defaultValue"
                  class="select-visibility-modal form-control" formControlName="inputDefaultValue">
                  <option value="" i18n="@@DynamicModificationDefautlValueNotSpecifiedLabel">
                    No default value
                  </option>
                  <option value="true" i18n="@@DynamicModificationDefautlValueTrueLabel">
                    True
                  </option>
                  <option value="false" i18n="@@DynamicModificationDefautlValueFalseLabel">
                    False
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isSupportingTextMaxLength() || isSupportingRegExp()">
          <div class="h5" i18n="@@DynamicModificationSettingWindowConstraintsSettingLabel">
            Constraints
          </div>
          <ng-container *ngIf="isSupportingTextMaxLength()">
            <!-- divTextMaxLengthLabelRow-->
            <div class="modal-input-field">
              <div class="row">
                <!-- divLabelCol -->
                <div class="col-sm-12 modal-input-label-container">
                  <label class="eazly-configs-label control-label"
                    i18n="@@DynamicModificationSettingWindowMaxLengthSettingLabel">
                    Maximum number of characters
                  </label>
                  <i class="required-info required-info-with-margin" aria-hidden="true"></i>
                </div>
              </div>
              <!-- divTextMaxLengthInputRow -->
              <div class="row">
                <div class="col-sm-12">
                  <input id="inputMaxLength" class="form-control" type="number" min="1" required="true"
                    formControlName="inputMaxLength" oncopy="false" onpaste="false"
                    (keypress)="allowOnlyNumberOnKeyPress($event, false)">
                  <div *ngIf="dmSettingsForm.controls['inputMaxLength'].hasError('min')" class="alert alert-danger"
                    i18n="@@DynamicModificationSettingWindowTextLengthInputInvalid">
                    Please input a number greater than zero.
                  </div>
                  <div *ngIf="dmSettingsForm.controls['inputMaxLength'].hasError('max')" class="alert alert-danger"
                    i18n="@@DynamicModificationSettingWindowTextLengthInputToHight">
                    Please input a number lower than 100,000,000.
                  </div>
                  <div *ngIf="dmSettingsForm.controls['inputMaxLength'].hasError('required')" class="alert alert-danger"
                    i18n="@@DynamicModificationSettingWindowTextLengthInputRequired">
                    Maximum number of characters is required.
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isSupportingRegExp()">
            <div *ngIf="isExpertUser" class="modal-input-field">
              <!-- divRegExRow -->
              <div class="row">
                <!-- divLabelRegExRow -->
                <div class="col-sm-12 modal-input-label-container">
                  <label class="eazly-configs-label control-label" i18n="@@RegExpLabel">
                    Regular expression
                  </label>
                </div>
              </div>
              <div class="row">
                <!-- divInputRegExCol -->
                <div class="col-sm-12">
                  <!-- inputRegExName -->
                  <input id="inputRegEx" type="text" class="form-control" formControlName="inputRegExp"
                    name="regExpValue">
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="isSupportingInfoText()">
          <div class="h5" i18n="@@DynamicModificationSettingWindowInfomativeText">
            Informative text
          </div>
          <div class="row">
            <div class="col-sm-12 input-container">
              <textarea id="infoTextTextArea" class="form-control" type="string" formControlName="infoText"></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div modalWindowFooter>
    <button (click)="hide()" id="CancelModalWindow" type="button" class="btn eazlybtn btn-lg" i18n="@@Cancel">
      <i class="fas fa-arrow-left"></i>
      Cancel
    </button>
    <button id="SubmitModalWindow" type="submit" form="dmConfigurationForm" [disabled]="hasAnyError"
      class="btn eazlybtn btn-lg" i18n="@@SubmitModalWindow">
      <i class="fas fa-check"></i>
      Submit
    </button>
  </div>
</eazly-modal-window>
