import { Observable } from 'rxjs';
import { DataDescriptor } from '../../models/data-descriptor';
import { DynamicModification } from '../../models/dynamic-modification';
import { DynamicModificationProperties } from '../../models/dynamic-modification-properties';
import { DynamicModificationTarget } from '../../models/dynamic-modification-target';
import { VisibilityCriteria } from '../../models/visibility-criteria/visibility-criteria';
import { ViewerService } from '../../services/viewer.service';
import { Action } from './action';
import { AddDmOperation } from './operations/add-dm-operation';
import { DeleteDmOperation } from './operations/delete-dm-operation';
import { Operation } from './operations/operation';


export class DeleteDmAction extends Action {
    name: string;
    viewerService: ViewerService;

    protected operation: Operation;
    protected reverseOperation: Operation;

    private appeazUrl: string;
    private stepId: string;
    private idAppeaz: number;
    private appeazRole: string;
    private dynamicModification: DynamicModification;


    constructor(viewerService: ViewerService, appeazUrl: string, stepId: string,
        idAppeaz: number, appeazRole: string, dynamicModification: DynamicModification) {
        super();
        this.viewerService = viewerService;
        this.name = dynamicModification.modificationTarget.label;
        this.appeazUrl = appeazUrl;
        this.stepId = stepId;
        this.idAppeaz = idAppeaz;
        this.appeazRole = appeazRole;
        this.dynamicModification = dynamicModification;
        this.operation = new DeleteDmOperation(this.viewerService, this.name, idAppeaz, appeazUrl, appeazRole, stepId,
            dynamicModification.id);
    }


    // Parameters should be used once the API returns the required informations for building the reverse operation
    buildReverseOperation(deletedDM: any) {
        this.dynamicModification = new DynamicModification();
        this.dynamicModification.id = deletedDM.id;
        this.dynamicModification.priorityOrder = deletedDM.order;
        this.dynamicModification.modificationTarget = new DynamicModificationTarget();
        this.dynamicModification.modificationTarget.label = deletedDM.modificationTarget.label;
        this.dynamicModification.modificationTarget.infoText = deletedDM.modificationTarget.infoText;
        this.dynamicModification.modificationTarget.properties = new DynamicModificationProperties();
        this.dynamicModification.modificationTarget.properties.elementType = deletedDM.modificationTarget.type;
        this.dynamicModification.modificationTarget.properties.editable = deletedDM.modificationTarget.properties.EDITABLE;
        this.dynamicModification.modificationTarget.properties.required = deletedDM.modificationTarget.properties.REQUIRED;
        this.dynamicModification.modificationTarget.properties.textMaxLength = deletedDM.modificationTarget.properties.TEXT_MAX_LENGTH;
        this.dynamicModification.modificationTarget.properties.regExpPattern = deletedDM.modificationTarget.properties.REG_EXP_PATTERN;
        this.dynamicModification.modificationTarget.properties.defaultValue = deletedDM.modificationTarget.properties.DEFAULT_VALUE;
        this.dynamicModification.modificationTarget.properties.visibilityCriteria = new VisibilityCriteria();
        if (deletedDM.modificationTarget.properties.VISIBILITY_CRITERIA !== undefined) {
            const visbilityCriteriaJSON = JSON.parse(deletedDM.modificationTarget.properties.VISIBILITY_CRITERIA);
            this.dynamicModification.modificationTarget.properties.visibilityCriteria.conditions =
                visbilityCriteriaJSON.conditions;
            this.dynamicModification.modificationTarget.properties.visibilityCriteria.visibilityMode =
                visbilityCriteriaJSON.visibilityMode;
        }
        this.dynamicModification.modificationTarget.dataDescriptor = new DataDescriptor();
        if (deletedDM.modificationTarget.dataDescriptor !== undefined && deletedDM.modificationTarget.dataDescriptor !== null) {
            this.dynamicModification.modificationTarget.dataDescriptor.name = deletedDM.modificationTarget.dataDescriptor.dataName;
            this.dynamicModification.modificationTarget.dataDescriptor.datatype = deletedDM.modificationTarget.dataDescriptor.dataType;
            this.dynamicModification.modificationTarget.values = deletedDM.modificationTarget.dataDescriptor.values;
        }
        // EAZ-1026
        this.dynamicModification.modificationTarget.labelTranslations = deletedDM.modificationTarget.labelTranslations;
        this.dynamicModification.modificationType = deletedDM.modificationType;
        this.dynamicModification.anchorReference = deletedDM.anchor.anchorReference;
        this.name = deletedDM.modificationTarget.label;
        this.reverseOperation = new AddDmOperation(this.viewerService, this.name, this.idAppeaz, this.appeazUrl, this.appeazRole,
            this.stepId, this.dynamicModification, this.dynamicModification.priorityOrder, undefined);
    }

    do(): Observable<any> {
        return super.do();
    }

    undo(): Observable<any> {
        return super.undo();
    }

}
