<eazly-auth-header></eazly-auth-header>

<div class="main container container-reset-password">
    <h1 i18n="@@ResetPassword">
        Reset your password
    </h1>
    <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted }">
            <div class="ectzAlertInfo alert alert-info eazlyItemListInfo">
                <span i18n='@@accountInfoPasswordInstructions'>
                    Please input a password matching the following conditions:
                </span>
                <ul class="">
                    <li i18n='@@accountInfoPasswordInstructions8Char'>At least 8 characters</li>
                    <li i18n='@@accountInfoPasswordInstructionsCharCase'>At least one uppercase and one lowercase</li>
                    <li i18n='@@accountInfoPasswordInstructionsNumberChar'>At least one number</li>
                    <li i18n='@@accountInfoPasswordInstructionsSpecialChar'>At least one special character (eg.
                        &@.:,!?=+)</li>
                </ul>
            </div>
            <eazly-alert-manager></eazly-alert-manager>
            <label class="control-label">
                <span i18n="@@CreateAccountPassword">Password:</span>
                <i class="required-info" aria-hidden="true"></i>
            </label>
            <input type="Password" id="password" class="form-control" name="password" required
                [(ngModel)]="account.password" #password="ngModel">
            <div *ngIf="f.submitted && !password.valid" class="alert alert-danger"
                i18n="@@CreateAccountPasswordRequired">
                The password is required
            </div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted }">
            <label class="control-label">
                <span i18n="@@CreateAccountCPassword">Confirm Password:</span>
                <i class="required-info" aria-hidden="true"></i>
            </label>
            <input type="Password" id="cpassword" class="form-control" name="cpassword" [(ngModel)]="model.cpassword"
                required #cpassword="ngModel">
            <div *ngIf="f.submitted && !cpassword.valid" class="alert alert-danger"
                i18n="@@CreateAccountCPasswordRequired">
                The confirmation of the password is required</div>
        </div>
        <div>
            <button (click)="returnToLoginPage()" type="button" class="btn btn-lg " i18n="@@resetPasswordCancel">
                <em class="fas fa-long-arrow-alt-left"></em> Cancel</button>
            <button id="submit" type="submit" class="btn btn-primary btn-lg" i18n="@@resetPasswordSubmit">
                <em class="fas fa-check"></em> Submit </button>
        </div>
    </form>
</div>
