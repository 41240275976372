<eazly-modal-window>
    <div modalWindowHeader i18n="@@ResetPassword">
        Reset your password
    </div>
    <div modalWindowBody>
        <form id="resetPasswordForm" (ngSubmit)="f.form.valid && requestResetPasswordEmail(emailToReset)" #f="ngForm"
            novalidate>
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                <label class="control-label">
                    <span i18n="@@UsersModalInviteEmail">Email:</span>
                    <i class="required-info" aria-hidden="true"></i>
                </label>
                <input type="email" id="email" class="form-control" email=true name="email" required
                    [(ngModel)]="emailToReset" #email="ngModel">
                <div [hidden]="email.valid || email.pristine" class="alert alert-danger">
                    <div [hidden]="!email.hasError('required')" i18n="@@UsersModalInviteEmailRequired">Email is required
                    </div>
                    <div [hidden]="!email.valid && email.hasError('required')" i18n="@@UsersModalInviteEmailInvalid">
                        Email format is
                        invalid
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div modalWindowFooter>
        <button (click)="hideModal()" type="button" class="btn btn-lg " id="Cancel" i18n="@@resetPasswordCancel">
            <em class="fas fa-long-arrow-alt-left"></em> Cancel</button>
        <button id="Reset" (click)="hideModal()" type="submit" form="resetPasswordForm" class="btn btn-lg "
            i18n="@@SendButton" [disabled]="!email.valid">
            <em class="fas fa-check"></em> Send </button>
    </div>
</eazly-modal-window>
