import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './loading.component';

@NgModule({
    declarations: [
        LoadingComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule

    ],
    exports: [LoadingComponent],
    bootstrap: [LoadingComponent]
})
export class LoadingModule { }
