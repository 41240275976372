import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Locale } from '../../models/locale';

@Injectable()
export class LanguageService {
  public localesDataLoaded: Observable<void>;
  localesDataLoadedSubject: Subject<void> = new Subject<void>();

  public selectedLocaleObservable: Observable<Locale>;
  private readonly selectedLocaleSubject: BehaviorSubject<Locale>;
  private selectedLocale: Locale;
  private defaultLocale: Locale;
  private supportedLocales: Locale[];


  constructor() {
    this.selectedLocale = undefined;
    this.defaultLocale = undefined;
    this.supportedLocales = [];
    this.selectedLocaleSubject = new BehaviorSubject<Locale>(undefined);
    this.localesDataLoaded = this.localesDataLoadedSubject.asObservable();
    this.selectedLocaleObservable = this.selectedLocaleSubject.asObservable();
  }

  public setSelectedLocale(locale: Locale): void {
    if (this.selectedLocale !== locale) {
      this.selectedLocale = locale;
      this.selectedLocaleSubject.next(locale);
    }
  }

  public getSelectedLocale(): Locale {
    return this.selectedLocale;
  }

  public setDefaultLocale(defaultLocale: Locale) {
    this.defaultLocale = defaultLocale;
  }

  public getDefaultLocale(): Locale {
    return this.defaultLocale;
  }

  public isDefaultLocaleSelected(): boolean {
    return this.areLocaleEquals(this.defaultLocale, this.selectedLocale);
  }

  public notifyLocalesDataLoaded(): void {
    this.localesDataLoadedSubject.next();
  }

  /**
   * Set the list of supported locales
   *
   * @param locales the list of supported locales
   */
  public setSupportedLocales(locales: Locale[]): void {
    this.supportedLocales = locales;
  }

  /**
   * Returns the list of the supported locales
   *
   * @returns the list of the supported locales
   */
  public getSupportedLocales(): Locale[] {
    return this.supportedLocales;
  }

  /**
   * Returns a boolean indicating if the locale given in parameter is equal to the currently selected locale
   *
   * @param locale The locale to check
   * @returns a boolean indicating if the locale given in parameter is equal to the currently selected locale
   */
  public isSelectedLocale(locale: Locale): boolean {
    return this.areLocaleEquals(locale, this.selectedLocale);
  }

  /**
   * Returns a boolean indicating if the two locales given in parameters are equals
   *
   * @param locale1 The first locale to compare
   * @param locale2 The second locale to compare
   * @returns a boolean indicating if the two locales are equels
   */
  public areLocaleEquals(locale1: Locale, locale2: Locale): boolean {
    return locale1.countryCode === locale2.countryCode
      && locale1.language === locale2.language
      && locale1.languageCode === locale2.languageCode;
  }
}
