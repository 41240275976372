import { Observable } from 'rxjs';
import { Operation } from './operations/operation';

export abstract class Action {

    name: string; // To be displayed in the undo/redo history and prefixed with "undo or redo". Ex: Undo adding DM
    protected operation: Operation;
    protected reverseOperation: Operation;

    do(): Observable<any> {
        return this.operation.execute();
    }

    undo(): Observable<any> {
        return this.reverseOperation.execute();
    }

    getOperation(): Operation {
        return this.operation;
    }

    getReverseOperation(): Operation {
        return this.reverseOperation;
    }

    getName(): string {
        return this.name;
    }

    abstract buildReverseOperation(parameters: any);

}
