import { HttpResponse } from '@angular/common/http/';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ModalWindowComponent } from '../../tools/modal-window/modal-window.component';
import { Appeaz } from '../../tools/models/appeaz';
import { AppeazService } from '../../tools/services/appeaz.service';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';
import { UserService } from '../../tools/services/user.service';

@Component({
  selector: 'eazly-appeaz-dashboard',
  templateUrl: './appeaz-dashboard.component.html',
  styleUrls: ['./appeaz-dashboard.component.scss'],
  providers: [NGXLogger]
})
export class AppeazDashboardComponent implements OnInit, OnDestroy {

  @ViewChild(ModalWindowComponent, { static: true })
  modalWindowComponent: ModalWindowComponent;

  @Input()
  myappeaz: Appeaz;
  @Output()
  eventEmitter = new EventEmitter<number>();
  isDeletable: boolean;
  errorMessage: EazlyMessage;

  constructor(private appeazService: AppeazService,
    private userService: UserService,
    private messagingService: MessagingService,
    private router: Router,
    private readonly logger: NGXLogger) { }

  ngOnInit() {
    if (typeof this.myappeaz.id === 'undefined') {
      this.isDeletable = false;
    } else {
      this.isDeletable = true;
    }
    if (this.myappeaz.stylesheet === undefined) {
      this.myappeaz.stylesheet = '';
    }
    if (this.myappeaz.multilingue === undefined) {
      this.myappeaz.multilingue = false;
    }
  }

  ngOnDestroy() {
    if (this.errorMessage) {
      this.messagingService.notifyError(this.errorMessage);
    }
  }

  onSubmit(appeazForm: NgForm) {
    if (appeazForm.form.valid) {
      this.logger.info('Submitting dashboard: ' + this.myappeaz.id);
      if (typeof this.myappeaz.id === 'undefined') {
        this.handleAppeazCreation();
      } else {
        this.handleAppeazUpdate();
      }
    }
  }

  showModal() {
    this.modalWindowComponent.show();
  }

  hideModal() {
    this.modalWindowComponent.hide();
  }

  // Method called by the HTML of the component
  goBack() {
    this.router.navigate(['/appeazs']);
  }

  deleteAppeaz(appeazId: number) {
    this.hideModal();
    this.appeazService.deleteAppeaz(appeazId)
      .subscribe((response: HttpResponse<string>) => {
        this.errorMessage = new EazlyMessage(AlertType.success, 'BackendService', MessageCause.DeletionSuccessful, '');
        this.router.navigate(['/appeazs']);
      },
        error => {
          this.handleAppeazDashboardError(error);
        }
      );
  }

  private handleAppeazCreation() {
    this.myappeaz.userQuota = 10;
    this.appeazService.createAppeaz(this.myappeaz).subscribe(
      (response: HttpResponse<string>) => {
        this.errorMessage = new EazlyMessage(AlertType.success, 'BackendService', MessageCause.CreationSuccessful, '');
        this.router.navigate(['/appeazs']);
      },
      error => {
        if (401 === error.status) {
          this.errorMessage = new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
          this.userService.logout();
          this.router.navigate(['/login']);
        }
        if (403 === error.status) {
          this.errorMessage = new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.InsufficientRole, error.status + ' ' + error.statusText);
          this.router.navigate(['/appeazs']);
        }
      }
    );
  }

  private handleAppeazUpdate() {
    this.appeazService.updateAppeaz(this.myappeaz).subscribe(
      (response: HttpResponse<string>) => {
        this.errorMessage = new EazlyMessage(AlertType.success, 'BackendService', MessageCause.UpdateSuccessful, '');
        this.router.navigate(['/appeazs']);
      },
      error => {
        this.handleAppeazDashboardError(error);
      }
    );
  }

  private handleAppeazDashboardError(error: any) {
    if (401 === error.status) {
      this.errorMessage = new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
      this.userService.logout();
      this.router.navigate(['/login']);
    }
    if (403 === error.status) {
      this.errorMessage = new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.InsufficientRole, error.status + ' ' + error.statusText);
      this.router.navigate(['/appeazs']);
    }
    if (404 === error.status) {
      this.errorMessage = new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.AppeazNotFound, error.status + ' ' + error.statusText);
      this.router.navigate(['/appeazs']);
    }
  }
}
