/* eslint-disable max-lines */
import { Component, ViewChild, Output, EventEmitter, NgZone, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { ModalWindowComponent } from '../../../tools/modal-window/modal-window.component';
import { DataDescriptor } from '../../../tools/models/data-descriptor';
import { DynamicModification } from '../../../tools/models/dynamic-modification';
import { DynamicModificationProperties } from '../../../tools/models/dynamic-modification-properties';
import { DynamicModificationTarget } from '../../../tools/models/dynamic-modification-target';
import { DynamicModificationValueList } from '../../../tools/models/dynamic-modification-value';
import { Step } from '../../../tools/models/step';
import { StepDynamicModifications } from '../../../tools/models/step-dynamic-Modifications';
import { DataDescriptorService } from '../../../tools/services/data-descriptor.service';
import { LabelTranslationService } from '../../../tools/services/i18n/label-translation.service';
import { AlertType, EazlyMessage, MessageCause } from '../../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../../tools/services/messaging/messaging.service';
import { LanguageService } from '../../../tools/services/shared/language.service';
import { CompositeEditDMAction } from '../../../tools/undo-redo-manager/actions/composite-edit-dm-action';
import { InputDataNameValidator } from './validators/input-data-name-validator';

@Component({
  selector: 'eazly-dynamic-field-configuration-window',
  templateUrl: './dynamic-field-configuration-window.component.html',
  styleUrls: ['./dynamic-field-configuration-window.component.scss']
})
export class DynamicFieldConfigurationWindowComponent {

  private static readonly INPUT_DATA_NAME_FIELD_ID = 'inputDataName';
  private static readonly DEFAULT_MAX_LENGTH = 1000;
  private static readonly MIN_LENGTH_LIMIT = 1;
  private static readonly MAX_LENGTH_LIMIT = 100000000;
  private static readonly INTEGER_MIN_VALUE = -100000000;
  private static readonly INTEGER_MAX_VALUE = 100000000;

  @Output() onSubmitDynamicModificationSettings: EventEmitter<DynamicModification[]> = new EventEmitter<DynamicModification[]>();
  @Input() isI18N: boolean;

  @ViewChild(ModalWindowComponent, { static: true })
  private readonly modalWindowComponent: ModalWindowComponent;

  infoText: string;

  fieldName: string;
  dynamicModification: DynamicModification;
  initialValuesOfList: DynamicModificationValueList[];
  dynamicModificationId: string;
  isExpertUser: boolean;

  inputTextMaxLengthValue: number;
  inputDefaultValue: string;
  regExpValue: string;
  inputDataNameValue: string;

  dmSettingsForm: FormGroup;
  hasAnyError: boolean;
  inputDataNameValidator: InputDataNameValidator;
  dataTypeConflictParameters: string;

  allLinkedFields: StepDynamicModifications[];
  isDissociateButtonClicked: boolean;
  isVisibilityConditionsClearedMessageAlreadyShown: boolean;
  isFieldLinkCreated: boolean;
  isInitiallyLinked: boolean;
  // The dataName used for creating the link between fields.
  // This dataName is necessary for handling composite actions 'Renaming after Linking'
  dataNameForLinkCreation: string;

  appeazUrl: string;
  stepBeingEdited: Step;

  compatibleSuggestionsForDataName: string[];

  isDataNameInputFocused: boolean;
  formKeyPressEventListenerBind: EventListener;

  resultsOfSearchLinkedFilter: any;

  referencedFields = '';
  alerts: EazlyMessage[];
  messageCause = MessageCause;

  compatibleDataDescriptors: Map<string, DataDescriptor> = new Map<string, DataDescriptor>();

  constructor(private readonly angularZone: NgZone,
    private readonly formBuilder: FormBuilder,
    private readonly dataDescriptorService: DataDescriptorService,
    private readonly logger: NGXLogger,
    private readonly messagingService: MessagingService,
    private readonly labelTranslationService: LabelTranslationService,
    private readonly languageService: LanguageService) {
    this.alerts = [];
    this.dmSettingsForm = this.formBuilder.group({
      'inputMaxLength': [this.inputTextMaxLengthValue, [
        Validators.min(DynamicFieldConfigurationWindowComponent.MIN_LENGTH_LIMIT),
        Validators.max(DynamicFieldConfigurationWindowComponent.MAX_LENGTH_LIMIT),
        Validators.required]
      ],
      'inputRegExp': [this.regExpValue, []
      ],
      'inputDefaultValue': [this.inputDefaultValue, [
        // Custom validators are required for number and are managed in the method 'inputDefaultValueNumberEventHandler'.
      ]],
      'infoText': [this.infoText, [
      ]],
      'inputDataName': [this.inputDataNameValue, [
        // Validators are set in the show() method with custom validators
      ]
      ]
    }
    );

    this.allLinkedFields = [];
    this.compatibleSuggestionsForDataName = [];
    this.isDataNameInputFocused = false;
  }

  public hide(): void {
    this.modalWindowComponent.hide();
    this.compatibleSuggestionsForDataName = [];
    // Removing event listener from the form
    const formHtmlElement: HTMLElement = jQuery('#dmConfigurationForm')[0];
    formHtmlElement.removeEventListener('keypress', this.formKeyPressEventListenerBind);
  }


  public show(dynamicModification: DynamicModification, isExpert: boolean, url: string, step: Step, localeId: string, referencedFields: string): void {
    this.angularZone.run(() => {
      this.alerts = [];
      this.referencedFields = referencedFields;
      this.resetInputDataNameValidators();
      this.hasAnyError = false;
      this.isDissociateButtonClicked = false;
      this.isVisibilityConditionsClearedMessageAlreadyShown = false;
      this.isFieldLinkCreated = false;
      this.isInitiallyLinked = undefined;
      this.appeazUrl = url;
      this.stepBeingEdited = step;
      const searchLinkedFieldsPromise: Promise<boolean> = this.searchLinkedFields(dynamicModification.modificationTarget.dataDescriptor.id);
      this.dynamicModification = dynamicModification;
      this.dynamicModificationId = this.dynamicModification.id.toString();
      this.initialValuesOfList = this?.dynamicModification?.modificationTarget?.values;
      this.isExpertUser = isExpert;
      this.loadDynamicModificationParameters();
      this.modalWindowComponent.show();
      // Adding an event listener on the form to prevent submitting the form by typing 'Enter'
      const formHtmlElement: HTMLElement = jQuery('#dmConfigurationForm')[0];
      this.formKeyPressEventListenerBind = this.formKeyPressEventListener.bind(this);
      formHtmlElement.addEventListener('keypress', this.formKeyPressEventListenerBind);
      const dataDictionnariesPromise: Promise<DataDescriptor[][]> = this.getDataDictionnaries();

      // Waiting for the resolutions of the promises searchLinkedFields and getDataDictionnaries
      // before initializeCompatibleSuggestions and the inputDataNameValidators.
      Promise.all([searchLinkedFieldsPromise, dataDictionnariesPromise]).then((promisesResponses) => {
        const dataDictionnariesPromiseResult: DataDescriptor[][] = promisesResponses[1];
        const dataDictionnary = dataDictionnariesPromiseResult[0];
        const dataDictionnaryOfTheStep = dataDictionnariesPromiseResult[1];

        if (!this.isLinkedField()) {
          this.initializeCompatibleSuggestions(dataDictionnary, dataDictionnaryOfTheStep);
        }
        this.initializeInputDataNameValidators(this.dynamicModification,
          this.isLinkedField(),
          dataDictionnary,
          dataDictionnaryOfTheStep,
          this.stepBeingEdited,
          localeId,
          this.logger);
      });
    });
    this.initSelectDefaultValue();
    if (this.isTextArea()) {
      this.resizeTextArea('inputTextAreaDefaultValue');
    }
  }

  /**
   * This method set the selected value of '#selectdefval' as the default value
   * of the DM if it is a ListValue
   */
  public initSelectDefaultValue(): void {
    if (this.isListValue()) {
      const defaultValueId: number = Number.parseInt(this.dynamicModification.modificationTarget.properties.defaultValue, 10);
      $('#selectdefval').val(defaultValueId);
    }
  }

  /**
   * EventHandler to bind to the 'keypress' event in the HTML file for limiting the accepted keypressed
   * of an inputField.
   *
   * This eventHandler allows to accept only numbers by returning a boolean indicating
   * if the key pressed is accepted.
   *
   * @param event the KeyboardEvent to analyze
   * @param allowNegativeNumber parameter to indicate if negative number should be accepted
   * @returns true if the key pressed is an accepted number or false otherwise
   */
  public allowOnlyNumberOnKeyPress(event: Event, allowNegativeNumber: boolean): boolean {
    // Checking if the key typed is a number
    if (event instanceof KeyboardEvent) {
      return this.inputKeyIsANumber(event.key, allowNegativeNumber);
    } else {
      return false;
    }
  }

  /**
   * Method called on submit (see (ngSubmit) of the form in the HTML file)
   *
   * This method initialize a DynamicModification containing the new values for the differents
   * properties of the DynamicModification to edit.
   *
   * This DynamicModification is then sent to the viewerComponent through the eventEmitter.
   * The ViewerComponent will relay this DynamicModification to the engine in the method
   * "relayDmUpdateFromModalWindowToEazlyEngine".
   */
  // eslint-disable-next-line complexity
  public updateDynamicModificationConfiguration(): void {
    // Initializing a DM with the new values to set
    const dynamicModificationSpecifyingNewValues: DynamicModification = this.initDMWithNewSettings();
    const dynamicModificationsToSend: DynamicModification[] = [dynamicModificationSpecifyingNewValues];
    let atLeastOnePropertyModified = false;

    // Setting the new values in the DynamicModification if they are supported and if they have been updated
    // Setting TextMaxLength property
    if (this.isSupportingTextMaxLength() && this.getTextMaxLength() !== undefined && !Number.isNaN(this.getTextMaxLength())) {
      const isTextLengthModified = this.getTextMaxLength().toString() !== this.dynamicModification?.modificationTarget?.properties.textMaxLength;
      const isTextLengthValid = Number(this.getTextMaxLength()) > 0 && this.getTextMaxLength() <= DynamicFieldConfigurationWindowComponent.MAX_LENGTH_LIMIT;
      if (isTextLengthModified && isTextLengthValid) {
        dynamicModificationSpecifyingNewValues.modificationTarget.properties.textMaxLength = this.getTextMaxLength().toString();
        atLeastOnePropertyModified = true;
      }
    }
    const isRegExpModified = this.getRegExp() !== this.dynamicModification?.modificationTarget?.properties.regExpPattern;
    // Setting RegExp property
    if (this.isSupportingRegExp() && this.isExpertUser) {
      if (isRegExpModified && this.getRegExp() !== null) {
        dynamicModificationSpecifyingNewValues.modificationTarget.properties.regExpPattern = this.getRegExp();
        atLeastOnePropertyModified = true;
      }
    }
    // Setting defaultValue property
    if (this.isSupportingDefaultValue()) {
      if (this.isDissociateButtonClicked && this.isListValue()) {
        const dynamicModificationForResetingDefaultValue: DynamicModification = this.initDMWithNewSettings();
        dynamicModificationForResetingDefaultValue.modificationTarget.properties.defaultValue = '';
        dynamicModificationsToSend.unshift(dynamicModificationForResetingDefaultValue);
        atLeastOnePropertyModified = true;
      }
      const isdefaultValueModified = this.getDefaultValue() !== this.dynamicModification.modificationTarget.properties.defaultValue;
      if (isdefaultValueModified) {
        dynamicModificationSpecifyingNewValues.modificationTarget.properties.defaultValue = this.getDefaultValue();
        atLeastOnePropertyModified = true;
      }
    }
    // Setting informative text property
    const isInfoTextModified = this.getInfoText() !== this.dynamicModification?.modificationTarget?.infoText;
    if (this.isSupportingInfoText() && isInfoTextModified && this.getInfoText() !== null) {
      dynamicModificationSpecifyingNewValues.modificationTarget.infoText = this.getInfoText();
      atLeastOnePropertyModified = true;
    }
    // Setting DataName property
    const isDataNameModified = this.getDataName() !== this.dynamicModification?.modificationTarget?.dataDescriptor?.name;
    if (this.isSupportingDataDescriptor()) {
      // Checking if the EditDM Request has created a link. If so, the undo operation should include a dissociation
      if (this.isFieldLinkCreated) {
        const linkingForComposite: DynamicModification = this.initDMWithNewSettings();
        (linkingForComposite as any).compositeOperationContext = CompositeEditDMAction.ASSOCIATING_FOR_COMPOSITE_CONTEXT;
        linkingForComposite.modificationTarget.dataDescriptor.name = this.dataNameForLinkCreation;
        if (this.isListValue()) {
          // Resetting the DefaultValue since it references a value from the old DataDescriptor (before the link creation)
          linkingForComposite.modificationTarget.properties.defaultValue = '';
        }
        atLeastOnePropertyModified = true;
        // Association should be placed after the dissociation and before the Final renaming
        dynamicModificationsToSend.unshift(linkingForComposite);
      }

      // Checking if the DataName has been modified or if a link as been created
      if (isDataNameModified || this.isFieldLinkCreated) {
        dynamicModificationSpecifyingNewValues.modificationTarget.dataDescriptor.name = this.getDataName();
        (dynamicModificationSpecifyingNewValues as any).compositeOperationContext = CompositeEditDMAction.FINAL_FOR_COMPOSITE_CONTEXT;
        atLeastOnePropertyModified = true;
      }
      if (this.isListValue() && !this.isInitiallyLinked && this.isFieldLinkCreated && this.initialValuesOfList?.length > 0) {
        // Adding a message warning the user that old values of the list are lost when linking the list
        // to a new list. Conditions of visibility of the fields "this.referencedFields" are also lost
        const infoMessageForValuesLost = new EazlyMessage(AlertType.info, 'ViewerService'
          , MessageCause.UpdateOfDatadescriptorOfReferencedValuesList, this.referencedFields);
        this.messagingService.notifyError(infoMessageForValuesLost);
      }

      // Checking if the EditDM request should be done in two parts (Dissociate + Edit datadescriptor)
      if (this.isDissociateButtonClicked && isDataNameModified) {
        // Adding the DM describing the dissociation at the beginning of the array
        // to send to the engine
        const dissociationForComposite: DynamicModification = this.initDMWithNewSettings();
        (dissociationForComposite as any).compositeOperationContext = CompositeEditDMAction.DISSOCIATING_FOR_COMPOSITE_CONTEXT;
        dissociationForComposite.modificationTarget.dataDescriptor.name = '';
        if (this.isListValue()) {
          // Resetting the DefaultValue since it references a value from the old DataDescriptor (before the dissociation)
          dissociationForComposite.modificationTarget.properties.defaultValue = '';
        }
        // Dissociation should be added at the beginning of the array
        dynamicModificationsToSend.unshift(dissociationForComposite);
        atLeastOnePropertyModified = true;
      }
    }
    if (!this.hasAnyError) {
      // Emitting the DynamicModification only if at least one property has been modified
      if (atLeastOnePropertyModified) {
        this.logger.debug('Emitting to the ViewerComponent ', dynamicModificationsToSend);
        this.onSubmitDynamicModificationSettings.emit(dynamicModificationsToSend);
      }
      this.modalWindowComponent.hide();
    }
  }

  public getTextMaxLength(): number {
    return this.dmSettingsForm.get('inputMaxLength').value;
  }

  public getRegExp(): string {
    return this.dmSettingsForm.get('inputRegExp').value;
  }

  public getDefaultValue(): string {
    let defaultValue = this.dmSettingsForm.get('inputDefaultValue').value;
    if (defaultValue === null || defaultValue === undefined) {
      defaultValue = '';
    }
    return defaultValue.toString();
  }

  public getInfoText(): string {
    let infoText = this.dmSettingsForm.get('infoText').value;
    if (infoText === null || infoText === undefined) {
      infoText = '';
    }
    return infoText;
  }

  public getDataName(): string {
    return this.dmSettingsForm.get(DynamicFieldConfigurationWindowComponent.INPUT_DATA_NAME_FIELD_ID).value;
  }

  public isSupportingInfoText(): boolean {
    let isSupportingInfoText = false;
    if (this.dynamicModification?.modificationTarget?.properties) {
      switch (this.dynamicModification.modificationTarget.properties.elementType) {
        case 'CHECK_BOX':
        case 'COMBO_BOX':
        case 'DATE':
        case 'INTEGER':
        case 'RADIO_BUTTON':
        case 'RADIO_LIST_HORIZONTAL':
        case 'RADIO_LIST_VERTICAL':
        case 'TEXT_AREA':
        case 'TEXT_FIELD':
          isSupportingInfoText = true;
          break;
        default:
          isSupportingInfoText = false;
      }
    }
    return isSupportingInfoText;
  }

  public isSupportingTextMaxLength(): boolean {
    let isSupportingTextMaxLength = false;
    if (this.dynamicModification?.modificationTarget?.properties) {
      switch (this.dynamicModification.modificationTarget.properties.elementType) {
        case 'TEXT_AREA':
        case 'TEXT_FIELD':
          isSupportingTextMaxLength = true;
          break;
        default:
          isSupportingTextMaxLength = false;
      }
    }
    return isSupportingTextMaxLength;
  }

  public isSupportingRegExp(): boolean {
    let isSupportingRegExp = false;
    if (this.dynamicModification?.modificationTarget?.properties) {
      switch (this.dynamicModification.modificationTarget.properties.elementType) {
        case 'TEXT_AREA':
        case 'TEXT_FIELD':
          isSupportingRegExp = true;
          break;
        default:
          isSupportingRegExp = false;
      }
    }
    return isSupportingRegExp;
  }

  public isSupportingDefaultValue(): boolean {
    let isSupportingDefaultValue = false;
    if (this.dynamicModification?.modificationTarget?.properties) {
      switch (this.dynamicModification.modificationTarget.properties.elementType) {
        case 'TEXT_AREA':
        case 'TEXT_FIELD':
        case 'INTEGER':
        case 'COMBO_BOX':
        case 'RADIO_LIST_HORIZONTAL':
        case 'RADIO_LIST_VERTICAL':
        case 'CHECK_BOX':
        case 'RADIO_BUTTON':
          isSupportingDefaultValue = true;
          break;
        case 'DATE':
        default:
          isSupportingDefaultValue = false;
      }
    }
    return isSupportingDefaultValue;
  }

  public isSupportingDataDescriptor(): boolean {
    let isSupportingDataDescriptor = false;
    if (this.dynamicModification?.modificationTarget?.properties) {
      switch (this.dynamicModification.modificationTarget.properties.elementType) {
        case 'CHECK_BOX':
        case 'COMBO_BOX':
        case 'DATE':
        case 'INTEGER':
        case 'RADIO_BUTTON':
        case 'RADIO_LIST_HORIZONTAL':
        case 'RADIO_LIST_VERTICAL':
        case 'TEXT_AREA':
        case 'TEXT_FIELD':
          isSupportingDataDescriptor = true;
          break;
        default:
          isSupportingDataDescriptor = false;
      }
    }
    return isSupportingDataDescriptor;
  }

  /**
   * Returns the minimal value an int can have.
   * This minimal value has been rounded and is used to limit the default value of Integer fields.
   *
   *
   * See https://docs.oracle.com/javase/8/docs/api/java/lang/Integer.html#MIN_VALUE
   * @returns the minimal value an int can have.
   */
  private getIntegerMinValue(): number {
    return DynamicFieldConfigurationWindowComponent.INTEGER_MIN_VALUE;
  }

  /**
   * Returns the maximal value an int can have.
   * This maximal value has been rounded and is used to limit the default value of Integer fields.
   *
   * See https://docs.oracle.com/javase/8/docs/api/java/lang/Integer.html#MAX_VALUE
   * @returns the maximal value an int can have.
   */
  private getIntegerMaxValue(): number {
    return DynamicFieldConfigurationWindowComponent.INTEGER_MAX_VALUE;
  }

  private isSimpleText(): boolean {
    return 'TEXT_FIELD' === this.dynamicModification?.modificationTarget?.properties?.elementType;
  }

  private isTextArea(): boolean {
    return 'TEXT_AREA' === this.dynamicModification?.modificationTarget?.properties?.elementType;
  }

  private isListValue(): boolean {
    let isListValue = false;
    switch (this.dynamicModification?.modificationTarget?.properties?.elementType) {
      case 'COMBO_BOX':
      case 'RADIO_LIST_HORIZONTAL':
      case 'RADIO_LIST_VERTICAL':
        isListValue = true;
        break;
      default:
        isListValue = false;
    }
    return isListValue;
  }

  private isBooleanValue(): boolean {
    let isBooleanValue = false;
    switch (this.dynamicModification?.modificationTarget?.properties?.elementType) {
      case 'CHECK_BOX':
      case 'RADIO_BUTTON':
        isBooleanValue = true;
        break;
      default:
        isBooleanValue = false;
    }
    return isBooleanValue;
  }

  private isNumberValue(): boolean {
    return 'INTEGER' === this.dynamicModification?.modificationTarget?.properties?.elementType;
  }

  private loadDynamicModificationParameters(): void {
    let regExpValue = '';
    let infoText = '';
    let textMaxLength = DynamicFieldConfigurationWindowComponent.DEFAULT_MAX_LENGTH;
    const translatedLabel: string = this.labelTranslationService.getDMLabelForTitle(
      this.dynamicModification, this.isI18N, this.languageService.getSelectedLocale());
    this.fieldName = translatedLabel ? this.modalWindowComponent.truncateString(translatedLabel) : this.dynamicModification.modificationTarget.label;
    if (this.dynamicModification.modificationTarget.properties.textMaxLength !== undefined) {
      textMaxLength = Number.parseInt(this.dynamicModification.modificationTarget.properties.textMaxLength, 10);
    }
    if (this.dynamicModification.modificationTarget.properties.regExpPattern !== undefined) {
      regExpValue = this.dynamicModification.modificationTarget.properties.regExpPattern;
    }
    if (this.dynamicModification.modificationTarget.infoText !== undefined) {
      infoText = this.dynamicModification.modificationTarget.infoText;
    }
    const dataName = this.dynamicModification?.modificationTarget?.dataDescriptor?.name;
    const defaultInputValue = this.dynamicModification?.modificationTarget?.properties?.defaultValue;

    this.dmSettingsForm.get('inputMaxLength').setValue(textMaxLength);
    this.dmSettingsForm.get('inputRegExp').setValue(regExpValue);
    this.dmSettingsForm.get('infoText').setValue(infoText);
    this.dmSettingsForm.get(DynamicFieldConfigurationWindowComponent.INPUT_DATA_NAME_FIELD_ID).setValue(dataName);
    this.dmSettingsForm.get('inputDefaultValue').setValue(defaultInputValue);
  }

  private inputKeyIsANumber(key: string, allowNegativeNumber: boolean): boolean {
    let isANumber;
    switch (key) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        isANumber = true;
        break;
      default:
        isANumber = false;
    }
    if (!isANumber && allowNegativeNumber && key === '-') {
      isANumber = true;
    }
    return isANumber;
  }

  /**
   * This method initialize a DM containing the new values to send to the eazly engine
   */
  private initDMWithNewSettings(): DynamicModification {
    const dynamicModificationSpecifyingNewValues: DynamicModification = new DynamicModification();
    dynamicModificationSpecifyingNewValues.id = this.dynamicModification.id;
    dynamicModificationSpecifyingNewValues.modificationTarget = new DynamicModificationTarget();
    dynamicModificationSpecifyingNewValues.modificationTarget.properties = new DynamicModificationProperties();
    dynamicModificationSpecifyingNewValues.modificationTarget.dataDescriptor = new DataDescriptor();

    return dynamicModificationSpecifyingNewValues;
  }

  /**
   * This method finds all linked fields which have the same datadescriptor name sent as parameter.
   * Take as parameter datadescriptor name of current dynamic modification
   * Initialize allLinkedFields with all linked fields found by calling the proper API
   *
   * @returns a Promise indicating if the field is linked
   */
  private async searchLinkedFields(dataDescriptorId: string): Promise<boolean> {
    return new Promise((resolve) => {
      // Retrieving the inputDataNameControl to check if there is any error
      const inputDataNameControl: AbstractControl = this.dmSettingsForm.get(DynamicFieldConfigurationWindowComponent.INPUT_DATA_NAME_FIELD_ID);
      if (undefined !== dataDescriptorId
        && inputDataNameControl.errors === null
      ) {
        this.dataDescriptorService.getLinkedDynamicModifications(this.appeazUrl, dataDescriptorId).subscribe(
          data => {
            this.allLinkedFields = this.deleteCurrentFieldFromAllLinkedFields(data);
            this.resultsOfSearchLinkedFilter = data;

            if (this.isInitiallyLinked === undefined) {
              this.isInitiallyLinked = this.allLinkedFields.length > 0;
            }
            resolve(this.isLinkedField());
          });
      } else {
        resolve(false);
      }
    });
  }

  private deleteCurrentFieldFromAllLinkedFields(allLinkedFields: StepDynamicModifications[]): StepDynamicModifications[] {
    allLinkedFields.forEach((linkedField, index) => {
      if (linkedField.stepName === this.stepBeingEdited.name) {
        allLinkedFields.splice(index, 1);
      }
    });
    return allLinkedFields;
  }

  private isLinkedField(): boolean {
    return !(this.allLinkedFields === undefined || this.allLinkedFields.length < 1);
  }

  private dissociateThisField() {
    let datadescriptor: DataDescriptor;
    this.dataDescriptorService.getAvailableDataDescriptor(this.appeazUrl, this.dynamicModification.modificationTarget.label).subscribe(
      data => {
        datadescriptor = data;
        this.dmSettingsForm.get(DynamicFieldConfigurationWindowComponent.INPUT_DATA_NAME_FIELD_ID).setValue(datadescriptor.name);
        this.isDissociateButtonClicked = true;
        this.isFieldLinkCreated = false;
        this.dataNameForLinkCreation = '';
        this.allLinkedFields = [];
        // Updating the attribute 'isLinkedField' of the 'inputDataNameValidator'
        this.inputDataNameValidator.setIsLinkedField(false);
        this.getDataDictionnaries().then((dataDictionnaries: DataDescriptor[][]) => {
          this.initializeCompatibleSuggestions(dataDictionnaries[0], dataDictionnaries[1]);
        });

        //reset default value
        this.inputDefaultValue = '';
        this.dynamicModification.modificationTarget.values = [];

        //show information message when the current linked dynamic field is used to define visibility conditions of other fields
        if (this.isVisibilityConditionsClearedMessageAlreadyShown === false &&
          this.referencedFields !== undefined && this.referencedFields !== null && this.referencedFields !== '') {
          this.showTheVisibilityConditionsResetMessage();
        }
      });
  }

  /**
   * EventHandler for the dataName input field.
   * This event handler triggers the method 'this.searchLinkedFields()'
   * when clicking on a suggestion or when typing 'Enter' in the field.
   *
   * @param event the event triggering the 'input' event
   */
  private dataNameInputEventHandler(event: Event) {
    const inputDataNameField: HTMLInputElement = event.target as HTMLInputElement;
    const enterKeyPressed: boolean = event instanceof KeyboardEvent && event.key === 'Enter';
    const suggestionClicked: boolean = event.type === 'input' && !(event instanceof InputEvent);
    if (enterKeyPressed || suggestionClicked) {
      this.handleInputDataNameSubmitEvent(inputDataNameField);
      inputDataNameField.blur();
    }
    this.updateHasAnyError();
  }

  private updateDefaultValueComboBox() {
    if (this.isListValue()) {
      const dynamicModification: DynamicModification = this.resultsOfSearchLinkedFilter[0].modifications[0] as DynamicModification;
      this.dynamicModification.modificationTarget.values = dynamicModification.modificationTarget.values;
    }
  }

  /**
   * EventHandler for the event 'focus' and 'focusout' of the dataNameInputField.
   * This event handler triggers the method 'this.searchLinkedFields()'
   * when losing the focus on the field.
   *
   * @param event event triggering the 'focus' or 'focusout' event
   */
  private setInputDataNameFocusEventHandler(event: Event) {
    this.isDataNameInputFocused = 'focus' === event.type;
    if (!this.isDataNameInputFocused) {
      const inputDataNameField: HTMLInputElement = event.target as HTMLInputElement;
      this.handleInputDataNameSubmitEvent(inputDataNameField);
    }
  }

  /**
   * This method handle the 'submit' event of the inputDataNameField.
   * This event handler search for linked fields with the value of the field
   * to update the state of the field (isLinked or !isLinked) and the UI
   * if the inputValue matches with an existing DataDescriptor which are
   * registered in the map 'compatibleDataDescriptors'.
   *
   * @param inputDataNameField the dataName inputField
   */
  private handleInputDataNameSubmitEvent(inputDataNameField: HTMLInputElement) {
    const dataDescriptor: DataDescriptor = this.compatibleDataDescriptors.get(inputDataNameField.value);
    if (undefined !== dataDescriptor) {
      this.searchLinkedFields(dataDescriptor.id).then((isFieldLinked) => {
        if (isFieldLinked) {
          this.isFieldLinkCreated = true;
          // Updating the attribute 'dataNameForLinkCreation' of the 'inputDataNameValidator'
          this.dataNameForLinkCreation = inputDataNameField.value;
          this.inputDataNameValidator.setDataNameForLinkCreation(this.dataNameForLinkCreation);
          // Updating the defaultValueComboBox for fields of type ListValues
          // for displaying the values of the new DataDescriptor fetched
          this.updateDefaultValueComboBox();

          //show information message when the current dynamic field is used to define visibility conditions of other fields
          this.showIfNeededTheVisibilityConditionsResetMessage(inputDataNameField);
        }
        // Updating the attribute 'isLinkedField' of the 'inputDataNameValidator'
        this.inputDataNameValidator.setIsLinkedField(isFieldLinked);
        this.updateHasAnyError();
      });
    }
  }

  private showIfNeededTheVisibilityConditionsResetMessage(inputDataNameField: HTMLInputElement) {
    if (this.isVisibilityConditionsClearedMessageAlreadyShown === false &&
      this.referencedFields !== undefined && this.referencedFields !== null && this.referencedFields !== '') {
      this.showTheVisibilityConditionsResetMessage();
    }
  }

  private showTheVisibilityConditionsResetMessage() {
    const infoMessage = new EazlyMessage(AlertType.info, this.referencedFields,
      MessageCause.DissociationOfDynamicFieldWhichWasUsedInCondition, '');
    this.alerts.push(infoMessage);
    this.isVisibilityConditionsClearedMessageAlreadyShown = true;
  }

  /**
   * This method returns a promise containing an array of two DataDictionnaries:
   * - dataDictionnary (The Dictionnary of the draft version)
   * - dataDictionnaryOfTheStep (The part of the Dictionnary used in the current step)
   *
   * @returns a Promise containing the two dataDictionnaries retrieved
   */
  private async getDataDictionnaries(): Promise<DataDescriptor[][]> {
    return new Promise<any>((resolve) => {
      this.compatibleSuggestionsForDataName = [];
      this.dataDescriptorService.getDataDictionnary(this.appeazUrl, '').subscribe(
        (dataDictionnaryReturnedByTheEngine: DataDescriptor[]) => {
          this.logger.debug('Data dictionnary of the draft version', dataDictionnaryReturnedByTheEngine);
          this.dataDescriptorService.getDataDictionnaryOfAStep(this.appeazUrl, this.stepBeingEdited.id).subscribe(
            (dataDictionnaryOfTheStep: DataDescriptor[]) => {
              this.logger.debug('Data descriptors already used in the step ',
                this.stepBeingEdited.name, '(Id = \'', this.stepBeingEdited.id, ' \'): ',
                dataDictionnaryOfTheStep);
              resolve([dataDictionnaryReturnedByTheEngine, dataDictionnaryOfTheStep]);
            }
          );
        });
    });
  }

  /**
   * This method initialize the array 'this.compatibleSuggestionsForDataName'.
   * This array will be used as the list of possible suggestions when the user
   * types a DataName of a DM that is not linked to other DM.
   *
   * @param dataDictionnary the DataDictionnary
   * @param dataDictionnaryOfTheStep the part of DataDictionnary used in the current step
   */
  private initializeCompatibleSuggestions(dataDictionnary: DataDescriptor[], dataDictionnaryOfTheStep: DataDescriptor[]): void {
    this.compatibleSuggestionsForDataName = this.filterSuggestions(dataDictionnary, dataDictionnaryOfTheStep);
    this.logger.debug('Compatible suggestions initialized with ', this.compatibleSuggestionsForDataName);

  }

  /**
   * This method initialize the following validators for the InputDataName field:
   * - Required
   * - DataTypeConflict (for dissociated fields)
   * - AlreadyUsedConflict (for dissociated fields)
   * - RenamingConflict (for linked fields)
   *
   * The InputDataNameValidator requires the DataDictionnary and the DataDictionnary of the step
   * to detect the different conflicts.
   *
   * @param dynamicModification the DynamicModification being edited
   * @param isLinkedField the state 'isLinked' of the DynamicModification
   * @param dataDictionnary the DataDictionnary of the draft version
   * @param dataDictionnaryOfTheStep the DataDescriptors already used in the current step
   * @param stepBeingEdited the Step being edited
   * @param logger the logger to use
   */
  private initializeInputDataNameValidators(dynamicModification: DynamicModification,
    isLinkedField: boolean,
    dataDictionnary: DataDescriptor[],
    dataDictionnaryOfTheStep: DataDescriptor[],
    stepBeingEdited: Step,
    localeId: string,
    logger: NGXLogger): void {
    // Adding validators for inputDataName
    this.inputDataNameValidator = new InputDataNameValidator(
      dynamicModification,
      isLinkedField,
      dataDictionnary,
      dataDictionnaryOfTheStep,
      stepBeingEdited,
      localeId,
      logger
    );
    const inputDataNameField: AbstractControl = this.dmSettingsForm.get(DynamicFieldConfigurationWindowComponent.INPUT_DATA_NAME_FIELD_ID);
    inputDataNameField.setValidators([Validators.required, this.inputDataNameValidator.getValidator()]);
    // Calling updateValueAndValidity() for the new validation to take effect
    this.dmSettingsForm.updateValueAndValidity();
  }

  /**
   * This method removes the validators from the inputDataNameField
   * and any errors raised by these validators.
   */
  private resetInputDataNameValidators(): void {
    this.inputDataNameValidator = null;
    const inputDataNameField: AbstractControl = this.dmSettingsForm.get(DynamicFieldConfigurationWindowComponent.INPUT_DATA_NAME_FIELD_ID);
    inputDataNameField.setErrors(null);
    inputDataNameField.clearValidators();
    // Calling updateValueAndValidity() for the new validation to take effect
    this.dmSettingsForm.updateValueAndValidity();
  }

  /**
   * This method filters the dataDictionnary given in the first parameter
   * by removing:
   * - the DataDescriptor whose type is different from the field being edited
   * - the DataDescriptors of the second parameter (the DataDescriptors already used in the same step)
   *
   * The result is returned as an array of string containing the name of the remaining DataDescriptors.
   * @param dataDictionnary The DataDictionnary of the Draft version of the model
   * @param dataDescriptorAlreadyUsedInTheStep The DataDictionnary containing the DataDescriptor already used in the step being edited
   * @returns an array of name of the remaining dataDescriptors
   */
  private filterSuggestions(
    dataDictionnary: DataDescriptor[],
    dataDescriptorAlreadyUsedInTheStep: DataDescriptor[]): string[] {
    const compatibleSuggestions: string[] = [];
    const originalDataDescriptor: DataDescriptor = this.dynamicModification.modificationTarget.dataDescriptor;
    this.compatibleDataDescriptors = new Map<string, DataDescriptor>();
    for (const dataDescriptor of dataDictionnary) {
      if (dataDescriptor.isIncluded === undefined) {
        // Copying the function 'isIncluded' if it is not defined (DataDescriptor returned by the engine)
        dataDescriptor.isIncluded = DataDescriptor.prototype.isIncluded;
      }
      // EAZ-729 : Checking if the dataDescriptor is already used in the step and if it was not the original DataDescriptor
      const isDataDescriptorAlreadyUsed: boolean = dataDescriptor.isIncluded(dataDescriptorAlreadyUsedInTheStep)
        && dataDescriptor.name !== originalDataDescriptor.name;
      if (this.dynamicModification?.modificationTarget?.dataDescriptor?.datatype === dataDescriptor.datatype
        && !isDataDescriptorAlreadyUsed) {
        compatibleSuggestions.push(dataDescriptor.name);
        // ECTZ-6999: Registering the DataDescriptor to be able to retrieve its id in the method 'handleInputDataNameSubmitEvent'
        this.compatibleDataDescriptors.set(dataDescriptor.name, dataDescriptor);
      }
    }
    return compatibleSuggestions.sort((valueA: string, valueB: string) =>
      valueA.toLowerCase().localeCompare(valueB.toLowerCase())
    );
  }

  /**
   * EventListener that prevents the submission and the closing of the modal window
   * when the user type 'Enter' in a field.
   *
   * @param event The keyboard event triggering this EventListener
   */
  private formKeyPressEventListener(event: KeyboardEvent): void {
    // Enabling the 'Enter' keypress to allow line break
    if (event.key === 'Enter' && !(event.target instanceof HTMLTextAreaElement)) {
      event.preventDefault();
      if (document.activeElement instanceof HTMLElement) {
        // Force the loss of focus on the acive element
        document.activeElement.blur();
      }
    }
  }

  private getDataTypeConflictParameters(param: number): string {
    return this.inputDataNameValidator.getDataTypeConflictParameters(param);
  }

  /**
   * EventHandler for the field '#inputDefaultValue' of type number (for Integer DM)
   *
   * This EventHandler validates the input and adds the following errors to the field if they are detected:
   * - 'hasInvalidNumber'
   * - 'max'
   * - 'min'
   *
   * @param event the Event triggered by the inputDefaultValueNumber field
   */
  private inputDefaultValueNumberEventHandler(event: Event): void {
    const target: HTMLInputElement = event?.target as HTMLInputElement;
    const valueInputAsNumber: number = target?.valueAsNumber;
    const inputDefaultValueControl: AbstractControl = this.dmSettingsForm.get('inputDefaultValue');

    const errors: ValidationErrors = { hasInvalidNumber: false, max: false, min: false };
    let hasError = false;

    if (!target.validity.valid && target.validity.badInput) {
      errors.hasInvalidNumber = true;
      hasError = true;
    } else {
      if (valueInputAsNumber <= this.getIntegerMinValue()) {
        errors.min = true;
        hasError = true;
      }
      if (valueInputAsNumber >= this.getIntegerMaxValue()) {
        errors.max = true;
        hasError = true;
      }
    }
    if (!hasError) {
      inputDefaultValueControl.setErrors(null);
    } else {
      inputDefaultValueControl.setErrors(errors);
    }
    this.updateHasAnyError();
  }

  private updateHasAnyError(): void {
    // Checking if the inputDefaultValue field has any error
    const inputDefaultValueControl: AbstractControl = this.dmSettingsForm.get('inputDefaultValue');
    // Checking if the inputDataNameField as any error before closing the modal window
    // and emitting the dynamicModifications
    const inputDataNameField: AbstractControl = this.dmSettingsForm.get(DynamicFieldConfigurationWindowComponent.INPUT_DATA_NAME_FIELD_ID);

    this.hasAnyError = inputDataNameField.errors !== null || inputDefaultValueControl.errors !== null;
  }

  /**
   * This method resize the height TextArea whose Id is "elementId"
   * to match its content.
   *
   * @param elementId The Id of the TextArea to resize
   */
  private resizeTextArea(elementId: string): void {
    $(`#${elementId}`).height(0);
    $(`#${elementId}`).height($(`#${elementId}`)[0].scrollHeight);
  }

}
