<eazly-header *ngIf="myappeaz" [myappeaz]="myappeaz" [selectedStepName]="selectedStepName"
  [displayPublishButton]="canPublish"></eazly-header>

<div class="row studio-row">
  <div class="col-md-3">
    <div class="row studio-left-panel">
      <div class="button-bar">
        <eazly-undo-redo id="eazly-undo-redo" class="card-header">
          Undo Redo loading
        </eazly-undo-redo>
        <eazly-import-export id="import-export" *ngIf="this?.myappeaz?.expert" class="card-header" [myappeaz]="myappeaz"
          (exportButtonClicked)="handleExportButtonClicked()" [exportBtnActive]="exportBtnActive"></eazly-import-export>

        <eazly-language-selector *ngIf="myappeaz && myappeaz.multilingue" id="eazly-language-selector-id"
          class="card-header" [myappeaz]="myappeaz"></eazly-language-selector>

        <eazly-toggle-button id="eazly-toggle-button-id" class="card-header">
        </eazly-toggle-button>
      </div>
      <eazly-navigator *ngIf="myappeaz" id="navigator" [myappeaz]="myappeaz" (stepChange)="onStepChange($event)"
        (exportButtonClicked)="handleExportButtonFromNavigatorClicked()">
      </eazly-navigator>
    </div>
  </div>
  <div class="col-md-9" id="viewer">
    <eazly-viewer *ngIf="myappeaz" [myappeaz]="myappeaz" [stepBeingEdited]="selectedStep"
      (errorEmit)="onErrorEmit($event)">
    </eazly-viewer>
  </div>
</div>
