import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { StepDynamicModifications } from '../models/step-dynamic-Modifications';

@Injectable({
  providedIn: 'root'
})
export class ImportExportService {

  @SessionStorage('auth.Engine.token')
  public engineToken;

  protected httpClient: HttpClient;

  constructor(private readonly http: HttpClient) {
    this.httpClient = http;
  }

  exportStepDynamicModifications(url: string, stepIds: string[]): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': this.engineToken,
      })
    };
    return this.http.post(url + '/eazly-api/v1/pages/export/', stepIds, httpOptions);
  }

  importStepDynamicModifications(url: string, stepsToImport: StepDynamicModifications[]): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': this.engineToken,
      })
    };
    return this.http.post(url + '/eazly-api/v1/pages/', stepsToImport, httpOptions);
  }

}
