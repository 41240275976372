import { Operation } from '../../actions/operations/operation';

export class OperationMessage {

    private operation: Operation;
    private resultOfTheOperation: any;
    private source: OperationSource;

    constructor(src: OperationSource, operation: Operation, result: any) {
        this.operation = operation;
        this.resultOfTheOperation = result;
        this.source = src;
    }

    getOperation(): Operation {
        return this.operation;
    }

    getResultOfTheOperation(): any {
        return this.resultOfTheOperation;
    }

    getOperationSource(): OperationSource {
        return this.source;
    }

}

export enum OperationSource {
    DO = 'DO',
    UNDO = 'UNDO',
    REDO = 'REDO'
}
