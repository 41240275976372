import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from '@docaposte_releases/dashboard';
import { RatingModule } from 'ngx-bootstrap/rating';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from '../eazly.routing';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppeazListModule } from './appeaz-list/appeaz-list.module';
import { AppeazModule } from './appeaz/appeaz.module';
import { AdminGuard } from './authentication/authenticated-admin.gard';
import { AuthenticatedManagerGuard } from './authentication/authenticated-manager.guard';
import { AuthenticatedNewUserGuard } from './authentication/authenticated-new-user.guard';
import { AuthenticatedPublisherGuard } from './authentication/authenticated-publisher.guard';
import { AuthenticatedUserGuard } from './authentication/authenticated-user.guard';
import { AuthenticationModule } from './authentication/authentication.module';
import { AUTH_GUARD_DEFAULT_ROLES } from './authentication/guard/base-authentication-guard';
import { AccountModule } from './create-account/account.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditorModule } from './editor/editor.module';
import { HeaderModule } from './header/header.module';
import { AboutComponent } from './other/about/about.component';
import { FooterModule } from './other/footer/footer.module';
import { HelpComponent } from './other/help/help.component';
import { LoadingModule } from './other/loading/loading.module';
import { PublishModule } from './publish/publish.module';
import { AlertManagerModule } from './tools/alert-manager/alert-manager.module';
import { LoggingErrorHandler } from './tools/logging.error-handler';
import { AppeazService } from './tools/services/appeaz.service';
import { DynamicModificationService } from './tools/services/dynamic-modification.service';
import { FileService } from './tools/services/file.service';
import { JwtInterceptor } from './tools/services/jwt.interceptor';
import { MessagingService } from './tools/services/messaging/messaging.service';
import { PaletteService } from './tools/services/palette.service';
import { StepService } from './tools/services/steps.service';
import { UrlHelperService } from './tools/services/url-helper.service';
import { UserService } from './tools/services/user.service';
import { ViewerService } from './tools/services/viewer.service';
import { UserProfileModule } from './user-profile/user-profile.module';
import { UsersModule } from './users/users.module';

@NgModule({
  declarations: [
    AppComponent,
    HelpComponent,
    AboutComponent,
    DashboardComponent
  ],
  imports: [
    DashboardModule,
    NgxWebstorageModule.forRoot({ prefix: 'eazly', separator: '.', caseSensitive: true }),
    AccountModule,
    AlertManagerModule,
    AppeazListModule,
    AppeazModule,
    AppRoutingModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    BrowserModule,
    EditorModule,
    FooterModule,
    FormsModule,
    HeaderModule,
    HttpClientModule,
    LoadingModule,
    LoggerModule.forRoot({ serverLoggingUrl: undefined, level: environment.ngxLogLevel, serverLogLevel: NgxLoggerLevel.ERROR }),
    PublishModule,
    RatingModule.forRoot(),
    ReactiveFormsModule,
    UserProfileModule,
    UsersModule,
    DragDropModule,
  ],
  providers: [
    UrlHelperService,
    UserService,
    AppeazService,
    MessagingService,
    StepService,
    FileService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },

    ViewerService,
    PaletteService,
    DynamicModificationService,
    { provide: AUTH_GUARD_DEFAULT_ROLES, useValue: '' },
    AuthenticatedNewUserGuard,
    AuthenticatedUserGuard,
    AuthenticatedManagerGuard,
    AuthenticatedPublisherGuard,
    AdminGuard,
    { provide: ErrorHandler, useClass: LoggingErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
