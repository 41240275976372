import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Appeaz } from '../tools/models/appeaz';
import { UserService } from '../tools/services/user.service';

@Component({
  selector: 'eazly-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() myappeaz: Appeaz;
  @Input() selectedStepName: string;
  @Input() headerText: string;
  @Input() manageMyAccount: string;
  @Input() dashboard: string;
  @Input() displayPublishButton: boolean;
  @Input() canEdit: boolean;
  isUsers: boolean;
  isConfiguration: boolean;

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {
    const admin: boolean = this.userService.isAdmin();
    if (admin) {
      this.isUsers = true;
      this.isConfiguration = true;
    }
    if (this.myappeaz && this.myappeaz.role === 'Manager') {
      this.isUsers = true;
    }
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  changeLocation() { }
}
