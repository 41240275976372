import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../eazly.routing';
import { FooterModule } from '../other/footer/footer.module';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { AlertManagerModule } from '../tools/alert-manager/alert-manager.module';
import { ModalWindowModule } from '../tools/modal-window/modal-window.module';
import { AuthenticationFormComponent } from './authentication-form/authentication-form.component';
import { AuthenticationComponent } from './authentication.component';
import { AuthHeaderComponent } from './header/auth-header.component';
import { ResetPasswordWindowComponent } from './modal-windows/reset-password-window/reset-password-window.component';
@NgModule({
    declarations: [
        AuthenticationComponent,
        AuthenticationFormComponent,
        AuthHeaderComponent,
        ResetPasswordComponent,
        ResetPasswordWindowComponent],
    imports: [
        CommonModule,
        AlertManagerModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FooterModule,
        ModalWindowModule

    ],
    bootstrap: [AuthenticationComponent]
})
export class AuthenticationModule { }
