export enum BinaryConditionType {
    MATCHES,
    NOT_CONTAINS,
    IS_EQUAL,
    IS_NOT_EQUAL,
    IS_LESS_THAN,
    IS_LESS_OR_EQUAL_THAN,
    IS_GREATER_THAN,
    IS_GREATER_OR_EQUAL_THAN,
    STARTS_WITH,
    ENDS_WITH,
    LESS_THAN_DATE,
    GREATER_THAN_DATE
}
