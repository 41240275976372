

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { users } from '../mocks/mocks';
import { Appeaz } from '../models/appeaz';
import { User } from '../models/user';
import { UrlHelperService } from './url-helper.service';

// TODO Check the URL used to make them match with the YAML specification of the back-end
@Injectable()
export class UserService {

  @SessionStorage('auth.user')
  public currentUser;

  @SessionStorage('auth.token')
  public token;

  users: User[];

  constructor(protected http: HttpClient, protected urlHelperService: UrlHelperService) { }

  getUsers(): Observable<User[]> {
    return of(users);
  }

  login(login: string, password: string) {
    return this.http.post<any>(this.urlHelperService.getBaseURLWithApiV1Url() + '/users/login', { username: login, password: password }).pipe(
      map((user: any) => {
        if (user && user.idUser && user.token) {
          // login successful, storing the jwt token given by the server in the local storage
          this.token = user.token;
          return user.idUser;
        } else {
          return null;
        }
      }), catchError(res => observableThrowError(res)));
  }

  getUser(id: number): Observable<User> {
    return this.http.get<any>(this.urlHelperService.getBaseURLWithApiV1Url() + '/users/' + id).pipe(
      map(user => {
        this.currentUser = user;
        return user;
      }), catchError(res => observableThrowError(res)));
  }

  getUserByToken(token: number): Observable<User> {
    return this.http.get<any>(this.urlHelperService.getBaseURLWithApiV1Url() + '/users/token/' + token).pipe(
      map(user => user), catchError(res => observableThrowError(res)));
  }

  isActiveUser(token: number): Observable<boolean> {
    return this.http.get<any>(this.urlHelperService.getBaseURLWithApiV1Url() + '/users/token/' + token).pipe(
      map((user: any) => {
        user.eazly_role = 'Designer';
        this.currentUser = user;
        return user.actif;
      }), catchError(res => observableThrowError(res)));
  }

  inviteUser(email: string, appeazId: number): Observable<User> {
    const jsonUserObject = '{"email":"' + email + '", "appeazId": "' + appeazId + '"}';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(this.urlHelperService.getBaseURLWithApiV1Url() + '/users/invitation', jsonUserObject, httpOptions).pipe(
      map(user => user), catchError(res => observableThrowError(res)));
  }

  sendEmailToResetPassword(email: string): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.urlHelperService.getBaseURLWithApiV1Url() + '/users/sendrecoverymail', email, httpOptions).pipe(
      map(user => user), catchError(res => observableThrowError(res)));
  }

  getUserByEmail(email: string, appeazId: number): Observable<User> {
    const jsonUserObject = '{"email":"' + email + '", "appeazId": "' + appeazId + '"}';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(this.urlHelperService.getBaseURLWithApiV1Url() + '/users/email', jsonUserObject, httpOptions).pipe(
      map(user => user), catchError(res => observableThrowError(res)));
  }

  updateUser(user: User): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const jsonUserObject = '{"first_name":"' + user.first_name
      + '","last_name":"' + user.last_name
      + '","email":"' + user.email + '"}';
    return this.http.put<any>(this.urlHelperService.getBaseURLWithApiV1Url() + '/users/' + user.id, jsonUserObject, httpOptions).pipe(
      map(data => data), catchError(res => observableThrowError(res)));
  }

  updateUserPassword(newPassword: string, userID: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put<any>(this.urlHelperService.getBaseURLWithApiV1Url() + `/users/updatepassword/${userID}`, newPassword, httpOptions).pipe(
      map(data => data), catchError(res => observableThrowError(res)));
  }

  confirmUser(user: User): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const jsonUserObject = '{"first_name":"' + user.first_name
      + '","last_name":"' + user.last_name
      + '","email":"' + user.email
      + '","password": "' + user.password + '"}';
    return this.http.put<any>(this.urlHelperService.getBaseURLWithApiV1Url() + '/users/token/' + user.token, jsonUserObject, httpOptions).pipe(
      map(data => data), catchError(res => observableThrowError(res)));
  }


  getCurrentUser(): any {
    return this.currentUser;
  }

  isAdmin(): boolean {
    if (this.currentUser.eazly_role === 'EAZLY_PLATFORM_ADMIN') {
      return true;
    }
    return false;
  }

  isCurrentUserAPublisher(appeaz: Appeaz): boolean {
    if (appeaz.role === 'Manager' || appeaz.role === 'Publisher') {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.currentUser = null;
    this.token = null;
  }

  updatePassword(newPassword: string): Observable<any> {
    return this.http.put<any>(`${this.urlHelperService.getBaseURLWithApiV1Url()}/users/${this.currentUser.id}`, { password: newPassword })
      .pipe(map(data => data), catchError(res => observableThrowError(res)));
  }

  deleteUser(id: number): Observable<HttpResponse<string>> {
    return this.http.delete(`${this.urlHelperService.getBaseURLWithApiV1Url()}/users/${id}`)
      .pipe(map((response: HttpResponse<string>) => response), catchError(res => observableThrowError(res)));
  }

}
