<ng-container *ngTemplateOutlet="alertTemplate"></ng-container>

<div *ngIf="!isEditingUserInfo else editUserInfo">
  <div class="row">
    <label class="control-label col-4 text-right">
      <span i18n="@@userInfoFirstName">First name:</span>
    </label>
    <label class="col-8 text-left">{{user.first_name}}</label>
  </div>
  <div class="row">
    <label class="control-label col-4 text-right">
      <span i18n="@@userInfolastName">Last name:</span>
    </label>
    <label class="col-8 text-left">{{user.last_name}}</label>
  </div>
  <div class="row">
    <label class="control-label col-4 text-right">
      <span i18n="@@userInfoEmail">Email:</span>
    </label>
    <label class="col-8 text-left">{{user.email}}</label>
  </div>
  <div class="row">
    <div class="col-12">
      <button (click)="displayEditUsertInfo()" class="btn ">
        <i class="fas fa-edit"></i>
        <span i18n="@@userInfoEditYourPersonalInformationButton">
          Edit your personnal information</span>
      </button>
    </div>
  </div>
</div>
<ng-template #editUserInfo>
  <form (ngSubmit)="userInfo.form.valid && onSubmit()" #userInfo="ngForm">
    <div class="form-group row">
      <label class="control-label col-4 text-right">
        <span i18n="@@userInfoFirstName">First name:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <div class="col-8 text-left">
        <input type="text" id="firstName" class="form-control" name="firstName" [(ngModel)]="user.first_name" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="control-label col-4 text-right">
        <span i18n="@@userInfoLastName">Last name:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <div class="col-8 text-left">
        <input type="text" id="lastName" class="form-control" name="lastName" [(ngModel)]="user.last_name" required>
      </div>
    </div>
    <!-- EAZ-604 deactivation of the edition of the email
     this is temporary and this code must be put back into place after reactivating 
     the edition of the email-->
    <!--    -------------------------------------------------------------------------------------       -->
    <!-- <div class="form-group row">
      <label class="control-label col-4 text-right">
        <span i18n="@@userInfoEmail">Email:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <div class="col-8 text-left">
        <input type="email" id="email" class="form-control" name="email" [(ngModel)]="user.email" required>
      </div>
    </div>
    <div class="form-group row">
      <label class="control-label col-4 text-right">
        <span i18n="@@userInfoConfirmEmail">Confirm your email:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <div class="col-8 text-left">
        <input type="email" id="emailConfirm" class="form-control" name="emailConfirm" [(ngModel)]="emailConfirmation"
          required>
      </div>
    </div> -->
    <div class="form-group row">
      <div class="col-8">
        <button id="submit" type="submit" class="btn ">
          <i class="fas fa-check"></i>
          <span i18n="@@userInfoSubmitButton">Submit</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #alertTemplate>
  <alert *ngFor="let alert of alerts" [type]="alert.type" [dismissible]="true" dismissOnTimeout="10000">
    <span *ngIf="alert.serverResponse === 'success'">
      <span id="alertSuccess" i18n='@@UserInfoUpdateSuccess'>
        <strong>User information update successful !</strong> Your informations have been successfully updated.
      </span>
    </span>
    <span *ngIf="alert.serverResponse === 'failure'">
      <span id="alertFail" i18n='@@UserInfoUpdateFailure'>
        <strong>User information update failed !</strong> Your informations have not been modified.
      </span>
    </span>
    <span *ngIf="alert.serverResponse === 'unknown failure'">
      <span id="alertFailUnknown" i18n='@@UserInfoUpdateUnknownFailure'>
        <strong>User information update failed !</strong> Your informations have not been modified due to an unknown
        error.
      </span>
    </span>
    <span *ngIf="alert.serverResponse === 'emailsNotMatching'">
      <span id="emailsNotMatch" i18n='@@AccountInfoUpdateEmailNotMachFailure'>
        <strong>Email and email confirmation don't match !</strong>
      </span>
    </span>
  </alert>
</ng-template>
