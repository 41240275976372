<eazly-modal-window>
    <div modalWindowHeader i18n="@@VisibilityWindowTitle">
        Visibility of "{{fieldName}}"
    </div>
    <div modalWindowBody>
        <form id="visibilityForm" (ngSubmit)="sendVisibilityCondition()" #f="ngForm" novalidate>
            <div class="container">
                <div *ngIf="emptyDynamics" class="row alert alert-info info-row" role="alert"
                    i18n="@@InformationMessageVisbilityWindow">
                    This field will always be visible. To customize its visibility, you may add a field that can change
                    the visibility of other fields (e.g. checkbox or a choice, except text area).
                </div>
                <div *ngIf="emptyArgument" class="row alert alert-danger" role="alert"
                    i18n="@@ErrorMessageForMissingArguments">
                    Please check missing arguments.
                </div>
                <div *ngIf="conditionListIsEmpty" id="NoConditionAlert" class="row alert alert-danger" role="alert"
                    i18n="@@ErrorMessageEmptyConditionsList">
                    Please add at least one condition.
                </div>
                <div *ngIf="cyclicDependency.length>0" class="row alert alert-danger" role="alert">
                    <div class="container">
                        <div class="row">
                            <p i18n="@@CycleDetectedErrorMessage">
                                The field <strong>{{cyclicDependency[1]}}</strong> cannot be used to
                                define the visibility of <strong>{{cyclicDependency[0]}}</strong>
                            </p>
                        </div>
                        <div class="row" *ngFor="let cyclic of cyclicDependency; let in = index">
                            <div *ngIf="in !== (cyclicDependency.length-1) && in !==0">
                                <p class="p-style-error-message" i18n="@@CycleMembresErrorMessage">
                                    -<strong> {{cyclicDependency[in+1]}}</strong> is used to define the visibility of
                                    <strong>{{cyclic}}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-modal-window">
                        <p class="p-style-modal-window" i18n="@@VisibilityWindowFieldStatus">
                            The field "{{fieldName}}" is:
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1 col-sm-modal-window">
                        <input type="radio" id="visibilityButtonAlways" [(ngModel)]="visibilityButtonValue"
                            name="visibilityButton" [value]="visibilityMode.ALWAYS_VISIBLE" [disabled]="emptyDynamics">
                    </div>
                    <div class="col-sm-6 col-visibility col-sm-modal-window" i18n="@@AlwaysDivTitle">
                        Always visible
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1 col-sm-modal-window">
                        <input type="radio" id="visibilityButtonAll" [(ngModel)]="visibilityButtonValue"
                            name="visibilityButton" [value]="visibilityMode.VISIBLE_IF_ALL_CONDITIONS"
                            [disabled]="!emptyDynamics">
                    </div>
                    <div class="col-sm-6 col-visibility col-sm-modal-window" i18n="@@AllConditionsDivTitle">
                        Visible if all of the following conditions are true
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1 col-sm-modal-window">
                        <input type="radio" id="visibilityButtonATLeastOne" [(ngModel)]="visibilityButtonValue"
                            name="visibilityButton" [value]="visibilityMode.VISIBLE_IF_AT_LEAST_ONE_CONDITION"
                            [disabled]="emptyDynamics">
                    </div>
                    <div class="col-sm-6 col-visibility col-sm-modal-window" i18n="@@AtLeastOneDivTitle">
                        Visible if at least one of the following conditions is true
                    </div>
                </div>
                <div class="row" *ngIf="visibilityButtonValue!==visibilityMode.ALWAYS_VISIBLE">
                    <div class="col-sm-7 col-sm-modal-window">
                        <p class="p-style-modal-window">Conditions:</p>
                    </div>
                </div>
                <fieldset class="fieldset-modal-window" *ngIf="visibilityButtonValue!==visibilityMode.ALWAYS_VISIBLE">
                    <div class="container">
                        <div class="row eazly-row-list-item" *ngFor="let condition of conditions; let in = index">
                            <div class="col-sm-4">
                                <select class="select-visibility-modal  form-control"
                                    [(ngModel)]="condition.targetDynamicMofication"
                                    [name]="'targetFieldSelect' + condition.id "
                                    [attr.id]="'DynamicModification' + condition.id"
                                    (change)="refreshOperatorsList(condition)">
                                    <option *ngFor="let dynamic of condition.listOfTargetDynamicMofications"
                                        [ngValue]="dynamic">
                                        {{dynamic.modificationTarget.dataDescriptor.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-4">
                                <select class="select-visibility-modal  form-control" [(ngModel)]="condition.operator"
                                    name="visibilityBooleanSelect" *ngIf="condition.listOfOperators.length!==0"
                                    [name]="'SecondOperand' + condition.id " [attr.id]="'SecondOperand' + condition.id "
                                    (change)="refreshOperandsType(condition)">
                                    <option *ngFor="let secondOperand of condition.listOfOperators"
                                        [value]="secondOperand">
                                        {{ secondOperand | operandsTranslation }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-4">
                                <input type="text" [hidden]="condition.argumentType!=='IsInputText'"
                                    class="input-argument-visbility-Modal form-control" [(ngModel)]="condition.argument"
                                    [attr.id]="'ArgumentInputTextField' + condition.id"
                                    [name]="'ArgumentInputFieldName' +condition.id">
                                <!--
                                    EAZ-668: 
                                    Binding "condition.argument" one way only for numbers. 
                                    The "document->component" binding is manually done
                                    with the document injection in the component VisibilityWindow.
                                 -->
                                <input type="number" [hidden]="condition.argumentType!=='IsInputNumber'"
                                    class="input-argument-visbility-Modal form-control" [ngModel]="condition.argument"
                                    [attr.id]="'ArgumentInputNumberField' + condition.id"
                                    [name]="'ArgumentInputFieldName' + condition.id" #number>

                                <input type="date" [hidden]="condition.argumentType!=='IsInputDate'"
                                    class="input-argument-visbility-Modal form-control" data-date-inline-picker="false"
                                    data-date-open-on-focus="true" [(ngModel)]="condition.argument"
                                    [attr.id]="'ArgumentInputDateField' + condition.id"
                                    [name]="'ArgumentInputFieldName' + condition.id">
                                <select class="select-visibility-modal form-control" [(ngModel)]="condition.argument"
                                    [hidden]="condition.argumentType!=='IsInputEnum'"
                                    [name]="'ArgumentInputFieldName' + condition.id"
                                    [attr.id]="'ArgumentInputEnumField' + condition.id">
                                    <option
                                        *ngFor="let value of condition.targetDynamicMofication.modificationTarget.values"
                                        [value]="value.id">
                                        {{value | listValueLabelTranslationPipe}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-1" (click)="deleteCondition(in)"
                                [attr.id]="'DeleteButton' + condition.id">
                                <div class="eazlyIconBtn">
                                    <i class="fas fa-trash-alt"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row eazly-row-list-item">
                            <div>
                                <button class="col-sm-12 btn btn-lg" id="AddCondition" (click)="appendCondition($event)"
                                    i18n="@@AddConditionButtonTitle">
                                    <i class="fas fa-plus fa-plus-style"></i>
                                    Click here to add a new condition
                                </button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
    <div modalWindowFooter>
        <button (click)="hide()" id="CancelModalWindow" type="button" class="btn eazlybtn btn-lg" i18n="@@Cancel">
            <i class="fas fa-arrow-left"></i>
            Cancel
        </button>
        <button id="SubmitModalWindow" type="submit" form="visibilityForm" class="btn eazlybtn btn-lg"
            i18n="@@SubmitModalWindow">
            <i class="fas fa-check"></i>
            Submit
        </button>
    </div>
</eazly-modal-window>
