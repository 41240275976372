import { Observable } from 'rxjs';
import { ViewerService } from '../../../services/viewer.service';
import { Operation, OperationType } from './operation';

export class DeleteDmOperation implements Operation {

    name: string;
    operationType: OperationType = OperationType.DELETE_DM;
    stepId: string;
    private viewerService: ViewerService;
    private appeazUrl: string;
    private dynamicModificationId: number;
    private idAppeaz: number;
    private appeazRole: string;

    constructor(viewerService: ViewerService, name: string, idAppeaz: number, appeazUrl: string, appeazRole: string,
        stepId: string, dynamicModificationId: number) {
        this.name = name;
        this.viewerService = viewerService;
        this.appeazUrl = appeazUrl;
        this.stepId = stepId;
        this.dynamicModificationId = dynamicModificationId;
        this.idAppeaz = idAppeaz;
        this.appeazRole = appeazRole;
    }

    getDynamicModificationId(): number {
        return this.dynamicModificationId;
    }

    setDynamicModificationId(newId: number) {
        this.dynamicModificationId = newId;
    }

    getName(): string {
        return this.name;
    }

    execute(): Observable<number> {
        return this.viewerService.deleteDynamicModificationPreviewById(this.appeazUrl, this.stepId, this.dynamicModificationId, this.idAppeaz, this.appeazRole);
    }

    getOperationType(): OperationType {
        return this.operationType;
    }

    getStepId(): string {
        return this.stepId;
    }
}
