
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { SessionStorage } from 'ngx-webstorage';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Appeaz } from '../models/appeaz';
import { DynamicVersion } from '../models/dynamic-version';
import { User } from '../models/user';
import { AlertType, EazlyMessage, MessageCause } from './messaging/eazly-message';
import { UrlHelperService } from './url-helper.service';
import { UserService } from './user.service';

const ContentTypeApplicationJSON = 'application/json';
@Injectable()
export class AppeazService {

  @SessionStorage('auth.token')
  public token;

  @SessionStorage('auth.user')
  public currentUser;

  @SessionStorage('auth.Engine.token')
  public engineToken;

  constructor(protected http: HttpClient,
    protected userService: UserService,
    protected urlHelperService: UrlHelperService,
    protected router: Router,
    protected logger: NGXLogger) { }

  getCurrentUser(): any {
    return this.currentUser;
  }

  getMyappeazs(): Observable<any> {
    return this.http.get(`${this.urlHelperService.getBaseURLWithApiV1Url()}/users/${this.userService.getCurrentUser().id}/appeazs`).pipe(
      map(
        data => data,
        catchError((err: HttpErrorResponse) => throwError(err))
      ));
  }

  getAllappeazs(): Observable<any> {
    return this.http.get(`${this.urlHelperService.getBaseURLWithApiV1Url()}/appeazs`).pipe(
      map(
        data => data,
        catchError((err: HttpErrorResponse) => throwError(err))
      ));
  }

  isUserExpert(appeaz: number, user: User) {
    this.getAppeazDescriptor(appeaz, user).subscribe(
      data => data.expert,
      error => false);
  }

  getAppeazById(id: number): Observable<any> {
    return this.http.get(`${this.urlHelperService.getBaseURLWithApiV1Url()}/appeazs/${id}`).pipe(
      map(
        data => data,
        catchError((err: HttpErrorResponse) => throwError(err))
      ));
  }

  getUsersbyAppeaz(id: number): Observable<any> {
    return this.http.get(`${this.urlHelperService.getBaseURLWithApiV1Url()}/appeazs/${id}/users`).pipe(
      map(
        data => data,
        catchError((err: HttpErrorResponse) => throwError(err))
      ));
  }

  getVersionByAppeaz(appeaz: Appeaz): Observable<any> {
    const constructedURL = appeaz.url + '/eazly-api';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': ContentTypeApplicationJSON,
        'x-access-token': this.engineToken
      })
    };
    return this.http.get(constructedURL, httpOptions).pipe(map((response) => response,
      catchError((err: HttpErrorResponse) => throwError(err))
    ));
  }

  createAppeaz(appeaz: Appeaz): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': ContentTypeApplicationJSON
      })
    };
    const jsonAppeazObject = `{"name":"${appeaz.name}", 
    "url": "${appeaz.url}", 
    "apiKey": "${appeaz.apiKey}", 
    "multilingue": "${appeaz.multilingue}","stylesheet": "${appeaz.stylesheet}",
    "userQuota": ${appeaz.userQuota}}`;
    return this.http.post(`${this.urlHelperService.getBaseURLWithApiV1Url()}/appeazs`, jsonAppeazObject, httpOptions).pipe(
      map(
        (data) => data,
        catchError((err: HttpErrorResponse) => throwError(err))
      ));
  }

  deleteAppeaz(id: number): Observable<HttpResponse<string>> {
    return this.http.delete(`${this.urlHelperService.getBaseURLWithApiV1Url()}/appeazs/${id}`).pipe(
      map((response: HttpResponse<string>) => response,
        catchError((err: HttpErrorResponse) => throwError(err))
      ));
  }

  updateAppeaz(appeaz: Appeaz): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': ContentTypeApplicationJSON
      })
    };
    const jsonAppeazObject = `{"name":"${appeaz.name}", 
    "url": "${appeaz.url}", 
    "apiKey": "${appeaz.apiKey}", 
    "multilingue": "${appeaz.multilingue}", "stylesheet": "${appeaz.stylesheet}",
    "userQuota": ${appeaz.userQuota}}`;
    return this.http.put(`${this.urlHelperService.getBaseURLWithApiV1Url()}/appeazs/${appeaz.id}`, jsonAppeazObject, httpOptions).pipe(
      map((response: Response) => response,
        catchError((err: HttpErrorResponse) => throwError(err))
      ));
  }

  updateAppeazRole(appeaz: number, user: User): Observable<any> {
    return this.http.put(`${this.urlHelperService.getBaseURLWithApiV1Url()}/appeazs/${appeaz}/users/${user.id}`,
      {
        'idUser': user.id,
        'appeazRole': user.eazly_role,
        'expert': user.expert
      }).pipe(
        map((response: Response) => response,
          catchError((err: HttpErrorResponse) => this.handleError(err))
        ));
  }

  getAppeazDescriptor(appeaz: number, user: User): Observable<any> {
    return this.http.get(`${this.urlHelperService.getBaseURLWithApiV1Url()}/appeazs/${appeaz}/descriptor/users/${user.id}`).pipe(
      map((response: Response) => response,
        catchError((err: HttpErrorResponse) => this.handleError(err))
      ));
  }

  deleteUser(appeaz: number, idUser: number): Observable<any> {
    return this.http.delete(`${this.urlHelperService.getBaseURLWithApiV1Url()}/appeazs/${appeaz}/users/${idUser}`).pipe(
      map((response: HttpResponse<string>) => response,
        catchError((err: HttpErrorResponse) => throwError(err))
      ));
  }

  addUser(appeaz: number, userid: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': ContentTypeApplicationJSON
      })
    };
    return this.http.post(this.urlHelperService.getBaseURLWithApiV1Url()
      + '/appeazs/' + appeaz + '/users/', { idUser: userid, appeazRole: 'Designer' }, httpOptions).pipe(
        map((response: HttpResponse<string>) => response,
          catchError((err) => throwError(err.json()))
        ));
  }

  getDynamicVersion(source: string, version: string): Observable<DynamicVersion> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': ContentTypeApplicationJSON,
        'x-access-token': this.engineToken
      })
    };
    const constructedURL = source + '/eazly-api/v1/dynamic-modifications/versions/' + version;
    return this.http.get(constructedURL, httpOptions).pipe(map((response: DynamicVersion) => response,
      catchError((err: HttpErrorResponse) => throwError(err))
    ));
  }

  postDynamicActiveVersion(source: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': ContentTypeApplicationJSON,
        'x-access-token': this.engineToken
      })
    };
    const constructedURL = source + '/eazly-api/v1/dynamic-modifications/versions/active';
    const jsonAppeazObject = '{"publisher":"' + this.currentUser.first_name + this.currentUser.last_name + '"}';

    return this.http.post(constructedURL, jsonAppeazObject, httpOptions).pipe(map(response => response,
      catchError((err: HttpErrorResponse) => throwError(err))
    ));
  }

  getAppeazJWTWithoutErrorHandling(id: number, appeazRole: string): Observable<string> {
    const httpOptions = {
      responseType: 'text' as 'text'
    };
    return this.http.get(this.urlHelperService.getBaseURLWithApiV1Url() + '/appeazs/' + id + '/jwt?appeazRole=' + appeazRole, httpOptions).pipe(
      map(response => {
        this.engineToken = response;
        return response;
      })
    );
  }

  getAppeazJWT(id: number, appeazRole: string): Observable<string> {
    return this.getAppeazJWTWithoutErrorHandling(id, appeazRole).pipe(
      catchError(error => this.handleError(error))
    );
  }

  // This method should return a Message that will be sent to the MessagingService by the invoker of the method.
  // The invoker of the method should notify the messaging service in the "OnDestroy" method to avoid to consume the message by
  // his AlertManager component before redirecting the user to the page that should consume it.
  private handleError(error: Response | any) {
    if (400 === error.status) {
      this.router.navigate(['/appeazs']);
      return this.generateSessionExpiredMessage(error, MessageCause.InvalidRoleProvided);
    }
    // this.logger.debug('APPEAZ SERVICE: Handling error', error);
    if (401 === error.status) {
      this.logger.debug('Generating error and routing:', error);
      this.userService.logout();
      this.router.navigate(['/login'], { queryParams: { expired: 'true' } });
      return this.generateSessionExpiredMessage(error, MessageCause.SessionExpired);
    }
    if (403 === error.status) {
      if (!this.token) {
        this.userService.logout();
        this.router.navigate(['/login'], { queryParams: { expired: 'true' } });
        return this.generateSessionExpiredMessage(error, MessageCause.SessionExpired);
      } else {
        this.router.navigate(['/appeazs']);
        return this.generateSessionExpiredMessage(error, MessageCause.InsufficientRole);
      }
    }
    if (error.status === 404) {
      this.router.navigate(['/appeazs']);
      return this.generateSessionExpiredMessage(error, MessageCause.AppeazNotFound);
    }
    if (500 === error.status) {
      this.logger.debug('APPEAZ SERVICE: Token not valid !');
      this.router.navigate(['/appeazs']);
      return this.generateSessionExpiredMessage(error, MessageCause.ApiKeyNotRecognized);
    }
    if (504 === error.status) {
      this.router.navigate(['/appeazs']);
      return this.generateSessionExpiredMessage(error, MessageCause.AppeazAccessTimeOut);
    }
    return throwError(error);
  }

  private generateSessionExpiredMessage(error: Response, failureCause: MessageCause): Observable<any> {
    // Throwing the errorReport so that it can be sent by the component that invoked the method
    const errorReport = new EazlyMessage(AlertType.danger, 'AppeazService', failureCause, error.status + ' ' + error.statusText);
    return throwError(errorReport);
  }
}
