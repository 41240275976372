import { Observable } from 'rxjs';
import { ImportExportService } from '../../../../tools/services/import-export.service';
import { StepDynamicModifications } from '../../../models/step-dynamic-Modifications';
import { Operation, OperationType } from './operation';

export class ImportOperation implements Operation {

    name: string;
    operationType: OperationType = OperationType.IMPORT;
    stepId = '';

    public readonly stepsToImport: StepDynamicModifications[];
    private readonly importExportService: ImportExportService;
    private readonly appeazUrl: string;

    constructor(importExportService: ImportExportService, name: string,
        appeazUrl: string, stepsToImport: StepDynamicModifications[]) {
        this.appeazUrl = appeazUrl;
        this.importExportService = importExportService;
        this.name = name;
        this.stepsToImport = stepsToImport;
    }

    execute(): Observable<StepDynamicModifications[]> {
        return this.importExportService.importStepDynamicModifications(this.appeazUrl, this.stepsToImport);
    }

    getName(): string {
        return this.name;
    }

    getOperationType(): OperationType {
        return this.operationType;
    }

    getStepId(): string {
        return this.stepId;
    }

    getStepsToImport(): StepDynamicModifications[] {
        return this.stepsToImport;
    }

}
