import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalWindowComponent } from './modal-window.component';

@NgModule({
  declarations: [ModalWindowComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ModalWindowComponent,
  ]
})
export class ModalWindowModule { }
