import { Component, Input, OnInit } from '@angular/core';
import { Appeaz } from '../../tools/models/appeaz';
import { User } from '../../tools/models/user';
import { UserService } from '../../tools/services/user.service';

@Component({
  selector: 'eazly-appeaz-list-item',
  templateUrl: './appeaz-list-item.component.html',
  styleUrls: ['./appeaz-list-item.component.scss'],
  providers: []
})
export class AppeazListItemComponent implements OnInit {
  @Input()
  appeaz: Appeaz;

  user: User;
  isConfiguration: boolean;
  isUsers: boolean;
  isPublish: boolean;
  isEdit: boolean;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.user = this.userService.getCurrentUser();
    this.isConfiguration = false;
    this.isUsers = false;
    this.isPublish = false;
    this.isEdit = true;
    if (this.user.eazly_role === 'EAZLY_PLATFORM_ADMIN') {
      this.isConfiguration = true;
      this.isUsers = true;
      this.isEdit = false;
      this.appeaz.role = 'Manager';
    } else if (this.appeaz.role === 'Manager') {
      this.isUsers = true;
      this.isPublish = true;
    } else if (this.appeaz.role === 'Publisher') {
      this.isPublish = true;
    }
  }
}
