import { Observable } from 'rxjs';
import { DynamicModification } from '../../models/dynamic-modification';
import { ViewerService } from '../../services/viewer.service';
import { Action } from './action';
import { AddDmOperation } from './operations/add-dm-operation';
import { DeleteDmOperation } from './operations/delete-dm-operation';
import { Operation } from './operations/operation';

export class AddDMAction extends Action {

    name: string;
    viewerService: ViewerService;

    protected operation: Operation;
    protected reverseOperation: Operation;

    private appeazUrl: string;
    private stepId: string;
    private idAppeaz: number;
    private appeazRole: string;

    private shadowPlaceholder: HTMLDivElement;

    constructor(viewerService: ViewerService, idAppeaz: number, appeazUrl: string, appeazRole: string, stepId: string,
        dynamicModification: DynamicModification, rank: number, shadowPlaceholder: HTMLDivElement) {
        super();
        this.viewerService = viewerService;
        this.name = dynamicModification.modificationTarget.label;
        this.appeazUrl = appeazUrl;
        this.stepId = stepId;
        this.idAppeaz = idAppeaz;
        this.appeazRole = appeazRole;
        this.shadowPlaceholder = shadowPlaceholder;
        this.operation = new AddDmOperation(this.viewerService, this.name, idAppeaz, appeazUrl, appeazRole, stepId,
            dynamicModification, rank, shadowPlaceholder);
    }

    buildReverseOperation(dynamicModificationId: any): void {
        const addDmOperation = this.operation as AddDmOperation;
        addDmOperation.getDynamicModification().id = dynamicModificationId;

        this.reverseOperation = new DeleteDmOperation(this.viewerService, this.name, this.idAppeaz, this.appeazUrl
            , this.appeazRole, this.stepId, dynamicModificationId);
    }

    do(): Observable<any> {
        return super.do();
    }

    undo(): Observable<any> {
        return super.undo();
    }

}
