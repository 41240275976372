
import { DataType } from '../../../tools/models/data-type';
import { DynamicModification } from '../../../tools/models/dynamic-modification';
import { Condition } from '../../../tools/models/visibility-criteria/condition';
import { BinaryVisibilityCondition } from '../../../tools/models/visibility-criteria/conditions/binary-visibility-condition';
import { BinaryConditionType } from '../../../tools/models/visibility-criteria/conditions/binary-visibility-condition-type';
import { UnaryVisibilityCondition } from '../../../tools/models/visibility-criteria/conditions/unary-visibility-condition';
import { UnaryConditionType } from '../../../tools/models/visibility-criteria/conditions/unary-visibility-condition-type';
import { VisibilityCondition } from '../../../tools/models/visibility-criteria/conditions/visibility-condition';
import { VisibilityConditionManager } from '../../../tools/models/visibility-criteria/conditions/visibility-condition-manager';

// this class is used to fill the model of the condition by the data
export class VisibilityWindowManager {
    constructor() { }

    // this method is used to fill operators of second combo box by data type
    insertSupportedOperatorsByDataTypeIntoCurrentConditionModel(elementType: string, condition: Condition): void {
        const visibilityConditionManager: VisibilityConditionManager = new VisibilityConditionManager();
        let visibilityConditionsList: VisibilityCondition[];
        condition.listOfOperators = [];

        switch (elementType) {
            case 'RADIO_BUTTON':
            case 'CHECK_BOX':
                condition.dataType = DataType.BOOLEAN;
                visibilityConditionsList = visibilityConditionManager.getSupportedVisibilityConditions(DataType.BOOLEAN);
                break;
            case 'TEXT_FIELD':
            case 'TEXT_AREA':
                condition.dataType = DataType.STRING;
                visibilityConditionsList = visibilityConditionManager.getSupportedVisibilityConditions(DataType.STRING);
                break;
            case 'INTEGER':
                condition.dataType = DataType.INTEGER;
                visibilityConditionsList = visibilityConditionManager.getSupportedVisibilityConditions(DataType.INTEGER);
                break;
            case 'DATE':
                condition.dataType = DataType.DATE;
                visibilityConditionsList = visibilityConditionManager.getSupportedVisibilityConditions(DataType.DATE);
                break;
            case 'RADIO_LIST_HORIZONTAL':
            case 'RADIO_LIST_VERTICAL':
            case 'COMBO_BOX':
                condition.dataType = DataType.ENUM;
                visibilityConditionsList = visibilityConditionManager.getSupportedVisibilityConditions(DataType.ENUM);
                break;
            default:
                visibilityConditionsList = [];
                break;
        }
        visibilityConditionsList.forEach(element => {
            if (element instanceof UnaryVisibilityCondition) {
                condition.listOfOperators.push(UnaryConditionType[element.getConditionType()]);
            } else if (element instanceof BinaryVisibilityCondition) {
                condition.listOfOperators.push(BinaryConditionType[element.getConditionType()]);
            }
        });
        if (condition.operator === undefined) {
            condition.operator = condition.listOfOperators.values().next().value;
        }
        this.insertSupportedArgumentTypeIntoCurrentConditionModel(condition);
    }

    // this method fill the argument type of condition model to display the adequate input (text, date, integer, combo box)
    insertSupportedArgumentTypeIntoCurrentConditionModel(condition: Condition): void {
        if (condition.dataType === DataType.STRING) {
            this.setSupportedArgumentTypeForTextType(condition);
        } else if (condition.dataType === DataType.DATE) {
            this.setSupportedArgumentTypeForDateType(condition);
        } else if (condition.dataType === DataType.INTEGER) {
            this.setSupportedArgumentTypeIntegerType(condition);
        } else if (condition.dataType === DataType.ENUM) {
            this.setSupportedArgumentTypeForEnumType(condition);
        } else {
            condition.argumentType = '';
        }
    }

    getCyclicDependency(currentDynamicModif: DynamicModification, conditions: Condition[], dynamics: DynamicModification[]): string[] {
        let dependencies: string[] = [];
        let cycle: string[] = [];
        dependencies = this.getAllDependencies(currentDynamicModif, conditions, dynamics);
        cycle = this.detectCyclicDependencyFromAllDependencies(dependencies);
        return cycle;
    }

    private setSupportedArgumentTypeForTextType(condition: Condition): void {
        if (condition.operator === BinaryConditionType[BinaryConditionType.MATCHES] ||
            condition.operator === BinaryConditionType[BinaryConditionType.NOT_CONTAINS] ||
            condition.operator === BinaryConditionType[BinaryConditionType.IS_EQUAL] ||
            condition.operator === BinaryConditionType[BinaryConditionType.IS_NOT_EQUAL] ||
            condition.operator === BinaryConditionType[BinaryConditionType.STARTS_WITH] ||
            condition.operator === BinaryConditionType[BinaryConditionType.ENDS_WITH]) {
            condition.argumentType = 'IsInputText';
        } else {
            condition.argumentType = '';
        }
    }

    private setSupportedArgumentTypeForDateType(condition: Condition): void {
        if (condition.operator === BinaryConditionType[BinaryConditionType.LESS_THAN_DATE] ||
            condition.operator === BinaryConditionType[BinaryConditionType.GREATER_THAN_DATE]) {
            condition.argumentType = 'IsInputDate';
        } else {
            condition.argumentType = '';
        }
    }

    private setSupportedArgumentTypeIntegerType(condition: Condition): void {
        if (condition.operator === BinaryConditionType[BinaryConditionType.IS_LESS_THAN] ||
            condition.operator === BinaryConditionType[BinaryConditionType.IS_LESS_OR_EQUAL_THAN] ||
            condition.operator === BinaryConditionType[BinaryConditionType.IS_GREATER_OR_EQUAL_THAN] ||
            condition.operator === BinaryConditionType[BinaryConditionType.IS_GREATER_THAN] ||
            condition.operator === BinaryConditionType[BinaryConditionType.IS_EQUAL] ||
            condition.operator === BinaryConditionType[BinaryConditionType.IS_NOT_EQUAL]) {
            condition.argumentType = 'IsInputNumber';
        } else {
            condition.argumentType = '';
        }
    }

    private setSupportedArgumentTypeForEnumType(condition: Condition): void {
        if (condition.operator === BinaryConditionType[BinaryConditionType.IS_EQUAL] ||
            condition.operator === BinaryConditionType[BinaryConditionType.IS_NOT_EQUAL]) {
            condition.argumentType = 'IsInputEnum';
        } else {
            condition.argumentType = '';
        }
    }

    private getAllDependencies(currentDynamicModif: DynamicModification, conditions: Condition[], dynamics: DynamicModification[]): string[] {
        const dependencies: string[] = [];
        conditions.forEach(condition => {
            if (!dependencies.includes(currentDynamicModif.id + '-' + condition.targetDynamicMofication.id)) {
                dependencies.push(currentDynamicModif.id + '-' + condition.targetDynamicMofication.id);
            }
        });
        dynamics.forEach(dynamic => {
            if (dynamic.modificationTarget.properties.visibilityCriteria !== undefined &&
                dynamic.modificationTarget.properties.visibilityCriteria.conditions) {

                dynamic.modificationTarget.properties.visibilityCriteria.conditions.forEach(condition => {
                    if (!dependencies.includes(dynamic.id + '-' + condition.modificationId)) {
                        dependencies.push(dynamic.id + '-' + condition.modificationId);
                    }
                });
            }
        });
        return dependencies;
    }

    private detectCyclicDependencyFromAllDependencies(cycles: string[]): string[] {
        const detectedCycleMembers = [];
        let detectedCycle = '';
        for (const currentDependency of cycles) {
            detectedCycle = this.recursiveCheckOfCyclicDependency(cycles, currentDependency);
            if (detectedCycle.length > 0) {
                break;
            }
        }
        while (detectedCycle.length > 0) {
            if (detectedCycle.indexOf('-') > 0) {
                detectedCycleMembers.push(detectedCycle.substring(0, detectedCycle.indexOf('-')));
                detectedCycle = detectedCycle.substring(detectedCycle.indexOf('-') + 1, detectedCycle.length);
            } else {
                detectedCycleMembers.push(detectedCycle.substring(0, detectedCycle.length));
                detectedCycle = '';
            }
        }
        return detectedCycleMembers;
    }

    private recursiveCheckOfCyclicDependency(cycles: string[], cycle: string): string {
        let currentCycle: string = cycle;
        for (const dependencyToCheck of cycles) {
            if (currentCycle.endsWith(dependencyToCheck.substring(0, dependencyToCheck.indexOf('-')))) {
                currentCycle = currentCycle + '-' + dependencyToCheck.substring(dependencyToCheck.lastIndexOf('-') + 1, dependencyToCheck.length);
                if (currentCycle.startsWith(currentCycle.substring(currentCycle.length - (currentCycle.length - (currentCycle.lastIndexOf('-') + 1)),
                    currentCycle.length))) {
                    return currentCycle;
                } else {
                    return this.recursiveCheckOfCyclicDependency(cycles, currentCycle);
                }
            }
        }
        return '';
    }
}
