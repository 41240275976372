<div class="container">
  <form (ngSubmit)="onSubmit(appeazForm)" #appeazForm="ngForm" novalidate>
    <h2 i18n="@@dashboardInformation">Appeaz information</h2>
    <div class="form-group" [ngClass]="{ 'has-error': appeazForm.submitted && !name.valid }">
      <label class="control-label">
        <span i18n="@@dashboardAppeazName">Appeaz name:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <input type="text" id="name" class="form-control" name="name" required [(ngModel)]="myappeaz.name"
        #name="ngModel">
      <div *ngIf="appeazForm.submitted && !name.valid" class="alert alert-danger" i18n="@@dashboardAppeazNameRequired">
        The name is required</div>
    </div>
    <div class="form-group">
      <label class="control-label">
        <span i18n="@@dashboardAppeazAddress">Appeaz address:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <input [(ngModel)]="myappeaz.url" class="form-control" id="address" name="address" #address="ngModel" required>
      <div *ngIf="appeazForm.submitted && !address.valid" class="alert alert-danger"
        i18n="@@dashboardAppeazAddressRequired">The address is required</div>
    </div>
    <div class="form-group">
      <label class="control-label">
        <span i18n="@@dashboardAppeazApiKey">Appeaz key:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <input [(ngModel)]="myappeaz.apiKey" class="form-control" id="Key" name="apiKey" #Key="ngModel" required>
      <div *ngIf="appeazForm.submitted && !Key.valid" class="alert alert-danger" i18n="@@dashboardAppeazKeyRequired">The
        Key is required</div>
    </div>
     <div class="form-group">
      <label class="control-label">
        <span i18n="@@dashboardAppeazStyleSheet">Appeaz stylesheet:</span>
      
      </label>
      <input [(ngModel)]="myappeaz.stylesheet" class="form-control" id="stylesheet" name="stylesheet" >
      
    </div>
    <div class="form-group">
      <label class="label control-label">
        <input class="checkbox" type="checkbox" id="multilingue" name="multilingue" [(ngModel)]="myappeaz.multilingue">
        <span class="checkbox-label" i18n="@@dashboardAppeaznI18nCheckBox">Multilingual Appeaz (i18n)</span>
      </label>
    </div>

    <div>
      <button (click)="goBack()" type="button" class="btn btn-lg" i18n="@@appeazBack">
        <i class="fas fa-long-arrow-alt-left"></i> Cancel</button>
      <button id="submit" type="submit" class="btn  btn-lg" i18n="@@dashboardAppeazSubmit">
        <i class="fas fa-check"></i> Submit</button>
      <button *ngIf="isDeletable" id="showModal" (click)="showModal(modalTemplate)" type="button" class="btn btn-lg "
        i18n="@@MyAppeazsDelete">
        <i class="fas fa-trash-alt"></i> Delete </button>
    </div>
  </form>
</div>
<eazly-modal-window>
  <div modalWindowHeader i18n="@@MyAppeazModalDeleteHeader">
    Deleting the Appeaz {{myappeaz.name}}
  </div>
  <div modalWindowBody>
    <div i18n="@@MyAppeazModalDeleteBody">
      <p>
        This action will remove {{myappeaz.name}} from your workspace and every dynamic modifications for this Appeaz.
      </p>
      <p>
        Are you sure you want to delete this Appeaz ?
      </p>
    </div>
  </div>
  <div modalWindowFooter>
    <button id="cancelDeletion" (click)="hideModal()" type="button" class="btn btn-lg " i18n="@@Cancel">
      <i class="fas fa-long-arrow-alt-left"></i> Cancel</button>
    <button id="confirmDeletion" (click)="deleteAppeaz(myappeaz.id)" class="btn btn-lg "
      i18n="@@MyAppeazModalDeleteButton">
      <i class="fas fa-trash"></i> Delete</button>
  </div>
</eazly-modal-window>
