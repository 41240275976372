import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ImportOperation } from '../../tools/undo-redo-manager/actions/operations/import-operation';
import { Operation, OperationType } from '../../tools/undo-redo-manager/actions/operations/operation';
import { OperationMessage, OperationSource } from '../../tools/undo-redo-manager/message-bus/message-types/operation-message';
import { UndoRedoManagerService } from '../../tools/undo-redo-manager/undo-redo-manager.service';
@Component({
  selector: 'eazly-undo-redo',
  templateUrl: './undo-redo.component.html',
  styleUrls: ['./undo-redo.component.scss']
})
export class UndoRedoComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('undoButton') undoButton: ElementRef;
  @ViewChild('redoButton') redoButton: ElementRef;

  undoable: boolean;
  redoable: boolean;
  undoLabel: string;
  undoOperation: OperationType;
  redoLabel: string;
  redoOperation: OperationType;
  operationType = OperationType;

  pastOperationStack: Operation[] = [];
  futureOperationStack: Operation[] = [];

  private subscriptions: Subscription[] = [];

  constructor(public undoRedoManagerService: UndoRedoManagerService) { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // Checking if the user has pressed Ctrl+Z (code is in english locale)
    if (this.undoable && event.ctrlKey && event.code === 'KeyW') {
      this.undo();
    }
    // Checking if the user has pressed Ctrl+Y
    if (this.redoable && event.ctrlKey && event.code === 'KeyY') {
      this.redo();
    }
  }

  ngOnInit() {
    this.updateUndoableRedoable();
    this.subscribeToUndoRedoManagerChannels();
  }

  ngAfterViewInit() {
    this.undoButton.nativeElement.addEventListener('mouseleave', () => {
      this.undoButton.nativeElement.blur();
    });
    this.redoButton.nativeElement.addEventListener('mouseleave', () => {
      this.redoButton.nativeElement.blur();
    });
  }

  ngOnDestroy() {
    this.unsubscribeToUndoRedoManagerChannels();
  }

  undo() {
    this.undoRedoManagerService.undo();
  }

  redo() {
    this.undoRedoManagerService.redo();
  }

  private subscribeToUndoRedoManagerChannels() {
    // Successful Action Channels
    const doChannel: Subject<OperationMessage> = this.undoRedoManagerService.getSubscriptionChannel(OperationSource.DO);
    const doSubscription: Subscription = doChannel.subscribe(
      (operationMessage: OperationMessage) => {
        this.pastOperationStack.push(operationMessage.getOperation());
        this.futureOperationStack.length = 0;
        this.updateUndoableRedoable();
      }
    );
    const undoChannel: Subject<OperationMessage> = this.undoRedoManagerService.getSubscriptionChannel(OperationSource.UNDO);
    const undoSubscription: Subscription = undoChannel.subscribe(
      (operationMessage: OperationMessage) => {
        this.futureOperationStack.push(operationMessage.getOperation());
        this.pastOperationStack.pop();
        this.updateUndoableRedoable();
      }
    );
    const redoChannel: Subject<OperationMessage> = this.undoRedoManagerService.getSubscriptionChannel(OperationSource.REDO);
    const redoSubscription: Subscription = redoChannel.subscribe(
      (operationMessage: OperationMessage) => {
        this.pastOperationStack.push(operationMessage.getOperation());
        this.futureOperationStack.pop();
        this.updateUndoableRedoable();
      }
    );
    this.subscriptions.push(doSubscription);
    this.subscriptions.push(undoSubscription);
    this.subscriptions.push(redoSubscription);
    // Errors does not have specific processing for this component, no need to subscribe
  }

  private unsubscribeToUndoRedoManagerChannels() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private updateUndoableRedoable() {
    const pastStackLength = this.pastOperationStack.length;
    this.undoable = (pastStackLength !== 0);
    if (this.undoable) {
      const operation: Operation = this.pastOperationStack[pastStackLength - 1];
      this.undoLabel = operation.getName() + ' (Ctrl + Z)';
      this.undoOperation = operation.getOperationType();
    } else {
      this.undoLabel = '';
      this.undoOperation = null;
    }

    const futureStackLength = this.futureOperationStack.length;
    this.redoable = (futureStackLength !== 0);
    if (this.redoable) {
      const operation: Operation = this.futureOperationStack[futureStackLength - 1];
      this.redoLabel = operation.getName() + ' (Ctrl + Y)';
      this.redoOperation = operation.getOperationType();
    } else {
      this.redoLabel = '';
      this.redoOperation = null;
    }
  }
}
