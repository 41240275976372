import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'eazly-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @HostBinding('class') class = 'page-content';

  accountInfoSelected: boolean;
  userInfoSelected: boolean;
  userSettingsSelected: boolean;

  constructor() { }

  ngOnInit() {
    this.accountInformationClicked();
  }

  accountInformationClicked() {
    this.accountInfoSelected = true;
    this.userInfoSelected = false;
    this.userSettingsSelected = false;
  }

  userInformationClicked() {
    this.accountInfoSelected = false;
    this.userInfoSelected = true;
    this.userSettingsSelected = false;
  }
  userSettingsClicked() {
    this.accountInfoSelected = false;
    this.userInfoSelected = false;
    this.userSettingsSelected = true;
  }

}
