import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Appeaz } from '../tools/models/appeaz';
import { Step } from '../tools/models/step';
import { User } from '../tools/models/user';
import { AppeazService } from '../tools/services/appeaz.service';
import { MessagingService } from '../tools/services/messaging/messaging.service';
import { NavigatorComponent } from './navigator/navigator.component';
import { ViewerComponent } from './viewer/viewer.component';
import { UserService } from '../tools/services/user.service';
@Component({
  selector: 'eazly-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'studio-content';

  @ViewChild(ViewerComponent)
  readonly viewerComponent: ViewerComponent;
  @ViewChild(NavigatorComponent)
  readonly navigatorComponent: NavigatorComponent;

  isLoading: boolean;
  myappeaz: Appeaz;
  title = 'editor';
  selectedStep: Step;
  selectedStepName: string;
  user: User;
  ready: Promise<boolean>;
  exportBtnActive: boolean;
  canPublish: boolean;

  constructor(private router: Router, private route: ActivatedRoute,
    private readonly messagingService: MessagingService, private readonly appeazService: AppeazService, private readonly userService: UserService) { }

  ngOnInit() {
    this.getAppeaz();
  }

  ngOnDestroy() { }

  onStepChange(prop) {
    if (prop !== undefined) {
      this.selectedStep = prop;
      this.selectedStepName = prop.name;
    }
  }

  handleExportButtonClicked() {
    this.navigatorComponent.toggleDisplayOfStepSelection();
    this.navigatorComponent.toggleDisplayOfExportButton();
    this.exportBtnActive = !this.exportBtnActive;
  }

  public handleExportButtonFromNavigatorClicked(): void {
    this.exportBtnActive = false;
  }

  onErrorEmit(errorReport) {
    if (errorReport !== undefined) {
      this.messagingService.notifyError(errorReport);
    }
  }

  getAppeaz() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.user = this.appeazService.getCurrentUser();
    this.appeazService.getAppeazDescriptor(id, this.user).subscribe(
      (data) => {
        this.myappeaz = data;
        this.canPublish = this.userService.isCurrentUserAPublisher(this.myappeaz);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      });
  }

  goBack(): void {
    this.router.navigate(['/appeazs']);
  }
}
