
export class EazlyMessage {

    wasDisplayed: boolean;
    origin: string; // example 'AppeazService'
    messageCause: MessageCause; // example 'ApiKeyNotRecognized'
    type: AlertType; // example 'danger'
    /* appeazName: string; // example 1 for 'SVE', */
    message: string; // example errorToHandle.status + ' ' + errorToHandle.statusText

    constructor(type: AlertType, origin: string, messageCause: MessageCause, message: string) {
        this.wasDisplayed = false;
        this.origin = origin;
        this.messageCause = messageCause;
        this.type = type;
        this.message = message;
    }

}

export enum AlertType {
    danger = 'danger',
    dark = 'dark',
    info = 'info',
    light = 'light',
    primary = 'primary',
    success = 'success',
    secondary = 'secondary',
    warning = 'warning'
}
export enum MessageCause {
    ApiKeyNotRecognized,
    BrowserNotSupported,
    HostUnreachable,
    InvalidLoginPassword,
    InternalError,
    SessionExpired,
    InsufficientRole,
    InsufficientRoleForGetAppeazById,
    InsufficientRoleForGetUsersByAppeaz,
    InsufficientRoleForInvitingUserOnAppeaz,
    InsufficientRoleForRemovingUsersOfAppeaz,
    UserAlreadyInvited,
    InsufficientRoleForGetUserByEmail,
    JwtExpired,
    AppeazAccessTimeOut,
    NotHaveTheRoleToAccess,
    InvalidRoleProvided,
    DynamicHasErrors,
    DynamicCouldNotBeCreated,
    DynamicCouldNotBeEdited,
    InvalidDefaultValue,
    DynamicHasConflict,
    AppeazNotFound,
    StepNotFound,
    StepOrDMNotFound,
    UserNotFound,
    ActiveVersionNotFound,
    LocaleCouldNotBeSet,
    DeletionSuccessful,
    DeletionFailure,
    CreationSuccessful,
    UpdateSuccessful,
    DeletionOfDynamicFieldWhichWasUsedInVisibilityCondition,
    DeletionOfListValueItemWhichWasUsedInCondition,
    ChangeOfDataDescriptorOfDynamicFieldWhichWasUsedInVisibilityCondition,
    DataDescriptorRenamingConflict,
    DataDescriptorAssociationConflict,
    UpdateOfDatadescriptorOfReferencedValuesList,
    DataDescriptorTypeConflict,
    DeletionOfListValueItemWhichWasnDefaultValue,
    DissociationOfDynamicFieldWhichWasUsedInCondition,
    PasswordUpdateSuccessful,
    EmailSentSuccessful,
    PasswordTooWeak,
    PasswordDoesNotMatch,
    InvalidToken,
    ExportInfoMessage,
    ExportFailedMessage,
    ImportSuccessMessage,
    ImportFailedMessage,
    ImportInvalidFileMessage,
    ImportFailedMessageBecauseOfDataDescriptorConflict,
    ImportFailedMessageBecauseOfWrongData
}
