import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorage } from 'ngx-webstorage';
import { User } from '../tools/models/user';
import { AlertType, EazlyMessage, MessageCause } from '../tools/services/messaging/eazly-message';
import { MessagingService } from '../tools/services/messaging/messaging.service';
import { UserService } from '../tools/services/user.service';

@Component({
  selector: 'eazly-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'page-content';

  @SessionStorage('auth.user')
  public currentUser;

  model: any = {};
  account: User;
  returnUrl: string;
  alerts: any = [];
  errorReport: EazlyMessage;

  constructor(private router: Router,
    private messagingService: MessagingService,
    private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {
    this.account = new User();
    this.account = this.currentUser;
    const token = +this.route.snapshot.paramMap.get('token');
    this.account.token = token;
  }

  ngOnDestroy() {
    if (this.errorReport) {
      this.messagingService.notifyError(this.errorReport);
    }
  }

  onSubmit() {
    if (this.account.password === this.model.cpassword) {
      //EAZ-1072
      if (this.account.password) {
        this.account.password = this.escapeSpecialCharacters(this.account.password);
      }
      this.userService.confirmUser(this.account).subscribe(data => {
        this.router.navigate(['/login']);
      },
        httpErrorResponse => {
          switch (httpErrorResponse.status) {
            case 400:
              const alert = {
                type: 'danger',
                serverResponse: httpErrorResponse.error.exception
              };
              this.alerts.push(alert);
              break;
            case 404:
              this.errorReport = new EazlyMessage(
                AlertType.danger,
                'UserService',
                MessageCause.UserNotFound,
                `${httpErrorResponse.status} ${httpErrorResponse.statusText}`);
              this.router.navigate(['/login']);
              break;
            default:
              const unknownErrorAlert = {
                type: 'danger',
                serverResponse: 'unknownError',
                message: httpErrorResponse.error.message
              };
              this.alerts.push(unknownErrorAlert);
              break;
          }
        });
    } else {
      const alert = {
        type: 'danger',
        serverResponse: 'passwordsNotMatching'
      };
      this.alerts.push(alert);
    }
  }

  //EAZ-1072
  escapeSpecialCharacters(inputString: string): string {
    return inputString.replace(/[\u0000-\u001F\\"]/g, (match) => {
      switch (match) {
        case '\b': return '\\b';  // Backspace
        case '\f': return '\\f';  // Form feed
        case '\n': return '\\n';  // Newline
        case '\r': return '\\r';  // Carriage return
        case '\t': return '\\t';  // Tab
        case '"': return '\\"';   // Double quote
        case '\\': return '\\\\';  // Backslash
        default:
          // Use Unicode escape for control characters and other special cases
          return '\\u' + ('0000' + match.charCodeAt(0).toString(16)).slice(-4);
      }
    });
  }
}
