import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Appeaz } from '../../tools/models/appeaz';
import { DynamicVersion } from '../../tools/models/dynamic-version';
import { AppeazService } from '../../tools/services/appeaz.service';
import { AlertType, EazlyMessage, MessageCause } from '../../tools/services/messaging/eazly-message';
import { MessagingService } from '../../tools/services/messaging/messaging.service';

@Component({
  selector: 'eazly-environement-dashboard',
  templateUrl: './environement-dashboard.component.html',
  styleUrls: ['./environement-dashboard.component.scss'],
})
export class EnvironementDashboardComponent implements OnInit, OnDestroy {

  @Input() myappeaz: Appeaz;

  activeVersion: DynamicVersion;
  draftVersion: DynamicVersion;
  alerts: any = [];
  permanentalerts: any = [];
  isLoading: boolean;
  errorMessage: EazlyMessage;
  publicationLock: boolean;


  constructor(private readonly appeazService: AppeazService,
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly messagingService: MessagingService) { }

  ngOnInit() {
    this.isLoading = true;
    this.publicationLock = false;
    this.updateVersions();

  }

  ngOnDestroy() {
    if (this.errorMessage) {
      this.messagingService.notifyError(this.errorMessage);
    }
  }

  getNiceLastUpdatedTime(date: Date): string {

    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric', month: 'numeric', year: 'numeric',
      hour: '2-digit', minute: '2-digit'
    };

    return date.toLocaleDateString('fr-FR', options);
  }

  //this method only allows one publish request to pass at a time
  public doPublishAPI() {
    if (!this.publicationLock) {
      this.logger.debug('the API will be called and we will set the lock');
      this.publicationLock = true;
      this.callPublishAPI(false).then(
        (onSuccess) => {
          this.publicationLock = false;
          this.logger.debug('the lock is released after success');
        },
        (onError) => {
          this.publicationLock = false;
          this.logger.debug('the lock is released after error');
        });
    } else {
      this.logger.debug('the API is not going to be called');
    }
  }

  /**
  *  this method makes a request to the Appeaz Publish API and returns a Promise
  * that is resolved when the API call ends either successfully or unsuccessfully
  *
  * @param alreadyRetried this alreadyRetried to check if the API treated  or not
  * @returns resolved promise with success or error when the call terminate
  */
  private callPublishAPI(alreadyRetried: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.draftVersion !== undefined && this.draftVersion !== null) {
        this.appeazService.postDynamicActiveVersion(this.myappeaz.url).subscribe(result => {
          if (result === 200) {
            this.updateVersions();
            const alert = {
              type: 'success',
              serverResponse: 'publish success'
            };
            this.alerts.push(alert);
          }
          if (result === 202) {
            const alert = {
              type: 'info',
              serverResponse: 'no publish'
            };
            this.alerts.push(alert);
          }
          resolve('done');
        }, error => {
          // Handling error of publish
          if ((400 === error.status || 401 === error.status) && !alreadyRetried) {
            // Session expired (JWT expired), should renew the JWT
            this.appeazService.getAppeazJWT(this.myappeaz.id, this.myappeaz.role).subscribe(
              dataJwt => {
                this.callPublishAPI(true);
              },
              errorOnGettingJWT => {
                this.errorMessage = error;
              }
            );
          }
          else {
            // Handling Other error of publish
            this.handlePublishError(error);
            reject(error);
          }
        });
      }
    });
  }

  private updateVersions() {
    if (!this.myappeaz) {
      this.router.navigate(['/login'], { queryParams: { expired: 'true' } });
    } else {
      this.appeazService.getAppeazJWT(this.myappeaz.id, 'Publisher').subscribe(dataJwt => {

        this.appeazService.getDynamicVersion(this.myappeaz.url, 'active').subscribe(result => {
          this.activeVersion = result;
        }, error => {
          this.isLoading = false;
          this.handlePublishError(error);
        });
        this.appeazService.getDynamicVersion(this.myappeaz.url, 'draft').subscribe(result => {
          this.draftVersion = result;
          this.isLoading = false;
          if (this.draftVersion === undefined || this.draftVersion === null) {
            const alert = {
              type: 'info',
              serverResponse: 'no draft version'
            };
            this.permanentalerts.push(alert);
          }
        }, error => {
          this.isLoading = false;
          this.handlePublishError(error);
        });
      },
        error => {
          // Handling error from GetAppeazJWT
          // Error already handled in AppeazService,
          // publishing the error needed after the destruction of the component
          this.isLoading = false;
          this.errorMessage = error;
        });
    }
  }

  private handlePublishError(error: any) {
    if (error.status === 0) {
      const errorReport = new EazlyMessage(AlertType.danger, 'VersionService', MessageCause.HostUnreachable, error.status + ' ' + error.statusText);
      this.messagingService.notifyError(errorReport);
    }
    if (error.status === 401 || error.status === 403) {
      const errorReport = new EazlyMessage(AlertType.danger, 'VersionService', MessageCause.JwtExpired, error.status + ' ' + error.statusText);
      this.messagingService.notifyError(errorReport);
    }
    if (error.status === 404) {
      const errorReport = new EazlyMessage(AlertType.warning, 'VersionService', MessageCause.ActiveVersionNotFound, error.status + ' ' + error.statusText);
      this.messagingService.notifyError(errorReport);
    }
    if (error.status === 504) {
      const errorReport = new EazlyMessage(AlertType.danger, 'VersionService', MessageCause.AppeazAccessTimeOut, error.status + ' ' + error.statusText);
      this.messagingService.notifyError(errorReport);
    }
  }
}
