<form class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
  <eazly-alert-manager></eazly-alert-manager>
  <div class="grid">
    <div class="form-group row" [ngClass]="{ 'has-error': f.submitted && !login.valid }">
      <label class="control-label form-label col-3">
        <span i18n="@@homeLogin">Login:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <div class="col-6">
        <input type="text" class="form-control" id="login" name="login" required [(ngModel)]="model.login"
          #login="ngModel" autocomplete="username email" placeholder="email@example.org"
          i18n-placeholder="@@loginPlaceholder">
        <div *ngIf="f.submitted && !login.valid" id="loginRequiredAlert" class="alert alert-danger"
          i18n="@@homeLoginRequired">The Login is required</div>
      </div>
      <div class="col-3"></div>
    </div>

    <div class="form-group row">
      <label class="control-label form-label col-3">
        <span i18n="@@homePassword">Password:</span>
        <i class="required-info" aria-hidden="true"></i>
      </label>
      <div class="col-6">
        <input type="password" id="password" [(ngModel)]="model.password" class="form-control" name="password"
          #password="ngModel" required autocomplete="password current-password">
        <div *ngIf="f.submitted && !password.valid" id="passwordRequiredAlert" class="alert alert-danger"
          i18n="@@homePasswordRequired">The Password is required</div>
      </div>
      <div class="col-3">
        <a href="#" id="forgotPassword" i18n="@@forgotPassword" (click)="showModal()">I forgot my
          password</a>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4"></div>
      <button type="submit" id="submit" class="btn btn-primary btn-lg col-4" i18n="@@HomeSubmitConnection">
        <i class="fas fa-check"></i> Sign in</button>
      <div class="col-4"></div>
    </div>
  </div>
</form>
<eazly-reset-password-window>
</eazly-reset-password-window>
