import { DataType } from '../data-type';
import { DynamicModification } from '../dynamic-modification';

export class Condition {
    id: string;
    targetDynamicMofication: DynamicModification;
    listOfTargetDynamicMofications: DynamicModification[] = [];
    operator: string;
    argument: string;
    listOfOperators: string[];
    argumentType: string;
    dataType: DataType;
}
/* This condition model is used to bind data from DOM to VisibilityWindowComponent*/
