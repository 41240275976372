<ng-container *ngTemplateOutlet="alertTemplate"></ng-container>

<ng-template #alertTemplate>
  <alert *ngIf="alertBrowser !== undefined" [type]="alertBrowser.type" [dismissible]="false">
    <span id="BrowserNotSupported" i18n='@@BrowserNotSupported'>
      <strong>This browser is not supported! Supported Browsers are :</strong>
      <br />
      <div *ngFor="let browser of supportedbrowsers">
        &#9733; {{browser.name}} version : {{browser.version}} or later
      </div>
    </span>
  </alert>
  <!-- Dismiss in 20 seconds-->
  <alert *ngFor="let alert of alerts" [type]="alert.type" [dismissible]="true" dismissOnTimeout="20000">
    <!-- COMMON ERRORS-->
    <span *ngIf="alert.messageCause === messageCause.SessionExpired">
      <span id="SessionExpired" i18n='@@ErrorSessionExpired'>
        <strong>Session expired !</strong> Your session has expired.
      </span>
    </span>
    <span *ngIf="alert.messageCause === messageCause.InternalError">
      <span id="InternalErrorMessage" i18n='@@ErrorInternalError'>
        <strong>InternalError !</strong>
      </span>
    </span>
    <span *ngIf="alert.messageCause === messageCause.AppeazNotFound">
      <span id="AppeazNotFound" i18n='@@ErrorAppeazNotFound'>
        <strong>Appeaz not found!</strong> The specified Appeaz could not be found.
      </span>
    </span>

    <!-- ORIGIN: USER SERVICE ERROR -->
    <span *ngIf="alert.origin === 'UserService'">
      <!-- AUTHENTICATION PAGE ERRORS-->
      <span *ngIf="alert.messageCause === messageCause.InvalidLoginPassword">
        <span id="InvalidLoginPassword" i18n='@@ErrorLoginError'>
          Invalid username or password.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.HostUnreachable">
        <span id="HostUnreachable" i18n='@@ErrorHostUnreachable'>
          <strong>Host unreachable !</strong> The remote server is not accessible at the moment. Please contact the
          Administrator.

        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.UserNotFound">
        <span id="UserNotFound" i18n='@@ErrorUserNotFoundError'>
          <strong>User not found !</strong> The user account was not found. Please contact the Administrator.

        </span>
      </span>
      <!-- USERS IN APPEAZ MANAGEMENT ERROR (SessionExpired & UserNotFound already defined)-->
      <span *ngIf="alert.messageCause === messageCause.InsufficientRoleForInvitingUserOnAppeaz">
        <span id="InsufficientRoleForInvitingUserOnAppeaz" i18n='@@ErrorInsufficientRoleForInvitingUserOnAppeaz'>
          <strong>Forbidden action !</strong> Only an Administrator or a Manager of this Appeaz can invite users on
          this Appeaz.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.InsufficientRoleForGetUserByEmail">
        <span id="InsufficientRoleForGetUserByEmail" i18n='@@ErrorInsufficientRoleForGetUserByEmail'>
          <strong>Forbidden action !</strong> Only an Administrator or a Manager of this Appeaz can retrieve
          informations of other
          users.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.UserAlreadyInvited">
        <span id="UserAlreadyInvited" i18n='@@ErrorUserAlreadyInvited'>
          This user has already been invited.
        </span>
      </span>
    </span>
    <!--ORIGIN: APPEAZ SERVICE ERRORS -->
    <span *ngIf="alert.origin === 'AppeazService'">
      <span *ngIf="alert.messageCause === messageCause.InvalidRoleProvided">
        <span id="InvalidRoleProvided" i18n='@@ErrorInvalidRoleProvided'>
          <strong>Invalid Role !</strong> Please contact the
          Administrator.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.InsufficientRoleForGetAppeazById">
        <span id="InsufficientRoleForGetAppeazById" i18n='@@ErrorInsufficientRoleForGetAppeazById'>
          <strong>Forbidden action !</strong> Only an Administrator or a Manager can get the information of the Appeaz.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.InsufficientRoleForGetUsersByAppeaz">
        <span id="InsufficientRoleForGetUsersByAppeaz" i18n='@@ErrorInsufficientRoleForGetUsersByAppeaz'>
          <strong>Forbidden action !</strong> Only an Administrator or a Manager can get the list of users of the
          Appeaz.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.InsufficientRoleForRemovingUsersOfAppeaz">
        <span id="InsufficientRoleForRemovingUsersOfAppeaz" i18n='@@ErrorInsufficientRoleForRemovingUsersOfAppeaz'>
          <strong>Forbidden action !</strong> Only an Administrator or a Manager of this Appeaz can remove a user from
          this Appeaz.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.JwtExpired">
        <span id="AppeazUnreachable" i18n='@@MyAppeazsAlertAppeazUnreachable'>
          <strong>Appeaz unreachable !</strong> The Appeaz could not be accessed. Please check if the Appeaz is running
          or contact
          the Administrator.</span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.ApiKeyNotRecognized">
        <span id="ApiKeyNotRecognized" i18n='@@MyAppeazsAlertApiKeyNotRecognized'>
          <strong>API Key Not recognized !</strong> The Appeaz's API Key was not recognized. Please contact the
          Administrator.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.AppeazAccessTimeOut">
        <span id="AppeazUnreachable" i18n='@@MyAppeazsAlertAppeazUnreachable'>
          <strong>Appeaz unreachable !</strong> The Appeaz could not be accessed. Please check if the Appeaz is running
          or contact
          the Administrator.
        </span>
      </span>
    </span>
    <!--ORIGIN: VIEWER SERVICE ERRORS -->
    <span *ngIf="alert.origin === 'ViewerService'">
      <span *ngIf="alert.messageCause === messageCause.AppeazAccessTimeOut">
        <span id="AppeazUnreachable" i18n='@@MyAppeazsAlertAppeazUnreachable'>
          <strong>Appeaz unreachable!</strong> The Appeaz could not be accessed. Please check if the Appeaz is running
          or contact
          the Administrator.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DynamicHasConflict">
        <span id="DynamicHasConflict" i18n='@@ViewerAlertDynamicHasConflict'>
          <strong>Conflict error!</strong> The Dynamic Modification could not be updated due to a conflict with
          another Dynamic Modification.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DataDescriptorAssociationConflict">
        <span id="DataDescriptorAssociationConflict" i18n='@@ViewerAlertDataDescriptorAssociationConflict'>
          The detailed name {{alert.message.split(';')[1]}} cannot be used because it is already used in the current
          step.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DataDescriptorRenamingConflict">
        <span id="DataDescriptorRenamingConflict" i18n='@@ViewerAlertDataDescriptorRenamingConflict'>
          The detailed name {{alert.message.split(';')[0]}}
          could not be renamed to {{alert.message.split(';')[1]}}
          because this name is already used.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DataDescriptorTypeConflict">
        <span id="DataDescriptorTypeConflict" i18n='@@ViewerAlertDataDescriptorTypeConflict'>
          Update not possible, {{alert.message.split(';')[1]}} cannot be linked with {{alert.message.split(';')[2]}}
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DeletionOfDynamicFieldWhichWasUsedInVisibilityCondition">
        <span id="DeletionOfDynamicFieldWhichWasUsedInVisibilityCondition"
          i18n='@@DeletionOfDynamicFieldWhichWasUsedInVisibilityCondition'>
          The field has been successfully deleted.
          <p class="p-style-error-message">
            However, it was used in the visibility conditions of the following
            fields:<strong> {{alert.message}}</strong>.
          </p>
          <p class="p-style-error-message">
            These conditions have been automatically removed. You can undo these deletions (ctrl+z).
          </p>
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.UpdateOfDatadescriptorOfReferencedValuesList">
        <span id="UpdateOfDatadescriptorOfReferencedValuesList" i18n='@@UpdateOfDatadescriptorOfReferencedValuesList'>
          The field has been successfully linked to the new list and the old values ​​of the list are deleted.
          <p class="p-style-error-message" *ngIf="alert.message">
            However, these values ​​were used in the visibility conditions of the following fields:<strong>
              {{alert.message}}</strong>.
          </p>
          <p class="p-style-error-message" *ngIf="alert.message">
            These conditions have been automatically removed.
          </p>
          You can undo these deletions (ctrl+z).
        </span>
      </span>
      <span
        *ngIf="alert.messageCause === messageCause.ChangeOfDataDescriptorOfDynamicFieldWhichWasUsedInVisibilityCondition">
        <span id="ChangeOfDataDescriptorOfDynamicFieldWhichWasUsedInVisibilityCondition"
          i18n='@@ChangeOfDataDescriptorOfDynamicFieldWhichWasUsedInVisibilityCondition'>
          The field and its values has been successfully updated.
          <p class="p-style-error-message">
            However, some values were used in the visibility conditions of the following
            fields:<strong> {{alert.message}}</strong>.
          </p>
          <p class="p-style-error-message">
            These conditions have been automatically removed. You can undo these deletions (ctrl+z).
          </p>
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.NotHaveTheRoleToAccess">
        <span id="NotHaveTheRoleToAccess" i18n='@@ViewerAlertNotHaveTheRoleToAccess'>
          <strong>Forbidden! </strong> You need to be Designer or have a superior role to access this service. Please
          contact the Manager of your Appeaz to check your role.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DynamicCouldNotBeCreated">
        <span id="DynamicCouldNotBeCreated" i18n='@@ViewerAlertDynamicCouldNotBeCreated'>
          <strong>Error! </strong> The Dynamic Modification could not be created, specified values are not valid.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DynamicCouldNotBeEdited">
        <span id="DynamicCouldNotBeEdited" i18n='@@ViewerAlertDynamicCouldNotBeEdited'>
          <strong>Error! </strong> The Dynamic Modification could not be edited, specified values are not valid.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.InvalidDefaultValue">
        <span id="InvalidDefaultValue" i18n='@@InvalidDefaultValue'>
          <strong>Error! </strong> The field was not modified because the default value is not valid.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.StepNotFound">
        <span id="StepNotFoundViewer" i18n='@@ViewerAlertStepNotFound'>
          <strong>Step not found!</strong> The step could not be edited, it could not be found in the Appeaz.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.StepOrDMNotFound">
        <span id="StepOrDMNotFoundViewer" i18n='@@ViewerAlertStepOrDMNotFound'>
          <strong>Step or Dynamic modification not found!</strong> The Step or the Dynamic Modification could not be
          edited, it could not be found in the Appeaz.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.InsufficientRole">
        <span id="InsufficientRoleForAccessingAppeaz" i18n='@@ErrorInsufficientRoleForAccessingAppeaz'>
          <strong>Forbidden action !</strong> Only a Designer of this Appeaz can edit it. Please contact your Manager
          to check your role.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.HostUnreachable">
        <span id="HostUnreachable" i18n='@@ErrorHostUnreachable'>
          <strong>Host unreachable !</strong> The remote server is not accessible at the moment. Please contact the
          Administrator.
        </span>
      </span>
    </span>
    <!--ORIGIN: BACKEND SERVICE ERRORS -->
    <span *ngIf="alert.origin === 'BackendService'">
      <span *ngIf="alert.messageCause === messageCause.CreationSuccessful">
        <span id="alertAppeazCreationSuccess" i18n='@@MyAppeazsAlertCreationSuccess'>
          <strong>Creation complete !</strong> The Appeaz {{alert.appeazName}} was successfully created.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.UpdateSuccessful">
        <span id="alertAppeazUpdateSuccess" i18n='@@MyAppeazsAlertUpdateSuccess'>
          <strong>Update complete !</strong> The Appeaz {{alert.appeazName}} was successfully updated.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DeletionSuccessful">
        <span id="alertAppeazDeletionSuccess" i18n='@@MyAppeazsAlertDeletionSuccess'>
          <strong>Deletion complete !</strong> The Appeaz {{alert.appeazName}} was successfully deleted.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DeletionFailure">
        <span id="alertAppeazDeletionFail" i18n='@@MyAppeazsAlertDeletionFailure'>
          <strong>Deletion failed !</strong> The Appeaz {{alert.appeazName}} was not deleted.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.InsufficientRole">
        <span id="InsufficientRoleForDeletingAppeaz" i18n='@@ErrorInsufficientRoleForDeletingAppeaz'>
          <strong>Forbidden action !</strong> Only an Administrator can delete the Appeaz.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.PasswordUpdateSuccessful">
        <span id="alertPasswordUpdateSuccess" i18n='@@MyPasswordsAlertUpdateSuccess'>
          <strong>Update complete !</strong> The password was successfully updated.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.EmailSentSuccessful">
        <span id="alertResetPaswordMailSuccess" i18n='@@emailSentSuccessfully'>
          If the email address entered is valid, you will receive an email to reset your password.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.PasswordDoesNotMatch">
        <span id="alertPasswordDoesNotMatchException" i18n='@@AccountInfoUpdatePasswordNotMatchFailure'>
          Password and password confirmation don't match !
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.PasswordTooWeak">
        <span id="alertPasswordTooWeakException" i18n='@@PasswordTooWeakFailure'>
          The password provided is too weak ! It does not match with the expected format.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.InvalidToken">
        <span id="alertInvalidToken" i18n='@@InvalidTokenFailure'>
          The password recovery URL is invalid or has expired.
        </span>
      </span>
    </span>
    <!--ORIGIN: VERSION SERVICE ERRORS -->
    <span *ngIf="alert.origin === 'VersionService'">
      <span *ngIf="alert.messageCause === messageCause.AppeazUnreachable">
        <span id="AppeazUnreachable" i18n='@@MyAppeazsAlertAppeazUnreachable'>
          <strong>Appeaz unreachable !</strong> The Appeaz could not be accessed. Please check if the Appeaz is
          running
          or contact the Administrator.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.HostUnreachable">
        <span id="HostUnreachable" i18n='@@ErrorHostUnreachable'>
          <strong>Host unreachable !</strong> The remote server is not accessible at the moment. Please contact the
          Administrator.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.ActiveVersionNotFound">
        <span id="ActiveVersionNotFound" i18n='@@ErrorActiveVersionNotFound'>
          <strong>No published version yet!</strong>
        </span>
      </span>
    </span>
    <!--ORIGIN: ImportExport SERVICE ERRORS -->
    <span *ngIf="alert.origin === 'ImportExportService'">
      <span *ngIf="alert.messageCause === messageCause.ExportInfoMessage">
        <span id="ExportInfoMessage" i18n='@@ExportInfoMessage'>
          The export is ready to start, and you'll find the file in your download directory.
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.ExportFailedMessage">
        <span id="ExportFailedMessage" i18n='@@ExportFailedMessage'>
          Exporting the selected steps failed, please contact the support or open the browser prompt
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.ImportSuccessMessage">
        <span id="ImportSuccessMessage" i18n='@@ImportSuccessMessage'>
          Import was performed successfully
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.ImportFailedMessage">
        <span id="ImportFailedMessage" i18n='@@ImportFailedMessage'>
          Import failed, you can contact support or open the browser's prompt
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.ImportInvalidFileMessage">
        <span id="ImportInvalidFileMessage" i18n='@@ImportInvalidFileMessage'>
          Import failed : file is not valid
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.ImportFailedMessageBecauseOfWrongData">
        <span id="ImportFailedMessageBecauseOfWrongData" i18n='@@ImportFailedMessageBecauseOfWrongData'>
          Import failed due to wrong data in the file, you can contact support.
        </span>
      </span>
    </span>
    <span *ngIf="alert.messageCause === messageCause.ImportFailedMessageBecauseOfDataDescriptorConflict">
      <span id="ImportFailedMessageBecauseOfDataDescriptorConflict"
        i18n='@@ImportFailedMessageBecauseOfDataDescriptorConflict'>
        Import failed because the file content is not valid: it links together the fields
        <strong>{{alert.message}}</strong> on the same step <strong>{{alert.origin}}</strong>
      </span>
    </span>
  </alert>
</ng-template>
