import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Appeaz } from '../tools/models/appeaz';
import { AppeazService } from '../tools/services/appeaz.service';



@Component({
  selector: 'eazly-publish-component',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.scss']
})
export class PublishComponent implements OnInit {

  @HostBinding('class') class = 'page-content';
  myappeaz: Appeaz;
  ready: Promise<boolean>;
  id: number;

  constructor(private router: Router, private route: ActivatedRoute, private appeazService: AppeazService) { }

  ngOnInit() {
    this.getAppeaz();
  }

  getAppeaz(): void {

    this.id = +this.route.snapshot.paramMap.get('id');
    this.appeazService.getAppeazById(this.id)
      .subscribe(
        data => {
          this.myappeaz = data;
          this.ready = Promise.resolve(true);
        },
        error => {
          this.ready = Promise.resolve(true);
        });

  }


  goBack(): void {
    this.router.navigate(['/appeazs']);
  }
}
