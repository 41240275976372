import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Asset } from '../models/asset';
import { assets } from '../palette/palette';


@Injectable()
export class PaletteService {

    constructor() { }

    getAssets(url: string, keyJWT: string): Observable<Asset[]> {
        return of(assets);
    }



}
