import { AbstractControl, ValidatorFn } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { DataDescriptor } from '../../../../tools/models/data-descriptor';
import { DataType, englishDataType, frenchDataType } from '../../../../tools/models/data-type';
import { DynamicModification } from '../../../../tools/models/dynamic-modification';
import { Step } from '../../../../tools/models/step';

/**
 * Custom validator for checking if the dataName specified
 * triggers the following conflicts:
 * - DataType not compatible when trying to link with a DataDescriptor with a different type
 * - DataName already used in the step when trying to create a link
 * - DataName already used when trying to rename a DataDescriptor
 */
export class InputDataNameValidator {

    private isLinkedField: boolean;
    private wasInitiallyLinked: boolean;
    private dataNameForLinkCreation: string;
    private dynamicModification: DynamicModification;
    private dataDictionnary: DataDescriptor[];
    private dataDictionnaryOfTheStep: DataDescriptor[];
    private dataTypeConflictParameters: string;
    private stepBeingEdited: Step;
    private localeId: string;
    private logger: NGXLogger;


    constructor(dynamicModification: DynamicModification,
        isLinkedField: boolean,
        dataDictionnary: DataDescriptor[],
        dataDictionnaryOfTheStep: DataDescriptor[],
        stepBeingEdited: Step,
        localeId: string,
        logger: NGXLogger) {
        this.dynamicModification = dynamicModification;
        this.isLinkedField = isLinkedField;
        this.wasInitiallyLinked = isLinkedField;
        // Initializing the attribute 'this.dataNameForLinkCreation'
        // to prevent the 'RenamingConflict' error when the InputDataNameValue
        // corresponds to the value used to create the link
        if (this.isLinkedField) {
            this.dataNameForLinkCreation = dynamicModification.modificationTarget.dataDescriptor.name;
        } else {
            this.dataNameForLinkCreation = '';
        }
        this.dataDictionnary = dataDictionnary;
        this.dataDictionnaryOfTheStep = dataDictionnaryOfTheStep;
        this.stepBeingEdited = stepBeingEdited;
        this.localeId = localeId;
        this.logger = logger;

    }

    /**
     * This method validates the inputDataName field by checking if a conflicts is detected.
     * When a conflict is detected, a matching error is added to the inputDataNameControl.
     *
     * @returns a validator that checks the possible conflicts on the DataDescriptorName
     */
    public getValidator(): ValidatorFn {
        return (inputDataNameControl: AbstractControl): { [key: string]: any } | null => {
            let errors = null;
            if (this.hasDataTypeConflict(inputDataNameControl)) {
                errors = { hasDataTypeConflict: true };
            }
            if (!this.isLinkedField && this.hasDataNameAlreadyUsedConflict(inputDataNameControl)) {
                errors = { hasDataNameAlreadyUsedConflict: true };
            }

            if (this.isLinkedField && this.hasDataNameRenamingConflict(inputDataNameControl)) {
                errors = { hasRenamingConflict: true };
            }
            if (errors) {
                this.logger.debug('The following errors have been identified for the InputDataName:', errors);
            }
            return errors;
        };
    }

    /**
     * This method retrieves the parameters from the DataTypeConflict error message
     *
     * @param param the number of the param to retrieve
     * @returns the param of the error message
     */
    public getDataTypeConflictParameters(param: number): string {
        let translatedParameter: string;
        const paramToTranslate = this.dataTypeConflictParameters.split(';')[param];
        if (this.localeId === 'fr') {
            translatedParameter = frenchDataType.get(DataType[paramToTranslate]);
        } else if (this.localeId === 'en-US') {
            translatedParameter = englishDataType.get(DataType[paramToTranslate]);
        }
        return translatedParameter;
    }

    public setIsLinkedField(isLinkedField: boolean): void {
        this.isLinkedField = isLinkedField;
    }

    /**
     * Set the name used for the link creation.
     * This variable is used to prevent displaying a DataNameRenamingConflict
     * when the value of the inputField matches with the name used for the creation of the link.
     *
     * @param dataNameForLinkCreation the name used for the link creation
     */
    public setDataNameForLinkCreation(dataNameForLinkCreation: string) {
        this.dataNameForLinkCreation = dataNameForLinkCreation;
    }

    /**
     * This method checks if the dataName requested by the user in the
     * input field 'inputDataName' would trigger a TypeConflict error.
     *
     * IMPORTANT: This method must be called only after the initialization
     * of the attribute 'this.dataDictionnary'
     *
     * @returns true if a DataType conflict is detected or false otherwise
     *
     */
    private hasDataTypeConflict(inputDataNameControl: AbstractControl): boolean {
        let hasDataTypeConflict = false;
        const dataNameRequested: string = inputDataNameControl.value;
        for (const dataDescriptor of this.dataDictionnary) {
            if (!hasDataTypeConflict && dataDescriptor.name === dataNameRequested) {
                const currentDataType: string = this.dynamicModification.modificationTarget.dataDescriptor.datatype;
                hasDataTypeConflict = dataDescriptor.datatype !== currentDataType;
                this.logger.debug(`DataType compatibility is ${!hasDataTypeConflict} for the name \'${dataNameRequested}\'.
         CurrentDataType = \'${currentDataType}\'; DataTypeFound = \'${dataDescriptor.datatype}\'`);
                this.dataTypeConflictParameters = `${currentDataType};${dataDescriptor.datatype}`;
            }
        }
        return hasDataTypeConflict;
    }

    /**
     * This methods checks if the dataName requested by the user in the
     * input field 'inputDataName' would trigger a 'DataDescriptorAlreadyUsedInTheStep"
     * conflict error.
     * This conflict is only for DM not linked.
     *
     * @returns true if a conflict 'alreadyUsed' is detected or false otherwise
     */
    private hasDataNameAlreadyUsedConflict(inputDataNameControl: AbstractControl): boolean {
        let hasDataNameAlreadyUsedConflict = false;
        const dataNameRequested: string = inputDataNameControl.value;

        const isOriginDataName = this.dynamicModification.modificationTarget.dataDescriptor.name === dataNameRequested;
        for (const dataDescriptor of this.dataDictionnaryOfTheStep) {
            if (!hasDataNameAlreadyUsedConflict
                && !isOriginDataName
                && dataDescriptor.name === dataNameRequested) {
                hasDataNameAlreadyUsedConflict = true;
                this.logger.debug(`DataName \'${dataNameRequested}\' is already used in the step \'${this.stepBeingEdited.name}\'.`);
            }
        }
        return hasDataNameAlreadyUsedConflict;
    }

    /**
     * This methods checks if the dataName requested by the user in the
     * input field 'inputDataName' would trigger a 'DataDescriptorRename"
     * conflict error.
     * This conflict is only for DM linked.
     *
     * @returns true if a conflict 'alreadyUsed' is detected or false otherwise
     */
    private hasDataNameRenamingConflict(inputDataNameControl: AbstractControl): boolean {
        let hasDataNameRenamingConflict = false;
        const dataNameRequested: string = inputDataNameControl.value;
        const originName = this.dynamicModification.modificationTarget.dataDescriptor.name;

        // Renaming with the origin name is only authorized if the DM was not initially linked
        const isOriginNameAuthorized: boolean = this.wasInitiallyLinked;

        const isNameDifferentFromTheOneUsedToCreateLinkAndFromOrigin =
            this.dataNameForLinkCreation !== dataNameRequested
            && originName !== dataNameRequested;

        const isTryingToRenameNewSharedDataNameWithOriginName =
            originName === dataNameRequested
            && originName !== this.dataNameForLinkCreation;
        for (const dataDescriptor of this.dataDictionnary) {
            const isNameAlreadyUsed = isNameDifferentFromTheOneUsedToCreateLinkAndFromOrigin
                && dataDescriptor.name === dataNameRequested;
            if (!hasDataNameRenamingConflict
                && (isNameAlreadyUsed || (isTryingToRenameNewSharedDataNameWithOriginName && isOriginNameAuthorized))) {
                hasDataNameRenamingConflict = true;
                this.logger.debug(`DataName \'${dataNameRequested}\' is already used.`);
            }
        }
        return hasDataNameRenamingConflict;
    }
}
