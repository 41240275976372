import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Action } from './actions/action';
import { Operation } from './actions/operations/operation';
import { OperationMessage, OperationSource } from './message-bus/message-types/operation-message';

@Injectable()
export class UndoRedoManagerService {

    protected pastActionStack: Action[];
    protected futureActionStack: Action[];
    private actionsObservables: Map<OperationSource, Subject<OperationMessage>>;
    private errorsObservables: Map<OperationSource, Subject<OperationMessage>>;

    constructor(private angularZone: NgZone) {
        this.pastActionStack = [];
        this.futureActionStack = [];
        this.actionsObservables = new Map<OperationSource, Subject<OperationMessage>>();
        this.errorsObservables = new Map<OperationSource, Subject<OperationMessage>>();
        this.initObservables();
    }

    do(action: Action) {
        // Appel des services du moteur eazly avec action
        const observableFromAppeazService: Observable<any> = action.do();
        observableFromAppeazService.subscribe(
            result => {
                // Result returned by the service is the ID of the DM
                // On success, build the reverse action
                action.buildReverseOperation(result);
                // Empty the future stack
                this.futureActionStack.length = 0;
                this.pastActionStack.push(action);
                this.notifyObservers(OperationSource.DO, action, result);
            },
            error => {
                // On fail, notify the observers
                this.notifyErrorObservers(OperationSource.DO, action, error);
            });
    }

    // Return the results of the rest call so that it can be processed
    // by the viewer
    undo() {
        if (this.pastActionStack.length !== 0) {
            const actionToPerform = this.pastActionStack.pop();
            // Appel des services du moteur eazly avec actionToPerform
            const observableFromAppeazService: Observable<any> = actionToPerform.undo();
            observableFromAppeazService.subscribe(
                result => {
                    // On success
                    this.futureActionStack.push(actionToPerform);
                    this.notifyObservers(OperationSource.UNDO, actionToPerform, result);
                },
                error => {
                    // On fail, put the action back in the stack and notify observers
                    this.pastActionStack.push(actionToPerform);
                    this.notifyErrorObservers(OperationSource.UNDO, actionToPerform, error);
                });
        }
    }

    redo() {
        if (this.futureActionStack.length !== 0) {
            const actionToPerform = this.futureActionStack.pop();
            // Appel des services du moteur eazly avec actionToPerform
            const observableFromAppeazService: Observable<any> = actionToPerform.do();
            observableFromAppeazService.subscribe(
                result => {
                    // On success, build the reverse action and store it
                    // Building the reverse action might not be required if we call the action.undo and action.redo
                    this.pastActionStack.push(actionToPerform);
                    this.notifyObservers(OperationSource.REDO, actionToPerform, result);

                },
                error => {
                    // On fail, put the action back in the stack and notify the observers
                    this.futureActionStack.push(actionToPerform);
                    this.notifyErrorObservers(OperationSource.REDO, actionToPerform, error);
                });
        }
    }

    getSubscriptionChannel(source: OperationSource): Subject<OperationMessage> {
        return this.actionsObservables.get(source);
    }

    getErrorSubscriptionChannel(source: OperationSource): Subject<OperationMessage> {
        return this.errorsObservables.get(source);
    }

    private notifyObservers(source: OperationSource, action: Action, result: any) {
        let subscribeChannel: Subject<OperationMessage> = null;
        let operation: Operation = null;
        if (OperationSource.DO === source) {
            operation = action.getOperation();
            subscribeChannel = this.actionsObservables.get(OperationSource.DO);
        }
        if (OperationSource.UNDO === source) {
            operation = action.getReverseOperation();
            subscribeChannel = this.actionsObservables.get(OperationSource.UNDO);
        }
        if (OperationSource.REDO === source) {
            operation = action.getOperation();
            subscribeChannel = this.actionsObservables.get(OperationSource.REDO);
        }
        const actionMessage: OperationMessage = new OperationMessage(source, operation, result);
        this.angularZone.run(() => {
            subscribeChannel.next(actionMessage);
        });
    }

    private initObservables() {
        const doObservable = new Subject<OperationMessage>();
        const undoObservable = new Subject<OperationMessage>();
        const redoObservable = new Subject<OperationMessage>();
        this.actionsObservables.set(OperationSource.DO, doObservable);
        this.actionsObservables.set(OperationSource.UNDO, undoObservable);
        this.actionsObservables.set(OperationSource.REDO, redoObservable);

        const errorDoObservable = new Subject<OperationMessage>();
        const errorUndoObservable = new Subject<OperationMessage>();
        const errorRedoObservable = new Subject<OperationMessage>();
        this.errorsObservables.set(OperationSource.DO, errorDoObservable);
        this.errorsObservables.set(OperationSource.UNDO, errorUndoObservable);
        this.errorsObservables.set(OperationSource.REDO, errorRedoObservable);
    }

    private notifyErrorObservers(source: OperationSource, action: Action, error: any) {
        let subscribeChannel: Subject<OperationMessage> = null;
        let operation: Operation = null;
        if (OperationSource.DO === source) {
            operation = action.getOperation();
            subscribeChannel = this.errorsObservables.get(OperationSource.DO);
        }
        if (OperationSource.UNDO === source) {
            operation = action.getReverseOperation();
            subscribeChannel = this.errorsObservables.get(OperationSource.UNDO);
        }
        if (OperationSource.REDO === source) {
            operation = action.getOperation();
            subscribeChannel = this.errorsObservables.get(OperationSource.REDO);
        }
        const actionMessage: OperationMessage = new OperationMessage(source, operation, error);
        this.angularZone.run(() => {
            subscribeChannel.next(actionMessage);
        });
    }
}
