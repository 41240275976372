/* eslint-disable max-lines */
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, NgZone, OnDestroy, Output, ViewChild, Inject, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { ModalWindowComponent } from '../../../tools/modal-window/modal-window.component';
import { DataType } from '../../../tools/models/data-type';
import { DynamicModification } from '../../../tools/models/dynamic-modification';
import { Condition } from '../../../tools/models/visibility-criteria/condition';
import { BinaryVisibilityCondition } from '../../../tools/models/visibility-criteria/conditions/binary-visibility-condition';
import { BinaryConditionType } from '../../../tools/models/visibility-criteria/conditions/binary-visibility-condition-type';
import { UnaryVisibilityCondition } from '../../../tools/models/visibility-criteria/conditions/unary-visibility-condition';
import { UnaryConditionType } from '../../../tools/models/visibility-criteria/conditions/unary-visibility-condition-type';
import { VisibilityCondition } from '../../../tools/models/visibility-criteria/conditions/visibility-condition';
import { VisibilityCriteria } from '../../../tools/models/visibility-criteria/visibility-criteria';
import { VisibilityMode } from '../../../tools/models/visibility-criteria/visibility-mode';
import { LabelTranslationService } from '../../../tools/services/i18n/label-translation.service';
import { LanguageService } from '../../../tools/services/shared/language.service';
import { VisibilityWindowManager } from './visibility-window-manager';

@Component({
  selector: 'eazly-visibility-window',
  templateUrl: './visibility-window.component.html',
  styleUrls: ['./visibility-window.component.scss']
})
export class VisibilityWindowComponent implements OnDestroy {

  @Output() onSubmitVisibilityProperties: EventEmitter<any> = new EventEmitter<any>();
  @Input() isI18N: boolean;

  @ViewChild(ModalWindowComponent, { static: true })
  private readonly modalWindowComponent: ModalWindowComponent;

  fieldName: string;
  listDynamics: DynamicModification[] = [];
  allDynamics: Map<number, DynamicModification>;
  data: any[] = [];
  subscriptions: Subscription[] = [];
  currentDynamicModif: DynamicModification;
  visibilityBoolean = false;
  visibilityButtonValue: VisibilityMode = VisibilityMode.ALWAYS_VISIBLE;
  emptyDynamics = true;
  conditions: Condition[] = [];
  visibilityConditions: VisibilityCondition[];
  secondOperandsList: string[] = [];
  emptyArgument = false;
  conditionListIsEmpty = false;
  cyclicDependency: string[] = [];
  visibilityWindowManager: VisibilityWindowManager;

  constructor(private readonly modalService: BsModalService,
    private readonly angularZone: NgZone,
    private readonly changeDetection: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly languageService: LanguageService,
    private readonly labelTranslationService: LabelTranslationService) {
    this.visibilityWindowManager = new VisibilityWindowManager();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  showModal(dynamicModif: DynamicModification, dynamics: Map<number, DynamicModification>) {
    this.modalWindowComponent.show();
    this.allDynamics = dynamics;
    this.emptyArgument = false;
    this.conditionListIsEmpty = false;
    this.conditions = [];
    this.visibilityButtonValue = VisibilityMode.ALWAYS_VISIBLE;
    this.angularZone.run(() => {
      this.currentDynamicModif = dynamicModif;
      this.handleModalWindow();
      this.populateListDynamics(dynamicModif, dynamics);
      const translatedLabel: string = this.labelTranslationService.getDMLabelForTitle(dynamicModif, this.isI18N, this.languageService.getSelectedLocale());
      this.fieldName = translatedLabel ? this.modalWindowComponent.truncateString(translatedLabel) : dynamicModif.modificationTarget.label;
      this.emptyDynamics = (this.listDynamics.length === 0);
      const visibilityCriteria: VisibilityCriteria = dynamicModif.modificationTarget.properties.visibilityCriteria;
      if (visibilityCriteria !== undefined) {
        switch (visibilityCriteria.visibilityMode) {
          case VisibilityMode[Object.keys(VisibilityMode).
            find(key => VisibilityMode[key] === VisibilityMode[VisibilityMode.ALWAYS_VISIBLE])]:
            this.visibilityButtonValue = VisibilityMode.ALWAYS_VISIBLE;
            this.visibilityBoolean = true;
            break;
          case VisibilityMode[Object.keys(VisibilityMode).
            find(key => VisibilityMode[key] === VisibilityMode[VisibilityMode.VISIBLE_IF_ANOTHER_FIELD_VISIBLE])]:
            this.visibilityButtonValue = VisibilityMode.VISIBLE_IF_ANOTHER_FIELD_VISIBLE;
            break;
          case VisibilityMode[Object.keys(VisibilityMode).
            find(key => VisibilityMode[key] === VisibilityMode[VisibilityMode.VISIBLE_IF_ALL_CONDITIONS])]:
            this.visibilityButtonValue = VisibilityMode.VISIBLE_IF_ALL_CONDITIONS;
            this.loadConditionsAndInitFields(visibilityCriteria);
            break;
          case VisibilityMode[Object.keys(VisibilityMode).
            find(key => VisibilityMode[key] === VisibilityMode[VisibilityMode.VISIBLE_IF_AT_LEAST_ONE_CONDITION])]:
            this.visibilityButtonValue = VisibilityMode.VISIBLE_IF_AT_LEAST_ONE_CONDITION;
            this.loadConditionsAndInitFields(visibilityCriteria);
            break;
          default:
            this.visibilityButtonValue = VisibilityMode.ALWAYS_VISIBLE;
            this.visibilityBoolean = true;
            break;
        }
      }
    });
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
    this.listDynamics = [];
  }

  hide() {
    this.modalWindowComponent.hide();
  }

  sendVisibilityCondition() {
    this.cyclicDependency = [];
    this.emptyArgument = false;
    const visibilityCriteria: VisibilityCriteria = new VisibilityCriteria();
    visibilityCriteria.visibilityMode = VisibilityMode[Object.keys(VisibilityMode).
      find(key => VisibilityMode[key] === VisibilityMode[this.visibilityButtonValue])];
    if (this.visibilityButtonValue === VisibilityMode.ALWAYS_VISIBLE) {
      this.emptyArgument = false;
      this.conditionListIsEmpty = false;
      this.conditions = [];
    } else {
      const visibilityCriteriaconditions: VisibilityCondition[] = [];
      visibilityCriteria.conditions = visibilityCriteriaconditions;
      this.conditions.forEach(condition => {
        const id = condition.targetDynamicMofication.id.toString();
        const dataType = DataType[Object.keys(DataType).find(key => DataType[key] === DataType[condition.dataType])];
        let visibilityCondition;
        if (Object.values(UnaryConditionType).some(val => val === condition.operator)) {
          const operator = UnaryConditionType[Object.keys(UnaryConditionType).find(key => UnaryConditionType[key] === condition.operator)];
          visibilityCondition = new UnaryVisibilityCondition(id, dataType, operator);
        } else {
          const operator = BinaryConditionType[Object.keys(BinaryConditionType).find(key => BinaryConditionType[key] === condition.operator)];
          visibilityCondition = new BinaryVisibilityCondition(id, dataType, operator);
          if (dataType === DataType[DataType.INTEGER]) {
            visibilityCondition.valueToCompare = this.getInputNumberValue(condition.id);
          } else {
            visibilityCondition.valueToCompare = condition.argument;
          }
          if (visibilityCondition.valueToCompare === '' || visibilityCondition.valueToCompare === undefined) {
            this.emptyArgument = true;
          } else {
            if (dataType === DataType[DataType.DATE]) {
              visibilityCondition.valueToCompare = visibilityCondition.valueToCompare.split('-').join('');
            }
          }
        }
        visibilityCriteria.conditions.push(visibilityCondition);
      });
      if (this.conditions.length === 0) {
        this.conditionListIsEmpty = true;
      }
    }
    if (this.visibilityButtonValue !== VisibilityMode.ALWAYS_VISIBLE) {
      const cycle: string[] = this.visibilityWindowManager.getCyclicDependency(this.currentDynamicModif, this.conditions, this.listDynamics);
      cycle.forEach(cycl => {
        this.cyclicDependency.push(this.getDynamicModificationById(+cycl).modificationTarget.label);
      });
    }
    this.data.push(visibilityCriteria);
    this.data.push(this.currentDynamicModif);
    if (this.emptyArgument === false &&
      this.conditionListIsEmpty === false &&
      this.cyclicDependency.length === 0) {
      this.onSubmitVisibilityProperties.emit(this.data);
      this.hide();
    }
    this.data = [];
  }

  appendCondition(event: Event) {
    // Preventing the event from closing the modal window
    event.preventDefault();
    // Removing the focus on the button
    (event.target as HTMLButtonElement).blur();
    this.conditionListIsEmpty = false;
    const condition = new Condition();
    condition.id = this.generateRandomId();
    condition.listOfTargetDynamicMofications = this.listDynamics;
    condition.targetDynamicMofication = condition.listOfTargetDynamicMofications[0];
    condition.listOfOperators = [];
    if (condition.listOfTargetDynamicMofications.length > 0) {
      this.conditions.push(condition);
      this.visibilityWindowManager.
        insertSupportedOperatorsByDataTypeIntoCurrentConditionModel(condition.targetDynamicMofication.modificationTarget.properties.elementType, condition);
    }
  }

  deleteCondition(conditionIndex: number) {
    this.conditions.splice(conditionIndex, 1);
  }

  refreshOperatorsList(condition: Condition) {
    this.visibilityWindowManager.
      insertSupportedOperatorsByDataTypeIntoCurrentConditionModel(condition.targetDynamicMofication.modificationTarget.properties.elementType, condition);
  }

  refreshOperandsType(condition: Condition) {
    if ((condition.targetDynamicMofication.modificationTarget.dataDescriptor.datatype ===
      DataType[Object.keys(DataType).find(key => DataType[key] === DataType[DataType.ENUM])]) &&
      condition.targetDynamicMofication.modificationTarget.values[0] !== null &&
      condition.targetDynamicMofication.modificationTarget.values[0] !== undefined) {
      // Forcing the condition to take the first value of list values to prevent the empty argument message from displaying EAZ-640
      condition.argument = condition.targetDynamicMofication.modificationTarget.values[0].id.toString();
    } else {
      condition.argument = '';
    }
    this.visibilityWindowManager.insertSupportedArgumentTypeIntoCurrentConditionModel(condition);
  }
  private getInputNumberValue(conditionId: string): string {
    const inputNumber = this.document.getElementById('ArgumentInputNumberField' + conditionId) as HTMLInputElement;
    return inputNumber.value;
  }
  private populateListDynamics(dynamicModif: DynamicModification, dynamics: Map<number, DynamicModification>) {
    dynamics.forEach(element => {
      if ((!element.modificationTarget.properties.elementType.includes('MESSAGE')) &&
        (!element.modificationTarget.properties.elementType.includes('TEXT_AREA')) &&
        (!element.modificationTarget.properties.elementType.includes('HEADER')) &&
        dynamicModif.id !== element.id) {
        this.listDynamics.push(element);
      }
    });
    this.listDynamics.sort(
      (elementA: DynamicModification, elementB: DynamicModification) =>
        elementA.modificationTarget.label.localeCompare(elementB.modificationTarget.label));
  }

  private handleModalWindow() {
    combineLatest(
      [this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden]
    ).subscribe(() => this.changeDetection.markForCheck());
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        this.cyclicDependency = [];
        this.unsubscribe();
      })
    );
  }

  private loadConditionsAndInitFields(visibilityCriteria: VisibilityCriteria) {
    for (const condition of visibilityCriteria.conditions) {
      const conditionJSON = JSON.parse(JSON.stringify(condition));
      const visibilityCondition: Condition = new Condition();
      visibilityCondition.id = this.generateRandomId();
      visibilityCondition.listOfTargetDynamicMofications = this.listDynamics;
      if (Object.values(UnaryConditionType).some(val => val === conditionJSON.operator)) {
        const unaryCondition: UnaryVisibilityCondition = condition as UnaryVisibilityCondition;
        visibilityCondition.dataType = unaryCondition.dataType;
        visibilityCondition.operator = UnaryConditionType[Object.keys(UnaryConditionType).find(key => UnaryConditionType[key] === conditionJSON.operator)];
        if (this.getDynamicModificationById(+unaryCondition.modificationId).id !== undefined) {
          visibilityCondition.targetDynamicMofication = this.getDynamicModificationById(+unaryCondition.modificationId);
          this.visibilityWindowManager.
            insertSupportedOperatorsByDataTypeIntoCurrentConditionModel(visibilityCondition.targetDynamicMofication.modificationTarget.properties.elementType,
              visibilityCondition);
          this.conditions.push(visibilityCondition);
        }
      }
      if (Object.values(BinaryConditionType).some(val => val === conditionJSON.operator)) {
        const binaryCondition: BinaryVisibilityCondition = condition as BinaryVisibilityCondition;
        visibilityCondition.dataType = binaryCondition.dataType;
        visibilityCondition.operator = BinaryConditionType[Object.keys(BinaryConditionType).find(key => BinaryConditionType[key] === conditionJSON.operator)];
        visibilityCondition.argument = binaryCondition.valueToCompare;
        if (binaryCondition.dataType === DataType[Object.keys(DataType).find(key => DataType[key] === DataType[DataType.DATE])]) {
          visibilityCondition.argument = visibilityCondition.argument.substring(0, 4) + '-' +
            visibilityCondition.argument.substring(4, 6) + '-' + visibilityCondition.argument.substring(6);
        }
        if (this.getDynamicModificationById(+binaryCondition.modificationId).id !== undefined) {
          visibilityCondition.targetDynamicMofication = this.getDynamicModificationById(+binaryCondition.modificationId);
          this.visibilityWindowManager.
            insertSupportedOperatorsByDataTypeIntoCurrentConditionModel(visibilityCondition.targetDynamicMofication.modificationTarget.properties.elementType,
              visibilityCondition);
          this.conditions.push(visibilityCondition);
        }
      }
    }
    if (this.conditions.length === 0) {
      this.visibilityButtonValue = VisibilityMode.ALWAYS_VISIBLE;
    }
  }

  private getDynamicModificationById(id: number): DynamicModification {
    let dynamicModif: DynamicModification = new DynamicModification();
    this.allDynamics.forEach(element => {
      if (element.id === id) {
        dynamicModif = element;
      }
    });
    return dynamicModif;
  }

  private generateRandomId() {
    return (Math.random() * 1e32).toString(36);
  }

  get visibilityMode() { return VisibilityMode; }
}
