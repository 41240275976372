<div [hidden]="!isDelayedRunning" class="in modal-backdrop spinner-overlay">

  <div class="spinner-message-container" aria-live="assertive" aria-atomic="true">
    <div class="spinner-message">
      <i class="fas fa-spinner fa-spin fa-2x"></i>
      <span i18n="@@LoadingWait">Please wait...</span>
    </div>

  </div>
</div>
