<eazly-modal-window>
  <div modalWindowHeader id="config-title" class="modal-title" i18n="@@ListValueEditorWindowInputTitle">
    Values of the list "{{listValueName}}"
  </div>
  <div modalWindowBody>
    <div *ngFor="let alert of alerts" class="alert alert-info alert-dismissible fade show information-message-style"
      role="alert">
      <span *ngIf="alert.messageCause === messageCause.DeletionOfListValueItemWhichWasUsedInCondition">
        <span id="DeletionOfListValueItemWhichWasUsedInCondition"
          i18n='@@DeletionOfListValueItemWhichWasUsedInCondition'>
          The value <strong> {{alert.origin}} </strong> will be deleted after clicking on the submit button.
          <p class="p-style-error-message">
            However, this value is used in the visibility conditions of the following
            fields:<strong> {{alert.message}}</strong>.
          </p>
          <p class="p-style-error-message">
            These conditions will be automatically removed. You can abort these deletions by clicking on the cancel
            button.
          </p>
        </span>
      </span>
      <span *ngIf="alert.messageCause === messageCause.DeletionOfListValueItemWhichWasnDefaultValue">
        <span id="DeletionOfListValueItemWhichWasnDefaultValue" i18n='@@DeletionOfListValueItemWhichWasnDefaultValue'>
          The value <strong> {{alert.origin}} </strong> will be deleted after clicking on the submit button.
          <p class="p-style-error-message">
            However, this value is the default value.
          </p>
          <p class="p-style-error-message">
            This value will be automatically removed. You can abort this deletion by clicking on the cancel
            button.
          </p>
        </span>
      </span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form id="listValueForm" (ngSubmit)="updateListValue()" #f="ngForm" [formGroup]="listValueForm" novalidate>
      <ol id="eazly-ul-values{{dynamicId}}" class="eazly-ul-values" cdkDropList (cdkDropListDropped)="onDrop($event)">
        <div *ngFor="let value of values; let i = index" cdkDrag class="div-element-drag">
          <li id="eazly-li-value-{{i}}" class="eazly-li-value">
            <div class="row value-list-row">
              <div class="col-sm-10">
                <input id="eazly-li-value-{{i}}-input" formControlName="{{i}}"
                  class="input-argument-visbility-Modal form-control" attr.data-idValue="{{getValueId(value)}}"
                  value="{{value | listValueLabelTranslationPipe}}">
              </div>
              <div class="col-sm-1 eazly-up-down-icon-style">
                <div class="eazlyIconBtnDD" cdkDragHandle>
                  <i class="fas fa-arrows-alt-v"></i>
                </div>
              </div>
              <div class="col-sm-1 eazly-trash-icon-style" (click)="deleteValue(i,value.id)">
                <div class="eazlyIconBtn">
                  <i class="fas fa-trash-alt" id="eazly-li-delete-{{dynamicId}}"></i>
                </div>
              </div>
            </div>
          </li>
        </div>
        <div class="row add-new-value-row value-list-row">
          <div class="col-sm-10 add-new-value-input-style">
            <input id="eazly-li-add-value-input" (click)="createNewValue()" readonly="true"
              placeholder="Click here to add a value" i18n-placeholder="@@ListValueEditorWindowInputAddValue"
              class="options-name-input  form-control">
          </div>
        </div>
      </ol>
    </form>
  </div>
  <div modalWindowFooter>
    <button id="CancelModalWindow" (click)="hide()" type="button" class="btn eazlybtn btn-lg" i18n="@@Cancel">
      <i class="fas fa-arrow-left"></i>
      Cancel
    </button>
    <button id="SubmitModalWindow" type="submit" form="listValueForm" class="btn eazlybtn btn-lg"
      i18n="@@SubmitModalWindow">
      <i class="fas fa-check"></i>
      Submit
    </button>
  </div>
</eazly-modal-window>
