import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RatingModule } from 'ngx-bootstrap/rating';
import { AppRoutingModule } from '../../eazly.routing';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../other/footer/footer.module';
import { LoadingModule } from '../other/loading/loading.module';
import { AlertManagerModule } from '../tools/alert-manager/alert-manager.module';
import { AccountComponent } from './account.component';

@NgModule({
    declarations: [
        AccountComponent],
    imports: [
        CommonModule, RouterModule,
        AppRoutingModule,
        FormsModule,
        HeaderModule,
        ReactiveFormsModule,
        AlertManagerModule,
        FooterModule, LoadingModule,
        AlertModule.forRoot(),
        RatingModule.forRoot()
    ],
    bootstrap: [AccountComponent]
})
export class AccountModule { }
