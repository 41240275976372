import { DataType } from '../../data-type';
import { BinaryVisibilityCondition } from './binary-visibility-condition';
import { BinaryConditionType } from './binary-visibility-condition-type';
import { UnaryVisibilityCondition } from './unary-visibility-condition';
import { UnaryConditionType } from './unary-visibility-condition-type';
import { VisibilityCondition } from './visibility-condition';

// This class is responsible for the listing of compatible visibilityConditions depending on the dataType
export class VisibilityConditionManager {

    getSupportedVisibilityConditions(dataType: DataType): VisibilityCondition[] {
        const supportedVisibilityConditions: VisibilityCondition[] = [];
        const unaryConditionsType: UnaryConditionType[] = this.getSupportedUnaryConditionsType(dataType);
        const binaryConditionsType: BinaryConditionType[] = this.getSupportedBinaryConditionsType(dataType);
        for (const unaryConditionType of unaryConditionsType) {
            const unaryVisibilityCondition = new UnaryVisibilityCondition('', dataType, unaryConditionType);
            supportedVisibilityConditions.push(unaryVisibilityCondition);
        }
        for (const binaryConditionType of binaryConditionsType) {
            const binaryVisibilityCondition = new BinaryVisibilityCondition('', dataType, binaryConditionType);
            supportedVisibilityConditions.push(binaryVisibilityCondition);
        }
        return supportedVisibilityConditions;
    }

    private getSupportedUnaryConditionsType(dataType: DataType): UnaryConditionType[] {
        let supportedConditionsType: UnaryConditionType[] = [];
        switch (dataType) {
            case DataType.BOOLEAN:
                supportedConditionsType.push(
                    UnaryConditionType.IS_FALSE,
                    UnaryConditionType.IS_TRUE,
                    UnaryConditionType.IS_NOT_NULL,
                    UnaryConditionType.IS_NULL);
                break;
            case DataType.DATE:
            case DataType.DOUBLE:
            case DataType.ENUM:
            case DataType.INTEGER:
            case DataType.STRING:
                supportedConditionsType.push(
                    UnaryConditionType.IS_NOT_NULL,
                    UnaryConditionType.IS_NULL);
                break;
            default:
                supportedConditionsType = [];
                break;
        }
        return supportedConditionsType;
    }

    private getSupportedBinaryConditionsType(dataType: DataType): BinaryConditionType[] {
        let supportedConditions: BinaryConditionType[] = [];
        switch (dataType) {
            case DataType.BOOLEAN:
                break;
            case DataType.DATE:
                supportedConditions.push(
                    BinaryConditionType.LESS_THAN_DATE,
                    BinaryConditionType.GREATER_THAN_DATE);
                break;
            case DataType.DOUBLE:
            case DataType.INTEGER:
                supportedConditions.push(
                    BinaryConditionType.IS_EQUAL,
                    BinaryConditionType.IS_NOT_EQUAL,
                    BinaryConditionType.IS_LESS_THAN,
                    BinaryConditionType.IS_LESS_OR_EQUAL_THAN,
                    BinaryConditionType.IS_GREATER_THAN,
                    BinaryConditionType.IS_GREATER_OR_EQUAL_THAN);
                break;
            case DataType.ENUM:
                supportedConditions.push(
                    BinaryConditionType.IS_EQUAL,
                    BinaryConditionType.IS_NOT_EQUAL);
                break;
            case DataType.STRING:
                supportedConditions.push(
                    BinaryConditionType.MATCHES,
                    BinaryConditionType.NOT_CONTAINS,
                    BinaryConditionType.IS_EQUAL,
                    BinaryConditionType.IS_NOT_EQUAL,
                    BinaryConditionType.STARTS_WITH,
                    BinaryConditionType.ENDS_WITH);
                break;
            default:
                supportedConditions = [];
                break;
        }
        return supportedConditions;
    }
}
