// eslint-disable-next-line max-lines
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Output, EventEmitter, ViewChild, NgZone, Input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { ModalWindowComponent } from '../../../tools/modal-window/modal-window.component';
import { DataType } from '../../../tools/models/data-type';
import { DynamicModification } from '../../../tools/models/dynamic-modification';
import { DynamicModificationProperties } from '../../../tools/models/dynamic-modification-properties';
import { DynamicModificationTarget } from '../../../tools/models/dynamic-modification-target';
import { DynamicModificationValueList } from '../../../tools/models/dynamic-modification-value';
import { BinaryVisibilityCondition } from '../../../tools/models/visibility-criteria/conditions/binary-visibility-condition';
import { VisibilityCondition } from '../../../tools/models/visibility-criteria/conditions/visibility-condition';
import { LabelTranslationService } from '../../../tools/services/i18n/label-translation.service';
import { AlertType, EazlyMessage, MessageCause } from '../../../tools/services/messaging/eazly-message';
import { LanguageService } from '../../../tools/services/shared/language.service';

@Component({
  selector: 'eazly-list-value-editor-window',
  templateUrl: './list-value-editor-window.component.html',
  styleUrls: ['./list-value-editor-window.component.scss']
})

export class ListValueEditorWindowComponent implements OnInit {

  @Output() onSubmitListValue: EventEmitter<DynamicModification[]> = new EventEmitter<DynamicModification[]>();
  @Input() idValue: string;
  @Input() isI18N: boolean;

  @ViewChild(ModalWindowComponent, { static: true })
  private readonly modalWindowComponent: ModalWindowComponent;

  dynamicId: number;
  listValueForm: FormArray = new FormArray([]);
  listValueName: string;
  values: DynamicModificationValueList[] = [];
  deletedValues: DynamicModificationValueList[] = [];
  dynamics = new Map<number, DynamicModification>();
  alerts: EazlyMessage[];
  messageCause = MessageCause;

  constructor(private readonly angularZone: NgZone,
    private readonly labelTranslationService: LabelTranslationService,
    private readonly languageService: LanguageService) {
    this.alerts = [];
  }

  public onDrop(event: CdkDragDrop<DynamicModificationValueList[]>): void {
    if (event.currentIndex !== event.previousIndex) {
      if (event.previousContainer === event.container) {
        // Reorder within the same container
        moveItemInArray(this.values, event.previousIndex, event.currentIndex);
        moveItemInArray(this.listValueForm.controls, event.previousIndex, event.currentIndex);
      } else {
        // Remove from previous container and add to new container
        this.listValueForm.removeAt(event.previousIndex);
      }
    }
  }

  ngOnInit() {
  }

  public hide(): void {
    this.modalWindowComponent.hide();
  }

  public show(dynamicModification: DynamicModification, dynamics: Map<number, DynamicModification>): void {
    this.angularZone.run(() => {
      this.alerts = [];
      this.dynamics = dynamics;
      const translatedLabel: string = this.labelTranslationService.getDMLabelForTitle(
        dynamicModification, this.isI18N, this.languageService.getSelectedLocale());
      this.listValueName = translatedLabel ? this.modalWindowComponent.truncateString(translatedLabel) : dynamicModification.modificationTarget.label;
      this.dynamicId = dynamicModification.id;
      this.loadValues(dynamicModification);
      this.modalWindowComponent.show();
    });
  }

  /**
  * This methods is called when clicking on the submit button.
  * A new DynamicModification containing the new values for
  * the list is sent to the viewer through the EventEmitter "onSubmitListValue".
  */
  public updateListValue(): void {
    // Initializing a DM with the new values to set
    const dynamicModificationSpecifyingNewValues: DynamicModification = this.initDMWithTheNewValues();
    const dynamicModificationsToSend: DynamicModification[] = [dynamicModificationSpecifyingNewValues];
    this.onSubmitListValue.emit(dynamicModificationsToSend);
    this.modalWindowComponent.hide();
  }

  public createNewValue(): void {
    const newValue: DynamicModificationValueList = new DynamicModificationValueList(this.values.length, '');
    this.values.push(newValue);
    this.listValueForm.setControl(newValue.index, new FormControl(newValue.value));
    this.angularZone.runOutsideAngular(() => {
      setTimeout(() => {
        document.getElementById(`eazly-li-value-${newValue.index}-input`).focus();
      }, 0);
    });
  }

  public deleteValue(index: number, valueId: number): void {
    let modificationTarget: DynamicModificationTarget;
    this.dynamics.forEach(dynamic => {
      if (dynamic.id === this.dynamicId) {
        modificationTarget = dynamic.modificationTarget;
      }
    });
    const deletedElement: DynamicModificationValueList[] = this.values.splice(index, 1);
    if (valueId !== undefined && valueId !== null && modificationTarget?.properties?.defaultValue !== undefined
      && modificationTarget.properties.defaultValue === valueId.toString()) {
      //show info message before deleting the default value
      const infoMessage = new EazlyMessage(AlertType.info, deletedElement[0].value
        , MessageCause.DeletionOfListValueItemWhichWasnDefaultValue, '');
      this.alerts.push(infoMessage);
    }
    this.deletedValues.push(deletedElement[0]);
    this.listValueForm.removeAt(index);
    this.updateValuesIndex();
    if (valueId !== undefined && valueId !== null) {
      this.displayInformationMessageIdDeletedValueUsedToDefineVisbilityRule(valueId, deletedElement[0]);
    }
  }

  public getValueId(value: DynamicModificationValueList): string {
    let idToReturn = '';
    if (value.id !== undefined) {
      idToReturn = value.id.toString();
    }
    return idToReturn;
  }

  /**
   * Display an information message indicating that the deleted value was used as a condition in a visibility rule
   * @param listValueItem This parameter is the id of the value
   */
  private displayInformationMessageIdDeletedValueUsedToDefineVisbilityRule(listValueItem: number, deletedElement: DynamicModificationValueList) {
    const allReferencedDynamics = this.constructTheListOfDynamicsWhichesUseThisDeletedValue(listValueItem);
    if (allReferencedDynamics.length > 0) {
      const errorMessage = new EazlyMessage(AlertType.info, deletedElement.value
        , MessageCause.DeletionOfListValueItemWhichWasUsedInCondition, allReferencedDynamics);
      this.alerts.push(errorMessage);
    }
  }

  /**
   * This method used to consctruct a sting containing all dynamic modifications whiches use the deleted
   * value value to define their visibility rules
   * e.g of the result : Radio, Message, Text Field
   * @param listValueItem This parameter is the id of the value
   */
  private constructTheListOfDynamicsWhichesUseThisDeletedValue(listValueItem: number): string {
    let allReferencedDynamics = '';
    this.dynamics.forEach(dynamic => {
      // run through all dynamics
      // If a dynamic modification contains visbility conditions then execute the next code
      // Else go to next dynamic modification
      if (dynamic.modificationTarget.properties.visibilityCriteria !== undefined &&
        dynamic.modificationTarget.properties.visibilityCriteria.conditions !== undefined) {
        // run through all conditions of current dynamic
        for (const condition of dynamic.modificationTarget.properties.visibilityCriteria.conditions) {
          let isReferencedDynamic = false;
          // Check if this deleted item is used to define visibility of other fields
          // If the current codition based on enumeration then execute the next code
          // Else go to next condition
          if (listValueItem !== null && (condition.dataType === DataType[Object.keys(DataType).find(key => DataType[key] === DataType[DataType.ENUM])])) {
            //Call checkIfCurrentConditionBasedOnThisDeletedValue to check if the current condition
            // is based on this deleted item and concatenate this dynamic label to the all referenced
            // dynamic
            const newListOfDynamics = this.checkIfCurrentConditionBasedOnThisDeletedValue(condition,
              listValueItem, allReferencedDynamics, dynamic);
            // This boolean become true if old list of dynamics is different from the new constructed string
            // If the two strings are different than this current dynamic is referenced
            isReferencedDynamic = (allReferencedDynamics.length !== newListOfDynamics.length);
            // Old dynamics list takes the new value
            allReferencedDynamics = newListOfDynamics;
          }
          //Break if at least one condition of this dynamic modification is based on this deleted value
          if (isReferencedDynamic) {
            break;
          }
        }
      }
    });
    return allReferencedDynamics;
  }
  /**
   * This method check if current condition is based on the deleted value
   *  and construct the string which contains referenced dynamic modifications
   *
   * @param condition Current condition
   * @param listValueItem This parameter is the id of the value
   * @param allReferencedDynamics Old list of dynamics string
   * @param dynamic Current dynamic
   */
  private checkIfCurrentConditionBasedOnThisDeletedValue(condition: VisibilityCondition,
    listValueItem: number, allReferencedDynamics: string, dynamic: DynamicModification): string {
    let referencedDynamicsList = allReferencedDynamics;
    const binary = condition as BinaryVisibilityCondition;
    // check if the value to compare of this current condition equals to the deleted value
    if (binary.valueToCompare !== undefined &&
      binary.valueToCompare !== null &&
      binary.valueToCompare === listValueItem.toString()) {
      // construct string of all referenced dynamics to display in the message
      referencedDynamicsList = this.concatenateListDynamicsString(referencedDynamicsList, dynamic);
    }
    return referencedDynamicsList;
  }
  /**
   * This method called to concatenate current referenced dynamic to list of all referenced dynamics
   *
   * @param referencedDynamicsList list of referenced dynamics
   * @param dynamic Current dynamic modification
   */
  private concatenateListDynamicsString(referencedDynamicsList: string, dynamic: DynamicModification): string {
    let listDynamicsString = '';
    if (referencedDynamicsList.length > 0) {
      listDynamicsString = `${referencedDynamicsList}, ${this.dynamics.get(dynamic.id).modificationTarget.label}`;
    } else {
      listDynamicsString = this.dynamics.get(dynamic.id).modificationTarget.label;
    }
    return listDynamicsString;
  }

  private loadValues(dynamicModification: DynamicModification): void {
    this.values = dynamicModification.modificationTarget.values;
    this.initInputValueBinding();
  }

  // Initializing the formControls to bind input values with their values in the variable `this.values` using formControlName
  private initInputValueBinding(): void {
    let i = 0;
    for (const value of this.values) {
      if (!this.listValueForm.get(value.index.toString()) !== null) {
        let inputValue: string = value.value;
        if (this.isI18N) {
          inputValue = this.labelTranslationService.getLabelForLocaleAndCountryCode(
            this.languageService.getSelectedLocale(), value.labelTranslations, value.value);
        }
        this.listValueForm.setControl(value.index, new FormControl(inputValue));
      }
      i++;
    }
  }

  /**
   * This method update the index of the values depending on their position in the array this.values
   */
  private updateValuesIndex(): void {
    let i = 0;
    for (const value of this.values) {
      value.index = i;
      i++;
    }
  }

  /**
   * This method initialize a DM containing the new values to send to the eazly engine
   */
  private initDMWithTheNewValues(): DynamicModification {
    const dynamicModificationSpecifyingNewValues: DynamicModification = new DynamicModification();
    dynamicModificationSpecifyingNewValues.id = this.dynamicId;
    dynamicModificationSpecifyingNewValues.modificationTarget = new DynamicModificationTarget();
    dynamicModificationSpecifyingNewValues.modificationTarget.properties = new DynamicModificationProperties();

    // Retrieving values from the inputs
    for (let i = 0; i < this.values.length; i++) {
      this.values[i].index = i;
      const value: string = this.getValue(this.values[i].index.toString());

      if (!this.isI18N || this.languageService.isDefaultLocaleSelected()) {
        this.values[i].value = value;
      }
      if (this.isI18N) {
        this.labelTranslationService.setLabelTranslation(
          this.languageService.getSelectedLocale(), value, this.values[i].labelTranslations);
      }
    }
    dynamicModificationSpecifyingNewValues.modificationTarget.values = this.values;
    return dynamicModificationSpecifyingNewValues;
  }

  /**
   * This method retrieve the values from the input form
   * @param inputFormName name of the inputForm to retrieve
   */
  private getValue(inputFormName: string): string {
    return this.listValueForm.get(inputFormName).value;
  }
}
