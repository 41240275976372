import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppeazService } from '../tools/services/appeaz.service';
import { MessagingService } from '../tools/services/messaging/messaging.service';
import { UserService } from '../tools/services/user.service';
import { BaseAuthenticationGuard } from './guard/base-authentication-guard';

@Injectable()
export class AuthenticatedManagerGuard extends BaseAuthenticationGuard {

    constructor(protected appeazService: AppeazService,
        protected messagingService: MessagingService,
        protected userService: UserService,
        protected router: Router
    ) {
        super(appeazService, messagingService, userService, router, ['Manager']);
    }


    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.userService.getCurrentUser() && this.userService.isAdmin()) {
            return true;
        } else if (this.userService.getCurrentUser()) {
            return super.canActivate(next, state);
        } else {
            // The user is not logged. Redirecting to login page
            this.router.navigate(['/login']);
            return false;
        }
    }

}
