<header class="header">

  <div id="appeaz-menu">
    <a routerLink="/appeaz">
      <button class="btn" type="button" id="quick-menu-button" aria-haspopup="true" aria-expanded="false"
        tooltip="My Appeazs" i18n-tooltip="@@MenuMyappeazs" placement="right">
        <img src="assets/logo-eazly-no-text.svg">
      </button>
    </a>
    <div *ngIf="myappeaz" class="header-button">
      <a *ngIf="displayPublishButton" routerLink="/publish/{{myappeaz.id}}" type="button" class="btn btn-primary btn-lg"
        tooltip="Publish" placement="right" i18n="@@MyAppeazsPublish"> <i class="fas fa-cloud-upload-alt"></i>
      </a>
    </div>
    <div *ngIf="myappeaz" class="header-button">
      <a *ngIf="canEdit" routerLink="/editor/{{myappeaz.id}}" type="button" class="btn btn-primary btn-lg"
        tooltip="Studio" placement="right" i18n="@@MyAppeazsStudio">
        <i class="fas fa-pencil-alt"></i> </a>
    </div>
  </div>
  <div *ngIf="isConfiguration">
    <a routerLink="/dashboard">
      <button class="btn" type="button" aria-haspopup="true" aria-expanded="false" placement="right">
        <i class="fas fa-cogs"></i>
      </button>
    </a>
  </div>

  <!--div id="helpPublish">
    <a *ngIf="isPublisher" id="help-publish-menu" tooltip="This interface allow making available last dynamic modifications added by Eazly studio in the e-Citiz application."
      i18n-tooltip="@@MyAppeazPublisherHelpeMenu" placement="bottom">
      <i class="fas fa-question fa-lg"></i>
    </a>

  </div-->

  <div id="title">
    <h1 *ngIf="headerText">{{headerText}}</h1>
    <h1 *ngIf="myappeaz">{{myappeaz.name}}</h1>
    <h5 *ngIf="selectedStepName">{{selectedStepName}}</h5>
    <h1 *ngIf="manageMyAccount">{{manageMyAccount}}</h1>
    <h1 *ngIf="dashboard">{{dashboard}}</h1>
  </div>



  <div id="user-menu" class="dropstart" tooltip="User" i18n-tooltip="@@userMenu" placement="left">

    <button class="btn dropdown-toggle" type="button" id="user-menu-button" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <i class="fas fa-user" aria-hidden="true"></i>
    </button>

    <div class="dropdown-menu" aria-labelledby="user-menu-button">
      <div class="dropdown-item">
        <a routerLink="/profile" class="link link-primary" [routerLinkActive]="['active']">
          <i class="far fa-user"></i>
          <span i18n="@@MenuMyAccount">Manage my account</span>
        </a>
      </div>
      <div class="dropdown-divider"></div>
      <div class="dropdown-item">
        <a (click)="logout()" href class="link link-secondary" [routerLinkActive]="['active']">
          <i class="fas fa-sign-out-alt"></i>
          <span i18n="@@MenuLogout">Log out</span>
        </a>
      </div>
    </div>
  </div>
</header>
