<footer role="contentinfo" class="container d-flex justify-content-between align-items-center">
  <div class="d-flex justify-content-start">
    <ul class="list-unstyled d-flex flex-wrap">
      <li class="mr">
        <a href="https://www.softeamgroup.fr">Softeam</a> &copy; {{year}}</li>
    </ul>
  </div>

  <img id="ecitiz-logo" src="assets/logo-eazly.svg" alt="Logo e-Citiz" />

  <div class="d-flex justify-content-end">
    <ul class="list-unstyled d-flex flex-wrap">
      <li class="mr">
        <a i18n-href="@@FooterPrivacyURL" href="https://en.e-citiz.com/privacy-policy-eazly/" i18n="@@FooterPrivacy">
          Privacy
        </a>
      </li>
      <li class="mr">
        <a i18n-href="@@FooterContactURL" href="https://en.e-citiz.com/contact/" i18n="@@FooterContact">
          Contact
        </a>
      </li>
      <li class="mr">
        <a routerLink="/about" i18n="@@FooterAbout">
          About
        </a>
      </li>
    </ul>
  </div>
</footer>
