<eazly-header [displayPublishButton]='false' [myappeaz]="myappeaz" canEdit="true"></eazly-header>



<div class="main container">
  <h2 i18n="@@TitlePublish">Publishing</h2>

  <eazly-environement-dashboard *ngIf="ready | async" [myappeaz]="myappeaz">
  </eazly-environement-dashboard>


</div>
