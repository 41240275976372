<div id="import-export" class="import-export-arrows">
    <span class="arrow-icon" tooltip="Import pages" i18n-tooltip="@@ImportTooltip" placement="bottom">
        <label class="label-import-export-file toolbar-item" for="import-file">
            <em class="fas fa-upload"></em>
        </label>
        <input (change)="handleImportButtonClicked($event)" id="import-file" class="import-file-input" type="file"
            accept=".json">
    </span>
    <span class="arrow-icon" tooltip="Export pages" i18n-tooltip="@@ExportTooltip" placement="bottom" (click)="handleExportButtonClicked()">
        <label class="label-import-export-file toolbar-item">
            <em class="fas fa-download" id="export-btn"
                [ngClass]="{'active': exportBtnActive == true}"></em>
        </label>
    </span>
</div>
<eazly-import-window [myappeaz]="myappeaz"></eazly-import-window>
