import { Injectable } from '@angular/core';
import { DataDescriptor } from '../models/data-descriptor';
import { DynamicModification } from '../models/dynamic-modification';
import { DynamicModificationProperties } from '../models/dynamic-modification-properties';
import { DynamicModificationTarget } from '../models/dynamic-modification-target';


@Injectable()
export class DynamicModificationService {
    constructor() { }

    createDataDescriptor(datatype: string, name: string): DataDescriptor {
        const dataDescriptor = new DataDescriptor();
        dataDescriptor.name = name;
        dataDescriptor.datatype = datatype;
        return dataDescriptor;
    }

    createDynamicModificationTargetWithoutDataDescriptor(label: string, properties: DynamicModificationProperties): DynamicModificationTarget {
        const dynamicModificationTarget = new DynamicModificationTarget();
        dynamicModificationTarget.label = label;
        //Avoiding the problem of null infoText
        dynamicModificationTarget.infoText = '';
        dynamicModificationTarget.properties = properties;
        return dynamicModificationTarget;
    }

    createDynamicModificationTarget(label: string, properties: DynamicModificationProperties,
        dataDescriptor: DataDescriptor): DynamicModificationTarget {
        const dynamicModificationTarget = this.createDynamicModificationTargetWithoutDataDescriptor(label, properties);
        dynamicModificationTarget.dataDescriptor = dataDescriptor;
        return dynamicModificationTarget;
    }
    createDynamicModificationProperties(elementType: string): DynamicModificationProperties {
        const dynamicModificationProperties = new DynamicModificationProperties();
        dynamicModificationProperties.elementType = elementType;
        return dynamicModificationProperties;
    }
    createDynamicModification(
        id: number,
        anchorReference: string,
        priorityOrder: number,
        modificationTarget: DynamicModificationTarget,
        modificationType: string): DynamicModification {
        const dynamicModification = new DynamicModification();
        dynamicModification.id = id;
        dynamicModification.anchorReference = anchorReference;
        dynamicModification.priorityOrder = priorityOrder;
        dynamicModification.modificationTarget = modificationTarget;
        dynamicModification.modificationType = modificationType;
        switch (modificationTarget.properties.elementType) {
            case 'COMBO_BOX':
            case 'RADIO_LIST_VERTICAL':
            case 'RADIO_LIST_HORIZONTAL':
                // Initializing an empty array for enum dataType (see FieldManagement.getDefaultDatatype() in the engine)
                dynamicModification.modificationTarget.values = [];
                break;
            default:
        }

        return dynamicModification;
    }
}
