<div class="undo-redo-group">
  <div class="btn-group">
    <!--Disabling button with `disabled` and disabling tooltip with `isDisabled`-->
    <button id="undoButton" #undoButton (click)="undo()" [disabled]="!undoable" [isDisabled]="!undoable" type="button"
      class="btn btn-primary toolbar-item" [tooltip]="undoTooltip" container="body" i18n-tooltip="@@UndoButton"
      placement="bottom">
      <i class="fas fa-undo"></i>
    </button>

    <!--------------------------------------------------->
    <!-- COMMENTED WHILE THE IMPLEMENTATION IS MISSING -->
    <!--------------------------------------------------->
    <!-- 
      <div class="dropdown">
      <button id="undoHistory" [disabled]="!undoable" type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      </button>
      <div class="dropdown-menu" aria-labelledby="undo-history-button">
        <a *ngFor="let operation of pastOperationStack.reverse()" class="dropdown-item">
          <span *ngIf="operation.operationType === operationType.ADD_DM" id="UndoAdding" i18n='@@UndoAdding'>
            Undo the adding of
          </span>
          <span *ngIf="operation.operationType === operationType.DELETE_DM" id="UndoDeleting" i18n='@@UndoDeleting'>
            Undo the deletion of
          </span>
          <span *ngIf="operation.operationType === operationType.EDIT_DM" id="UndoEditing" i18n='@@UndoEditing'>
            Undo the edition of
          </span>
          {{operation.name}}
        </a>
      </div>
    </div> 
  -->
  </div>

  <div class="btn-group">
    <!--Disabling button with `disabled` and disabling tooltip with `isDisabled`-->
    <button id="redoButton" #redoButton (click)="redo()" [disabled]="!redoable" [isDisabled]="!redoable" type="button"
      class="btn btn-primary toolbar-item" [tooltip]="redoTooltip" container="body" i18n-tooltip="@@RedoButton"
      placement="bottom">
      <i class="fas fa-redo"></i>
    </button>

    <!--------------------------------------------------->
    <!-- COMMENTED WHILE THE IMPLEMENTATION IS MISSING -->
    <!--------------------------------------------------->
    <!--
    <div class="dropdown">
      <button id="redoHistory" [disabled]="!redoable" type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      </button>
      <div class="dropdown-menu" aria-labelledby="redo-history-button">
        <a *ngFor="let operation of futureOperationStack.reverse()" class="dropdown-item">
          <span *ngIf="operation.operationType === operationType.DELETE_DM" id="RedoAdding" i18n='@@RedoAdding'>
            Redo the adding of
          </span>
          <span *ngIf="operation.operationType === operationType.ADD_DM" id="RedoDeleting" i18n='@@RedoDeleting'>
            Redo the deletion of
          </span>
          <span *ngIf="operation.operationType === operationType.EDIT_DM" id="RedoEditing" i18n='@@RedoEditing'>
            Redo the edition of
          </span>
          {{operation.name}}
        </a>
      </div>
    </div>
    -->
  </div>
</div>

<!-- TEMPLATES FOR TOOLTIP LOCALISATION-->
<ng-template #undoTooltip>
  <span *ngIf="undoOperation === operationType.ADD_DM" id="UndoAddingTooltip" i18n='@@UndoAddingTooltip'>
    Undo the adding of
  </span>
  <span *ngIf="undoOperation === operationType.DELETE_DM" id="UndoDeletingTooltip" i18n='@@UndoDeletingTooltip'>
    Undo the deletion of
  </span>
  <span *ngIf="undoOperation === operationType.EDIT_DM" id="UndoEditingTooltip" i18n='@@UndoEditingTooltip'>
    Undo the edition of
  </span>
  <span *ngIf="undoOperation === operationType.IMPORT" id="UndoImportTooltip" i18n='@@UndoImportTooltip'>
    Undo the import of the file
  </span>
  {{undoLabel}}
</ng-template>
<ng-template #redoTooltip>
  <span *ngIf="redoOperation === operationType.DELETE_DM" id="RedoAddingTooltip" i18n='@@RedoAddingTooltip'>
    Redo the adding of
  </span>
  <span *ngIf="redoOperation === operationType.ADD_DM" id="RedoDeletingTooltip" i18n='@@RedoDeletingTooltip'>
    Redo the deletion of
  </span>
  <span *ngIf="redoOperation === operationType.EDIT_DM" id="RedoEditingTooltip" i18n='@@RedoEditingTooltip'>
    Redo the edition of
  </span>
  <span *ngIf="redoOperation === operationType.IMPORT" id="RedoImportTooltip" i18n='@@RedoImportTooltip'>
    Redo the import of the file
  </span>
  {{redoLabel}}
</ng-template>
