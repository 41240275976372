import { Editor, EditorEvent } from '../../../../assets/libraries/eazlytinymce/js/eazlytinymce/eazlytinymce.min.js';


/**
 * EAZ-716
 * Based on a modified version of TinyMCE 5.10 to avoid JS loading conflicts
 * if the wysiwyg preview already has a static TinyMCE (the modification of TinyMCE carried out is a renaming of the tinymce export variable to eazlyTinyMce)
 *
 * Access to TinyMCE functions is therefore done with this.iframeContentWindow.eazlyTinyMce
 * (eazlyTinyMce is loaded in the iframe and not the Studio editor window eazly)
 *
 * The CSS applied on the tinyMCE can be found in the eazly.scss file
 */
export class EazlyTinyMce {

    private readonly iframeContentWindow: any;
    private readonly spanContainingLabelToEdit: HTMLElement;
    private readonly tinyMceSelector: string;

    constructor(iframeContentWindow: any, spanWithTheLabel: HTMLElement) {
        this.iframeContentWindow = iframeContentWindow;
        this.tinyMceSelector = spanWithTheLabel.id;
        this.spanContainingLabelToEdit = this.iframeContentWindow.document.getElementById(this.tinyMceSelector);
    }

    initTinyMce(): void {
        const editorToInit: Editor = this.iframeContentWindow.eazlyTinyMce.get(this.tinyMceSelector);
        if (editorToInit) {
            // Removing the existing editor with the same id if it has not been removed before initializing
            // a new one to prevent silent error
            editorToInit.remove();
        }
        this.iframeContentWindow.eazlyTinyMce.init({
            selector: '#' + this.tinyMceSelector,
            plugins: 'autoresize fullscreen link lists paste table textcolor',
            toolbar1: 'undo redo | bold italic underline removeformat | fontsizeselect fontselect | forecolor backcolor',
            toolbar2: 'alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | blockquote | '
                + 'table tabledelete | link unlink | fullscreen',
            menubar: 'edit insert format table',
            language: 'fr_FR',
            paste_data_images: false,
            setup: (editor: Editor) => {
                this.onInit(editor);
            }
        });
    }

    private onInit(editor: Editor): void {
        editor.on('PreInit', () => {
            const moveAndDeleteHTMLDiv: HTMLElement = this.iframeContentWindow.document.getElementById(this.tinyMceSelector + '-eazly-dynamic-action');
            moveAndDeleteHTMLDiv.style.display = 'none';
            if (!editor.editorContainer.classList.contains('eazlyTinyMce')) {
                editor.editorContainer.classList.add('eazlyTinyMce');
            }
        });
        editor.on('init', () => {
            editor.editorContainer.style.opacity = '1';
            // Setting the focus on the editor ensure the trigger of the blur event
            // when clicking outside of the TinyMce
            editor.focus();
        });
        editor.on('blur', (evt: EditorEvent<{ focusedEditor: Editor }>) => {
            // Triggering the save on blur (when clicking outside of the TinyMce)
            // This event that will be retrieved by the viewer.component, after that saveTinyMceContent() function will be executed
            this.onSave(editor);
        });
    }

    private onSave(editor: Editor): void {
        const dynamicIdAttribute: string = this.spanContainingLabelToEdit.getAttribute('dynamic_id');
        let editorContent: string = editor.getContent();
        editorContent = editorContent.replace(new RegExp('\n', 'g'), '');
        const eventForSave: Event = new CustomEvent('tinymce_save', { bubbles: true, detail: { text: editorContent, dynamicId: dynamicIdAttribute } });
        this.spanContainingLabelToEdit.dispatchEvent(eventForSave);
        this.hideEditor(editor);
    }

    private hideEditor(editor: Editor) {
        editor.editorContainer.style.opacity = '0';
        // Waiting for the end of the transition before removing the tinymce
        editor.editorContainer.addEventListener('transitionend', () => {
            const moveAndDeleteHTMLDiv: HTMLElement = this.iframeContentWindow.document.getElementById(this.tinyMceSelector + '-eazly-dynamic-action');
            moveAndDeleteHTMLDiv.style.display = 'flex';
            editor.remove();
        });
    }
}
