import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Locale } from '../models/locale';
@Injectable()
export class LanguageSelectorService {
  constructor(private readonly http: HttpClient) { }

  public getLocales(
    url: string,
    keyJWT: string
  ): Observable<{
    defaultLocale: Locale;
    supportedLocales: Locale[];
  }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': keyJWT,
      }),
    };
    return this.http
      .get<{ defaultLocale: Locale; supportedLocales: Locale[] }>(
        url + '/eazly-api/v1/locales/', httpOptions
      )
      .pipe(
        map((data) => data),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }
}
