import { Observable } from 'rxjs';
import { DynamicModification } from '../../../models/dynamic-modification';
import { ViewerService } from '../../../services/viewer.service';
import { Operation, OperationType } from './operation';

export class AddDmOperation implements Operation {

    public shadowPlaceholder: HTMLDivElement;
    name: string;
    operationType: OperationType = OperationType.ADD_DM;
    stepId: string;
    private viewerService: ViewerService;
    private appeazUrl: string;
    private dynamicModification: DynamicModification;
    private idAppeaz: number;
    private appeazRole: string;
    private rank: number;

    constructor(viewerService: ViewerService, name: string,
        idAppeaz: number,
        appeazUrl: string,
        appeazRole: string,
        stepId: string,
        dynamicModification: DynamicModification,
        rank: number,
        shadowPlaceholder: HTMLDivElement) {
        this.name = name;
        this.viewerService = viewerService;
        this.appeazUrl = appeazUrl;
        this.stepId = stepId;
        this.dynamicModification = dynamicModification;
        this.idAppeaz = idAppeaz;
        this.appeazRole = appeazRole;
        this.rank = rank;
        this.shadowPlaceholder = shadowPlaceholder;
    }

    execute(): Observable<number> {
        return this.viewerService.addDynamicModification(this.appeazUrl, this.stepId, this.dynamicModification, this.idAppeaz, this.appeazRole);
    }

    getName(): string {
        return this.name;
    }

    getDynamicModification(): DynamicModification {
        return this.dynamicModification;
    }

    getRank(): any {
        return this.rank;
    }

    getOperationType(): OperationType {
        return this.operationType;
    }

    getStepId(): string {
        return this.stepId;
    }

}
