import { NGXLogger } from 'ngx-logger';
import { from as ObservableFromPromise, Observable } from 'rxjs';
import { DynamicModification } from '../../models/dynamic-modification';
import { ViewerService } from '../../services/viewer.service';
import { OperationSource } from '../message-bus/message-types/operation-message';
import { AbstractEditDmAction } from './edit-dm-interface';
import { EditDmOperation } from './operations/edit-dm-operation';
import { Operation } from './operations/operation';

/**
 * CompositeEditDMAction
 *
 * This class is a Composite action for the edition of the
 * DataDescriptor in the DynamicFieldConfigurationWindow
 * (the modal window displayed when clicking on the cog icon).
 *
 * This class allows to execute sequentially the dissociation
 * and the renaming of the DataDescriptor (which can create a new
 * link between fields) while registering it as a single undoable
 * action.
 *
 * When other parameters are also modified in the modal window, these parameters
 * are also taken into account by this Action (eg. TextMaxLength, RegExp).
 *
 * This class is only used when the user has clicked on the 'Dissociate' button
 * of the DynamicFieldConfigurationWindow since it is the only case
 * in which it is necessary to execute sequentially several operations with
 * a specific order. Indeed, in this context, the modal window and its cancel
 * button prevents from executing POST/PUT requests to the eazly engine as the users
 * interacts with the UI.
 *
 */
export class CompositeEditDMAction extends AbstractEditDmAction {

    public static ASSOCIATING_FOR_COMPOSITE_CONTEXT = 'AssociatingForComposite';
    public static DISSOCIATING_FOR_COMPOSITE_CONTEXT = 'DissociatingForComposite';
    public static FINAL_FOR_COMPOSITE_CONTEXT = 'FinalActionForComposite';

    protected operations: Operation[];
    protected reverseOperations: Operation[];

    private readonly loggerService: NGXLogger;
    private readonly viewerService: ViewerService;
    private readonly appeazUrl: string;
    private readonly stepId: string;
    private readonly idAppeaz: number;
    private readonly appeazRole: string;
    // Label of the DM being modified. This label is used
    // as the name of the operations 'do' ('this.operations')
    // and 'undo'('this.reverseOperations')
    private dmLabel: string;
    private readonly myDynamicModifications: DynamicModification[];

    // The following maps are used as an intermediate for transfering
    // the array of edited properties from the 'Do' method to the 'BuildReverseOperation' method.
    // These array are initialized in the 'buildOperations' method, which could not
    // be placed in the constructor due to its asynchronous nature.
    private readonly editedPropertiesWithOperationsAsKey: Map<Operation, string[]>;
    private readonly editedPropertiesWithEngineResponseAsKey: Map<DynamicModification, string[]>;
    private readonly compositeOperationContextWithOperationsAsKey: Map<Operation, string>;
    private readonly compositeOperationContextWithEngineResponseAsKey: Map<DynamicModification, string>;

    constructor(
        loggerService: NGXLogger,
        viewerService: ViewerService,
        idAppeaz: number,
        appeazUrl: string,
        appeazRole: string,
        stepId: string,
        dynamicModifications: DynamicModification[]) {
        super();
        this.loggerService = loggerService;
        this.viewerService = viewerService;
        this.appeazUrl = appeazUrl;
        this.stepId = stepId;
        this.idAppeaz = idAppeaz;
        this.appeazRole = appeazRole;
        this.operations = [];
        this.reverseOperations = [];
        this.editedPropertiesWithOperationsAsKey = new Map<EditDmOperation, string[]>();
        this.editedPropertiesWithEngineResponseAsKey = new Map<DynamicModification, string[]>();
        this.compositeOperationContextWithOperationsAsKey = new Map<EditDmOperation, string>();
        this.compositeOperationContextWithEngineResponseAsKey = new Map<DynamicModification, string>();
        this.myDynamicModifications = dynamicModifications;
    }

    // Method added for allowing the creation of spy in the unit tests
    getEditedProperties(dynamicModification: DynamicModification): string[] {
        return super.getEditedProperties(dynamicModification);
    }

    /**
     * This method executes sequentially all the EditOperations stored in the attribute 'operations'.
     * It returns an array of observable containing the responses of the eazly engine for each request.
     *
     * @returns an Observable<DynamicModification>[] containing the responses from the server for each operations
     */
    public do(): Observable<any> {
        this.loggerService.debug('CompositeEditDM: Preparing do with the following operations ', this.operations);
        const resultsOfEditDm: Observable<DynamicModification>[] = [];
        // Reversing the operations since they are fetched using 'pop' which take the last item of the array
        // The method 'slice()' is used to copy the array into a new array to allow 'undoing/redoing' several times
        const operationsToExecute: Operation[] = this.operations.slice().reverse();
        // Returning Observable from the result of the Promise returned by 'doOneOperationAtATime'
        // to ensure that all operations were performed before returning the value
        return ObservableFromPromise(this.doOneOperationAtATime(operationsToExecute, resultsOfEditDm));
    }

    /**
     * This method executes sequentially all the EditOperations stored in the attribute 'reverseOperations'.
     * It returns an array of observable containing the responses of the eazly engine for each request.
     *
     * @returns an Observable<DynamicModification>[] containing the responses from the server for each operations
     */
    public undo(): Observable<any> {
        this.loggerService.debug('CompositeEditDM: Preparing Undo with the following operations ', this.reverseOperations);
        const resultsOfEditDm: Observable<DynamicModification>[] = [];
        // The order of the operations are already in the right order
        // since the array was built with 'push' method in the method 'buildReverseOperation.
        // Thus, the 'for' does not requires to reverse the array as opposed to the 'do' method
        const operationsToExecute: Operation[] = this.reverseOperations.slice();
        return ObservableFromPromise(this.doOneOperationAtATime(operationsToExecute, resultsOfEditDm));
    }

    /**
     * This method builds the operations to execute in the method 'do'.
     * The operations are not built in the constructor since this method is async
     * due to the calls to the eazly Rest API to retrieve information about the DM being edited.
     *
     * The DM is retrieved from the eazly Engine to get its label that will be used in the tooltip
     * of the 'undo/redo'.
     *
     * Thus, this method initializes the following:
     * - this.dmLabel: The name of the DM
     * - this.operations: The array of operations to execute in the 'do' method
     * - this.operation: The operation whose name used in the undo/redo tooltip
     *
     * @returns a promise indicating that the calls to the engine and the operations are done successfully
     */
    public async buildOperations(): Promise<any> {
        // Initializing the expected number of successful response to retrieve from the eazly Engine.
        const expectedNumberOfResolutions: number = this.myDynamicModifications.length;
        let numberOfResolution = 0;

        return new Promise((resolve, reject) => {
            for (const dynamicModification of this.myDynamicModifications) {
                // Calling the 'getDynamicModification' to retrieve the information of the DM from the eazlyEngine
                this.viewerService.getDynamicModification(this.appeazUrl, this.stepId, dynamicModification.id).subscribe(
                    (dmReturnedByTheEngine: DynamicModification) => {
                        // Setting the DM label which will be used as the name of the EditDmOperation and the
                        // reverse EditDmOperation
                        this.dmLabel = dmReturnedByTheEngine.modificationTarget.label;
                        // Building the array listing the properties being edited by the operation
                        // This array will be used in the viewerComponent in the method 'handleEditDmSuccess'
                        // (eg. To regenerate the preview if necessary)
                        const editedPropertiesOfADM: string[] = this.getEditedProperties(dynamicModification);
                        const editOperation: EditDmOperation = new EditDmOperation(this.viewerService,
                            OperationSource.DO,
                            this.dmLabel,
                            this.appeazUrl,
                            this.stepId,
                            dynamicModification,
                            editedPropertiesOfADM);
                        this.operations.push(editOperation);
                        // Storing the array of editedProperties in a map to allow the method 'buildReverseOperation'
                        // to retrieve it for initializing the matching reverse operation.
                        // To do so, further modifications are made in the method 'doOneOperationAtATime'
                        this.editedPropertiesWithOperationsAsKey.set(editOperation, editedPropertiesOfADM);
                        if ((dynamicModification as any).compositeOperationContext) {
                            this.compositeOperationContextWithOperationsAsKey.set(editOperation, (dynamicModification as any).compositeOperationContext);
                        }
                        numberOfResolution++;
                        this.loggerService.debug('Operation built', editOperation, '(', numberOfResolution, '/', expectedNumberOfResolutions, ')');
                        if (numberOfResolution >= expectedNumberOfResolutions) {
                            this.loggerService.debug('All operations of the composite action were built', this.operations);
                            this.operation = this.operations[0];
                            resolve('Done');
                        }
                    });
            }
        });
    }

    /**
     * This method builds the reverse operations to execute in the method 'undo'.
     * These reverse operations are built using the responses from the EditDM API calls
     * made by each operations in the 'do' method (see 'UndoRedoManagerService.do')
     *
     * @param resultsOfEditDm The responses from the server to the EditDM API calls for each operation
     * executed by the method 'Do'
     */
    public buildReverseOperation(resultsOfEditDm: DynamicModification[]): void {
        this.loggerService.debug('Building reverse operations for composite action from ', resultsOfEditDm);
        for (const dmReturnedByTheEngine of resultsOfEditDm) {
            // Retrieving the properties edited by the operations 'Do'
            const editedPropertiesOfTheDm: string[] = this.editedPropertiesWithEngineResponseAsKey.get(dmReturnedByTheEngine);
            const compositeOperationContext: string = this.compositeOperationContextWithEngineResponseAsKey.get(dmReturnedByTheEngine);
            // Cleaning server response to keep only the attributes that have been changed by the 'Do' method
            const cleanedDynamicModification: DynamicModification = this.cleanServerResponse(dmReturnedByTheEngine, editedPropertiesOfTheDm);

            this.loggerService.debug('Building one reverse operation with ', dmReturnedByTheEngine,
                '(Edited properties: ', editedPropertiesOfTheDm, '; Context: ', compositeOperationContext, ')');

            switch (compositeOperationContext) {
                case CompositeEditDMAction.ASSOCIATING_FOR_COMPOSITE_CONTEXT:
                    // Setting the reverse operation of the 'association' operation as a 'Dissociate dataDescriptor' action
                    cleanedDynamicModification.modificationTarget.dataDescriptor.name = '';
                    cleanedDynamicModification.modificationTarget.values = (dmReturnedByTheEngine as any).modificationTarget.dataDescriptor.values;
                    break;
                case CompositeEditDMAction.DISSOCIATING_FOR_COMPOSITE_CONTEXT:
                default:
                    // No manipulation of the reverse operation to do
                    break;
            }

            const reverseOperation: EditDmOperation = new EditDmOperation(this.viewerService,
                OperationSource.UNDO, this.dmLabel, this.appeazUrl,
                this.stepId, cleanedDynamicModification, editedPropertiesOfTheDm);
            this.loggerService.debug('Reverse operation built: ', reverseOperation);
            this.reverseOperations.push(reverseOperation);
        }
        // Setting the attribute 'Reverse Operation' for the tooltip of the 'Redo' button
        this.reverseOperation = this.reverseOperations[0];
    }

    /**
     * This recursive method execute sequentially an array of operation by making sure that the first operation
     * was successfully performed prior executing the next one.
     *
     * Once all the operations are done, a Promise is returned containing an array of Observable which
     * corresponds to the responses of the eazly Engine for each calls to the editDM Rest API
     *
     * @param operations The list of operations to execute sequentially.
     * The external call to this method should provide a copy of the array since it is modified internally by this method
     * @param results An array containing the responses of the eazlyEngine from the editDM Rest API calls.
     *  The external call to this method should provide an empty array since the results array is built iteratively with each recursion
     * @returns A Promise containing the responses from the eazlyEngine of all the executed operations
     */
    private async doOneOperationAtATime(operations: Operation[], results: Observable<DynamicModification>[]): Promise<Observable<DynamicModification>[]> {
        const editDMOperation: EditDmOperation = operations.pop() as EditDmOperation;
        if (editDMOperation) {
            this.loggerService.debug('Doing one operation: ', editDMOperation, '; Remaining operations: ', operations);
            // Waiting the end of all the recursive calls before returning the promise using the keyword 'await'
            await editDMOperation.execute().toPromise().then(
                async (result: any) => {
                    // Converting the context of the operation from 'do' to 'redo' after executing it once
                    if (OperationSource.DO === editDMOperation.operationSource) {
                        editDMOperation.operationSource = OperationSource.REDO;
                    }
                    this.loggerService.debug('Received the response ', result, ' for executing ', editDMOperation);
                    results.push(result);
                    // Storing editedProperties to make it retrievable by the method buildReverseOperation
                    const editedPropertiesOfTheOperation: string[] = this.editedPropertiesWithOperationsAsKey.get(editDMOperation);
                    this.editedPropertiesWithEngineResponseAsKey.set(result, editedPropertiesOfTheOperation);
                    this.editedPropertiesWithOperationsAsKey.delete(editDMOperation);

                    const compositeOperationContext: string = this.compositeOperationContextWithOperationsAsKey.get(editDMOperation);
                    if (compositeOperationContext) {
                        this.compositeOperationContextWithEngineResponseAsKey.set(result, compositeOperationContext);
                        this.compositeOperationContextWithOperationsAsKey.delete(editDMOperation);
                    }

                    // Executing the next operation and waiting its end before continuing
                    await this.doOneOperationAtATime(operations, results);
                },
                (error) => {
                    this.loggerService.error('Error during the execution of ', editDMOperation, ':', error);
                    return Promise.reject(error);
                }
            );
        }
        // Returning the Observable containing the DM returned by the eazly engine in the Promise
        return Promise.resolve(results);
    }
}
