import { Appeaz } from '../models/appeaz';
import { DataDescriptor } from '../models/data-descriptor';
import { DynamicModification } from '../models/dynamic-modification';
import { DynamicVersion } from '../models/dynamic-version';
import { Step } from '../models/step';
import { StepDynamicModifications } from '../models/step-dynamic-Modifications';
import { User } from '../models/user';
import { VisibilityMode } from '../models/visibility-criteria/visibility-mode';

const API_KEY_1 = 'xllaw2uqpAdo3mHLpFaP9Mm8lSg=';

export const users: User[] = [
    {
        id: 1, first_name: 'Ali', last_name: 'Saguer', username: 'Ali', password: 'pass1', email: 'ali@eazly.fr', eazly_role: 'Manager',
        token: 1525260992368, actif: true, expert: false
    },
    {
        id: 2, first_name: 'Cyril', last_name: 'Chene', username: 'Cyril', password: 'pass2', email: 'cyril@eazly.fr', eazly_role: 'Tester',
        token: 1525260992360, actif: true, expert: false
    },
    {
        id: 3, first_name: 'Monia', last_name: 'Rezgui', username: 'Monia', password: 'pass3', email: 'monia@eazly.fr', eazly_role: 'Designer',
        token: 1525260992362, actif: true, expert: false
    },
    {
        id: 4, first_name: 'JeanLuc', last_name: 'Hak', username: 'JeanLuc', password: 'pass4', email: 'luc@eazly.fr', eazly_role: 'Designer',
        token: 1525260992365, actif: true, expert: true
    },
    {
        id: 5, first_name: 'Nicolas', last_name: 'Ducoin', username: 'nducoin',
        password: 'nducoin', email: 'nicolas@eazly.fr', eazly_role: 'EAZLY_PLATFORM_ADMIN', token: 1525260992361, actif: true, expert: true
    },
    {
        id: 6, first_name: '', last_name: '', username: '',
        password: '', email: 'mail@email.com', eazly_role: '', token: 123, actif: false, expert: false
    }
];

export const myAppeaz: Appeaz[] = [{
    id: 1,
    name: 'SVE',
    url: 'http://localhost:8080/SaaS.TS.SVE',
    apiKey: API_KEY_1,
    role: 'Manager', userQuota: 10, expert: true, multilingue: false, stylesheet: '',
    version: "1.0.0", status: true, admins: [], modificationDate: new Date(), publicationDate: new Date()
}];

export const appeazs: Appeaz[] = [
    {
        id: 1, name: 'Réservation', url: 'http://localhost:8080/DM-Demo',
        apiKey: API_KEY_1, role: 'Manager', userQuota: 10, expert: true, multilingue: false,
        stylesheet: '', version: "1.0.0", status: true, admins: [], modificationDate: new Date(), publicationDate: new Date()
    },
    {
        id: 2, name: 'Petite enfance', url: 'http://localhost:8080/Kindergarten',
        apiKey: API_KEY_1, role: 'Tester', userQuota: 10, expert: true, multilingue: false,
        stylesheet: '', version: "", status: true, admins: [], modificationDate: new Date(), publicationDate: new Date()
    },
    {
        id: 3, name: 'SVE', url: 'http://localhost:8080/SaaS.TS.SVE',
        apiKey: 'wqhqeOSpwMtIWHzCHRa6V4YQx0Y=', role: 'Designer', userQuota: 10, expert: true, multilingue: false,
        stylesheet: '', version: "1.0.0", status: true, admins: [], modificationDate: new Date(), publicationDate: new Date()
    },
];

export const mockLogger = function () {
    return {
        info(msg: any) { },
        warn(msg: any) { },
        debug(msg: any) { },
        error(msg: any) { },
        trace(msg: any) { }
    };
};

export const steps: Step[] = [
    { id: '0', name: 'Saisie de la demande' },
    { id: '1', name: 'Réservation' },
    { id: '2', name: 'Récapitulatif de la Réservation' }
];


export const dynamic: DynamicModification = {
    id: 5,
    anchorReference: 'WhoAreYou_eserviceelementActivityelementgroupId18_9IqeBegins',
    priorityOrder: 1,
    modificationTarget: {
        label: 'Liste',
        infoText: 'infotext',
        properties: {
            elementType: 'COMBO_BOX',
            editable: 'true',
            required: 'false',
            textMaxLength: '50',
            regExpPattern: '^((\+)33|0|0033)[1-9](\d{2}){4}',
            defaultValue: 'default_value',
            visibilityCriteria: {
                visibilityMode: VisibilityMode.ALWAYS_VISIBLE,
                conditions: []
            },
        }
        ,
        values: [{
            id: 0,
            index: 1,
            value: 'aaaaaaa',
            labelTranslations: []
        },
        {
            id: 1,
            index: 2,
            value: 'bbbbbb',
            labelTranslations: []
        }
        ]
        ,
        dataDescriptor: {
            id: '',
            name: 'combo',
            datatype: 'ENUM',
            isIncluded: (array: DataDescriptor[]) => false
        },
        labelTranslations: []
    },
    modificationType: 'ELEMENT_ADDING'

};
export const stepDynamicModifications: StepDynamicModifications[] = [
    { stepId: '0', stepName: 'step1', modifications: [] },
    { stepId: '1', stepName: 'step2', modifications: [] }
];

export const activeVersion: DynamicVersion = {
    name: '1968-11-16T00:00:00',
    versionName: '1968-11-16T00:00:00',
    timeStamp: new Date('1968-11-16T10:50:09'),
    lastAuthor: 'AK',
    publisher: 'AK',
    publicationDate: new Date('1968-11-16T10:58:09'),
};
export const draftVersion: DynamicVersion = {
    name: new Date().toDateString(),
    versionName: new Date().toDateString(),
    timeStamp: new Date(),
    lastAuthor: 'AK',
    publisher: 'AK',
    publicationDate: new Date(),
};
