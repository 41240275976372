import { Component, OnInit } from '@angular/core';
import { Appeaz } from '../tools/models/appeaz';
import { AppeazService } from '../tools/services/appeaz.service';
@Component({
  selector: 'eazly-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  myAppeazs: Appeaz[];
  appeazsToDisplay: Appeaz[];

  constructor(protected appeazService: AppeazService
  ) {
  }

  filterAppeazAttributes(originalAppeaz: Appeaz): Appeaz {
    const attributeOrder = ['name', 'admins', 'publicationDate', 'modificationDate', 'version', 'status'];
    const orderedAppeaz: any = {};
    attributeOrder.forEach(attribute => {
      orderedAppeaz[attribute] = originalAppeaz[attribute];
    });
    return orderedAppeaz;
  }

  isValidUrl(url: string) {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  }

  setStatusBasedOnErrorCause(appeaz: Appeaz, error: any): void {
    switch (error.status) {
      case 500:
        appeaz.status = 'INVALID APPEAZ KEY';
        break;
      case 504:
        appeaz.status = 'APPEAZ URL NOT AVAILABLE';
        break;
      case 0:
        appeaz.status = this.isValidUrl(appeaz.url) ? 'NO RESPONSE' : 'WRONG APPEAZ URL';
        break;
      default:
        appeaz.status = 'UNKOWN ISSUE';
        break;
    }
  }

  updateAppeazsToDisplay(appeaz: Appeaz): void {
    if (!this.appeazsToDisplay) {
      this.appeazsToDisplay = [];
    }
    this.appeazsToDisplay.push(this.filterAppeazAttributes(appeaz));
  }

  /**
    * Handles specific errors that occur during the processing of an Appeaz.
    * This method is specifically designed to handle errors related to retrieving an Appeaz.
    * @param error The error that occurred.
    * @param appeaz The Appeaz affected by the error.
  */
  handleAppeazError(error: any, appeaz: Appeaz): void {
    this.setStatusBasedOnErrorCause(appeaz, error);
    this.updateAppeazsToDisplay(appeaz);
  }


  handleAdmins(appeaz: Appeaz, usersResult: any): void {
    const admins = usersResult.filter(user => user.role === 'Manager');
    appeaz.admins = admins.map(admin => admin.email);
  }

  handleVersion(appeaz: Appeaz, versionResult: PromiseSettledResult<any>): void {
    if (versionResult.status === 'fulfilled' && versionResult.value) {
      appeaz.version = versionResult.value;
    }
    appeaz.status = appeaz.version ? true : false;
  }

  handleDynamicVersion(appeaz: Appeaz, dynamicVersionResult: PromiseSettledResult<any>): void {
    if (dynamicVersionResult.status === 'fulfilled' && dynamicVersionResult.value) {
      appeaz.modificationDate = new Date(dynamicVersionResult.value.timeStamp);
      appeaz.publicationDate = new Date(dynamicVersionResult.value.publicationDate);
    }
  }

  ngOnInit() {
    this.appeazService.getAllappeazs().subscribe(myAppeazs => {
      myAppeazs.forEach(appeaz => {
        this.appeazService.getUsersbyAppeaz(appeaz.id).subscribe(
          users => {
            this.handleAdmins(appeaz, users);
          }
        );
        new Promise<void>((resolve, reject) => {
          this.appeazService.getAppeazJWTWithoutErrorHandling(appeaz.id, 'Manager').subscribe(
            (dataJWT) => {
              const versionPromise = this.appeazService.getVersionByAppeaz(appeaz).toPromise();
              const dynamicVersionPromise = this.appeazService.getDynamicVersion(appeaz.url, 'active').toPromise();
              Promise.allSettled([versionPromise, dynamicVersionPromise]).then(([versionResult, dynamicVersionResult,]) => {
                this.handleVersion(appeaz, versionResult);
                this.handleDynamicVersion(appeaz, dynamicVersionResult);
                this.updateAppeazsToDisplay(appeaz);
              });
            },
            error => {
              this.handleAppeazError(error, appeaz);
            }
          );
        });
      });
    });
  }
}
