import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '../../eazly.routing';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../other/footer/footer.module';
import { LoadingModule } from '../other/loading/loading.module';
import { ModalWindowModule } from '../tools/modal-window/modal-window.module';
import { AppeazDashboardComponent } from './appeaz-dashboard/appeaz-dashboard.component';
import { AppeazComponent } from './appeaz.component';

@NgModule({
  declarations: [
    AppeazComponent,
    AppeazDashboardComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    FooterModule,
    FormsModule,
    HeaderModule,
    LoadingModule,
    ModalWindowModule,
    ReactiveFormsModule,
    RouterModule
  ],
  bootstrap: [AppeazComponent]
})
export class AppeazModule { }
