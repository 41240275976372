import { DataDescriptor } from '../../models/data-descriptor';
import { DynamicModification } from '../../models/dynamic-modification';
import { DynamicModificationProperties } from '../../models/dynamic-modification-properties';
import { DynamicModificationTarget } from '../../models/dynamic-modification-target';
import { DynamicModificationValueList } from '../../models/dynamic-modification-value';
import { VisibilityCriteria } from '../../models/visibility-criteria/visibility-criteria';
import { Action } from './action';
import { EditDmOperation } from './operations/edit-dm-operation';

export abstract class AbstractEditDmAction extends Action {

    /**
     * This method analyzes a dynamicModification that should be sent in
     * a editDM API Rest call to the engine and returns an array
     * of string listing the properties that should be updated by the engine
     *
     * @param dynamicModification The Dynamic Modification to analyze
     * @returns An array containing the properties edited by the dynamicModification
     */
    public getEditedProperties(dynamicModification: DynamicModification): string[] {
        const editedProperties: string[] = [];
        if (dynamicModification.modificationTarget) {
            if (dynamicModification.modificationTarget.properties) {
                if (dynamicModification.modificationTarget?.dataDescriptor?.name !== undefined) {
                    editedProperties.push('dataDescriptorName');
                }
                if (dynamicModification.modificationTarget.properties.editable !== undefined) {
                    editedProperties.push('Editable');
                }
                if (dynamicModification.modificationTarget.properties.required !== undefined) {
                    editedProperties.push('Required');
                }
                if (dynamicModification.modificationTarget.properties.textMaxLength !== undefined) {
                    editedProperties.push('textMaxLength');
                }
                if (dynamicModification.modificationTarget.properties.regExpPattern !== undefined) {
                    editedProperties.push('regExpPattern');
                }
                if (dynamicModification.modificationTarget.properties.visibilityCriteria !== undefined) {
                    editedProperties.push('visibilityCriteria');
                }
                if (dynamicModification.modificationTarget.properties.defaultValue !== undefined) {
                    editedProperties.push('defaultValue');
                }
            }
            if (dynamicModification.modificationTarget.label !== undefined) {
                editedProperties.push('Label');
            }
            if (dynamicModification.modificationTarget.values !== undefined && dynamicModification.modificationTarget.label === undefined) {
                editedProperties.push('Values');
            }
            if (dynamicModification.modificationTarget.infoText !== undefined) {
                editedProperties.push('infoText');
            }
            if (dynamicModification.modificationTarget.labelTranslations !== undefined) {
                editedProperties.push('labelTranslations');
            }
        }
        if (dynamicModification.anchorReference !== undefined) {
            editedProperties.push('AnchorReference');
        }
        if (dynamicModification.priorityOrder !== undefined) {
            editedProperties.push('PriorityOrder');
        }
        return editedProperties;
    }

    /**
     * Utility method for reducing getEditedProperties Cognitive Complexity.
     *
     * @param dynamicModification The Dynamic Modification to analyze.
     * @param editedProperties the properties edited by the dynamicModification.
    */
    public addInfoText(dynamicModification: DynamicModification, editedProperties: string[]): void {
        if (dynamicModification.modificationTarget.infoText !== undefined) {
            editedProperties.push('infoText');
        }
    }

    /**
     * This method filters the dynamicModification given in the parameters
     * using the array editedProperties and returns a new DynamicModification
     * containing only the attributes for the properties listed in the array 'editedProperties'.
     *
     * This method is used to build a reverse operation for the 'undo' from the response of
     * the server to the API Rest call made in the 'do' method.
     *
     * @param dynamicModification the Response from the server to the Rest API call made in the 'do' method
     * @param editedProperties the array of edited properties to use as a filter
     * @returns a new DynamicModification with the same values for each attributes of the DM 'dynamicModification'
     *  but only for the attributes matching the filter editedProperties
     */
    public cleanServerResponse(dynamicModification: any, editedProperties: string[]): DynamicModification {
        const dmToReturn: DynamicModification = new DynamicModification();
        dmToReturn.id = dynamicModification.id;
        editedProperties.forEach((key: string) => {
            if ('dataDescriptorName' === key) {
                dmToReturn.modificationTarget = this.initDmTargetPropertiesIfRequired(dmToReturn);
                dmToReturn.modificationTarget.dataDescriptor = new DataDescriptor();
                dmToReturn.modificationTarget.dataDescriptor.name = dynamicModification.modificationTarget.dataDescriptor.dataName;
            }
            if ('Editable' === key) {
                dmToReturn.modificationTarget = this.initDmTargetPropertiesIfRequired(dmToReturn);
                dmToReturn.modificationTarget.properties.editable = dynamicModification.modificationTarget.properties.EDITABLE;
            }
            if ('defaultValue' === key) {
                dmToReturn.modificationTarget = this.initDmTargetPropertiesIfRequired(dmToReturn);
                dmToReturn.modificationTarget.properties.defaultValue = dynamicModification.modificationTarget.properties.DEFAULT_VALUE;
            }
            if ('Required' === key) {
                dmToReturn.modificationTarget = this.initDmTargetPropertiesIfRequired(dmToReturn);
                dmToReturn.modificationTarget.properties.required = dynamicModification.modificationTarget.properties.REQUIRED;
            }
            if ('textMaxLength' === key) {
                dmToReturn.modificationTarget = this.initDmTargetPropertiesIfRequired(dmToReturn);
                dmToReturn.modificationTarget.properties.textMaxLength = dynamicModification.modificationTarget.properties.TEXT_MAX_LENGTH;
            }
            if ('visibilityCriteria' === key) {
                dmToReturn.modificationTarget = this.initDmTargetPropertiesIfRequired(dmToReturn);
                const visibilityCriteriaJSON = JSON.parse(dynamicModification.modificationTarget.properties.VISIBILITY_CRITERIA);
                dmToReturn.modificationTarget.properties.visibilityCriteria = new VisibilityCriteria();
                dmToReturn.modificationTarget.properties.visibilityCriteria.visibilityMode = visibilityCriteriaJSON.visibilityMode;
                dmToReturn.modificationTarget.properties.visibilityCriteria.conditions = visibilityCriteriaJSON.conditions;
            }
            if ('regExpPattern' === key) {
                dmToReturn.modificationTarget = this.initDmTargetPropertiesIfRequired(dmToReturn);
                dmToReturn.modificationTarget.properties.regExpPattern = dynamicModification.modificationTarget.properties.REG_EXP_PATTERN;
            }

            this.cleanServerResponseInfoText(key, dmToReturn, dynamicModification);

            if ('Label' === key) {
                dmToReturn.modificationTarget = this.initDmTargetIfRequired(dmToReturn);
                dmToReturn.modificationTarget.label = dynamicModification.modificationTarget.label;

                const editOperation: EditDmOperation = this.operation as EditDmOperation;
                // Checking if the DM is associated with a listValue
                if (dynamicModification.modificationTarget.dataDescriptor
                    && dynamicModification.modificationTarget.dataDescriptor.values) {
                    if (editOperation.getDynamicModification().modificationTarget.values
                        && editOperation.getDynamicModification().modificationTarget.values.length !== 0) {
                        // Storing the old list values that was associated with the old label
                        dmToReturn.modificationTarget.values = editOperation.getDynamicModification().modificationTarget.values;
                        // Updating the list value of the operation to update the modal window for editing the ListValue
                        editOperation.getDynamicModification().modificationTarget.values = dynamicModification.modificationTarget.dataDescriptor.values;
                    } else {
                        // No values were stored in the operation: The label has been updated in the Do operation.
                        // Updating the dmToReturn with the values of the list returned by the engine
                        // for allowing the restoration of the values with the undo if the DataDescriptor has been cleaned.
                        dmToReturn.modificationTarget.values = dynamicModification.modificationTarget.dataDescriptor.values;
                    }
                }
            }
            if ('Values' === key) {
                dmToReturn.modificationTarget = this.initDmTargetIfRequired(dmToReturn);
                dmToReturn.modificationTarget.values = this.convertAndCopyListValue(dynamicModification);
            }
            if ('AnchorReference' === key) {
                dmToReturn.anchorReference = dynamicModification.anchor.anchorReference;
            }
            if ('PriorityOrder' === key) {
                dmToReturn.priorityOrder = dynamicModification.order;
            }
            if ('labelTranslations' === key) {
                dmToReturn.modificationTarget = this.initDmTargetIfRequired(dmToReturn);
                dmToReturn.modificationTarget.labelTranslations = dynamicModification.modificationTarget.labelTranslations;
            }
        });
        return dmToReturn;
    }

    private cleanServerResponseInfoText(key: string, dmToReturn: DynamicModification, dynamicModification: any) {
        if ('infoText' === key) {
            dmToReturn.modificationTarget = this.initDmTargetIfRequired(dmToReturn);
            dmToReturn.modificationTarget.infoText = dynamicModification.modificationTarget.infoText;
        }
    }

    private initDmTargetIfRequired(dynamicModification: DynamicModification): DynamicModificationTarget {
        if (!dynamicModification.modificationTarget) {
            return new DynamicModificationTarget();
        }
        return dynamicModification.modificationTarget;
    }

    private initDmTargetPropertiesIfRequired(dynamicModification: DynamicModification): DynamicModificationTarget {
        dynamicModification.modificationTarget = this.initDmTargetIfRequired(dynamicModification);
        if (!dynamicModification.modificationTarget.properties) {
            dynamicModification.modificationTarget.properties = new DynamicModificationProperties();
        }
        return dynamicModification.modificationTarget;
    }

    private convertAndCopyListValue(dynamicModification: any): DynamicModificationValueList[] {
        const valuesToReturn: DynamicModificationValueList[] = [];
        // Key = value,  value = id of the ListValue
        const idSortedByValueMap: Map<string, number> = new Map<string, number>();
        dynamicModification.modificationTarget.dataDescriptor.values.forEach(valueFromServer => {
            const dmValue: DynamicModificationValueList = new DynamicModificationValueList();
            dmValue.id = valueFromServer.id;
            dmValue.index = valueFromServer.index;
            dmValue.value = valueFromServer.value;
            dmValue.labelTranslations = valueFromServer.labelTranslations;
            valuesToReturn.push(dmValue);
            idSortedByValueMap.set(dmValue.value, dmValue.id);
        });
        // Retrieving the id of newly added values to update the 'do' operation and recreate the value with the old id
        (this.operation as EditDmOperation).getDynamicModification().modificationTarget.values.forEach(value => {
            if (value.id == null) {
                value.id = idSortedByValueMap.get(value.value);
            }
        });

        return valuesToReturn;
    }
}
