import { Component, Input, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ModalWindowComponent } from '../../../tools/modal-window/modal-window.component';
import { Appeaz } from '../../../tools/models/appeaz';
import { Step } from '../../../tools/models/step';
import { StepDynamicModifications } from '../../../tools/models/step-dynamic-Modifications';
import { AppeazService } from '../../../tools/services/appeaz.service';
import { ImportExportService } from '../../../tools/services/import-export.service';
import { EazlyMessage } from '../../../tools/services/messaging/eazly-message';
import { StepService } from '../../../tools/services/steps.service';
import { ImportAction } from '../../../tools/undo-redo-manager/actions/import-action';
import { UndoRedoManagerService } from '../../../tools/undo-redo-manager/undo-redo-manager.service';
import { NavigatorComponent } from '../../navigator/navigator.component';

@Component({
  selector: 'eazly-import-window',
  templateUrl: './import-window.component.html',
  styleUrls: ['./import-window.component.scss']
})
export class ImportWindowComponent {
  @Input() myappeaz: Appeaz;

  @ViewChild(NavigatorComponent)
  navigatorComponent: NavigatorComponent;

  @ViewChild(ModalWindowComponent, { static: true })
  private readonly modalWindowComponent: ModalWindowComponent;
  importedFileContentAsJson: any;

  intersectionStep: Step[] = [];

  importedFile: File = undefined;

  errorReport: EazlyMessage;

  showConfirmationMessage = false;

  isButtonDisabled = true;

  constructor(private readonly stepService: StepService,
    private readonly appeazService: AppeazService,
    private readonly importExportService: ImportExportService,
    private readonly undoRedoManagerService: UndoRedoManagerService,
    private readonly loggerService: NGXLogger) { }

  public hide(): void {
    this.modalWindowComponent.hide();
  }

  getappeaz() {
    return this.myappeaz;
  }

  public showModal(importedFile: File, importedFileContentAsJson: any): void {
    this.showConfirmationMessage = false;
    this.isButtonDisabled = true;
    this.importedFile = importedFile;
    this.navigatorComponent.setDisplayOfStepSelection(true);
    this.importedFileContentAsJson = importedFileContentAsJson;
    const stepIdsOfImportedFile: string[] = [];
    this.modalWindowComponent.show();
    for (const item of this.importedFileContentAsJson) {
      stepIdsOfImportedFile.push(item.stepId);
    }
    if (this.getappeaz()) {
      this.appeazService.getAppeazJWT(this.myappeaz.id, this.myappeaz.role).subscribe(dataJwt => {
        this.stepService.getSteps(this.myappeaz.url, dataJwt).subscribe(steps => {
          if (steps) {
            this.intersectionStep = steps.filter(step => stepIdsOfImportedFile.includes(step.id));
          }
        });
      });
    }
    this.navigatorComponent.resetSearchInput();
  }

  showConfirmation() {
    this.showConfirmationMessage = true;
  }

  hideConfirmation() {
    this.showConfirmationMessage = false;
  }

  handleSubmitButtonClicked() {
    const selectedSteps: Step[] = this.navigatorComponent.getSelectedSteps();
    if (selectedSteps.length > 0) {
      const dataToImport = this.prepareDataToImport(selectedSteps);
      this.loggerService.debug('Importing ', dataToImport);
      const importAction = new ImportAction(this.importedFile.name, this.importExportService, this.myappeaz.url, dataToImport);
      this.undoRedoManagerService.do(importAction);
    } else {
      this.loggerService.debug('No step to import was selected. Aborting import.');
    }
    this.hide();
  }

  /**
   * Build an array of StepDynamicModifications containing the data for the import.
   *
   * This array is built from the list of selected steps of the navigator and the modifications
   * of the corresponding steps from the json of the uploaded file.
   *
   * @param selectedSteps The selected steps
   * @returns an array of StepDynamicModifications containing data to import
   */
  prepareDataToImport(selectedSteps: Step[]): StepDynamicModifications[] {
    const dataToImport: StepDynamicModifications[] = [];
    selectedSteps.forEach((selectedStep: Step) => {
      const stepInJson = this.importedFileContentAsJson.filter((jsonElement) => selectedStep.id === jsonElement.stepId);
      if (stepInJson[0]) {
        const stepDynamicModifications: StepDynamicModifications = {
          stepId: selectedStep.id,
          stepName: selectedStep.name,
          modifications: stepInJson[0].modifications
        };
        dataToImport.push(stepDynamicModifications);
      }
    });
    return dataToImport;
  }

  enableButton() {
    if (this.navigatorComponent.getSelectedSteps().length > 0) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }
}
