import { Component, HostBinding, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'eazly-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  @HostBinding('class') class = 'page-content';

  eazlyVersion = environment.eazlyVersion;
  termsOfServiceFileUrl = './assets/private/about/Softeam_SaaS_ContratDeService_eazly_V1.0.pdf';

  constructor() {
  }

  ngOnInit() {
  }

}
