import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { browsers } from '../browser-version';
import { EazlyMessage, MessageCause } from '../services/messaging/eazly-message';
import { MessagingService } from '../services/messaging/messaging.service';
@Component({
  selector: 'eazly-alert-manager',
  templateUrl: './alert-manager.component.html',
  styleUrls: ['./alert-manager.component.scss']
})
export class AlertManagerComponent implements OnInit, OnDestroy {

  alerts: EazlyMessage[];
  subscription: Subscription;
  messageCause = MessageCause;
  supportedbrowsers = browsers;
  alertBrowser: EazlyMessage;
  constructor(private messagingService: MessagingService) {
    this.alerts = [];
  }

  ngOnInit() {
    this.subscription = this.messagingService.update$.subscribe(error => {
      if (error !== null && !error.wasDisplayed) {
        if (error.messageCause === MessageCause.BrowserNotSupported) {
          error.wasDisplayed = true;
          this.alertBrowser = error;
        } else {
          error.wasDisplayed = true;
          if (error.messageCause === MessageCause.InternalError) {
            this.alerts = [];
          }
          this.alerts.push(error);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
