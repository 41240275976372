import { HttpResponse } from '@angular/common/http';
import { AfterViewChecked, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ModalWindowComponent } from '../tools/modal-window/modal-window.component';
import { Appeaz } from '../tools/models/appeaz';
import { User } from '../tools/models/user';
import { AppeazService } from '../tools/services/appeaz.service';
import { AlertType, EazlyMessage, MessageCause } from '../tools/services/messaging/eazly-message';
import { MessagingService } from '../tools/services/messaging/messaging.service';
import { UserService } from '../tools/services/user.service';


@Component({
  selector: 'eazly-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewChecked {

  @HostBinding('class') class = 'page-content';
  @ViewChild(ModalWindowComponent, { static: true })
  private readonly modalWindowComponent: ModalWindowComponent;

  emailToInvite: string;
  users: User[];
  id: number;
  isLoading: boolean;
  isAdmin: boolean;
  myappeaz: Appeaz;
  errorReport: EazlyMessage;

  constructor(private route: ActivatedRoute, private router: Router, private logger: NGXLogger, private appeazService: AppeazService,
    private readonly userService: UserService, private readonly messagingService: MessagingService) { }

  ngOnInit() {
    this.isLoading = true;
    this.route.params.subscribe((params: Params) => {
      this.id = +this.route.snapshot.paramMap.get('id');
      this.appeazService.getAppeazById(this.id)
        .subscribe(
          data => {
            this.myappeaz = data;
          },
          error => {
            if (error.status === 401) {
              // Session expired
              this.errorReport = new EazlyMessage(AlertType.danger, 'AppeazService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
              this.router.navigate(['/login']);
            }
            if (error.status === 403) {
              // Only an Administrator or a Manager can retrieve Appeaz Info
              this.errorReport = new EazlyMessage(AlertType.danger, 'AppeazService',
                MessageCause.InsufficientRoleForGetAppeazById, error.status + ' ' + error.statusText);
              this.messagingService.notifyError(this.errorReport);
            }
            if (error.status === 404) {
              this.errorReport = new EazlyMessage(AlertType.danger, 'AppeazService', MessageCause.AppeazNotFound, error.status + ' ' + error.statusText);
              this.router.navigate(['/appeazs']);
            }
          });
      this.appeazService.getUsersbyAppeaz(this.id).subscribe(
        data => {
          this.users = data;
          this.isLoading = false;
        },
        // Managing getUsersByAppeazError
        error => {
          if (error.status === 401) {
            // Session expired
            this.errorReport = new EazlyMessage(AlertType.danger, 'AppeazService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
            this.router.navigate(['/login']);
          }
          if (error.status === 403) {
            // Only an Administrator or a Manager can retrieve Appeaz Info
            const errorReport = new EazlyMessage(AlertType.danger, 'AppeazService',
              MessageCause.InsufficientRoleForGetUsersByAppeaz, error.status + ' ' + error.statusText);
            this.messagingService.notifyError(errorReport);
          }
          if (error.status === 404) {
            this.errorReport = new EazlyMessage(AlertType.danger, 'AppeazService', MessageCause.AppeazNotFound, error.status + ' ' + error.statusText);
            this.router.navigate(['/appeazs']);
          }
        });
    });
    this.isAdmin = this.userService.isAdmin();
  }

  ngOnDestroy() {
    if (this.errorReport) {
      this.messagingService.notifyError(this.errorReport);
    }
  }

  handleErrorFromChild(errorReport: EazlyMessage) {
    this.errorReport = errorReport;
  }

  addUser() {
    if (this.emailToInvite !== undefined && this.emailToInvite.length > 0) {
      this.userService.getUserByEmail(this.emailToInvite, this.myappeaz.id).subscribe(user => {
        this.logger.debug('[Invite user to an appeaz/Get a user informations by email] User informations retrieved by email: "' + this.emailToInvite + '"');
        if (user == null || user.id !== this.userService.getCurrentUser().id) {
          this.userService.inviteUser(this.emailToInvite, this.myappeaz.id).subscribe(newuser => {
            this.logger.debug('[Invite user to an appeaz] User (whose mail: "' + newuser.email + '") invited to the appeaz: "' + this.myappeaz.name + '". Wait for his account validation.');
            this.users.push(newuser);
          },
            // Managing errors for INVITE_USER
            error => {
              if (error.status === 401) {
                this.logger.debug('[Invite user to an appeaz] Unauthorized or JWT expired!');
                this.errorReport = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
                this.router.navigate(['/login']);
              }
              if (error.status === 403) {
                // Only an Administrator or a Manager can invite other users
                this.logger.debug('[Invite user to an appeaz] Forbidden! Only an Administrator or a Manager can invite other users to an appeaz');
                const errorReport = new EazlyMessage(AlertType.danger,
                  'UserService', MessageCause.InsufficientRoleForInvitingUserOnAppeaz,
                  error.status + ' ' + error.statusText);
                this.messagingService.notifyError(errorReport);
              }
              if (error.status === 409) {
                // User already invited
                this.logger.debug('[Invite user to an appeaz] User has already been invited to appeaz');
                const errorReport = new EazlyMessage(AlertType.warning,
                  'UserService', MessageCause.UserAlreadyInvited,
                  error.status + ' ' + error.statusText);
                this.messagingService.notifyError(errorReport);
              }
            });
        }
      },
        // Managing errors for GET_USER_BY_EMAIL
        error => {
          if (error.status === 401) {
            this.logger.debug('[Invite user to an appeaz/Get a user informations by email] Unauthorized or JWT expired!');
            this.errorReport = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
            this.router.navigate(['/login']);
          }
          if (error.status === 403) {
            // Only an Administrator or a Manager can invite other users
            this.logger.debug('[Invite user to an appeaz/Get a user informations by email] Forbidden! Only an Administrator or a Manager can retrieve informations of other users');
            const errorReport = new EazlyMessage(AlertType.danger, 'UserService',
              MessageCause.InsufficientRoleForGetUserByEmail, error.status + ' ' + error.statusText);
            this.messagingService.notifyError(errorReport);
          }
          if (error.status === 404) {
            // User was not found
            this.logger.debug('[Invite user to an appeaz/Get a user informations by email] User not found!');
            const errorReport = new EazlyMessage(AlertType.info, 'UserService', MessageCause.UserNotFound, error.status + ' ' + error.statusText);
            this.messagingService.notifyError(errorReport);
          }
        });
      this.modalWindowComponent.hide();
    }
  }

  searchUsers() {
    jQuery('.search').on('keyup', function (e) {
      jQuery('.nameRow').parent().parent().show();
      const filter = <string>jQuery('.search').val();
      //   $('.usersList').find('.nameRow:not(:contains(' + filter + '))').parent().parent().css('display', 'none');


      const filterString = filter.trim().toLowerCase().split(' ');

      $('.nameRow').parent().parent().show().filter(function () {
        const text = $(this).text().toLowerCase();
        /* eslint-disable @typescript-eslint/prefer-for-of */
        for (let i = 0; i < filterString.length; i++) {
          if (text.indexOf(filterString[i]) < 0) {
            return true;
          }
        }
        /* eslint-enable @typescript-eslint/prefer-for-of */

        return false;
      }).hide();

    });
  }

  showModal() {
    this.modalWindowComponent.show();
  }

  hideModal() {
    this.modalWindowComponent.hide();
  }

  ngAfterViewChecked() {
    this.searchUsers();
  }


  deleteUser(iduser: number) {

    this.appeazService.deleteUser(this.myappeaz.id, iduser).subscribe((response: HttpResponse<string>) => {
      // const appeazDeleted = this.users.find((user) => user.id === id);

      this.users = this.users.filter((user) => user.id !== iduser);
      this.logger.debug('user  deleted');

    },
      // Managing error for Delete user
      error => {
        if (401 === error.status) {
          this.errorReport = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
          this.userService.logout();
          this.router.navigate(['/login']);
        }
        if (403 === error.status) {
          this.errorReport = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.SessionExpired, error.status + ' ' + error.statusText);
          this.userService.logout();
          this.router.navigate(['/login']);
        }
        if (error.status === 404) {
          this.errorReport = new EazlyMessage(AlertType.danger, 'UserService', MessageCause.AppeazNotFound, error.status + ' ' + error.statusText);
          this.router.navigate(['/appeazs']);
        }
      });
  }
}
