export enum DataType {
    BOOLEAN,
    STRING,
    INTEGER,
    DOUBLE,
    ENUM,
    DATE
}
export const frenchDataType = new Map<number, string>([
    [DataType.BOOLEAN, 'une valeur oui/non'],
    [DataType.DATE, 'une date'],
    [DataType.ENUM, 'une liste de valeurs'],
    [DataType.DOUBLE, 'un double'],
    [DataType.STRING, 'un texte'],
    [DataType.INTEGER, 'un entier']
]);

export const englishDataType = new Map<number, string>([
    [DataType.BOOLEAN, 'a true/false value'],
    [DataType.DATE, 'a date'],
    [DataType.ENUM, 'a list of values'],
    [DataType.DOUBLE, 'a double'],
    [DataType.STRING, 'a text'],
    [DataType.INTEGER, 'an integer']
]);
