<eazly-loading [isRunning]="isLoading"></eazly-loading>
<eazly-header [myappeaz]="myappeaz">
</eazly-header>

<div class="main container">
  <eazly-alert-manager></eazly-alert-manager>
  <h2 i18n="@@UsersmanagementTitle">Users management</h2>

  <div *ngIf="users" class="panel-group">
    <div class="row rowHeader">
      <div [className]="isAdmin ? 'col-sm-5' : 'col-sm-6'">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <input type="text" class="search form-control" placeholder="Search for a user"
            i18n-placeholder="@@UsersSearch" name="search" id="search">
        </div>
      </div>
      <div class="col-sm-1">
        <!--span>Testeur &nbsp; Concepteur &nbsp; Livreur &nbsp; Manager</span-->
        <span title="Designer can edit the Appeaz : add, modify and delete dynamic modifications."
          i18n-title="@@UserHeaderDesigner" i18n="@@UserDesigner">Designer</span>
      </div>
      <div class="col-sm-1 colDesign ">
        <!--span>Testeur &nbsp; Concepteur &nbsp; Livreur &nbsp; Manager</span-->
        <span title="Publisher can publish versions." i18n-title="@@UserHeaderPublisher"
          i18n="@@UserPublisher">Publisher</span>
      </div>
      <div class="col-sm-1 colDesign">
        <!--span>Testeur &nbsp; Concepteur &nbsp; Livreur &nbsp; Manager</span-->
        <span title="Manager can edit the users of the Appeaz : invite, delete and change permissions."
          i18n-title="@@UserHeaderManager" i18n="@@UserMAnager">Manager</span>
      </div>
      <div *ngIf="isAdmin" class="col-sm-3 colDesign colExpert">
        <span>Expert</span>
      </div>
    </div>
    <div class="usersList" *ngFor="let user of users">
      <eazly-user-item (eventEmitter)="deleteUser($event)" (errorMesssageEmiter)="handleErrorFromChild($event)"
        [appeaz]="id" [user]="user"></eazly-user-item>
    </div>
  </div>
  <button id="showAddUser" (click)="showModal()" type="button" class="btn btn-primary col-sm-4 btn-lg" i18n="@@AddUser">
    <i class="fa fa-plus-circle" aria-hidden="true"></i>
  </button>

  <eazly-modal-window>
    <div modalWindowHeader i18n="@@UsersModalInviteUsers">
      Invite a User
    </div>
    <div modalWindowBody>
      <form id="userInvitationForm" (ngSubmit)="f.form.valid && addUser()" #f="ngForm" novalidate>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted &&!email.valid }">
          <label class="control-label">
            <span i18n="@@UsersModalInviteEmail">Email:</span>
            <i class="required-info" aria-hidden="true"></i>
          </label>
          <input type="text" id="email" class="form-control" name="email" required [(ngModel)]="emailToInvite" email
            #email="ngModel">
          <div [hidden]="email.valid || email.pristine" class="alert alert-danger">
            <div [hidden]="!email.hasError('required')" i18n="@@UsersModalInviteEmailRequired">Email is required</div>
            <div [hidden]="!email.errors?.email" i18n="@@UsersModalInviteEmailInvalid">Email format is invalid
            </div>
          </div>
        </div>
      </form>
    </div>
    <div modalWindowFooter>
      <button (click)="hideModal()" type="button" class="btn btn-lg " i18n="@@Cancel">
        <i class="fas fa-long-arrow-alt-left"></i> Cancel</button>
      <button id="Invite" type="submit" form="userInvitationForm" class="btn btn-lg " i18n="@@UsersModalInviteUsersAdd">
        <i class="fas fa-check"></i> Invite</button>
    </div>
  </eazly-modal-window>
