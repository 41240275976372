import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RatingModule } from 'ngx-bootstrap/rating';
import { AppRoutingModule } from '../../eazly.routing';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../other/footer/footer.module';
import { LoadingModule } from '../other/loading/loading.module';
import { AlertManagerModule } from '../tools/alert-manager/alert-manager.module';
import { ModalWindowModule } from '../tools/modal-window/modal-window.module';
import { UserItemComponent } from './user-item/user-item.component';
import { UsersComponent } from './users.component';

@NgModule({
    declarations: [
        UsersComponent,
        UserItemComponent],
    imports: [
        AlertManagerModule,
        AppRoutingModule,
        CommonModule,
        FooterModule,
        FormsModule,
        HeaderModule,
        ModalWindowModule,
        LoadingModule,
        ReactiveFormsModule,
        RouterModule,
        RatingModule.forRoot()
    ],
    bootstrap: [UsersComponent]
})
export class UsersModule { }
