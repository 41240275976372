import { Component, HostBinding, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { browsers } from '../tools/browser-version';
import { AlertType, EazlyMessage, MessageCause } from '../tools/services/messaging/eazly-message';
import { MessagingService } from '../tools/services/messaging/messaging.service';
@Component({
  selector: 'eazly-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})


export class AuthenticationComponent implements OnInit {

  @HostBinding('class') class = 'page-content';
  deviceInfo = null;

  constructor(private deviceService: DeviceDetectorService, private messagingService: MessagingService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  ngOnInit() {
    const issupported: boolean = this.isSupportedBrowser();
    if (issupported === false) {
      const errorReport = new EazlyMessage(AlertType.danger, 'Home', MessageCause.BrowserNotSupported, '');
      this.messagingService.notifyError(errorReport);
    }
  }

  isSupportedBrowser() {
    for (const browser of browsers) {
      if (browser.name.toLowerCase() === this.deviceInfo.browser.toLowerCase()) {
        const version = +   this.deviceInfo.browser_version.substring(0, 4);
        if (browser.version <= version) {
          return true;
        }
      }
    }
    return false;
  }
}
