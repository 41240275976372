
export class DataDescriptor {
    id: string;
    name: string;
    datatype: string;

    /**
     * This method returns a boolean indicating if the dataDescriptor is included in the array.
     *
     * Contrary to the native 'includes' method, this method does not compare
     * the references of the objects and uses the values 'name' and 'datatype' instead.
     *
     * @param dataDictionnary The list of DataDescriptor to check
     * @returns true if the DataDescriptor is included in the array or false otherwise
     */
    public isIncluded(dataDictionnary: DataDescriptor[]): boolean {
        let isIncluded = false;
        for (const dataDescriptor of dataDictionnary) {
            if (dataDescriptor.name === this.name
                && dataDescriptor.datatype === this.datatype) {
                isIncluded = true;
            }
        }
        return isIncluded;
    }
}
