import { Locale } from './locale';

export class LabelTranslation {
    label: string;
    countryCode: string;
    locale: string;

    constructor(locale: Locale, label: string) {
        this.locale = locale?.languageCode;
        this.countryCode = locale?.countryCode;
        this.label = label;
    }
}
