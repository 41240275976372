<fieldset class="container-shadow">
  <div class="panel-heading active">
    <h4 class="panel-title">
      <a data-toggle="collapse" href="#collapse{{appeaz.id}}" type="button"
        class="btn btn-lg appeaz-title btn-block">{{appeaz.name}}</a>
    </h4>
  </div>
  <div id="collapse{{appeaz.id}}" class="panel-collapse collapse show" attr.data-appeaz="{{appeaz.id}}">
    <div class="btn-group-horizontal">
      <a *ngIf="isConfiguration" id="configuration" routerLink="/appeaz/{{appeaz.id}}" type="button"
        class="btn btn-primary btn-lg" i18n="@@MyAppeazsConfiguration">
        <i class="fas fa-cog"></i> Configuration</a>
      <a *ngIf="isUsers" id="userManagement" routerLink="/users/{{appeaz.id}}" type="button"
        class="btn btn-primary btn-lg" i18n="@@MyAppeazsUsersManagement"> <i class="fas fa-user"></i> Users
        Management</a>
      <a *ngIf="isPublish" id="PublishAppeaz" routerLink="/publish/{{appeaz.id}}" type="button"
        class="btn btn-primary btn-lg" i18n="@@MyAppeazsPublish"> <i class="fas fa-cloud-upload-alt"></i>
        Publish</a>
      <a *ngIf="isEdit" routerLink="/editor/{{appeaz.id}}" type="button" class="btn btn-primary btn-lg"
        i18n="@@MyAppeazsStudio">
        <i class="fas fa-pencil-alt"></i> Studio</a>
      <!--a routerLink="/editor/{{appeaz.id}}" type="button" class="btn btn-primary btn-lg btn-appeaz" i18n="@@MyAppeazsTest">
        <i class="fas fa-play"></i> Test</a-->
    </div>
  </div>
</fieldset>
