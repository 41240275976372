import { Observable } from 'rxjs';
import { StepDynamicModifications } from '../../models/step-dynamic-Modifications';
import { ImportExportService } from '../../services/import-export.service';
import { Action } from './action';
import { ImportOperation } from './operations/import-operation';
import { Operation } from './operations/operation';


export class ImportAction extends Action {

    name: string;
    importExportService: ImportExportService;

    protected operation: Operation;
    protected reverseOperation: Operation;

    private readonly appeazUrl: string;
    private readonly stepsToImport: StepDynamicModifications[];
    constructor(filename: string, importExportService: ImportExportService, appeazUrl: string, stepsToImport: StepDynamicModifications[]) {
        super();
        this.name = filename;
        this.importExportService = importExportService;
        this.appeazUrl = appeazUrl;
        this.stepsToImport = stepsToImport;
        this.operation = new ImportOperation(this.importExportService, this.name, this.appeazUrl, this.stepsToImport);
    }

    buildReverseOperation(dataForUndo: StepDynamicModifications[]) {
        this.reverseOperation = new ImportOperation(this.importExportService, this.name, this.appeazUrl, dataForUndo);
    }

    do(): Observable<any> {
        return super.do();
    }

    undo(): Observable<any> {
        return super.undo();
    }
}
