<eazly-header i18n-headerText="@@HeaderAbout" headerText="About eazly"></eazly-header>

<div class="main container">
  <div id="versionInformation">
    <h3 i18n='@@versionTitle'>Version</h3>
    <div i18n='@@eazlyVersionDescription'>
      Eazly Editor version {{eazlyVersion}}
    </div>
  </div>

  <div id="termsOfService">
    <h3 i18n='@@termsOfServiceTitle'> Terms of service </h3>
    <div i18n='@@termsOfServiceDescription'>You can download the terms of service using the following link:</div>
    <a id="downloadTOSButton" download="./Softeam_SaaS_ContratDeService_eazly_V1.0.pdf" class="btn btn-lg"
      href='{{termsOfServiceFileUrl}}' i18n="@@download">
      <i class='fa fa-download'></i> Download</a>
  </div>
</div>
