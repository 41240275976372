import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eazly-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})

export class AuthHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  changeLocation() { }
}
