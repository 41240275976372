import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../tools/models/user';
import { AlertType, EazlyMessage, MessageCause } from '../tools/services/messaging/eazly-message';
import { MessagingService } from '../tools/services/messaging/messaging.service';
import { UserService } from '../tools/services/user.service';

@Component({
  selector: 'eazly-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  model: any = {};
  account: User;
  errorMessage: EazlyMessage;
  token: any;

  constructor(private readonly messagingService: MessagingService, private readonly router: Router,
    private readonly route: ActivatedRoute, private readonly userService: UserService) { }

  ngOnInit(): void {
    this.account = new User();
    this.token = this.route.snapshot.paramMap.get('token');
  }
  ngOnDestroy() {
    if (this.errorMessage) {
      this.messagingService.notifyError(this.errorMessage);
    }
  }
  returnToLoginPage() {
    this.router.navigate(['/login']);
  }

  onSubmit() {
    if (this.account.password === this.model.cpassword) {
      this.userService.updateUserPassword(this.account.password, this.token).subscribe(
        data => {
          this.errorMessage = new EazlyMessage(AlertType.success, 'BackendService', MessageCause.PasswordUpdateSuccessful, '');
          this.returnToLoginPage();
        },
        httpErrorResponse => {
          switch (httpErrorResponse.status) {
            case 400:
              if (httpErrorResponse.error.exception === 'com.eazly.backend.exceptions.PasswordTooWeakException') {
                this.messagingService.notifyError(new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.PasswordTooWeak, ''));
              } else {
                this.messagingService.notifyError(new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.InvalidToken, ''));
              }
              break;
            case 404:
              this.messagingService.notifyError(new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.InvalidToken, ''));
              break;
            default:
              this.messagingService.notifyError(new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.InternalError, ''));
              break;
          }
        });
    } else {
      this.messagingService.notifyError(new EazlyMessage(AlertType.danger, 'BackendService', MessageCause.PasswordDoesNotMatch, ''));
    }
  }
}
